<template>
  <div class="department">
    <el-row class="fit" :gutter="14" type="flex">
      <el-col class="r-flex-1">
        <r-panel>
          <!-- <template v-slot:title>
            <el-button-group>
              <el-button :type="type == 'area' ? 'primary' : ''" size="mini" round
                @click="typeChange('area')">区域</el-button>
              <el-button :type="type == 'dep' ? 'primary' : ''" size="mini" round
                @click="typeChange('dep')">部门</el-button>
            </el-button-group>
          </template> -->

          <template v-slot:toolbar>
            <el-button icon="el-icon-plus" size="mini" type="primary" @click="addDep"
                       v-if="type == 'dep'">新增
            </el-button>
          </template>

          <template v-if="type == 'dep'">
            <div class="search r-flex r-pb-10">
              <div class="r-flex-1">
                <el-input placeholder="请输入搜索内容" v-model="treeKeyword" size="small" @input="treeKeywordChange"
                          class="input-box">
                  <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
              </div>
              <el-checkbox v-model="isHideDep" class="sub-dep" @change="handleHideDep">包含隐藏部门
              </el-checkbox>
            </div>
            <el-tree ref="tree" :data="depTree" node-key="id" highlight-current :expand-on-click-node="false"
                     :default-expand-all="false" :default-expanded-keys="defaultExpandedKeys" :props="{ label: 'name' }"
                     @current-change="handleCurrentChange" :filter-node-method="filterNode" v-loading="treeLoading"
                     @node-expand="nodeExpand" @node-collapse="nodeCollapse">
              <div class="custom-tree-node r-flex  r-row-bet" slot-scope="{ node, data }" @mouseover="mouseOver"
                   @mouseleave="mouseLeave">
                <div class="department-content" :class="{ is_hide: data.isHide === 1 }">
                  {{ node.label }}
                  <span class="dep-num" v-if="node.data.employeeNum > 0">{{ node.data.employeeNum }}</span>
                  <span class="director">
                    <span v-for="(item, index) in data.director" class="director-item" :key="index">
                      <i class="fxy-icon-user"></i>{{ item.name }}
                    </span>
                  </span>
                  <el-tag size="mini" v-if="data.isHide === 1">
                    已隐藏
                  </el-tag>
                </div>
                <div class="custom-tree-btn" :class="{ show: btnShow }">
                  <el-button type="text" size="mini" icon="el-icon-edit" class="btn"
                             @click.stop="() => editDep(data, node)">
                  </el-button>
                  <el-button type="text" size="mini" class="btn" @click.stop="() => handleHide(data)">
                    <i class="fxy-icon-yanjing_yincang_o hide-icon" v-if="data.isHide === 0"></i>
                    <i class="fxy-icon-yanjing_xianshi_o hide-icon" v-if="data.isHide === 1"></i>
                  </el-button>
                </div>
              </div>
            </el-tree>
          </template>
          <template v-else>
            <div class="search r-flex r-pb-10">
              <div class="r-flex-1">
                <el-input placeholder="请输入区域名称" v-model="areaTreeKeyword" size="small" @input="areaTreeKeywordChange"
                          class="input-box">
                  <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
              </div>
            </div>
            <el-tree ref="areaTree" :data="areaTree" node-key="id" highlight-current :expand-on-click-node="false"
                     :default-expand-all="false" :props="{ label: 'name' }" :filter-node-method="filterNode"
                     v-loading="areaTreeLoading" :default-expanded-keys="areaDefaultExpandedKeys">
            </el-tree>
          </template>
        </r-panel>
      </el-col>
      <el-col class="table-cover">
        <r-panel title="当前节点：">

          <template v-slot:title>
            <div v-if="currentNode">
              <el-tag size="small">{{ currentNode.name }}{{
                  currentNode.employeeNum ? ` ${currentNode.employeeNum}人` :
                      ''
                }}
              </el-tag>
              <el-checkbox v-model="subDep" class="sub-dep" @change="handleSupDep">包含子级
              </el-checkbox>
            </div>
          </template>

          <template v-slot:toolbar>
            <div class="r-flex" v-if="currentNode">
              <el-button icon="el-icon-search" size="mini" @click="searchOpen"></el-button>
              <el-button size="mini" type="primary" @click="importExcel">导入</el-button>
              <el-button size="mini" type="primary" @click="exportExcel">导出</el-button>
              <el-button size="mini" type="primary" @click="rolesOpen" v-if="$has('framework-role')">角色权限管理</el-button>
              <el-button icon="el-icon-plus" size="mini" type="primary" @click="addEmployee"
                         v-if="$has('framework-employee-create')">新增员工
              </el-button>
            </div>
          </template>
          <r-table height="100%" :columns="columns" :loader="loader" ref="table">

            <template v-slot:handle="{ row }">
              <el-link type="primary" class="small" @click="editEmployee(row)" v-if="$has('framework-employee-edit')">
                编辑
              </el-link>
              <el-divider direction="vertical"
                          v-if="$has('framework-employee-role-edit') && $has('framework-employee-edit')"></el-divider>
              <el-link type="primary" class="small" @click="setRole(row)" v-if="$has('framework-employee-role-edit')">
                角色
              </el-link>
              <el-divider direction="vertical"
                          v-if="$has('framework-employee-delete') && ($has('framework-employee-role-edit') || $has('framework-employee-edit'))"></el-divider>
              <el-link type="info" class="small" @click="delEmployee(row)" v-if="$has('framework-employee-delete')">删除
              </el-link>
            </template>
          </r-table>
        </r-panel>
      </el-col>
    </el-row>
    <update-dep ref="updateDep" @success="getDepByTree"></update-dep>
    <update-employee ref="updateEmployee" @success="refresh"></update-employee>
    <search ref="search" @depChange="setDep"></search>
    <roles ref="roles"></roles>
    <set-role ref="setRole"></set-role>
    <import-excel
        v-model="dialogImportExcel"
        type="TYPE_OWN"
        @refresh="refresh"
    ></import-excel>
  </div>
</template>

<script>
import {uniq} from "@/utils";
import employee from "@/mixins/employee";
import {
  getDepByTree,
  setHideDepartment,
  getDepartmentDetail,
} from "@/api/dep/index";
import {fetchList as getStaffList} from "@/api/staff/index";
import updateDep from "./components/update-dep";
import updateEmployee from "./components/update-employee";
import {getCityList} from "@/api/common";
import {delList, exportList} from "@/api/employee/index";
import search from "./components/search";
import roles from "./components/roles";
import setRole from "./components/set-role";
import fileDownload from "js-file-download";
import importExcel from "@/components/excel/importExcel.vue";

export default {
  mixins: [employee],
  components: {updateDep, updateEmployee, search, roles, setRole, importExcel},
  data() {
    return {
      type: 'dep',
      treeKeyword: '',
      areaTreeKeyword: '',
      isHideDep: false,
      depTree: [],
      areaTree: [],
      defaultExpandedKeys: [],
      defaultExpandedKeysBak: [],
      areaDefaultExpandedKeys: [],
      treeLoading: false,
      areaTreeLoading: false,
      department: "",
      currentNode: null,
      subDep: false,
      keyword: '',
      exportLoading: false,
      dialogImportExcel: false,
      columns: [
        {
          label: '姓名',
          prop: 'nickname',
          'min-width': '80px',
          'show-overflow-tooltip': true
        }, {
          label: '部门',
          prop: 'dep.name',
          'min-width': '120px',
          'show-overflow-tooltip': true
        }, {
          label: '职位',
          prop: 'positionName',
          'min-width': '120px',
          'show-overflow-tooltip': true
        }, {
          label: '角色',
          prop: 'roleShow',
          'min-width': '120px',
          'show-overflow-tooltip': true
        }, {
          label: '备注',
          prop: 'remarks',
          'min-width': '300px',
          'show-overflow-tooltip': true
        }, {
          label: '操作',
          prop: 'handle',
          'width': '140px',
          fixed: "right"
        }
      ]
    }
  },
  methods: {
    areaTreeKeywordChange(val) {
      this.$refs.areaTree.filter(val);
    },
    treeKeywordChange(val) {
      this.$refs.tree.filter(val);
    },
    handleHideDep() {
      this.getDepByTree();
    },
    handleCurrentChange(data, node) {
      this.expandedKeys = [data.id];
      this.currentNode = node.data;
      this.$refs.table.refresh(1);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    //节点展开
    nodeExpand(data) {
      this.defaultExpandedKeysBak.push(data.id);
      this.defaultExpandedKeysBak = uniq(this.defaultExpandedKeysBak);
    },
    //节点关闭
    nodeCollapse(data) {
      this.defaultExpandedKeysBak = this.defaultExpandedKeysBak.filter((el) => {
        return el != data.id;
      });
    },
    getDepByTree() {
      this.treeLoading = true;
      return getDepByTree({
        type: 1,
        formType: "system",
        isHideDep: this.isHideDep,
      }).then((response) => {
        if (response.data.length > 0) {
          if (!this.currentNode) {
            this.currentNode = response.data[0];
            this.$nextTick(() => {
              this.$refs?.tree?.setCurrentKey && this.$refs.tree.setCurrentKey(this.currentNode.id);
            })
          }
          if (this.defaultExpandedKeysBak.length == 0) {
            this.defaultExpandedKeys = [response.data[0].id];
          } else {
            this.defaultExpandedKeys = this.defaultExpandedKeysBak;
          }
        }
        this.depTree = response.data;
        this.treeLoading = false;
      });
    },
    //新建部门
    addDep() {
      this.$refs.updateDep.open();
    },
    //编辑
    editDep(data) {
      this.$refs.updateDep.open({
        id: data.id,
        name: data.name
      });
    },
    //隐藏部门
    handleHide(node) {
      this.$confirm(
          "此操作要" +
          (node.isHide === 1 ? "显示" : "隐藏") +
          "该部门, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(() => {
        setHideDepartment({id: node.id}).then((response) => {
          this.getDepByTree();
          this.$message.success((node.isHide === 1 ? "显示" : "隐藏") + "成功");
        });
      }).catch(err => {
      });
    },
    //员工子部门切换
    handleSupDep() {
      this.$refs.table.refresh(1);
    },
    refresh() {
      this.$refs.table.refresh();
    },

    //员工列表请求
    async loader(page, pageSize) {
      if (!this.currentNode) {
        await this.getDepByTree();
      }
      let pid = this.currentNode.id;
      return getStaffList({
        department: pid,
        form: "system",
        subDep: this.subDep ? 1 : 0,
        page: page,
        pageSize: pageSize,
        keyword: this.keyword
      }).then((response) => {
        if (this.currentNode?.id == pid) {
          //此时得更新数据了
          this.getDepartmentDetail(pid)
        }
        return response.data;
      });
    },
    getDepartmentDetail(id) {
      getDepartmentDetail({id}).then(res => {
        //再次判断
        if (this.currentNode?.id == id) {
          this.currentNode.employeeNum = res.data.employeeCount;
        }
      })
    },
    //新增员工
    addEmployee() {
      this.$refs.updateEmployee.open(
          {
            department: this.currentNode ? [this.currentNode] : [],
            areaId: this.$store.state.userInfo?.company?.areaId == '00000000-0000-0000-0000-000000000000' || !this.$store.state.userInfo?.company?.areaId ? '' : this.$store.state.userInfo?.company?.areaId
          }
      );
    },
    //编辑员工
    editEmployee(row) {
      this.$refs.updateEmployee.open({
        employeeId: row.id,
        nickname: row.nickname,
        areaId: row.areaId,
        phone: row.phone,
        hidePhone: row.hidePhone,
        remarks: row.remarks,
        positionName: row.positionName,
        department: [row.dep],
        position: row.position,
        roleData: row.employeeRole?.length> 0 ? row.employeeRole.map(el=>el.roleId) : []
      }, true);
    },
    //删除员工
    delEmployee(row) {
      this.$confirm(
          `确定要删除员工 ${row.nickname}吗？`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(() => {
        delList({employee_id: row.id}).then((response) => {
          this.refresh();
          this.$message.success("删除成功");
        });
      }).catch(err => {
      });
    },
    typeChange(type) {
      this.type = type;
    },
    getCityList() {
      this.areaTreeLoading = true;
      getCityList().then(res => {
        //默认展开两级
        res.data.forEach(el => {
          this.areaDefaultExpandedKeys.push(el.id);
          el.children && el.children.forEach(_el => {
            this.areaDefaultExpandedKeys.push(_el.id);
          })
        })
        this.areaTree = res.data;
      }).finally(() => {
        this.areaTreeLoading = false;
      })
    },
    employeeSearch() {
      this.$refs.table.refresh(1);
    },
    setDep(id) {
      this.$refs.tree.setCurrentKey && this.$refs.tree.setCurrentKey(id);
      //获取当前节点
      this.currentNode = this.$refs.tree.getCurrentNode();
      this.$nextTick(() => {
        this.handleSupDep();
      })
    },
    searchOpen() {
      this.$refs.search.open();
    },
    rolesOpen() {
      this.$refs.roles.open();
    },
    setRole(row) {
      this.$refs.setRole.open({
        dialogTitle: '设置角色',
        isEdit: true,
        id: row.id
      });
    },
    exportExcel() {
      this.exportLoading = true;
      exportList({
        ...this.query,
      }).then((res) => {
        fileDownload(res, "组织架构.xlsx");
      }).finally((res) => {
        this.exportLoading = false;
      });
    },
    importExcel() {
      this.dialogImportExcel = true
    }
  },
  mounted() {
    this.getCityList();
  }
}
</script>

<style lang="scss" scoped>
.department {
  height: 100%;

  .fit {
    height: 100%;
  }

  .table-cover {
    flex: 2.2;
    width: 0;
  }

  ::v-deep .sub-dep {
    margin-left: 20px;

    .el-checkbox__label {
      padding-left: 4px;
    }
  }

  .custom-tree-node {
    flex: 1;
    font-size: 14px;
    padding-right: 8px;
  }

  .dep-num {
    background-color: #3f85f4;
    color: #ffffff;
    font-size: 12px;
    min-width: 34px;
    display: inline-block;
    height: 18px;
    text-align: center;
    line-height: 18px;
    margin: 0 6px;
    border-radius: 3px;
  }


  .custom-tree-btn {
    visibility: hidden;
    padding-left: 10px;

    .btn {
      font-size: 16px;
    }

    &.show {
      visibility: visible;
    }
  }

  .hide-icon {
    font-size: 20px;
  }

}
</style>