import {isArray} from "@/utils";
export default{
    methods: {
        //添加报销选项
        addReimbursementTypeOption(){

            if(!isArray(this.activeData?.reimbursementTypeOptions)) return;
            this.activeData.reimbursementTypeOptions.push({label:'',value:''});
        },

        removeReimbursementTypeOption(index){
            if(!isArray(this.activeData?.reimbursementTypeOptions)) return;
            this.activeData.reimbursementTypeOptions.splice(index,1);
        }

    },


}
