import request from '@/utils/request'

export function list(data) {
  return request({
    url: `/api/indicator/list?page=${data.page}&pageSize=${data.pageSize}`,
    method: 'post',
    data
  })
}

export function exportList(data) {
  return request({
    url: `/api/indicator/list?page=${data.page}&pageSize=${data.pageSize}`,
    method: 'post',
    data,
    responseType: 'blob',
    timeout: 60000
  })
}

export function add(data) {
  return request({
    url: '/api/indicator/save',
    method: 'post',
    data
  })
}

export function detail(data) {
  return request({
    url: '/api/indicator/detail',
    method: 'get',
    params: data
  })
}

export function del(data) {
  return request({
    url: '/api/indicator/delete',
    method: 'post',
    data
  })
}

export function delTemplate(data) {
  return request({
    url: '/api/indicator/del-template',
    method: 'post',
    data
  })
}

export function enums(data) {
  return request({
    url: '/api/indicator/parent-list',
    method: 'get',
    params: data
  })
}

export function template(data) {
  return request({
    url: '/api/indicator/fields',
    method: 'get',
    params: data
  })
}

export function updateTemplate(data) {
  return request({
    url: '/api/indicator/edit-fields',
    method: 'post',
    data
  })
}

export function weightSave(data) {
  return request({
    url: '/api/indicator/weight-save',
    method: 'post',
    data
  })
}

//获取自动评分详情
export function getScoreRule(data) {
  return request({
    url: '/api/company/get-score-rule',
    method: 'post',
    data
  })
}

//修改指标状态
export function statusSave(data) {
  return request({
    url: '/api/indicator/status-save',
    method: 'post',
    data
  })
}

//设置自动评分
export function editScoreRule(data) {
  return request({
    url: '/api/company/edit-score-rule',
    method: 'post',
    data
  })
}

//指标状态列表
export function statusList(data) {
  return request({
    url: '/api/indicator/status-list',
    method: 'post',
    data
  })
}