<template>
    <div class="task-name">
        任务协作部门
    </div>
    </template>
    <script>
    
    export default {
        name:'taskCollaborationDep'
    
    
    }
    </script>
    
    <style lang="scss" scoped>
        .task-name{
            font-weight: bold;
        }
    </style>