import { isObject, cloneDeep, readObjectNodes } from "@/utils"
import Vue from "vue"
/**
 * 审批流程store
 */

const state = {
  suiteLock: false,
  scoreLock: false,
  //审批流程表单设计
  group: [],
  leaveType: [],
  bankAccount: [],
  expenseType: [],
  salaryArchiveTemplate: [],
  departmentList: [],
  expenseTypeList: [],
  //编辑状态
  editStatus: {
    //流程相关包含分管领导、审批人、评分人、及流程的修改状态
    workflow: true,
    //计划开始时间
    startDate: true,
    //计划结束时间,当值为add时，表示只能增加，不能减少
    endDate: true,
    //逾期空间,当值为add时，表示只能增加，不能减少
    overdueValidDay: true
  },
  form: {
    formGennerator: [],
    info: {
      "createTask": '',
      "name": '',
      "parentName": '',
      "date": [],
      "createRule": 1,
      "overdueValidDay": 0,
      "personId": [],
      "personDepId": [],
      "collaborator": [],
      "collaborationDep": [],
      "indicatorDate": '',
      "taskLeader": [],
      "taskReviewer": [],
      "taskScorePerson": [],
      "ruleEnd": 0,
      "ruleStart": 0,
      "batchList": [],
      "taskFilingsPersonMany": []
    },
    workflow: {
      "directorMaxLevel": 6,
      "flowPermission": [1],
      "nodeConfig": {
        "pkId": "sid-start-node",
        "nodeName": "发起人",
        "type": 0,
        "priorityLevel": 0,
        "setType": 0,
        "selectMode": 0,
        "selectRange": 0,
        "examineRoleId": 0,
        "directorLevel": 0,
        "examineMode": 0,
        "noHanderAction": 0,
        "examineEndType": 0,
        "examineEndRoleId": 0,
        "examineEndDirectorLevel": 0,
        "ccSelfSelectFlag": 0,
        "conditionList": [],
        "nodeUserList": [],
        "conditionNodes": [],
      }
    }
  },
  //缓存审判单列表数据
  applyFormList: null,
  //缓存审批单数据
  applyFormData: null,
  //缓存的审批单id
  applyFormId: null
}

const mutations = {

  /**
   * 设置表单数据
   * @param {Object} options ,传入一个对象
   */
  setApproveFormData(state, data) {

    if (!isObject(data)) {
      console.error('data 必须要为一个对象');
      return;
    }

    Object.keys(data).forEach(el => {
      Vue.set(state.form, el, data[el]);
    });
  },

  approveSetData(state, data) {

    if (!isObject(data)) {
      console.log('data 必须为一个对象');
      return;
    }
    // 通过正则表达式  查找路径数据
    Object.keys(data).forEach(el => {
      const regex = /([\w$]+)|\[(:\d)\]/g
      const patten = el.match(regex);
      let result = state // 指向调用的数据 如data
      // 遍历路径  逐级查找  最后一级用于直接赋值
      for (let i = 0; i < patten.length - 1; i++) {
        const key = patten[i]
        result = result[key]
      }
      result[patten[patten.length - 1]] = data[el]

    });
  },


  initApproveFormData(state, data) {
    state.form = data;
  },

  group(state, data) {
    state.group = data;
  },

  leaveType(state, data) {
    state.leaveType = data;
  },

  bankAccount(state, data) {
    state.bankAccount = data;
  },

  expenseType(state, data) {
    state.expenseType = data;
  },

  salaryArchiveTemplate(state, data) {
    state.salaryArchiveTemplate = data;
  },

  departmentList(state, data) {
    state.departmentList = data;
  },

  expenseTypeList(state, data) {
    state.expenseTypeList = data;
  },

  //重置当前的form表单
  resetApproveForm(state, data) {
    state.form = {
      formGennerator: [],
      info: {
        "createTask": '',
        "name": '',
        "parentName": '',
        "date": [],
        "createRule": 1,
        "overdueValidDay": 0,
        "personId": [],
        "personDepId": [],
        "collaborator": [],
        "collaborationDep": [],
        "indicatorDate": '',
        "taskLeader": [],
        "taskReviewer": [],
        "taskScorePerson": [],
        "ruleEnd": 0,
        "ruleStart": 0,
        "batchList": [],
        "taskFilingsPersonMany": [],
        "defaultBatchItem": {
          "date": [],
          "createRule": 1,
          "overdueValidDay": 0,
          "personId": [],
          "personDepId": [],
          "collaborator": [],
          "collaborationDep": [],
          "indicatorDate": '',
          "taskLeader": [],
          "taskReviewer": [],
          "taskScorePerson": [],
          "ruleEnd": 0,
          "ruleStart": 0,
          "workflow": []
        }
      },
      workflow: {
        "directorMaxLevel": 6,
        "flowPermission": [1],
        "nodeConfig": {
          "pkId": "sid-start-node",
          "nodeName": "发起人",
          "type": 0,
          "priorityLevel": 0,
          "setType": 0,
          "selectMode": 0,
          "selectRange": 0,
          "examineRoleId": 0,
          "directorLevel": 0,
          "examineMode": 0,
          "noHanderAction": 0,
          "examineEndType": 0,
          "examineEndRoleId": 0,
          "examineEndDirectorLevel": 0,
          "ccSelfSelectFlag": 0,
          "conditionList": [],
          "nodeUserList": [],
          "conditionNodes": []
        }
      }
    };
    state.editStatus = {
      workflow: true,
      startDate: true,
      endDate: true,
      overdueValidDay: true
    },
      state.scoreLock = false;
  },
  //初始化批量列表
  resetApproveBatchForm(state) {
    state.form.info.batchList = [];
    state.form.info.batchList.push(cloneDeep(state.form.info.defaultBatchItem))
  },
  //新增批量元素
  addTaskItem(state) {
    state.form.info.batchList.push(cloneDeep(state.form.info.defaultBatchItem))
  },
  //删除批量元素
  delTaskItem(state, index) {
    state.form.info.batchList.splice(index, 1);
  },
  //是否锁住套件
  setSuiteLock(state, lock) {
    state.suiteLock = lock;
  },
  //是否评分人
  setScoreLock(state, lock) {
    state.scoreLock = !!lock;
  },
  //生成流程
  updatePropertyValue(state) {
    let obj = {
      "directorMaxLevel": 6,
      "flowPermission": [1],
      "nodeConfig": {
        "pkId": "sid-start-node",
        "nodeName": "发起人",
        "type": 0,
        "priorityLevel": 0,
        "setType": 0,
        "selectMode": 0,
        "selectRange": 0,
        "examineRoleId": 0,
        "directorLevel": 0,
        "examineMode": 0,
        "noHanderAction": 0,
        "examineEndType": 0,
        "examineEndRoleId": 0,
        "examineEndDirectorLevel": 0,
        "ccSelfSelectFlag": 0,
        "conditionList": [],
        "nodeUserList": [],
        "conditionNodes": [],
      }
    };
    let infoData = [{
      "type": 1,
      "level": 1,
      "nodeUserList": [],
      "nodeName": "审核人",
      "priorityLevel": 0,
      "setType": 1,
      "selectMode": 0,
      "selectRange": 0,
      "directorLevel": 1,
      "examineMode": 1,
      "noHanderAction": 1,
      "examineEndDirectorLevel": 0,
      "ccSelfSelectFlag": 0,
      "nodeUserList": state.form?.info.taskReviewer,
    },
    {
      "type": 5,
      "level": 1,
      "nodeUserList": [],
      "nodeName": "评分人",
      "priorityLevel": 0,
      "setType": 1,
      "selectMode": 0,
      "selectRange": 0,
      "directorLevel": 1,
      "examineMode": 1,
      "noHanderAction": 1,
      "examineEndDirectorLevel": 0,
      "ccSelfSelectFlag": 0,
      "nodeUserList": state.form?.info.taskScorePerson,
    },
    {
      "type": 2,
      "level": 1,
      "nodeUserList": [],
      "nodeName": "抄送人",
      "priorityLevel": 0,
      "setType": 1,
      "selectMode": 0,
      "selectRange": 0,
      "directorLevel": 1,
      "examineMode": 1,
      "noHanderAction": 1,
      "examineEndDirectorLevel": 0,
      "ccSelfSelectFlag": 0,
      "nodeUserList": state.form?.info.taskLeader,
    }];
    infoData = infoData.filter(el => el.nodeUserList.length > 0 && el.nodeUserList[0].id !== '00000000-0000-0000-0000-000000000000');
    for (let i = 0; i < infoData.length; i++) {
      if (infoData[i + 1]) {
        infoData[i].childNode = infoData[i + 1];
      }
    }
    if (infoData.length > 0) {
      let flowNode = obj.nodeConfig;
      //此处必须判断当前是否为备案任务,即是否存在type == 8的节点
      //查找type == 8 或 type == 9的节点
      let filingsNode = readObjectNodes(state.form?.workflow?.nodeConfig || {}, (item) => {
        return item.type == 9 || item.type == 8;
      });
      if (filingsNode.length > 0) {
        //进一步判断是否存在备案审批人节点
        let checkNode = filingsNode.find(el => el.type == 9);
        flowNode.childNode = infoData[0];
        //写入备案审批人下
        if (checkNode) {
          checkNode.childNode = flowNode;
          state.form.workflow.nodeConfig.childNode = checkNode;
        } else {
          state.form.workflow.nodeConfig.childNode = flowNode;
        }
      } else {
        //只清楚原有的节点
        obj.nodeConfig.childNode = infoData[0];
        state.form.workflow = obj;
      }
    } else {
      let filingsNode = readObjectNodes(state.form?.workflow?.nodeConfig || {}, (item) => {
        return item.type == 9 || item.type == 8;
      });
      if (filingsNode.length > 0) {
        //找出发起人节点清空数据
        let node = readObjectNodes(state.form?.workflow?.nodeConfig || {}, (item) => {
          return item.type == 0;
        });

        if (node.length == 0) return;
        node[0].childNode = null;
      } else {
        state.form.workflow = obj;
      }
    }
  },
  //生成流程
  updateBatchPropertyValue(state, index) {
    if (index == -1) return;
    let obj = {
      "directorMaxLevel": 6,
      "flowPermission": [1],
      "nodeConfig": {
        "pkId": "sid-start-node",
        "nodeName": "发起人",
        "type": 0,
        "priorityLevel": 0,
        "setType": 0,
        "selectMode": 0,
        "selectRange": 0,
        "examineRoleId": 0,
        "directorLevel": 0,
        "examineMode": 0,
        "noHanderAction": 0,
        "examineEndType": 0,
        "examineEndRoleId": 0,
        "examineEndDirectorLevel": 0,
        "ccSelfSelectFlag": 0,
        "conditionList": [],
        "nodeUserList": [],
        "conditionNodes": [],
      }
    };
    let infoData = [{
      "type": 1,
      "level": 1,
      "nodeUserList": [],
      "nodeName": "审核人",
      "priorityLevel": 0,
      "setType": 1,
      "selectMode": 0,
      "selectRange": 0,
      "directorLevel": 1,
      "examineMode": 1,
      "noHanderAction": 1,
      "examineEndDirectorLevel": 0,
      "ccSelfSelectFlag": 0,
      "nodeUserList": state.form?.info.batchList[index].taskReviewer,
    },
    {
      "type": 5,
      "level": 1,
      "nodeUserList": [],
      "nodeName": "评分人",
      "priorityLevel": 0,
      "setType": 1,
      "selectMode": 0,
      "selectRange": 0,
      "directorLevel": 1,
      "examineMode": 1,
      "noHanderAction": 1,
      "examineEndDirectorLevel": 0,
      "ccSelfSelectFlag": 0,
      "nodeUserList": state.form?.info.batchList[index].taskScorePerson,
    },
    {
      "type": 2,
      "level": 1,
      "nodeUserList": [],
      "nodeName": "抄送人",
      "priorityLevel": 0,
      "setType": 1,
      "selectMode": 0,
      "selectRange": 0,
      "directorLevel": 1,
      "examineMode": 1,
      "noHanderAction": 1,
      "examineEndDirectorLevel": 0,
      "ccSelfSelectFlag": 0,
      "nodeUserList": state.form?.info.batchList[index].taskLeader,
    }];
    infoData = infoData.filter(el => el.nodeUserList.length > 0 && el.nodeUserList[0].id !== '00000000-0000-0000-0000-000000000000');
    for (let i = 0; i < infoData.length; i++) {
      if (infoData[i + 1]) {
        infoData[i].childNode = infoData[i + 1];
      }
    }
    if (infoData.length > 0) {
      obj.nodeConfig.childNode = infoData[0];
      state.form.info.batchList[index].workflow = obj;
    }
  },
  //生成备案任务流程
  updateFilingsPropertyValue(state) {
    //提取出实际的审批节点 type == 0;
    let flowNode = readObjectNodes(state.form?.workflow?.nodeConfig || {}, (item) => {
      return item.type == 0;
    });
    if (flowNode.length == 0) return;
    if (state.form.info.taskFilingsPersonMany.length) {
      //插入新节点
      let node = {
        "type": 9,
        "level": 1,
        "nodeUserList": [],
        "nodeName": "备案审核人",
        "priorityLevel": 0,
        "setType": 1,
        "selectMode": 0,
        "selectRange": 0,
        "directorLevel": 1,
        "examineMode": 1,
        "noHanderAction": 1,
        "examineEndDirectorLevel": 0,
        "ccSelfSelectFlag": 0,
        "nodeUserList": state.form.info.taskFilingsPersonMany,
        "childNode": flowNode[0]
      };
      state.form.workflow.nodeConfig.childNode = node;
    } else {
      state.form.workflow.nodeConfig.childNode = flowNode[0];
    }
  }
}

const actions = {}
const getters = {}

export default {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
