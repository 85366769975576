import Vue from 'vue';
import App from './App.vue';
import {router} from './router';
import store from './store';
import common from "@/mixins/main";
import { Icon,Col,Row,Radio,RadioGroup,Message,MessageBox,Popconfirm,Popover,Dialog,Divider,Checkbox,Input,Select,Option,Button,DropdownItem,DropdownMenu,Dropdown,InputNumber,Upload,Notification,Pagination,Card,Form,FormItem,Tag,Drawer,Image,Loading,Tree,Table,TableColumn,Cascader,Link,ButtonGroup,DatePicker,Tabs,TabPane,Scrollbar,CheckboxGroup,Switch,Collapse,CollapseItem,CheckboxButton,TimePicker,RadioButton,Tooltip,Progress,Timeline,TimelineItem,Alert,Menu,MenuItem,MenuItemGroup,Submenu,Carousel,CarouselItem,Descriptions,DescriptionsItem } from 'element-ui';
import rTagSelect from '@/components/r-theme/tag-select';
import rDrawer from '@/components/r-theme/drawer';
import rDialog from '@/components/r-theme/dialog';
import rEmpty from '@/components/r-theme/empty';
import rPanel from '@/components/r-theme/panel';
import rTable from '@/components/r-theme/table';
import rTooltip from '@/components/r-theme/tooltip';
import initFormGeneratorIcons from '@/components/approve/createForm/formGenerator/icons';
import VueClipboard from 'vue-clipboard2';
import 'normalize.css';
import '@/style/common.scss';
import '@/style/page.scss';
import '@/style/element.scss';
import '@/assets/icon/iconfont.css';
import RIcon from "@/components/icons";
import directives from "@/directives";
//注入指令
Object.keys(directives).forEach(key => {
  Vue.directive(key, directives[key]);
});
//复制用插件
Vue.use(VueClipboard);
//初始化图标文件
initFormGeneratorIcons && initFormGeneratorIcons();
Vue.config.productionTip = false;
const {confirm,alert,prompt}  = MessageBox;
Vue.use(Icon);
Vue.use(RIcon);
Vue.use(Col);
Vue.use(Row);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Popconfirm);
Vue.use(Popover);
Vue.use(Dialog);
Vue.use(Divider);
Vue.use(Checkbox);
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Dropdown);
Vue.use(InputNumber);
Vue.use(Upload);
Vue.use(Pagination);
Vue.use(Card);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Tag);
Vue.use(Image);
Vue.use(Drawer);
Vue.use(Loading);
Vue.use(Tree);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Cascader);
Vue.use(Link);
Vue.use(DatePicker);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Scrollbar);
Vue.use(CheckboxGroup);
Vue.use(Switch);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(CheckboxButton);
Vue.use(TimePicker);
Vue.use(RadioButton);
Vue.use(Tooltip);
Vue.use(Progress);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Alert);
Vue.use(MenuItemGroup);
Vue.use(MenuItem);
Vue.use(Submenu);
Vue.use(Menu);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Descriptions);
Vue.use(DescriptionsItem);
//自定义组件
Vue.use(rTagSelect);
Vue.use(rDrawer);
Vue.use(rEmpty);
Vue.use(rPanel);
Vue.use(rTable);
Vue.use(rTooltip);
Vue.use(rDialog);

Vue.prototype.$message = Message;
Vue.prototype.$confirm = confirm;
Vue.prototype.$alert = alert;
Vue.prototype.$prompt = prompt;
Vue.prototype.$notify = Notification;

Vue.mixin(common)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
