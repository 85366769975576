<template>
  <div class="kpi-wrapper r-page" @click="popCoverHide">
    <div class="r-filters">
      <div class="r-search">
        <el-form size="small" inline @submit.native.prevent>
          <el-form-item>
            <el-input placeholder="请输入分类名称" v-model="query.keyword" @keydown.enter.native="search"
                      clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
            <el-button type="primary" @click="handleAdd({},'新建分类')" icon="el-icon-plus">新增</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="r-content">
      <r-table ref="table" :loader="loader" :columns="tableColumns" height="100%"
               :cell-class-name="cellClassName" @cell-click="detailOpen" row-key="id" @sortChange="sortChange">
        <template v-slot:status="{ row }">
          <el-tag v-if="row.status == 1" size="mini">开启</el-tag>
          <el-tag v-if="row.status == 0" type="danger" size="mini">关闭</el-tag>
        </template>
        <template v-slot:handle="{ row }">
          <el-button type="text" @click="handleAdd(row,'编辑分类')">编辑</el-button>
          <el-divider direction="vertical"></el-divider>
          <el-popconfirm title="确定要删除该任务吗？" @confirm="handleDel(row)">
            <el-link type="primary" slot="reference">删除</el-link>
          </el-popconfirm>
        </template>
      </r-table>
    </div>
    <update-category ref="updateCategoryForm" @confirm="tableRefresh"></update-category>
  </div>
</template>
<script>
import {categoryList, delCategory} from "@/api/article";
import {cloneDeep, isObjectNone} from "@/utils";
import UpdateCategory from "./components/update-category";

const defaultQuery = {
  keyword: ''
}
export default {
  components: {UpdateCategory},
  data() {
    return {
      modalConfig: {},
      dialogVisible: false,
      query: cloneDeep(defaultQuery),
      searchQuery: cloneDeep(defaultQuery),
      popShow: false,
      columns: [
        {
          label: '分类名称',
          prop: 'name',
          'min-width': '300px'
        },
        {
          label: '排序',
          prop: 'sort',
          'min-width': '300px'
        },
        {
          label: '状态',
          prop: 'status',
          'width': '100px'
        }
      ],
      statusColors: {
        1: '#AEAEAE',
        2: '#1D7FFE',
        3: '#1D7FFE',
        4: '#E48000',
        5: '#1E7E00',
        6: '#FF0000'
      },
      statusList: []
    }
  },
  computed: {
    searchActive() {
      return this.query.employee.length > 0 || this.query.department.length > 0 || this.query.date[0] || this.query.date[1];
    },
    tableColumns() {
      const columns = this.columns.map((col, index) => {
        return {
          ...col,
          showOverflowTooltip: true,
          filterMethod: col.filters ? this.filterMethod : null,
        };
      });
      columns.push({
        label: "操作",
        type: "handle",
        prop: "handle",
        width: "160px",
        align: "center",
        fixed: "right",
      });
      return columns;
    },
  },
  methods: {
    loader(page, pageSize) {
      let form = this.getFormData();
      return categoryList({page, pageSize, ...form}).then(res => {
        return res.data;
      })
    },
    refresh(page) {
      this.$refs.table.refresh(page || '');
    },
    search() {
      this.$refs.table.refresh(1);
    },
    handleConfirm(val) {
      this.searchQuery[this.modalConfig['model']] = val;
      this.dialogVisible = false;
    },
    sortChange(order) {
      this.query = Object.assign(this.query, order);
      this.$nextTick(() => {
        this.search();
      })
    },
    detailClose(isUpdate = false) {
      if (isUpdate) {
        this.refresh();
      }
    },
    tableRefresh() {
      this.$refs.table.columnsRefresh();
    },
    popOpen() {
      this.popShow = !this.popShow;
      if (this.popShow) {
        this.searchQuery = cloneDeep(this.query);
      }
    },
    popHide() {
      this.popShow = false;
    },
    popCoverHide() {
      this.popHide();
    },
    searchConfirm() {
      this.popHide();
      this.query = cloneDeep(this.searchQuery);
      this.search();
    },
    getFormData() {
      let form = cloneDeep(this.query);
      return form;
    },
    approveStatusList() {
      approveStatusList().then(res => {
        this.statusList = res.data.map(el => {
          return {
            label: el.label,
            value: el.status
          }
        });
      })
    },
    handleAdd(row, title) {
      this.$refs.updateCategoryForm.open(row, title)
    },
    handleDel(row) {
      delCategory({id: row.id}).then(res => {
        this.$message.success('删除成功');
        this.$refs.table.refresh();
      })
    },
  },
  created() {
    if (!isObjectNone(this.$route.params)) {
      this.query = Object.assign(this.query, this.$route.params);
    }
    this.approveStatusList();
  }

}
</script>

<style lang="scss" scoped>
.kpi-wrapper {
  .item-menu {
    opacity: 0;
    transition: opacity .2s;
    padding: 0px 5px;
    color: var(--primary);
  }

  .sx-btn {
    padding: 7px 15px;

    &.active {
      border-color: var(--primary);
      color: var(--primary);
    }
  }

  .table-expend {
    &.menu-show {
      .item-menu {
        opacity: 1;
      }
    }

    &:hover {
      .item-menu {
        opacity: 1;
      }
    }
  }

  .count-wrapper {
    font-size: 13px;
  }
}
</style>