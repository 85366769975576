<template>
<div class="task-collaborate-employee">
    任务协作人
</div>
</template>
<script>

export default {
    name:"taskCollaborateEmployee"

}
</script>

<style lang="scss" scoped>
.task-collaborate-employee{
    font-weight: bold;
}
</style>