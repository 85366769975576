<template>
<div class="r-Tooltip r-flex" :style="customStyle">
    <span><slot></slot></span>
    <el-tooltip  effect="dark" :content="content" placement="top-start">
        <span class="r-Tooltip-content">
            <r-icon name="iconset0143" type="task" hover color="var(--primary)" size="17"></r-icon>
        </span>
    </el-tooltip>
</div>
</template>
<script>

export default {
    name:"rTooltip",
    props:{
        content:{
            type:String,
            default:''
        },
        customStyle:{
            type:Object,
            default(){
                return {}
            }
        }
    }


}
</script>

<style lang="scss" scoped>
    .r-Tooltip{
        &-content{
            margin-left:8px;
            position: relative;
            top:1px;
        }
    }
</style>
