<template>
    <div class="indicator-detail-header r-flex r-col-top">
        <div class="indicator-detail-header_breadcrumb r-flex-auto-w r-flex-wrap r-flex">
            <div v-for="(tag, index) in tags" :key="index" class="r-flex r-mb-10 r-flex-wrap">
                <div class="indicator-detail-header_breadcrumb--tag hover--opacity" @click="openDetail(tag.id)">{{
                    tag.name }}</div>
                <div class="indicator-detail-header_breadcrumb--grid" v-if="index != tags.length - 1">/</div>
            </div>
            <div class="indicator-detail-header_title r-flex r-w-max r-col-top" :class="{ grid: tags.length > 0 }">
                <span class="sn hover--opacity" v-if="sn" @click="copySn">{{ sn }}</span>
                <span>{{ name }}</span>
            </div>
        </div>
        <div class="indicator-detail-header_toolbar">
            <div class="r-flex r-row-right">
                <div class="u-flex" v-if="statusStr">
                    <span class="r-pr-5">状态：</span>
                    <el-dropdown @command="statusDropdownClick" placement="bottom-end" trigger="click"
                        v-if="isInit">
                        <div class="r-flex r-mr-20 indicator-detail-header_status" v-if="statusStr" :style="{'color':statusColor,'border-color':statusColor}">
                            <r-icon name="share" :color="statusColor" size="16" v-if="statusEdit"></r-icon>
                            <span :class="{'r-pl-5':statusEdit}">{{ statusStr }}</span>
                        </div>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="item.value" v-for="item in statusList" :key="item.value">
                                <span>{{ item.label }}</span>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <div>
                    <el-dropdown @command="dropdownClick" placement="bottom-end" trigger="click"
                        @visible-change="dropdownVisibleChange" v-if="isInit">
                        <r-icon name="more" size="20" border circle pointer hover
                            :color="visible ? 'var(--primary)' : ''"></r-icon>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="add-indicator" v-if="(type == 0 || type == 1) && $has('filings-indicator-create')">
                                <div class="r-flex">
                                    <r-icon name="plus" left="0" right="5" size="14"></r-icon>
                                    新建子指标
                                </div>
                            </el-dropdown-item>
                            <el-dropdown-item command="add-form-template" v-if="type == 0 && $has('filings-indicator-form-template')">
                                <div class="r-flex">
                                    <r-icon name="plus" left="0" right="5" size="14"></r-icon>
                                    新建任务模板
                                </div>
                            </el-dropdown-item>
                            <el-dropdown-item command="edit-form-template" v-if="type == 2 && $has('filings-indicator-form-template')">
                                <div class="r-flex">
                                    <r-icon name="edit-outline" left="0" right="5" size="14"></r-icon>
                                    编辑任务模板
                                </div>
                            </el-dropdown-item>
                            <el-dropdown-item command="del-form-template" v-if="type == 2 && isCanDelTemplate && $has('filings-indicator-form-template')">
                                <div class="r-flex">
                                    <r-icon name="delete" left="0" right="5" size="14"></r-icon>
                                    删除任务模板
                                </div>
                            </el-dropdown-item>
                            <el-dropdown-item command="add-task" v-if="type == 2 && $has('task-create')">
                                <div class="r-flex">
                                    <r-icon name="plus" left="0" right="5" size="14"></r-icon>
                                    新建任务
                                </div>
                            </el-dropdown-item>
                            <el-dropdown-item command="edit-indicator" v-if="$has('filings-indicator-edit')">
                                <div class="r-flex">
                                    <r-icon name="edit-outline" left="0" right="5" size="14"></r-icon>
                                    编辑指标
                                </div>
                            </el-dropdown-item>
                            <el-dropdown-item command="del-indicator" v-if="type == 0 && $has('filings-indicator-delete')">
                                <div class="r-flex">
                                    <r-icon name="delete" left="0" right="5" size="14"></r-icon>
                                    删除指标
                                </div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <div class="r-mt-15 r-flex r-row-right indicator-detail-header_toolbar-point r-col-bottom" v-if="isInit">
                <span>评分：</span>
                <span>{{ data.scoreAvg || 0 }}</span>
            </div>
            <update-indicator @success="updateIndicator" ref="updateIndicator"></update-indicator>
            <approveDetail ref="approveDetail" @close="approveUpdate"></approveDetail>
        </div>
    </div>
</template>
<script>
import updateIndicator from "../update";
import approveDetail from "@/components/approve/createForm";
import { del, delTemplate, statusSave, statusList } from "@/api/indicator";
import { getDateRange } from "@/utils"
export default {
    components: { updateIndicator, approveDetail },
    props: {
        data: {
            type: Object,
            default() {
                return {};
            }
        },
        isInit: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            visible: false,
            statusList:[]
        }
    },
    computed: {
        type() {
            return this.data?.type || 0;
        },
        tags() {
            return this.data?.parentList || [];
        },
        name() {
            return this.data?.name || '';
        },
        pid() {
            return this.data?.pid ? this.data?.pid == '00000000-0000-0000-0000-000000000000' ? '' : this.data?.pid : '';
        },
        sn() {
            return this.data?.sn || '';
        },
        isCanDelTemplate() {
            return !!this.data?.isCanDelTemplate;
        },
        statusStr(){
            return this.data?.statusStr || '';
        },
        statusColor(){
            return this.data?.statusColor || '';
        },
        statusEdit(){
            return !!this.data?.statusEdit;
        },
        status(){
            return this.data?.status || 1;
        }
    },
    methods: {
        dropdownVisibleChange(visible) {
            this.visible = visible;
        },
        dropdownClick(command) {
            switch (command) {
                case 'add-indicator':
                    this.$refs.updateIndicator.open({
                        pid: this.data.id,
                        pName: this.data.name,
                        pDate: [this.data?.startTime, this.data?.endTime],
                        date: [this.data?.startTime, this.data?.endTime],
                        leader: this.data.leader || [],
                        employee: this.data.employee || []
                    }, false);
                    break;
                case 'edit-indicator':
                    this.$refs.updateIndicator.open({
                        id: this.data.id
                    }, true, true);
                    break;
                case 'del-indicator':
                    this.delIndicator();
                    break;
                case 'add-form-template':
                    this.taskOpen();
                    break;
                case 'add-task':
                    this.addTask();
                    break;
                case 'edit-form-template':
                    this.taskOpen(this.data.id);
                    break;
                case 'del-form-template':
                    this.delTemplate();
                    break;
            }
        },
        statusDropdownClick(command){
            statusSave({id:this.data.id,status:command,statusEdit:1}).then(res=>{
                this.$message.success('指标状态修改成功');
                this.getStatusList(command);
                this.$emit('update');
            })
        },
        //更新数据
        updateIndicator(isEdit) {
            this.$emit('update', isEdit ? 'edit-indicator' : 'add-indicator');
        },
        //删除指标
        delIndicator() {
            this.$confirm('确定要删除该指标吗？此操作不可逆', '提示', { type: 'warning' }).then(
                () => {
                    del({ id: this.data.id }).then(res => {
                        this.$message.success('指标删除成功');
                        this.$emit('close', 'del-indicator')
                    })
                }
            )
        },
        //删除模板
        delTemplate() {
            this.$confirm('确定要删除该任务模板吗？此操作不可逆', '提示', { type: 'warning' }).then(
                () => {
                    delTemplate({ indicatorId: this.data.id }).then(res => {
                        this.$message.success('任务模板删除成功');
                        this.$emit('close', 'del-template')
                    })
                }
            )
        },
        taskOpen(id) {
            this.$refs.approveDetail.handleOpen(
                {
                    id: id,
                    title: id ? '编辑任务模板' : '新建任务模板',
                    indicatorId: this.data.id,
                    leader: id ? null : this.data?.leader || [],
                    filings:true
                }
            );
        },
        addTask(){
            this.$refs.approveDetail.handleOpen(
                {
                    id:0,
                    title:'新建任务',
                    indicatorId:this.data.id,
                    task:true,
                    indicatorName:[...this.tags.map(el=>el.name),this.data.name].join('/'),
                    indicatorDate:getDateRange(this.data.startTime, this.data.endTime),
                    leader:this.data?.leader || [],
                    employee:this.data?.employee || [],
                    filings:true
                }
            );
        },
        copySn() {
            this.$copyText(this.sn).then(() => {
                this.$message.success(`编号复制成功`)
            })
        },
        openDetail(id) {
            this.$emit('openDetail', id)
        },
        approveUpdate(isUpdate) {
            if (isUpdate) {
                this.$emit('update');
            }
        },
        getStatusList(command = 0){
            statusList().then(res=>{
                this.statusList = res.data.filter(el=>el.value != (command || this.status));
            })
        }
    }

}
</script>

<style lang="scss" scoped>
.indicator-detail-header {
    &_status{
        padding: 2px 16px;
        border:1px solid #eeeeee;
        border-radius: 4px;
        cursor: pointer;
        &:hover{
            opacity: .7;
        }
    }
    &_breadcrumb {
        &--tag {
            background: #e5e5e5;
            padding: 5px 10px;
            border-radius: 5px;
        }

        &--grid {
            padding: 0 10px;
        }
    }

    &_title {
        font-size: 20px;
        line-height: 32px;

        &.grid {
            padding-top: 9px;
        }

        .sn {
            padding: 1px 10px;
            background: #e5e5e5;
            font-size: 16px;
            border-radius: 4px;
            margin-right: 10px;
        }
    }

    &_toolbar {
        margin-left: 40px;
        padding-right: 5px;
        padding-top: 1px;
        text-align: right;

        &-point {
            transform: translateX(35px);
            line-height: 18px;

            span:last-child {
                font-size: 24px;
                font-weight: bold;
                color: var(--primary);
                line-height: 1;
            }
        }
    }
}
</style>