<template>
    <r-drawer ref="rDialog" size="800px" :dialog-title="dialogTitle" :footer="false" @close="reset">
        <div class="rich-text-content" v-html="context"></div>
    </r-drawer>
</template>
<script>

export default {
    data(){
        return {
            dialogTitle:'富文本信息',
            context:''
        }
    },
    methods:{
        open(context,dialogTitle = '富文本信息'){
            this.context = context;
            this.dialogTitle = dialogTitle
            this.$refs.rDialog.handleOpen();
        },
        reset(){
            this.context = '';
        }
    }



}
</script>

<style lang="scss" scoped>
 .rich-text-content{
    ::v-deep img{
        max-width:100%;
        height:auto!important;
    }
}
</style>
