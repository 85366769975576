<template>
<div class="form-edit" v-html="content"></div>
</template>
<script>

export default {
    name:'edit',
    props: {
        config: {
        type: Object,
            default() {
                return {}
            }
        }
    },
    computed:{
        content(){
            return this.config.defaultValue;
        }
    }
}
</script>

<style lang="scss" scoped>
.form-edit{
    line-height:24px;
    ::v-deep img,::v-deep video{
        max-width: 100%!important;
        height:auto!important;
    }
}
</style>