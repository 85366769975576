<template>
    <div class="index-wrapper">
        <!-- <el-card>
            <div class="info r-flex">
                <div class="info-tips r-flex-1-5 r-flex r-row-center r-border-right">
                    <img :src="icon01"
                        alt="" class="info-tips-icon">
                    <div class="info-tips-content">
                        <p>我的代办</p>
                        <p>18</p>
                    </div>
                </div>
                <div class="r-flex-5 info-approval r-flex r-row-ar">
                    <div class="info-approval-item">
                        <p><img :src="icon02"
                                alt=""></p>
                        <p>我审核的</p>
                    </div>
                    <div class="info-approval-item">
                        <p><img :src="icon03"
                                alt=""></p>
                        <p>我评分的</p>
                    </div>
                    <div class="info-approval-item">
                        <p><img :src="icon04"
                                alt=""></p>
                        <p>抄送我的</p>
                    </div>
                </div>
            </div>
        </el-card> -->
        <el-card class="workbench" v-loading="loading || !isInit" :element-loading-text="loadingText">
            <div slot="header" class="r-flex r-row-bet workbench-header">
                <span>应用</span>
                <el-button type="primary" size="small" icon="el-icon-plus" round @click="createCompany">新建应用</el-button>
            </div>
            <div class="workbench-content" v-if="companyList.length > 0">
                <div class="item r-flex" v-for="item in companyList" :key="item.companyId" @click="selectCompany(item)">
                    <div class="icon">
                        <img :src="item.logo | $staticFilter" alt="">
                    </div>
                    <div class="name r-flex-auto-w">{{ item.companyName }}</div>
                </div>
            </div>
            <r-empty v-else-if="companyList.length == 0">{{  isInit ? '暂无应用，请新建应用' : '加载中...' }}</r-empty>
        </el-card>
        <create-company ref="createCompany" @success="getCompany"></create-company>
    </div>
</template>

<script>
import createCompany from "./components/create-company";
import { getCompany,selectCompany } from "@/api/user";
import icon01 from "@/assets/images/01.svg";
import icon02 from "@/assets/images/02.svg";
import icon03 from "@/assets/images/03.svg";
import icon04 from "@/assets/images/04.svg";
export default {
    components: { createCompany },
    data() {
        return {
            companyList: [],
            isInit:false,
            loading:false,
            activeItem:'2194586e-3b7d-4ae4-bead-4d52166cb305',
            loadingText:'加载中...',
            icon01,
            icon02,
            icon03,
            icon04,
        }
    },
    methods: {
        createCompany() {
            this.$refs.createCompany.open();
        },
        getCompany() {
            getCompany().then(res => {
                this.companyList = res.data;
            }).catch(err=>{}).finally(()=>{
                this.isInit = true;
            })
        },
        selectCompany(item){
            if(this.loading) return;
            this.loading = true;
            this.loadingText = '正在进入应用...'
            this.activeItem = item.companyId;
            selectCompany({companyId:item.companyId})
            .then(res=>{
                this.$store.commit('systemInit',res.data);
                //进入
                this.$nextTick(()=>{
                    //权限判断
                    this.checkAuth();
                })
            })
            .catch(()=>{
                this.loading = false;
            })
        },
        checkAuth(){
            let menuRules = this.$store.state.userInfo.menuRules;
            let pageName = (this.$router.getRoutes()).map(el=>el.name);
            if(menuRules.length == 0){
                this.$message.warning('您暂无页面权限，请联系管理员开启权限');
                this.loading = false;
            }else{
                if(menuRules.includes('indicator')){
                    this.$router.push({name:'indicator'})
                }else{
                    //取存在的选项
                    let _pageName = menuRules.find(el=>pageName.includes(el))
                    this.$router.push({name:_pageName})
                }
            }
        }
    },
    mounted() {
        this.getCompany();
    }


}
</script>

<style lang="scss" scoped>
.index-wrapper {
    .info {
        height: 128px;

        &-tips {
            height: 128px;
            padding-right: 20px;

            &-icon {
                width: 90px;
                height: 90px;
            }

            &-content {
                padding-left: 10%;
                white-space: nowrap;
                text-align: center;
                font-size: 20px;
                font-weight: bold;

                p:last-child {
                    font-size: 32px;
                    padding-top: 10px;
                }
            }
        }

        &-approval {
            padding: 0 50px;

            &-item {
                padding: 0 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
                transition: opacity, .2s;

                &:hover {
                    opacity: .7;
                }

                p:first-child {
                    width: 40px;
                    height: 40px;
                    margin-bottom: 10px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }

    .workbench {
        margin-top: 40px;

        ::v-deep .el-card__header {
            border-bottom: 0;
            padding: 20px;
        }

        &-header {
            span {
                font-size: 20px;
            }
        }

        &-content {
            display: grid;
            gap: 20px;
            grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
            min-height:100px;
            .item {
                display: flex;
                height:60px;
                overflow: hidden;
                cursor: pointer;
                transition:opacity .2s;
                .icon {
                    width: 60px;
                    height: 60px;
                    margin-right: 10px;
                    border-radius: 4px;
                    overflow: hidden;
                    transition:opacity .2s;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                
                &:hover{
                    color:var(--primary-color);
                    .icon{
                        opacity:.8;
                    }
                }
            }
        }
    }
}
</style>