<template>
<div class="r-flex r-flex-wrap">
    <el-tag type="info" size="small">1、任务1</el-tag>
    <el-tag type="info" size="small">2、任务2</el-tag>
    <el-tag type="info" size="small">3、任务3</el-tag>
    <el-tag type="info" size="small">4、任务4</el-tag>
</div>
</template>
<script>

export default {

    name:'taskTags'


}
</script>

<style lang="scss" scoped>
.el-tag{
    margin-right:10px;
    margin-bottom:10px;
}
</style>