export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    appendTBody: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      fullscreen: false
    }
  },
  filters: {
    filterAvatar(val) {
      return process.env.VUE_APP_IMG_URL + val.replace(process.env.VUE_APP_IMG_URL, "")
    }
  },
  computed: {
    //显示dialog
    dialogShow: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
        if (!val) {
          this.$emit('close');
        }
      }
    },
    img_url() {
      return process.env.VUE_APP_IMG_URL;
    },
  },
  methods: {
    /**
     * 是否更新数据
     * @param {boolean} update 是否更新数据
     */
    onClose(update) {
      this.$emit('input', false);
      this.$emit('close', update);
    },

    //关闭前的回调
    dialogHideBefore(done) {
      if (this.loading || this.returnVisitLoading) {
        this.$message({
          type: "warning",
          message: "正在提交或拉取数据，请不要关闭此窗口",
        });
        done(false);
      } else {
        done(true);
      }
    },
    //使用全屏
    useFullscreen() {
      this.fullscreen = !this.fullscreen;
      this.$nextTick(() => {
        this.setTableMaxHeight && this.setTableMaxHeight();
      });
    }
  }
}
