<template>
<div class="task-overdue">
    可逾期天数
</div>
</template>
<script>

export default {

    name:"taskOverdue"


}
</script>

<style lang="scss" scoped>
.task-overdue{
    font-weight: bold;
}
</style>