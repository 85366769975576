<template>
<div class="task-date">
    年 ~ 月 ~ 日
</div>
</template>
<script>

export default {
    name:'taskEndDate',
    props: {
        config: {
        type: Object,
            default() {
                return {}
            }
        }
    },
    computed:{
        content(){
            return this.config.label;
        }
    }
}
</script>

<style lang="scss" scoped>
.task-date{
    font-weight: bold;
}
</style>