<template>
<div class="task-score">
    评分人给出的评分
</div>
</template>
<script>

export default {

    name:'taskScore'


}
</script>

<style lang="scss" scoped>
    .task-score{
        color:red;
    }
</style>