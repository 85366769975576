<template>
    <r-dialog
       :dialogTitle="dialogTitle"
       custom-class="set-role-dialog"
       :close-on-click-modal="false"
       :size="dialogWidth"
       @close="resetForm"
       :footer="false"
       ref="rDialog"
     >
       <div class="set-role-wrapper" v-loading="loading">
           <div class="set-role-content">
           <div class="set-role-dialog_role-tree"  v-if="isEdit">
               <div class="set-role-dialog_title">所有角色</div>
               <div class="set-role-dialog_content tree-content">
                   <div class="set-role-dialog_role-tree-search">
                       <el-input v-model="keyword" placeholder="请输入角色名称" size="small" @input="(val)=>{$refs.roleTree.filter(val)}"></el-input>
                   </div>
                   <div class="set-role-dialog_role-tree-content">
                       <el-tree
                       :data="treeData"
                       ref="roleTree"
                       node-key="id"
                       :filter-node-method="roleTreeFilter"
                       :props="{
                           children: 'children',
                           label: 'title'
                       }"
                       @check="roleCheck"
                       check-on-click-node
                       show-checkbox>
                         <span class="custom-tree-node" slot-scope="{ node }">
                           <i class="el-icon-s-custom role-icon"></i>
                           <span class="role-title">{{ node.label }}</span>
                           <span>{{ node.name }}</span>
                         </span>
                       </el-tree>
                   </div>
               </div>
           </div>
           <div class="set-role-dialog_select_content">

               <div class="set-role-dialog_select_content-item">
                   <div class="set-role-dialog_title">已绑角色</div>
                   <div class="set-role-dialog_content">
                       <div class="set-role-dialog_content_item" v-for="(item,index) in employeeRoles" :key="index">
                           <div class="set-role-dialog_content_item-label">
                               <i class="el-icon-s-custom role-icon"></i>
                               <span class="role-title">{{item.title}}</span>
                           </div>
                           <div class="set-role-dialog_content_item-close" @click="deleteRole(item,index)"  v-if="isEdit">
                               <i class="el-icon-error"></i>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
           </div>
           <div class="set-role-btns">
               <el-button v-if="isEdit" @click="onClose(false)" size="mini">取消</el-button>
               <el-button type="primary" @click="roleConfirm" size="mini">确定</el-button>
           </div>
       </div>
     </r-dialog>
   </template>
   <script>
   import {getList,getEmployeeRoles} from "@/api/system/role";
   import { editRole } from "@/api/employee/index";
   import {cloneDeep, findIndex,uniqBy} from "@/utils";
   export default {
       data(){
           return{
               keyword:'',
               treeData:[],
               positionRoles:[],
               employeeRoles:[],
               loading:true,
               dialogTitle:'',
               id:'',
               isEdit:false
           }
       },
       computed:{
           dialogWidth(){
               return this.isEdit ? '860px' : '440px';
           }
       },
       methods: {
           open(options){
            this.dialogTitle = options?.dialogTitle || '设置角色';
            this.isEdit = !!options?.isEdit;
            this.id = options?.id || '';
            this.$refs.rDialog.handleOpen();
            this.loading = true;
            this.getList();
           },
           resetForm(){
               this.loading=true;
               this.positionRoles=[];
               this.employeeRoles=[];
               this.treeData = [];
               this.keyword=''
           },
           //获取树
           getList(){
              getList()
               .then(res=>{
                   this.treeData = res.data;
                   getEmployeeRoles(this.id)
                   .then(res=>{
                       this.positionRoles = res.data.postRole;
                       this.employeeRoles = res.data.employeeRole;
                       //处理禁用以及启用状态
                       if(this.isEdit){
                           this.treeData.forEach(el=>{
                           let index =findIndex(this.positionRoles,(_el)=>{
                               return _el.id == el.id;
                           });
                           this.$set(el,'disabled',index != -1);
                           });
                           let selectKeys = uniqBy([...this.positionRoles,...this.employeeRoles],'id');
                           //选中当前数据
                           this.$nextTick(()=>{
                               this.$refs.roleTree.setCheckedKeys(selectKeys.map(el=>{
                                   return el.id;
                               }));
                           })
                       }
   
                       this.loading=false;
                   })
               })
           },
           //选中树
           roleCheck(node,{checkedNodes}){
               //检查是否选中该元素
               let checked = findIndex(checkedNodes,(el=>{
                   return el.id == node.id;
               }))
               //选中
               if(checked != -1){
                   let item = cloneDeep(node);
                   this.employeeRoles.push(item)
               }else{
               //取消选中
                   this.employeeRoles = this.employeeRoles.filter(el=>{
                       return el.id !=node.id;
                   })
               }
           },
           //删除选中角色
           deleteRole(item,index){
               this.employeeRoles.splice(index,1);
               this.$refs.roleTree.setChecked(item.id,false);
           },
           roleTreeFilter(value,data){
               if (!value) return true;
               return data.title.indexOf(value) !== -1;
           },
           onClose(isUpdate){
            this.$refs.rDialog.close();
            if(isUpdate){
                this.$emit('update');
            }
           },
           //确定选择角色
           roleConfirm(){
               if(!this.isEdit){
                   this.onClose(false);
               }else{
                   let roleData = [...this.employeeRoles];
                   roleData = roleData.map(el=>{
                       return el.id;
                   })
                   editRole({
                       employeeId: this.id,
                       roleData
                   }).then((res) => {
                       this.$message.success(res.message);
                       this.onClose(true);
                       this.$emit('confirm');
                   })
                   .finally(()=>{
                       this.loading=false;
                   })
               }
           }
       }
   
   }
   </script>
   
   <style lang="scss">
   .set-role-wrapper{
       .role-icon{
           color:#CAD6ED;
           padding-right:8px;
           font-size:16px;
           vertical-align: middle;
       }
       .role-title{
           position: relative;
           top:1px;
           user-select: none;
       }
       .set-role-content{
           display: flex;
           padding: 0 20px;
           .set-role-dialog_role-tree,.set-role-dialog_select_content{
               flex:1;
               height:576px;
               display: flex;
               flex-direction: column;
           }
           .set-role-dialog_role-tree{
               padding-right:18px;
           }
       }
       .set-role-dialog_title{
           font-size:17px;
           margin-bottom:18px;
       }
       .set-role-dialog_content{
           border: 1px solid #D2D2D2;
           border-radius: 4px;
           padding: 10px 17px;
       }
       .set-role-dialog_content{
           flex:1 1 auto;
           height:0;
           &.tree-content{        
               display: flex;
               flex-direction: column;
           }
       }
       .set-role-dialog_role-tree-content{
           flex:1 1 auto;
           height:0;
           margin-top:15px;
           overflow-x:hidden;
           overflow: auto;
           .el-tree-node__expand-icon{
               display: none;
           }
           .el-tree-node__content{
               background: transparent!important;
           }
           .el-tree-node__label{
               user-select: none;
           }
       }
       .set-role-dialog_select_content-item{
           flex:1;
           display: flex;
           flex-direction: column;
           &:first-child{
               padding-bottom: 18px;
               .set-role-dialog_content{
                   background: #FAFAFA;
               }
           }
           .set-role-dialog_content{
               overflow-x:hidden;
               overflow-y:auto;
           }
       }
       .set-role-dialog_content_item{
           height:30px;
           line-height:30px;
           display: flex;
           align-items: center;
           justify-content: space-between;
       }
       .set-role-dialog_content_item-close{
           font-size:20px;
           color:#D2D2D2;
           cursor: pointer;
           transition: opacity .3s;
           &:hover{
               opacity: .7;
           }
       }
       .set-role-btns{
           margin-top:27px;
           border-top:1px solid #EEF2F6;
           padding: 23px 20px 0;
           display: flex;
           justify-content: flex-end;
           align-items: center;
       }
   }
   </style>