import { router } from "@/router";
import { isArray, cloneDeep, deepRouter } from "@/utils";
import layout from "@/config/routes";
import notFound from "@/views/404"
const state = {
  //需要自定义的路由列表
  routes: [],
  pageScrollLock: false
}
const mutations = {
  /**
   * @param {*} state
   * @param {*} menuList 菜单列表
   * 设置路由路径，以及生成菜单列表
   *
   */
  setRouter(state, routeList = {}) {
    //为空或不为对象的时候不进行路由添加
    if (!isArray(routeList)) return;
    //路由列表,默认存在首页
    let routes = [];
    //创建一个layout对象,重定向到首页
    //首先重置一下router路由列表
    router.fxyResetRouter();
    routes.push(layout);
    state.routes = routes;
    router.addRoute(...routes);
    //添加一个404
    router.addRoute(
      {
        path: '*',
        name:'404',
        component: notFound,
        meta: {
          common: true,
          title: "404 该页面不存在"
        }
      }
    );
  },
  /**
   * 重置路由
   *
   */
  resetRouter(state) {
    state.routes = [];
    router.fxyResetRouter();
  }
}
const actions = {}
const getters = {}
export default {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
