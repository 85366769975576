<template>
    <div>
        <r-drawer ref="rDialog" size="800px" dialog-title="新建应用" @submit="submit" @close="reset">
            <el-form label-position="top" :rules="rules" :model="form" ref="form" :disabled="loading">
                <el-form-item label="名称" prop="companyName">
                    <el-input placeholder="请输入应用名称" v-model="form.companyName"></el-input>
                </el-form-item>
                <el-form-item label="区域" prop="areaId">
                    <el-cascader :options="cityList" v-model="form.areaId" class="r-w-max"
                        :props="{ label: 'name', value: 'id', emitPath: false, checkStrictly: true }" filterable></el-cascader>
                </el-form-item>
                <el-form-item label="图标" prop="logo">
                    <singleImageUpload preview cropper v-model="form.logo"></singleImageUpload>
                </el-form-item>
            </el-form>
        </r-drawer>
    </div>
</template>
<script>
import singleImageUpload from "@/components/imageUpload/singleImageUpload";
import { createCompany } from "@/api/user";
import {getCityList} from "@/api/common"
export default {
    components: { singleImageUpload },
    data() {
        return {
            form: {
                companyName: '',
                logo: '',
                areaId:''
            },
            rules: {
                companyName: [{ required: true, message: '请填写公司名称', trigger: "change" }],
                logo: [{ required: true, message: '请上传图标', trigger: "change" }],
                areaId: [{ required: true, message: '请选择区域', trigger: "change" }],
            },
            loading: false,
            cityList:[]
        }
    },
    methods: {
        reset() {
            this.form = {
                companyName: '',
                logo: ''
            };
            this.loading = false;
        },
        open() {
            this.getCityList();
            this.$refs.rDialog.handleOpen();
            this.$nextTick(() => {
                this.$refs['form']?.clearValidate && this.$refs['form'].clearValidate();
            })
        },
        getCityList(){
            getCityList().then(res=>{
                this.cityList = res.data;
            })
        },
        submit(done) {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    createCompany(this.form).then(res => {
                        done(true);
                        this.$message.success('应用创建成功');
                        this.$emit('success');
                    }).catch(err => {
                        done(false);
                    })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    done();
                }
            });
        }
    }


}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
    font-size: 16px;
    padding-bottom: 5px;
}
</style>
