<template>
    <div class="indicator-detail">
        <r-drawer size="90%" ref="dialog" @opened="init" :footer="false" @close="reset" :headerIpt="type==2">
            <template slot="title">
                <detail-header :isInit="isInit" :data="detailData" @update="update" @close="close" @openDetail="openDetail" ref="detailHeader"></detail-header>
            </template>
            <div class="indicator-detail-content" v-loading="!isInit" element-loading-text="加载中..."
            element-loading-background="rgba(255,255, 255, 0.8)">
                <el-tabs v-model="activeName" type="card" v-if="isInit">
                    <el-tab-pane label="子指标" name="subIndicator" v-if="type == 1">
                        <detail-indicator :data="detailData" v-if="activeName == 'subIndicator'"  @openDetail="openDetail" ref="detailIndicator"></detail-indicator>
                    </el-tab-pane> 
                    <el-tab-pane label="包含任务" name="task" v-if="type == 2">
                        <detail-task :data="detailData" v-if="activeName == 'task'" @refresh="detailClose"></detail-task>
                    </el-tab-pane>
                    <el-tab-pane label="详情" name="detail">
                        <detail-desc :data="detailData"></detail-desc>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </r-drawer>
        <detail-dialog ref="detail" v-if="isInit" @close="detailClose"></detail-dialog>
    </div>
</template>
<script>
import detailHeader from "./header";
import detailDesc from "./content";
import detailIndicator from "./indicator";
import detailTask from "./task";
import {detail} from "@/api/indicator";
export default {
    components:{
        detailHeader,
        detailDesc,
        detailIndicator,
        detailTask,
        detailDialog: () => import('./index')
    },
    data(){
        return {
            id:'',
            detailData:{},
            isInit:false,
            activeName:''
        }
    },
    computed:{
        type(){
            return this.detailData?.type || 0;
        }
    },
    methods: {
        open(id) {
            if(!this.$has('indicator-get')){
                this.$message.error('无指标查看权限');
                return;
            }
            this.id = id;
            this.$refs.dialog.handleOpen();
        },
        detail(){
            detail({id:this.id}).then(res=>{
                if(res.data.id != this.id) return;
                this.activeName = res.data.type == 1 ? 'subIndicator' : res.data.type == 2 ? 'task' : 'detail';
                this.detailData = res.data;
                this.isInit = true;
                //更新
                this.$refs.detailHeader.getStatusList && this.$refs.detailHeader.getStatusList();
            })
        },
        init(){
            this.detail();
        },
        reset(){
            this.id = '';
            this.detailData = {};
            this.isInit = false;
            this.activeName = 'detail';
        },
        update(type){
            this.detail();
            if(type == 'add-indicator'){
                this.$refs.detailIndicator?.refresh && this.$refs.detailIndicator?.refresh();
            }

            this.$emit('update');
        },
        close(isUpdate){
            if(isUpdate == 'del-template'){
                this.detail();
            }else{
                this.$refs.dialog.close();
            }
            this.$emit('close',isUpdate);
        },
        openDetail(id){
            this.$refs.detail.open(id);
        },
        detailClose(type){
            if(type == 'del-indicator'){
                this.$refs.detailIndicator?.refresh && this.$refs.detailIndicator?.refresh();
            }
            if(type == 'detail'){
                this.detail();
            }
        }
    }


}
</script>

<style lang="scss" scoped>

.indicator-detail-content{
    height:100%;
    .el-tabs{
        display: flex;
        flex-direction: column;
        height:100%;
        ::v-deep .el-tabs__content{
            flex:1 1 auto;
            height:0;
            .el-tab-pane{
                height:100%;
            }
        }
    }
}
</style>