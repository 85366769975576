<template>
    <div>
        <r-drawer ref="rDialog" size="800px" :dialog-title="isEdit ? '编辑部门' : '创建部门'" @submit="submit" @close="reset">
            <el-form label-position="top" :rules="rules" :model="form" ref="form" :disabled="loading">
                <el-form-item label="部门名称" prop="name">
                    <el-input placeholder="请输入部门名称" v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="上级部门" prop="parentDep">
                    <r-tag-select v-model="form.parentDep" labelKey="name" :collapseTags="true" placeholder="请选择上级部门"
                        max @focus="handleChangeSelect" @remove-tag="removePid" ></r-tag-select>
                </el-form-item>
            </el-form>
        </r-drawer>

        <dep-role-modal :config="modalConfig" v-model="dialogVisible" @confirm="handleConfirm"></dep-role-modal>
    </div>
</template>

<script>
import {
    create,
    updateDepartment,
    getDepartmentDetail
} from "@/api/dep/index";
import {isArray } from "@/utils";
import DepRoleModal from "@/components/dialog/DepRoleModal";
export default {
    components: { DepRoleModal },
    data() {
        return {
            form: {
                name: '',
                parentDep: []
            },
            rules: {
                name: [{ required: true, message: '请填写部门名称', trigger: "blur" }],
            },
            isEdit: false,

            modalConfig: {},
            dialogVisible: false,
            loading:false
        }
    },
    methods: {
        open(form = null) {
            if (form) {
                this.form = Object.assign(this.form, form);
                this.getDepartmentDetail(form.id);
                this.isEdit = true;
            }
            this.$refs.rDialog.handleOpen();
            this.$nextTick(() => {
                this.$refs['form']?.clearValidate && this.$refs['form'].clearValidate();
            })
        },
        submit(done) {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    (this.isEdit ? updateDepartment : create)(this.form).then(res => {
                        done(true);
                        this.$message.success(`部门${this.isEdit ? '编辑' : '创建'}成功`);
                        this.$emit('success');
                    }).catch(err => {
                        done(false);
                    })
                    .finally(()=>{
                        this.loading = false;
                    })
                } else {
                    done();
                }
            });
        },
        //获取父级数据
        getDepartmentDetail(id) {
            getDepartmentDetail({ id })
                .then(res => {
                    this.form.parentDep = res.data.parentDep;
                });
        },
        reset() {
            this.form = {
                name: '',
                parentDep: []
            };
            this.isEdit = false;
            this.loading = false;
        },
        removePid(){
            this.form.pid = '';
        },
        handleConfirm(val) {
            if (this.form.id && this.form.id === val[0].id) {
                return this.$message.error("不能选择上级为自己");
            }
            this.form.pid = val[0].id;
            this.form.parentDep = val;
            this.dialogVisible = false;
        },
        handleChangeSelect() {
            this.modalConfig = {
                type: 1,
                selectType: "radio",
                title: "选择上级部门",
                checkStrictly: true,
                model: "pid",
                checkList: isArray(this.form.parentDep) ? this.form.parentDep : [],
            };
            this.dialogVisible = true;
        },
    }


}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
    font-size: 16px;
    padding-bottom: 5px;
}
</style>