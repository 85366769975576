/*
 * @Descripttion:
 * @version:
 * @Author: 杨小颖
 * @Date: 2021-06-25 14:41:00
 * @LastEditors: sueRimn
 * @LastEditTime: 2021-07-12 14:50:09
 */
import request from '@/utils/request'

export function getList(params) {
    return request({
        url: '/api/employee/employee-list',
        method: 'get',
        params: params
    });
}

export function downloadList(params) {
    return request({
        url: '/api/employee/employee-list',
        method: 'get',
        params: params,
        // responseType: 'blob'
    });
}

export function addList(data) {
    return request({
        url: '/api/employee/add-employee',
        method: 'post',
        data: data
    })
}

export function delList(data) {
    return request({
        url: '/api/employee/del-employee',
        method: 'post',
        data: data
    })
}

/**
 * @用途:编辑用户
 */
export function editList(data) {
    return request({
        url: '/api/employee/edit-employee',
        method: 'post',
        data: data
    })
}

/**
 * @用途:员工详情
 */
export function detail(params) {
    return request({
        url: '/api/employee/employee-detail',
        method: 'GET',
        params
    })
}

export function getDirector(params) {
    return request({
        url: '/api/common/get-director',
        method: 'GET',
        params
    })
}

export function editRole(data) {
    return request({
        url: '/api/employee/edit-role',
        method: 'POST',
        data: data
    })
}

/**
 * @用途:初始化考勤组
 */
export function initAttendance(params) {
    return request({
        url: '/api/employee/init-attendance',
        method: 'GET',
        params
    })
}

/**
 * @用途:考勤组列表
 */
export function attendanceList(params) {
    return request({
        url: '/api/employee/employee-attendance-list',
        method: 'GET',
        params
    })
}

/**
 * @用途:薪资档案字段
 */
export function salaryArchive(params) {
    return request({
        url: '/api/employee/salary-archive',
        method: 'GET',
        params
    })
}

/**
 * @用途:社保方案列表
 */
export function insurancePlan(params) {
    return request({
        url: '/api/employee/select-insurance-plan',
        method: 'GET',
        params
    })
}

/**
 * @用途:归属公司下拉框
 */
export function bankPersonalAll(params) {
    return request({
        url: '/api/finance/get-bank-personal-list',
        method: 'get',
        params
    })
}

/**
 * @用途:归属公司下拉框
 */
export function bankAll(params) {
    return request({
        url: '/api/finance/get-bank-all-list',
        method: 'get',
        params
    })
}

/**
 * @用途:获取薪资档案模板
 */
export function salaryArchiveTemplate(params) {
    return request({
        url: '/api/employee/salary-archive-template',
        method: 'get',
        params
    })
}

/**
 * @用途:批量修改部门
 */
export function batchEmployeeToDep(params) {
    return request({
        url: '/api/employee/batch-employee-to-dep',
        method: 'post',
        data: params
    })
}

/**
 * @用途:启用/停用系统
 */
export function enableCompany(params) {
    return request({
        url: '/api/employee/enable-company',
        method: 'post',
        data: params
    })
}

/**
 * @用途:编辑手机号校验
 */
export function verifyPhone(params) {
    return request({
        url: '/api/employee/verify-phone',
        method: 'get',
        params
    })
}

/**
 * @用途:人事审批单
 */
export function approveDashboard(params) {
    return request({
        url: '/api/approve/get-approve-dashboard',
        method: 'get',
        params
    })
}

/**
 * @用途:新增员工默认值
 */
export function defaultValue(params) {
    return request({
        url: '/api/employee/default-value',
        method: 'get',
        params
    })
}

/**
 * @用途：导出
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function exportList(data) {
    return request({
        url: `/api/employee/down-employee`,
        method: 'post',
        data,
        responseType: 'blob',
        timeout: 60000
    })
}

/**
 * @用途：导入
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function importList(data) {
    return request({
        url: `/api/employee/import-employee`,
        method: 'post',
        data,
    })
}