/*
 * @Descripttion:
 * @version:
 * @Author: 杨小颖
 * @Date: 2021-06-25 14:41:00
 * @LastEditors: sueRimn
 * @LastEditTime: 2021-07-13 16:01:38
 */
export default {
  data() {
    return {
      btnShow: false,
      columns: [
        // {
        //   label: '档案号',
        //   prop: 'archiveNo'
        // },
        {
          label: '姓名',
          prop: 'nickname',
          align: "center",
          'min-width': '80px'
        },
        {
          label: "部门",
          prop: "department",
          align: "center",
          'min-width': '120px'
        },
        {
          label: '直属领导',
          prop: 'directLeadership',
          align: "center",
          'min-width': '80px'
        },
        {
          label: '职位',
          prop: 'position',
          showOverflowTooltip: true,
          align: "center",
          'min-width': '100px'
        },
        {
          label: "成本部门",
          prop: "costDepartment.name",
          align: "center",
          'min-width': '120px'
        },
        {
          label: '性别',
          prop: 'sexCn',
          // filters: [{text: '未知', value: 0}, {text: '男', value: 1}, {text: '女', value: 2}],
          align: "center",
          'min-width': '80px'
        },
        {
          label: '状态',
          prop: 'statusCn',
          sortable: 'custom',
          align: "center",
          'min-width': '120px'
        },
        {
          label: '角色',
          prop: 'roleShow',
          showOverflowTooltip: true,
          align: "center",
          'min-width': '100px'
        },
        { label: '入职时间', prop: 'entryDate', align: "center" },
        { label: "转正日期", prop: "regularEmployeesDate", align: "center" },
        { label: "最后工作日", prop: "departureDate", align: "center",'min-width': '120px' },
        { label: "员工类型", prop: "employeeTypeCn", align: "center" },
        { label: "评分", prop: "score", sortable: 'custom', align: "center"},
        { label: "生日 ", prop: "birthday", sortable: 'custom', align: "center" },
        { label: "入职渠道", prop: "employmentChannel", align: "center" },
        { label: "备注", prop: "remarks", width: 100, align: "center" },
        {
          label: "入职合同状态",
          prop: "entryContract",
          width: "120px",
          align: "center",
        },
        {
          label: "离职合同状态",
          prop: "quitContract",
          width: "120px",
          align: "center",
        }
      ]
    }
  },
  computed: {
    tableColumns() {
      const columns = this.columns.map((col, index) => {
        return {
          ...col,
          showOverflowTooltip: true,
          display: index < 5 ? 'always' : false,
          filterMethod: col.filters ? this.filterMethod : null
        }
      })
      columns.unshift(
        {
          type: 'selection'
        })

      columns.push({
        label: '操作',
        type: 'handle',
        prop: 'handle',
        width: '120px',
        align: "center",
        fixed: "right"
      })
      return columns
    },
  },
  methods: {
    mouseOver() {
      this.btnShow = true;
    },
    mouseLeave() {
      this.btnShow = false;
    }
  },
}
