<template>
<div>
    <r-drawer ref="rDialog" size="60%" dialogTitle="编辑指标模板" :isScroll="false" @submit="submit" @close="destroy">
        <r-table height="100%" :columns="columns" :data="tableData" drag :cell-class-name="addDragClass" :dragBefore="dragBefore" @dragEnd="dragEnd">
            <template v-slot:alias="{row}">
                <el-input v-model="row.alias" placeholder="可输入文字" size="small" :disabled="loading"></el-input>
            </template>     
            <template v-slot:show="{row}">
                <el-checkbox v-model="row.show" :true-label="1" :false-label="0" :disabled="!!row.showDisabled || loading" :label="row.showDisabled ? '不可取消' : ''"></el-checkbox>
            </template>    
            <template v-slot:require="{row}">
                <el-checkbox v-model="row.require" :true-label="1" :false-label="0" :disabled="!!row.showDisabled || loading" :label="row.showDisabled ? '不可取消' : ''" v-if="row.requireEdit"></el-checkbox>
                <span v-else class="tips">系统计算生成</span>
            </template>
            <template v-slot:sort="{row}">
                <div class="r-flex r-row-center"><r-icon name="rank" :disabled="row.prop =='no' || row.prop == 'name'" :hover="row.prop !='no' && row.prop != 'name'"></r-icon></div>
            </template>
        </r-table>
    </r-drawer>
</div>
</template>
<script>
import {template,updateTemplate} from "@/api/indicator";
export default {

    data(){
        return{
            columns:[
                {
                    label:'排序',
                    prop:'sort',
                    'width':'60px'
                },{
                    label:'默认名称',
                    prop:'label',
                    'min-width':'120px'
                },{
                    label:'别名',
                    prop:'alias',
                    'min-width':'120px'
                },{
                    label:'类型',
                    prop:'type',
                    'min-width':'100px'
                },{
                    label:'显示',
                    prop:'show',
                    'min-width':'100px'
                },{
                    label:'必填',
                    prop:'require',
                    'min-width':'120px'
                },
            ],
            tableData:[],
            loading:false
        }
    },


    methods:{
        open(){
            this.$refs.rDialog.handleOpen();
            //拉取数据
            this.loader();
        },
        loader(){
            template({module:'filings'}).then(res=>{
                this.tableData = res.data;
            })
        },
        addDragClass({row,column}){
            return column.property == 'sort' && !['no','name'].includes(row.prop) ? 'handle' : '';
        },
        dragBefore(newIndex){
            if([0,1].includes(newIndex)){
                this.$message.error('序号及指标名称的顺序不可更改');
                return false;
            }else{
                return true
            }
        },
        dragEnd(list,callback){
            this.tableData = [];
            this.$nextTick(()=>{
                this.tableData = list;
                callback && callback();
            })
        },
        submit(close){
            this.loading = true;
            updateTemplate({fields:this.tableData,module:'filings'}).then(res=>{
                this.$message.success('指标模板编辑成功')
                close(true);
                this.$emit('update');
            })
            .catch(err=>{console.log(err)}).finally(()=>{this.loading=false})
        },
        destroy(){
            this.tableData = [];
        }
    }


}
</script>

<style lang="scss" scoped>
    .tips{
        color:#C0C4CC;
    }
</style>