<template>
<div class="fxy-tips-text">
    <div class="fxy-tips-text-wrapper">
        <i class="el-icon-warning-outline icon"></i>
        <el-input placeholder="请填写说明文字" v-model="tipsText" :disabled="disabled"></el-input>
    </div>
</div>
</template>
<script>

export default {
    name:"FxyTips",
    props:['value','disabled','config'],
    computed:{

        tipsText:{
            get(){
                return this.value || this.config?.defaultValue;
            },
            set(val){
                this.$emit('input',val);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.fxy-tips-text{
    padding-top:10px;
    .fxy-tips-text-wrapper{
        display: flex;
        align-items: center;
        padding: 0 70px 0 0;
        .icon{
            font-size:18px;
            padding-right:10px;
            color:#00a0e4;
        }
        .fxy-tips-text{
            font-size:16px;
        }
    }
}
</style>
