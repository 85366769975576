/**
 * 获取全局运行时配置
 */

import routesFactory from '@/router/routes';
import store from "@/store";
import Vue from "vue";
import Router from "vue-router";
Vue.use(Router)
//避免打印重定向跳转报错
const originalPush = Router.prototype.push;
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
/**
 * 只引入纯手工路由
 */
const routes = routesFactory();
const createRouter = ()=> new Router({
  routes,
  mode: 'history',
  base: '/'
});
/**
 * 实例化路由
 * @type {Router}
 */
const router = createRouter();
//注入路由重置方法
router.fxyResetRouter = ()=>{
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
}

//监听异步加载错误
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  if(isChunkLoadFailed){
    window.location.reload();
  }else{
    console.log(error);
  }
})
 
//创建路由完成之前的回调
const routerBeforeEach = (to, from, next)=>{
  //检查是否存在token,且是否存在路由表，不存在路由表全部跳转到登录页或公共页
  if(store.state.userInfo.token){
    if(to.name == 'login'){
      next({name:'index',replace:true})
    }else{
      if(store.state.userInfo.company?.id){
        if(to.meta?.auth){
          if(store.state.userInfo.menuRules.includes(to.meta?.auth)){
            next();
          }else{
            next({name:'404',replace:true})
          }
        }else{
          next();
        }
      }else{
        if(to.name == 'index'){
          next()
        }else{
          next({name:'index',replace:true})
        }
      }
    }
  }else{
      //动态判断，并注册路由表
      store.dispatch('getInitData',to).then((config)=>{
        //公共页面允许直接访问
        if(to.meta.common && to.name != 'login'){
          next();
        }else{
          if(config?.path){
            next({path:config?.path,replace:true});
          }else{
            if(to.path == from.path){
              next({path:'/login',replace:true});
            }else{
              next({...to,replace:true});
            }
          }
        }
        
      }).catch((err)=>{
        //打印一下错误
        if(err){
          console.log(err);
        }
        //公共页面允许直接访问
        if(to.meta.common){
          next();
        }else{
          //登录状态就退出
          next({path:"/login",replace:true});
        }
      })
    
  }

};

//创建路由完成之后的回调

const routerAfterEach = (to,from)=>{
  if(to.meta?.title){
    document.title = to.meta.title;
  }
}


router.beforeEach((to,form,next)=>{
  routerBeforeEach(to,form,next)
});
router.afterEach((to,form)=>{
  routerAfterEach(to,form);
})
export{
  router
}
