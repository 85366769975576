<template>
    <div class="stack-line-item_value_pipe_line" :style="{ background: color, width: lineWidth }">{{ num }}</div>
</template>
<script>

export default {

    props: {
        color: {
            type: String,
            default: ''
        },
        num: {
            type: String | Number,
            default: 0
        }
    },
    data() {
        return {
            lineWidth: '0'
        }
    },
    mounted() {
        setTimeout(() => {
            this.lineWidth = '100%'
        }, 500)
    }


}
</script>

<style lang="scss" scoped>
.stack-line-item_value_pipe_line {
    height: 30px;
    color: #fff;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    transition: all .3s;
}
</style>
