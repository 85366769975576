import Vue from 'vue';
/**
 * 异步注册组件，因为引用此编辑器，需要重新注册elementUi的部分组件
 */

 const slotsFiles = require.context('../components/slots', false, /\.vue$/);
 const keys = slotsFiles.keys() || [];
 const _components=[];
 keys.forEach(key => {
    const tag = key.replace(/^\.\/(.*)\.\w+$/, '$1');
    const value = slotsFiles(key).default;
    _components.push(value);
 });
const componentRegisterControl = {

    components:[..._components],

    register:[],

    /**
     * 传入一个对象，对象中包含组件信息
     */

    componentsRegister(el){
        //判断组件是否已经注册过
        if(el?.name && !this.register.includes(el?.name)){
            Vue.component(el.name,el);
            this.register.push(el.name);
        }
    },

    init(){
        this.components.forEach(el=>{
            this.componentsRegister(el);
        });
    }
}



export default componentRegisterControl;