<template>
  <el-dialog
    :title="title"
    :visible.sync="value"
    width="800px"
    :before-close="handleClose"
    :append-to-body="true"
    :fullscreen="fullscreen"
    @open="openScreen"
  >
    <div slot="title">
      <div class="flex flex-center">
        <div class="flex-item title">{{ title }}</div>
        <div class="full" @click="fullscreen = !fullscreen">
        </div>
      </div>
    </div>
<!--    <el-steps :active="activeIndex" finish-status="success" align-center v-if="activeIndex !== 4">-->
<!--      <el-step title="选择Excel"></el-step>-->
<!--      <el-step title="预览数据"></el-step>-->
<!--      <el-step title="配置表单"></el-step>-->
<!--    </el-steps>-->
    <div class="fxy-excel-main-wrapper">
      <div class="txt-box">
        <template v-if="activeIndex === 1">
<!--          <p>1，为保证数据导入顺利，推荐您使用-->
<!--            <el-button type="text" @click="downloadExcel">标准模板</el-button>（这里做成链接，下载excel）-->
<!--          </p>-->
          <p>1，支持 10MB 以内的xlsx格式文件</p>
          <p>2，文件中数据不能超过10000行、200列</p>
        </template>
        <template v-if="activeIndex === 2">
          工作表
          <el-select v-model="selectSheet" size="small" placeholder="请选择">
            <el-option
              v-for="item in excelData"
              :key="item.label"
              :label="item.label"
              :value="item.label">
            </el-option>
          </el-select>
          点击任意一行可将其设置为标题行，标题行之前的数据不导入。
        </template>
      </div>
      <div class="excel-import-tool " v-if="activeIndex === 3">
        <span>表单名称：</span>
        <div class="excel-import-name">
          <div class="x-input input-disable input-normal" style="width: 190px;">
            <div class="x-inner-wrapper">{{ headDataTitle }}</div>
          </div>
        </div>
        <div class="excel-import-count">
          <div class="text"><i class="el-icon-warning"></i>导入 {{ headerSelectLen }} 列 / 共 {{ headerTd.length }} 列</div>
        </div>
      </div>
      <template v-if="activeIndex === 2">
        <div class="fxy-excel-import-grid" v-loading="loading">
          <div class="fxy-excel-grid" :class="{'screen':fullscreen}">
            <table cellpadding="0" cellspacing="0" v-for="(val,idx) in excelData" v-if="val.label === selectSheet">
              <tbody>
              <tr :class="{'row-head':val.activeTdIndex === key,'row-disable':key<val.activeTdIndex}"
                  v-for="(trItem,key) in val.data" @click="handleActiveTd(idx,key)">
                <td class="row-idx" rowspan="1"><span class="row-num">{{ key + 1 }}</span><span
                  class="row-label">标题行</span></td>
                <td v-for="tdItem in trItem"><span>{{ tdItem }}</span></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <template v-if="activeIndex ===3">
        <div class="fxy-excel-import-grid" v-loading="loading">
          <div class="fxy-excel-grid" :class="{'screen':fullscreen}">
            <table cellpadding="0" cellspacing="0">
              <tbody>
              <tr class="fxy-tr-import">
                <td rowspan="1"></td>
                <td v-for="(td,key) in headerTd" :key="key"><span>{{ td.label }}</span></td>
              </tr>
              <tr class="fxy-tr-import">
                <td rowspan="1"><span>表单字段</span></td>
                <td v-for="(td,tdKey) in headerTd" style="padding: 5px" :key="tdKey">
                  <el-select v-model="headerTd[tdKey].value" @change="((val)=>{handleHeader(val, tdKey)})" filterable
                             size="small"
                             placeholder="请选择">
                    <el-option
                      v-for="(item,hKey) in headerData"
                      :key="hKey"
                      :label="item.label"
                      :value="item.field">
                    </el-option>
                  </el-select>
                </td>
              </tr>
              <tr v-for="(trItem,key) in tableData" @click="handleActiveTd(idx,key)" :key="key" class="fxy-tr-import">
                <td rowspan="1"><span>{{ key + 1 }}</span></td>
                <td v-for="tdItem in trItem"><span>{{ tdItem }}</span></td>
              </tr>
              </tbody>
            </table>
            <div class="limit-tip">最多可预览前{{ tableData.length }}条数据</div>
          </div>
        </div>
      </template>
      <template v-if="activeIndex === 4">
        <div class="fxy-excel-import-grid" v-loading="loading">
          <div class="import-result">
            <div class="import-result-icon">
<!--              <my-icon name="icon-warning-fill" svg v-if="errorNum > 0"></my-icon>-->
<!--              <my-icon name="icon-success-fill" svg v-else></my-icon>-->
            </div>
            <div class="result-tip">
              <template v-if="errorNum > 0">
                导入成功<span class="success-num">{{ successNum }}</span> 条数据，失败 <span class="error-num">{{
                  errorNum
                }}</span> 条。
                <el-link type="primary" @click="handleDownload">下载错误报告</el-link>
              </template>
              <template v-else>
                导入成功<span class="success-num">{{ successNum }}</span> 条数据。
              </template>
            </div>
          </div>
        </div>
      </template>
      <div class="upload-box" v-if="activeIndex === 1">
        <el-upload
          :data="dataObj"
          :headers="headers"
          drag
          :action="base_api + '/api/upload/index'"
          :before-upload="handleBeforeUpload"
          :on-success="handleUploadSuccess"
          :on-error="handleError"
          :show-file-list="false"
          accept='.xls, .xlsx'
        >
          <div v-loading="loading">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">选择或拖拽上传文件,10MB以内
            </div>
          </div>
        </el-upload>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="activeIndex = activeIndex - 1" size="small"
                 v-if="activeIndex !== 1 && activeIndex !== 4">上一步</el-button>
      <el-button type="primary" @click="handleExcelHead(activeIndex)" size="small" :loading="loading"
                 v-if="activeIndex !== 1">{{ activeIndex === 2 ? '下一步' : '完成' }}</el-button>
    </span>
  </el-dialog>
</template>
<script>
import store from "@/store";
import {readExcel, getExcelHeader, importExcel,getImportExcelTemplate} from "@/api/common"
import {importList} from "@/api/employee/index";
import {cloneDeep} from "@/utils"

export default {
  props: {
    value: {
      type: Boolean,
    },
    type: {
      type: String
    }
  },
  name: "importExcel",
  data() {
    return {
      dataObj: {
        isSaveLink: true,
      },
      excelData: [],
      loading: false,
      title: "导入 Excel 数据",
      fullscreen: false,
      base_api: process.env.VUE_APP_BASE_URL,
      url_api: process.env.VUE_APP_IMG_URL,
      activeIdx: 0,
      activeIndex: 1,
      selectSheet: 'Sheet1',
      headerSelectLen: 0,
      headDataTitle: '',
      headerTd: [],
      headerData: [],
      tableData: [],
      successNum: 0,
      errorNum: 0,
      excelUrl: '',
      excelTemplate:''
    };
  },
  computed: {
    headers() {
      return {
        Authorization: store.state.userInfo.token || "",
        companyId: store.state.userInfo.companyId || "",
      };
    },
  },
  methods: {
    openScreen() {
      // getImportExcelTemplate({type:this.type}).then((result) => {
      //   this.excelTemplate = result.data.excelTemplate
      // });
    },
    //关闭弹窗，不可以删这个方法。不用这个方法点击遮罩层关闭会报错
    handleClose() {
      if (this.activeIndex === 4) {
        this.$emit("refresh")
      }
      this.activeIndex = 1
      this.loading = false
      this.fullscreen = false
      this.excelData = []
      this.$emit("input", !this.value);
    },
    handleUploadSuccess(res, file) {
      if (res.code === 200) {
        importList({excel: res.data.url}).then((result) => {
          this.loading = false
          this.successNum = result.data.list.length
          this.activeIndex = 4
        });
      }
    },
    handleError(err) {
      this.$message.error(err.message || '文件上传失败');
    },
    handleBeforeUpload(file) {
      this.loading = true
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 10MB!");
        this.loading = false
        return false;
      }
    },
    handleActiveTd(idx, key) {
      this.activeIdx = idx
      this.excelData[idx].activeTdIndex = key
    },
    handleExcelHead(activeIndex) {
      this.loading = true
      if (activeIndex === 2) {
        let headerTd = this.excelData[this.activeIdx].data[this.excelData[this.activeIdx].activeTdIndex]
        let headerTdData = []
        for (let i = 0; i < headerTd.length; i++) {
          headerTdData.push({
            'label': headerTd[i],
            'value': ''
          })
        }
        this.headerTd = headerTdData
        this.tableData = cloneDeep(this.excelData[this.activeIdx].data)
        this.tableData = this.tableData.filter((item, index) => {
          return index > this.excelData[this.activeIdx].activeTdIndex
        })
        getExcelHeader({type: this.type}).then((res) => {
          this.loading = false
          this.activeIndex = 3
          res.data.headData.unshift({label: '不导入', field: ''})
          this.headerData = res.data.headData
          this.headDataTitle = res.data.label
        });
      } else if (activeIndex === 3) {
        let headerData = []
        for (let i = 0; i < this.headerTd.length; i++) {
          if (this.headerTd[i].value != '') {
            headerData.push({
              cursor: i,
              field: this.headerTd[i].value,
            })
          }
        }
        importExcel({type: this.type, headerTd: headerData, tableData: this.tableData}).then((res) => {
          this.successNum = res.data.successNum
          this.errorNum = res.data.errorNum
          this.excelUrl = res.data.excelUrl
          this.loading = false
          this.activeIndex = 4;
        }).catch(err => {
          this.loading = false
        });
      } else if (activeIndex === 4) {
        this.handleClose()
      }
    },
    handleHeader(value, key) {
      this.headerSelectLen = 0
      this.headerTd.map((el, index) => {
        if (index !== key && el.value === value) {
          el.value = ''
        }
        if (el.value != '') {
          this.headerSelectLen = this.headerSelectLen + 1
        }
        return el
      })
    },
    handleDownload() {
      // this.$downloadFile(this.excelUrl, '错误报告.xlsx')
    },
    downloadExcel() {
      // this.$downloadFile(this.excelTemplate, '标准模板.xlsx')
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog {
  &.is-fullscreen {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: auto;
    background: #fff;

    .el-dialog__body {
      position: relative;
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      height: 100%;
      overflow: auto;

      .txt-box {
        padding-left: 15px;
      }

      .fxy-excel-main-wrapper {
        position: absolute;
        top: 80px;
        right: 0;
        bottom: 0;
        left: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;

        .fxy-excel-import-grid {
          position: absolute;
          top: 40px;
          right: 0;
          bottom: 0;
          left: 0;
          padding: 10px 15px 0;

          .fxy-excel-grid {
            overflow: auto;
          }
        }
      }
    }
  }

  .title {
    font-weight: bold;
  }

  .txt-box {
    margin-top: 20px;

    p {
      line-height: 1.6;
    }
  }

  .el-dialog__body {
    border-top: 1px solid #e8e8e8;
    padding: 20px;
  }

  .el-dialog__title {
    font-size: 16px;
  }

  .full {
    padding-right: 30px;
    color: rgba(0, 0, 0, .5);
    cursor: pointer;

    &:hover {
      color: #1890ff;
    }
  }

  .el-dialog__headerbtn {
    font-size: 20px;
    top: 17px;

    .el-dialog__close:not(:hover) {
      color: rgba(0, 0, 0, .5);
    }
  }

  .el-steps {
    .el-step__icon {
      border: 1px solid;
      width: 30px;
      height: 30px;
    }

    .el-step {
      &.is-horizontal {
        .el-step__line {
          height: 2px;
          top: 15px;
          background-color: #edeff3;
        }
      }
    }

    .el-step__title {
      font-size: 13px;
    }

    .el-step__head {
      &.is-success {
        color: #46a6ff;
        border-color: #46a6ff;
      }
    }

    .el-step__title {
      &.is-success {
        color: #46a6ff;
      }
    }
  }

  .upload-box {
    margin-top: 20px;

    .el-upload {
      display: block;

      .el-upload-dragger {
        width: 100%;
      }
    }
  }

  .excel-import-tool {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 15px;

    .excel-import-count {
      position: absolute;
      top: 50%;
      right: 0;
      padding: 0 8px;
      color: #141e31;
      line-height: 30px;
      background: #fff6d6;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);

      i {
        margin-right: 4px;
        color: #fdc31c;
      }
    }
  }

  .fxy-excel-import-grid {
    margin-top: 20px;
  }

  .fxy-excel-grid {
    height: 350px;
    overflow: auto;
    border: 1px solid #e1e3e5;

    .limit-tip {
      margin: 10px 0 20px;
      color: #838892;
      text-align: center;
    }

    &.screen {
      height: 100%;
    }

    table {
      width: 100%;
      table-layout: fixed;

      tr {
        line-height: 30px;

        &.row-disable {
          color: #838892;
        }

        &.fxy-tr-import {
          &:hover {
            background: #e6e8ed;
            cursor: pointer;
          }
        }

        &:hover:not(.fxy-tr-import) {
          background: #e6e8ed;
          cursor: pointer;

          td {
            &:first-child {
              span {
                display: none;
              }

              .row-label {
                display: block;
                position: relative;
                width: 85px;
                height: 24px;
                margin: 3px auto;
                overflow: visible;
                line-height: 24px;
                color: #141e31;
                background: #fff;

                &:after {
                  position: absolute;
                  top: 0;
                  right: -24px;
                  bottom: 0;
                  border: 12px solid transparent;
                  border-left-color: #0db3a6;
                  content: "";
                  border-color: transparent transparent transparent #fff;
                }
              }
            }
          }
        }

        &.row-head {
          background: #e7f7f6;
          font-weight: 700;

          td {
            &:first-child {
              span {
                display: none;
              }

              .row-label {
                display: block;
                position: relative;
                width: 85px;
                height: 24px;
                margin: 3px auto;
                overflow: visible;
                color: #fff;
                line-height: 24px;
                background: #0db3a6;

                &:after {
                  position: absolute;
                  top: 0;
                  right: -24px;
                  bottom: 0;
                  border: 12px solid transparent;
                  border-left-color: #0db3a6;
                  content: "";
                }
              }
            }
          }
        }

        td {
          width: 170px;
          min-width: 170px;
          max-width: 200px;
          padding: 0 7px;
          text-align: center;
          border-right: 1px solid #e1e3e5;
          border-bottom: 1px solid #e1e3e5;

          &:first-child {
            min-width: 120px;
          }

          span {
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .row-label {
            display: none;
          }
        }
      }
    }
  }

  .import-result {
    text-align: center;
    padding: 90px 0 100px;

    .import-result-icon {
      color: #fa0;
      font-size: 45px;
    }

    .result-tip {
      margin-top: 20px;
      font-size: 15px;

      .success-num {
        margin: 0 4px;
        color: #1890ff;
      }

      .error-num {
        margin: 0 4px;
        color: #e64340;
      }
    }
  }
}
</style>
