<template>
  <div class="dialog__wrapper">
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogShow"
      :destroy-on-close="true"
      custom-class="disabled-none"
      :top="top"
      :modal="dialogModal"
      :append-to-body="true"
      :close-on-click-modal="false"
      :props="{ label: 'name' }"
      width="800px"
      @close="onClose(false)"
      @closed="onClosed"
      @open="handleOpen"
    >
      <div class="dep-container">
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="12" class="panel-box">
            <div class="panel-box_wrap">
              <div class="left-panel_box">
                <div class="search-box">
                  <el-row type="flex">
                    <el-input
                      placeholder="请输入搜索内容"
                      v-model="keyword"
                      size="small"
                      class="input-box"
                    >
                      <i
                        slot="prefix"
                        class="el-input__icon el-icon-search"
                      ></i>
                    </el-input>
                  </el-row>
                </div>
                <div class="nav-box" v-if="isNav">
                  <el-radio-group
                    v-model="typeNav"
                    size="mini"
                    @change="handleChangeTypeNav"
                  >
                    <el-radio-button label="system">组织架构</el-radio-button>
                    <el-radio-button label="post">职务列表</el-radio-button>
                  </el-radio-group>
                </div>
                <!-- @node-click="handleClick" -->
                <div class="dep-box" v-loading="loading">
                  <el-tree
                    ref="tree"
                    :data="treeData"
                    node-key="id"
                    highlight-current
                    :expand-on-click-node="false"
                    :default-expand-all="false"
                    show-checkbox
                    :check-on-click-node="true"
                    :default-expanded-keys="defaultExpandedKeys"
                    :check-strictly="config.checkStrictly"
                    @check="handleCheckTree"
                    :filter-node-method="filterNode"
                    :props="{ label: 'name' }"
                    :auto-expand-parent="true"
                    class="dep-tree"
                  >
                    <div class="custom-tree-node" slot-scope="{ node, data }">
                      <template v-if="data.type === 1">
                        <i class="fxy-icon-wenjianjia"></i>
                      </template>
                      <template v-else-if="data.type === 2">
                        <i class="fxy-icon-username"></i>
                      </template>
                      <template v-else-if="data.type === 3">
                        <i class="fxy-icon-yingpinzhiwei"></i>
                      </template>
                      <span class="nickname" :class="'item-'+data.id">{{ node.label }}</span>
                      <span class="nickname" v-if="data.type == 1 && node.data && node.data.employeeNum">（{{node.data.employeeNum}}）</span>
                    </div>
                  </el-tree>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12" class="panel-box">
            <div class="panel-box_wrap">
              <div class="right-panel_box">
                <div class="check-txt">已选：</div>
                <div class="scroll-cover">
                  <draggable
                    class="list-group"
                    v-model="checkList"
                    :options="{ draggable: '.t-group' }"
                    animation="300"
                  >
                  <div
                    class="r-flex t-group"
                    v-for="(item, i) in checkList"
                    :key="i"
                  >
                    <div class="r-flex-1">
                      <el-row type="flex" class="row-bg" justify="space-between">
                        <div class="">
                          <template v-if="item.type === 1">
                            <i class="fxy-icon-wenjianjia"></i>
                          </template>
                          <template v-else-if="item.type === 2">
                            <i class="fxy-icon-username"></i>
                          </template>
                          <template v-else-if="item.type === 3">
                            <i class="fxy-icon-yingpinzhiwei"></i>
                          </template>
                          <span class="nickname">{{ item.name }}</span>
                        </div>
                      </el-row>
                    </div>
                    <div class="child" @click="handleDeleteNode(item)">
                      <i class="el-icon-delete"></i>
                    </div>
                  </div>
                </draggable>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" class="modal-footer" justify="end">
          <el-checkbox v-model="isHideDep" class="switch-flex flex-left" :true-label="1" :false-label="0"
                       v-if="typeNav == 'system' && !hideDepLock" @change="handleOpen(true)">显示隐藏部门
          </el-checkbox>
          <el-checkbox v-model="includeSubDepSwitch" class="switch-flex" v-if="isIncludeSubDep">是否包含子部门</el-checkbox>
          <el-button size="small" @click="onClose(false)"
          >取 消
          </el-button
          >
          <el-button type="primary" size="small" @click="onSubmit"
          >确 认
          </el-button
          >
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dialogMixin from "@/mixins/dialogCommon";
import {getDepByTree} from "@/api/dep";
import {getPostListByTree} from "@/api/post/index";
import {cloneDeep, isArray, readNodes} from "@/utils";
import {setDepDisabled} from "@/utils/arr";
import draggable from "vuedraggable";
export default {
  name: "DepRoleModal",
  mixins: [dialogMixin],
  components:{
    draggable
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    top: {
      type: String,
      default: '12vh'
    }
  },
  watch: {
    config: {
      deep: true,
      immediate: true,
      handler(val) {
      },
    },
    keyword(val) {
      this.$refs.tree.filter(val);
    },
  },
  filters: {
    avatarFilter(val) {
      return val ? val.indexOf('http:') != -1 || val.indexOf('https:') != -1 ? val : process.env.VUE_APP_IMG_URL + val : process.env.VUE_APP_IMG_URL + 'wx-image/avatar-none-f.png'
    }
  },
  data() {
    return {
      loading: false,
      keyword: null,
      typeNav: "system",
      checkList: [],
      treeData: [],
      depTree: [],
      postTree: [],
      defaultExpandedKeys: [],
      childrenNodeList: [],
      includeSubDepSwitch: false,
      treeResetLock: true,
      isHideDep: 0
    };
  },
  computed: {
    dialogTitle() {
      return this.config.title;
    },
    dialogModal() {
      return this.config.hasOwnProperty("modal") ? this.config.modal : true;
    },
    isIncludeSubDep() {
      return this.config?.includeSubDep && (this.config?.type == 1 || this.config?.type == 2);
    },
    isNav() {
      return this.config?.isNav || false
    },
    hideDepLock() {
      return !!this.config?.hideDepLock;
    }
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    onSubmit() {
      let callbackData = this.isIncludeSubDep ? {
        includeSubDep: this.includeSubDepSwitch ? 1 : 0,
        list: this.checkList,
        model: this.config.model
      } : this.checkList;
      this.$emit("confirm", callbackData, this.config);
    },
    onClosed() {
      this.typeNav = "system";
      this.keyword = '';
      this.checkList = [];
      this.treeData = [];
      this.depTree = [];
      this.postTree = [];
      this.defaultExpandedKeys = [];
    },
    handleCheckTree(data, node) {
      let checkList = [];
      let nodes = [];
      if (this?.config?.isSelectSub) {
        //获取当前元素的node
        let _node = this.$refs.tree.getNode(data.id);
        //获取所有选中的下级元素
        nodes = readNodes(node.checkedNodes, () => {
          return true
        }, []);
        //过滤掉上级或平级的元素
        let parent = nodes.filter(el => {
          let _parent = this.$refs.tree.getNode(el.id);
          return _node.level >= _parent.level && el.id != data.id;
        });
        //提取所有未选中元素的子元素
        let unselectedChildren = readNodes(parent, (el) => {
          return !node.checkedNodes.includes(el)
        }, []);
        //提取未选中的元素
        nodes = nodes.filter(el => {
          return !unselectedChildren.includes(el);
        })
        //获取当前选中元素的所有下级
        if (node.checkedKeys.includes(data.id)) {
          //获取他的子元素，全部添加进入数据中
          let _child = readNodes(data.children, () => {
            return true
          }, []);
          nodes.push(..._child);
        }
        //去重
        nodes = [...(new Set(nodes))];
      }
      if (this.config.selectType === "radio") {
        checkList = cloneDeep(this.checkList);
        if (node.checkedKeys.indexOf(data.id) > -1) {
          let option = {
            id: data.id,
            userId: data?.userId || '',
            name: data.name,
            parentId: data.parentId,
            employeeId: data.employeeId,
            type: data.type,
            avatar: data.avatar || "",
            depName:data.depName || "",
            depId:data.depId && data.depId != '00000000-0000-0000-0000-000000000000' ? data.depId : ""
          }
          //2 员工  4 职务
          if (this?.config?.type == 2 && this?.config?.isStaffDisabled == 1 && !!this?.config?.isContact) {
            //判断是否有和他一样的数据
            let index = findIndex(checkList, el => {
              return el.type == data.type;
            });
            if (index != -1) {
              checkList[index] = option;
            } else {
              checkList.push(option);
            }
          } else {
            checkList = [option];
          }
        } else {
          checkList = [];
        }
      } else if (this.config.selectType === "checkbox") {
        //并选模式
        if (this.config?.isContact) {
          //检查对应选项是否选中
          let check = node.checkedNodes.find(el => {
            return el.id == data.id;
          });
          //选中
          if (check) {
            //删除对应选项
            checkList = cloneDeep(this.checkList);
            checkList.push({
              id: data.id,
              userId: data?.userId || '',
              name: data.name,
              parentId: data.parentId,
              type: data.type,
              employeeId: data.employeeId,
              avatar: data.avatar || "",
              depName:data.depName || "",
              depId:data.depId && data.depId != '00000000-0000-0000-0000-000000000000' ? data.depId : ""
            })
          } else {
            checkList = this.checkList.filter(el => {
              return el.id != data.id;
            })
          }
        } else {
          //判断是否为选中
          let isSelected = node.checkedKeys.indexOf(data.id) > -1;
          if (this.config.isSelectSub) {
            if (isSelected) {
              nodes.forEach((item) => {
                checkList.push({
                  id: item.id,
                  userId: data?.userId || '',
                  name: item.name,
                  parentId: item.parentId,
                  type: item.type,
                  employeeId: item.employeeId,
                  avatar: item.avatar || "",
                  depName:data.depName || "",
                  depId:item.depId && item.depId != '00000000-0000-0000-0000-000000000000' ? item.depId : ""
                });
              });
            } else {
              //提取所有下级元素
              let children = readNodes(data.children, () => {
                return true
              }, []);
              //过滤nodes
              nodes = nodes.filter(el => {
                return !children.includes(el);
              });
              nodes = nodes.filter(el => {
                return el.id != data.id;
              })
              //此模式下数据变化
              nodes.forEach((item) => {
                checkList.push({
                  id: item.id,
                  userId: data?.userId || '',
                  name: item.name,
                  parentId: item.parentId,
                  type: item.type,
                  employeeId: item.employeeId,
                  avatar: item.avatar || "",
                  depName:item.depName || "",
                  depId:item.depId && item.depId != '00000000-0000-0000-0000-000000000000' ? item.depId : ""
                });
              });
            }
          } else {
            //此模式下数据变化
            node.checkedNodes.forEach((item) => {
              checkList.push({
                id: item.id,
                userId: data?.userId || '',
                name: item.name,
                parentId: item.parentId,
                type: item.type,
                employeeId: item.employeeId,
                avatar: item.avatar || "",
                depName:item.depName || "",
                depId:item.depId && item.depId != '00000000-0000-0000-0000-000000000000' ? item.depId : ""
              });
            });
          }
        }
      }
      this.checkList = checkList;
      //选中效果
      if (this.config.isSelectSub) {
        //判断是否为选中
        let isSelected = node.checkedKeys.indexOf(data.id) > -1;
        if (isSelected) {
          //选中状态
          this.$refs.tree.setCheckedNodes(nodes);
        } else {
          //选中状态
          this.$refs.tree.setCheckedNodes(nodes);
        }
      } else {
        if (this.config.isContact) {
          //只选择和自己一致的元素
          let selectKeys = checkList.filter(el => {
            return el.type == data.type;
          });
          this.$refs.tree.setCheckedKeys(selectKeys.map(el => {
            return el.id;
          }));
        } else {
          this.$refs.tree.setCheckedNodes(checkList);
        }
      }
    },
    handleDeleteNode(node) {
      this.checkList.forEach((item, index) => {
        if (item.id === node.id) {
          this.checkList.splice(index, 1);
        }
      });
      this.$refs.tree.setCheckedNodes(this.checkList);
    },
    async handleChangeTypeNav(val) {
      //职位
      if (val === "system") {
        this.treeData = this.depTree;
      } else if (val === "post") {
        if (this.postTree.length === 0) {
          this.postTree = await this.getPostData();
        }
        this.treeData = this.postTree;
      } else if (val === "role") {
      }

      //重新选中
      if (this.config?.isContact) {
        let type = val == 'system' ? 2 : 4;
        let selected = this.checkList.filter(el => {
          return el.type == type;
        });

        this.$refs.tree.setCheckedKeys(selected.map(el => {
          return el.id;
        }))
      }
    },
    getPostData() {
      this.loading = true;
      return getPostListByTree({
        type: this.config.type,
        isStaff: this.config.isStaff || 0,
        isStaffDisabled: this?.config?.isStaffDisabled || 0
      })
        .then((response) => {
          if (response.data.length > 0) {
            this.defaultExpandedKeys = [response.data[0].id];
          }
          this.postTree = response.data;
          this.loading = false;
          return response.data;
        })
        .catch((error) => {
        });
    },
    async handleOpen(isReset) {
      if (!isReset) {
        this.checkList = this.config?.checkList
          ? cloneDeep(this.config.checkList)
          : [];
        this.includeSubDepSwitch = !!this?.config?.includeSubDepSwitch;
      }
      let res = [];
      if (this.config.type === 3) {
        res = await this.getPostData();
      } else {
        this.loading = true;
        res = await getDepByTree({
          type: this.config.type,
          isStaff: this.config.isStaff || 0,
          isStaffDisabled: this?.config?.isStaffDisabled || 0,
          isQuit: this?.config.isQuit || 0,
          isHideDep: this.config?.hideDepLock ? 0 : this.isHideDep,
          isRule: this.config?.isRule || 0,
          isGroup: this.config?.isGroup || 0,
          id: isArray(this.config?.id) ? this.config.id.filter(el => !!el) : [],
          formType: 'system'
        }).then((response) => {
          if (response.data.length > 0) {
            //此处判断一下，是否存在他对应的值
            if(this.config.type == '1' && this.$store.state?.userInfo?.employee?.department){
              this.defaultExpandedKeys = [this.$store.state?.userInfo?.employee?.department];
            }else{
              this.defaultExpandedKeys = [response.data[0].id];
            }
          }
          this.loading = false;
          if (this.config.onlyStaff) {
            setDepDisabled(response.data, 'disabled');
          }
          return response.data;
        });
        this.depTree = res;

        if(this.config.type == '1'){
          //确保获取
          this.$nextTick(()=>{
            this.$nextTick(()=>{
              let item = document.getElementsByClassName(`item-${this.$store.state?.userInfo?.employee?.id}`)[0];
              if(item){
                item.scrollIntoView && item.scrollIntoView(false);
              }
            })
          })
        }
      }
      this.treeData = res;
      this.$nextTick(() => {
        this.$refs.tree.setCheckedNodes(this.checkList);
      });
    }
  },

};
</script>
<style lang="scss" scoped>

::v-deep .el-dialog__body {
  padding: 0 30px 20px;
}

.switch-flex {
  display: flex;
  align-self: center;
  padding-right: 10px;

  ::v-deep .el-checkbox__input {
    position: relative;
    top: 2px;
  }

  &.flex-left {
    margin-right: auto;
  }
}

.dep-container {
  .dep-tree {
    margin-top: 20px;
  }

  .modal-footer {
    margin-top: 10px;
  }

  .panel-box {
    .left-panel_box {
      margin-top: 10px;
      padding-right: 15px;
      height: 400px;
      overflow: auto;

      ::v-deep .input-box {
        .el-input__inner {
          background: #f5f6f7;
          border-color: #f5f6f7;
        }
      }
    }

    .right-panel_box {
      padding-left: 15px;
      height: 400px;
      display: flex;
      flex-direction: column;

      .check-txt {
        margin-bottom: 10px;
      }
    }

    .all-checkbox_box {
      margin: 10px 0;
    }

    .scroll-cover {
      flex: 1 1 auto;
      height: 0;
      overflow: auto;
      padding-right: 10px;
    }

    .checkbox-box {
      padding: 5px 0;

      ::v-deep .el-checkbox__label {
      }

      ::v-deep .el-checkbox__input {
        vertical-align: inherit;
      }
    }

    .fxy-icon-wenjianjia,.fxy-icon-username {
      margin-right: 4px;
      color: #aee4fa;
      font-size: 17px;
      vertical-align: middle;
    }

    .fxy-icon-yingpinzhiwei {
      font-size: 20px;
      vertical-align: middle;
      color: #faad14;
    }

    .child {
      cursor: pointer;

      .el-icon-delete {
        font-size: 16px;
        color: #f56c6c;
      }
    }

    .nav-box {
      text-align: center;
      margin-top: 10px;
    }
  }

  .fxy-avatar {
    width: 25px;
    height: 25px;
    line-height: 25px;
    margin-right: 4px;
  }

  .nickname {
    vertical-align: middle;
  }
}
</style>
