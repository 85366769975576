import request from '@/utils/request';
//图片长传
export function policy(params){
    return request({
      url:`/api/upload/index`,
      method:'post',
      data:params
    })
}

//文件下载(包含改文件名)
export function fileDownload(params){
  return request({
    url:`/api/upload/down-url`,
    method:'get',
    params:params
  })
}
