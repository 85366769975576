<template>
    <transition name="fade-out">
        <div class="login-verification-code">
            <div class="login-verification-code-content r-flex r-flex-column r-col-center">
                <div class="login-verification-code-close" @click="close">
                    <i class="el-icon-close"></i>
                </div>
                <div class="login-verification-code-title">机器验证</div>
                <div class="login-verification-code-img" @click="getVerifyCode">
                    <img v-if="verifyCode.img" :src="verifyCode.img" alt="">
                </div>
                <div class="login-verification-code-input">
                    <el-input v-model="code" placeholder="请输入上方的图形验证码" ref="codeInput"
                        @keydown.native.enter="codeCheck"></el-input>
                </div>
                <el-button type="primary" @click="codeCheck" size="small"
                    :disabled="code.length < 4 || !verifyCode.sn">验证</el-button>
            </div>
        </div>
    </transition>
</template>
<script>
import { verifyCode } from "@/api/user"
export default {
    data() {
        return {
            verifyCode: {},
            code: ''
        }
    },

    methods: {
        codeCheck() {
            this.$emit('confirm', {
                code: this.code,
                sn: this.verifyCode.sn
            })
        },
        getVerifyCode() {
            verifyCode().then(res => {
                this.verifyCode = res.data;
            })
        },
        reset() {
            this.getVerifyCode();
            this.code = '';
        },
        close() {
            this.$emit('close');
        }
    },
    created() {
        this.code = '';
        this.verifyCode = {};
        this.getVerifyCode();
        this.$nextTick(() => {
            this.$refs.codeInput.focus();
        })
    }
}
</script>

<style lang="scss" scoped>
.login-verification-code-content {
    position: absolute;
    background: rgba(255,255,255,1);
    z-index: 99;
    left: 50%;
    top:50%;
    width: 400px;
    height:300px;
    transform: translate(-50%,-50%);

    .login-verification-code-close {
        position: absolute;
        font-size: 28px;
        width: 60px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        right: 0;
        top: 0;
        cursor: pointer;
        color: #b5b5b5;
        transition: color .3s;

        &:hover {
            color: #666666;
        }
    }

    .login-verification-code-img {
        width: 170px;
        height: 60px;
        margin-bottom: 20px;
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .login-verification-code-title {
        font-weight: bold;
        font-size: 24px;
        color: #888888;
        margin-bottom: 20px;
        padding-top: 35px;
    }

    .login-verification-code-input {
        margin-bottom: 20px;
        width: 300px;

        v-deep .el-input {
            width: 100%;

            .el-input__inner {
                text-align: center;

                &:focus {
                    border-color: #1890ff !important;
                }
            }
        }
    }
}

.fade-out-leave-active {
    transition: opacity .3s;
}

.fade-out-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
}</style>