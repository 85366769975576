<template>
  <div class="login-module login-start" ref="loginStart" style="padding: 0">
    <div class="login-module-back-container">
      <!-- <div class="login-module-back-container-cover" @click="toggle">
        <div class="el-icon-monitor" v-if="isCut">
          <div class="sanjiao"></div>
        </div>
        <div class="fxy-icon-erweima1" v-else>
          <div class="sanjiao"></div>
        </div>
      </div> -->
    </div>
    <div class="login-module-title__container">
      <div class="login-module-title">
        <span @click="shortcut(7)">守</span>
        <span @click="shortcut(6)">望</span>
        <span @click="shortcut(5)">者</span>
        <span @click="shortcut(4)">数</span>
        <span @click="shortcut(8)">字</span>
        <span @click="shortcut(9)">乡</span>
        <span @click="shortcut(10)">村</span>
        <span @click="shortcut(11)">管</span>
        <span @click="shortcut(1)">理</span>
        <span @click="shortcut(2)">平</span>
        <span @click="shortcut(3)">台</span>
      </div>
    </div>
    <div
      class="login-module-content between n-p-b"
      style="padding: 0 32px"
      v-if="!isCut"
    >
      <div class="login-module-content-top">
        <div class="input-cover first">
          <el-input
            placeholder="请输入您的手机号码"
            v-model="mobile"
            maxlength="11"
            class="input-with-select"
            :disabled="loading"
            clearable
            ref="phoneInput"
            @keydown.native.enter="mobileEnter"
          >
            <el-select
              v-model="mobileType"
              slot="prepend"
              placeholder="请选择"
              :disabled="loading"
            >
              <el-option label="+86" value="+86"></el-option>
            </el-select>
          </el-input>
        </div>
        <div class="input-cover">
          <el-input
            placeholder="请输入验证码"
            v-model="checkCode"
            class="input-with-select"
            :disabled="loading || !isGetCode"
            clearable
            ref="checkCode"
            @keydown.native.enter="checkCodeComplete"
          >
            <div class="get-code-btn" slot="append" :class="{disabled:disabled||countdown}" @click="getCheckCode">{{countdown ? num :'获取验证码'}}</div>
          </el-input>
        </div>
        <el-button
          type="primary"
          class="login-btn"
          :disabled="loginDisabled"
          @click="checkCodeComplete"
          :loading="loading"
          >登录</el-button
        >
      </div>
    </div>
    <div class="login-code" v-else>
      <p class="login-code-title">请用微信扫描二维码</p>
      <div
        class="login-code-img"
        v-loading="loadingCode"
        :element-loading-text="status == 'LOGGED' ? '正在登录...' :'拼命加载中'"
      >
        <div class="mask" v-if="isRefresh" @click="refresh">
          <div class="mask-cover">
            <div class="fxy-icon-shuaxin tips-icon"></div>
            <div class="mask-title">
              <div class="txt">{{status == 'CANCEL' ? '已取消登录' : '二维码失效'}}</div>
              <div class="shuaxin">点击刷新二维码</div>
            </div>
          </div>
        </div>
        <div class="mask" v-if="isScanned">
          <div class="mask-cover">
            <div class="el-icon-circle-check tips-icon"></div>
            <div class="mask-title">
              <div class="txt">扫码成功</div>
              <div class="shuaxin">请在小程序中点击授权登录</div>
            </div>
          </div>
        </div>
        <el-image fit="fill" :src="qrCodeImg" v-if="!loadingCode"></el-image>
      </div>
    </div>
    <el-dialog
      title="提示"
      custom-class="login-module-tips-dialog"
      :visible.sync="tipsDialogShow"
      width="500px"
      top="37vh"
      append-to-body
      :show-close="false"
      :style="tipsDialogStyle"
      :close-on-click-modal="false"
    >
      <span class="login-tips"
        >请先同意<a href="/" class="main-color">服务协议</a>和<a
          href="/"
          class="main-color"
          >隐私政策</a
        ></span
      >
      <div slot="footer" class="dialog-footer">
        <el-button @click="tipsDialogShow = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="tipsDialogShow = false" size="mini"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <login-verification-code ref="loginVerificationCode" v-if="codeShow" @confirm="verificationCodeConfirm" @close="verifyCodeClose"></login-verification-code>
  </div>
</template>
<script>
import { isPhone } from "@/utils";
import { getCheckCode, qrCode, qrCodeLogin,login } from "@/api/user";
import throttle from "@/mixins/throttle";
import loginVerificationCode from "./login_verification_code";
export default {
  props: ["leftWidth"],
  mixins: [throttle],
  components:{
    loginVerificationCode
  },
  data() {
    return {
      //是否同意客户协议
      checked: true,
      //提示弹窗
      tipsDialogShow: false,
      //加载样式
      loading: false,
      //样式偏移
      paddingLeft: 519,
      isCut: false,
      // qrCodeImg:"",
      datum: {},
      loadingCode: false,
      status: "",
      timer: [],
      isRefresh: false,
      isScanned:false,
      shortcutObj:{
        duration:2,
        over:true,
        keys:{},
        invId:'',
        lock:true
      },
      codeShow:false,
      mobile:'',
      mobileType:'+86',
      checkCode:'',
      //是否在倒计时
      countdown:false,
      //倒计时秒数
      countdownSecond:60,
      //当前倒计时
      num:0,
      isGetCode:false
    };
  },

  computed: {
    // mobile: {
    //   get() {
    //     return this.$store.state.login?.mobile;
    //   },
    //   set(val) {
    //     this.$store.commit("setLoginData", {
    //       mobile: val,
    //     });
    //   },
    // },

    // mobileType: {
    //   get() {
    //     return this.$store.state.login?.mobileType;
    //   },
    //   set(val) {
    //     this.$store.commit("setLoginData", {
    //       mobileType: val,
    //     });
    //   },
    // },

    disabled() {
      return !isPhone(this.mobile) || this.loading;
    },

    loginDisabled(){
      return !isPhone(this.mobile) || this.loading || !this.checkCode
    },

    //调整样式
    tipsDialogStyle() {
      return `padding-left:${this.paddingLeft}px`;
    },
    qrCodeImg() {
      return this.datum.qrCode;
    },
  },
  watch: {
    // status: {
    //   immediate: true,
    //   deep: true,
    //   handler(newVal, oldVal) {
    //     if (newVal == "WAITING") {
    //       this.timer = setInterval(() => {
    //         setTimeout(() => {
    //           this.getQrCodeLogin(this.datum.key);
    //         });
    //       }, 1000);
    //     }
    //   },
    // },
  },
  methods: {
    shortcut(number){
      if(number>3) return;
      //不在定时中，开始定时
      if(!this.shortcutObj.invId){
        //第一个选择的数字
        this.shortcutObj.keys[number] = 1;
        this.shortcutObj.invId = setInterval(()=>{
          if(this.shortcutObj.duration == 0){
            clearInterval(this.shortcutObj.invId);
            this.shortcutObj.invId = '';
            let check = 0;
            this.shortcutObj.duration = 2;
            let keys = Object.keys(this.shortcutObj.keys);
            keys.forEach(el=>{
              check+=this.shortcutObj.keys[el];
            });
            if(check > 8 && keys.length == 3){
              //关锁
              this.shortcutObj.lock = false;
              if(this.$refs?.phoneInput){
                this.$refs.phoneInput.focus();
              }
            }else{
              this.shortcutObj.lock = true;
            }
            this.shortcutObj.keys = {};
          }else{
            this.shortcutObj.duration--;
          }
        },1000)
      }else{
        if(!this.shortcutObj.keys[number]){
          this.shortcutObj.keys[number] = 0;
        }
        this.shortcutObj.keys[number]+=1;
      }
    },
    toggle() {
      this.isCut = !this.isCut;
      this.clearTimer();
      if (this.isCut) {
        this.loadingCode = true;
        this.status = "";
        this.getQrCode();
      } else {
        this.isRefresh = false;
      }
    },
    clearTimer(){
      this.timer.forEach(el=>{
        clearInterval(el);
      })
    },
    refresh() {
      this.loadingCode=true;
      this.isRefresh = false;
      this.status = "";
      this.getQrCode();
    },
    getQrCode() {
      this.throttle({
        key:"countdown",
        time:1000,
        callback:()=>{
          if(!this.isCut) return;
          qrCode().then((res) => {
            this.loadingCode = false;
            this.datum = res.data;
            let timer = setInterval(() => {
              this.getQrCodeLogin(this.datum.key);
            }, 1000);
            this.timer.push(timer);
          })
          .catch(err=>{
            console.log(err);
            this.clearTimer();
          })
        }
      })
    },
    getQrCodeLogin(val) {
      return qrCodeLogin({ key: val }).then((res) => {
        this.status = res.data.status;
          if (this.status == "EXPIRED" || this.status == 'CANCEL') {
            this.isRefresh = true;
            this.isScanned = false;
            this.loadingCode = false;
            this.clearTimer();
          }else if(this.status == 'SCANNED'){
            this.isScanned = true;
            this.isRefresh = false;
          }else if(this.status == 'LOGGED'){
            this.isScanned = false;
            this.isRefresh = false;
            this.loadingCode = true;
            this.clearTimer();
            this.$nextTick(()=>{
                //登录成功,设置用户基础数据缓存
                this.$store.commit('userInfoInit',res.data);
            })
          }
      });
    },
    //检查验证码
    checkCodeComplete(){
      if(this.loginDisabled) return;
      //判断是否勾选了用户协议
      if (!this.checked) {
        this.tipsDialogOpen();
        return;
      }
      this.loading=true;
      login({phone:this.mobile,code:this.checkCode})
      .then((res)=>{
        //登录成功,设置用户基础数据缓存
        this.$store.commit('userInfoInit',res.data);
        //进入页面
        this.$router.push({path:"/",replace:true});
      }).catch(err=>{
        console.log(err)
      }).finally(()=>{
        this.loading=false;
      })
    },
    //打开提示弹窗
    tipsDialogOpen() {
      this.paddingLeft =
        document.getElementsByClassName("login-left")[0].clientWidth || 519;
      this.$nextTick(() => {
        this.tipsDialogShow = true;
      });
    },
    getCheckCode(){
      if(this.disabled || this.countdown) return;
      this.codeShow = true;
    },
    //发起校验
    verificationCodeConfirm(data){
      if(!this.shortcutObj.lock){
        this.$message.success('已发送验证码')
        this.shortcutObj.lock=true;
        this.codeShow = false;
        this.isGetCode=true;
        this.$nextTick(()=>{
          this.$refs.checkCode.focus();
        })
        return;
      }else{
        this.resetCheckCode(data);
      }
    },
    //重新发送验证码
    resetCheckCode(code){
      if(this.loading) return;
      getCheckCode({phone:this.mobile,...code}).then((res)=>{
        this.$message.success('已发送验证码')
        this.countdownStart();
        this.codeShow = false;
        this.isGetCode=true;
        this.$nextTick(()=>{
          this.$refs.checkCode.focus();
        })
      }).catch((err)=>{
        this.$refs.loginVerificationCode.reset();
      })
    },
    //开始倒计时
    countdownStart(){
      //开始倒计时
      this.countdown=true;
      this.num=this.countdownSecond;
      this.num--;
      const inv_id = global.setInterval(()=>{
        this.num--;
        if(!this.num){
          this.countdown=false;
          global.clearInterval(inv_id);
        }
      },1000);
    },
    verifyCodeClose(){
      this.codeShow = false;
    },
    mobileEnter(){
      !this.loginDisabled ? this.checkCodeComplete() :  this.getCheckCode();
    }
  },
  mounted(){
    this.loadingCode = true;
    this.status = "";
    this.getQrCode();
  },
  beforeDestroy(){
    if(this.timer){
      clearInterval(this.timer)
    }
  }

};
</script>

<style lang="scss">
.login-start {
  .login-module-back-container {
    position: relative;
    .login-module-back-container-cover{
      position: absolute;
      right:0;
      top:0;
      width: 78px;
      height:78px;
      cursor: pointer;
    }
    .fxy-icon-erweima1 {
      position: absolute;
      right: 0px;
      top: 0px;
      width: 78px;
      height: 78px;
      font-size: 42px;
      background-color: #4285f6;
      color: #ffffff;
      line-height:21px;
    }
    .fxy-icon-erweima1::before {
      position: absolute;
      top: 20px;
      right: 8px;
    }
    .el-icon-monitor {
      position: absolute;
      right: 0;
      top: 0;
      width: 78px;
      height: 78px;
      font-size: 50px;
      background-color: #4285f6;
      color: #ffffff;
    }
    .el-icon-monitor::before {
      position: absolute;
      top: 3px;
      right: 4px;
    }
    .sanjiao {
      border-top: 41px solid transparent;
      border-right: 41px solid transparent;
      border-bottom: 41px solid #ffffff;
      border-left: 41px solid #ffffff;
      position: relative;
      left:-1px;
      bottom:-1px;
    }
  }
  .login-module-content-top{
    width: 350px;
    padding-top:30px;
  }
  .login-module-title__container {
    .login-module-title {
      text-align: center;
      user-select: none;
      
      span {
        text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
      }
    }
  }
  .login-code {
    .login-code-title {
      text-align: center;
      margin: 22px 0;
      font-size: 13px;
      color: #777777;
    }
    .login-code-img {
      width: 250px;
      height: 250px;
      margin: 60px auto;
    }
    .mask {
      cursor: pointer;
      width: 250px;
      height: 250px;
      position: absolute;
      opacity: 0.8;
      background: rgba(0,0,0,.8);
      z-index: 1;
      .mask-cover{
        width: 100%;
        height:100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      .tips-icon {
        font-size: 50px;
        color:#fff;
        line-height:1;
        margin-bottom:10px;
        &.fxy-icon-shuaxin{
          font-size:46px;
        }
      }
      .mask-title {
        .txt {
          text-align: center;
          font-size: 18px;
          color: #fff;
          margin-bottom: 10px;
        }
        .shuaxin {
          text-align: center;
          color:#fff;
        }
      }
    }
  }
  .login-btn{
    height:45px;
    border-radius: 33px;
  }
  .input-cover {
    margin-top: 15px;
    border-radius: 5px;
    overflow: hidden;
    &.first{
      margin-top:35px;
    }
    .el-input-group__append{
      background: transparent;
      border:0;
      padding: 0;
    }
    .get-code-btn{
      padding: 0 20px;
      height:45px;
      line-height:45px;
      user-select: none;
      transition: opacity .3s;
      cursor: pointer;
      color:#fff;
      background: #1890ff;
      &:active{
        opacity: .8;
      }
      &.disabled{
        background:#8cc8ff;
         &:active{
          opacity: 1;
        }
        cursor: not-allowed;
      }
    }
  }
  .el-select .el-input {
    width: 80px;
  }
  .el-input.is-disabled {
    & > input {
      background-color: #fff;
    }
  }
  .input-with-select {
    height:45px;
    border-radius: 33px;
    overflow: hidden;
    .el-input__inner {
      border: 0;
      height:45px;
      &:focus {
        border-color: transparent;
      }
    }
    .el-input-group__prepend {
      background-color: #fff;
      border: 0;
      .el-input.is-disabled {
        .el-input__inner {
          border-color: transparent;
          background-color: transparent;
          color: inherit;
          border-top: 0;
          border-bottom: 0;
        }
      }
      .el-input__inner {
        color: #666;
      }
      position: relative;
      &::before {
        content: "";
        position: absolute;
        right: 0;
        height: 50%;
        width: 1px;
        top: 50%;
        transform: translateY(-50%);
        background: #ddd;
      }
    }
  }

  .checkbox-cover {
    margin-top: 15px;
    .login-tips {
      font-size: 13px;
      padding-left: 8px;
      color: #333333;
    }
  }
  .login-module-content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .login-module-content-bottom {
      .el-divider .el-divider__text {
        color: #999999;
        font-size: 12px;
      }
      .login-type-list {
        height: 30px;
      }
    }
  }
}

.login-module-tips-dialog {
  border-radius: 5px;

  .el-dialog__title {
    font-size: 18px;
  }

  .el-dialog__body {
    padding: 10px 20px;

    .login-tips {
      font-size: 14px;

      color: #333333;
    }
  }
}
</style>
