<template>
    <div>
        <r-drawer ref="rDialog" size="60%" dialogTitle="角色管理" :isScroll="false" @close="destroy" :footer="false">
            <template v-slot:title>
                <div class="r-flex r-row-bet r-col-bottom r-pr-10">
                    <p class="r-drawer-title">角色管理</p>
                    <el-button type="primary" size="mini" icon="el-icon-plus" @click="addRole">新增角色</el-button>
                </div>
            </template>
            <r-table height="100%" :columns="columns" :loader="loader" ref="table">
                <template v-slot:handle="{row}">
                    <el-link type="primary" @click="edit(row)">编辑</el-link>
                    <el-divider direction="vertical"></el-divider>     
                    <el-popconfirm title="确定要删除该指标吗？" @confirm="delRole(row)">
                        <el-link type="primary" slot="reference">删除</el-link>
                    </el-popconfirm>
                </template>
            </r-table>
        </r-drawer>
        <add-role ref="addRole" @update="refresh"></add-role>
    </div>
    </template>
    <script>
    import {roleListByPage,del} from "@/api/system/role";
    import addRole from "./add-role.vue"
    export default {
        components:{addRole},
        data(){
            return{
                columns:[
                    {
                        label:'角色',
                        prop:'title',
                        'min-width':'120px'
                    },{
                        label:'操作',
                        prop:'handle',
                        'width':'150px'
                    }
                ],
                tableData:[],
                loading:false
            }
        },
    
    
        methods:{
            open(){
                this.$refs.rDialog.handleOpen();
            },
            loader(){
               return roleListByPage().then(res=>{
                    return res.data;
                })
            },
            destroy(){
                this.tableData = [];
            },
            addRole(){
                this.$refs.addRole.open();
            },
            edit(row){
                this.$refs.addRole.open(row);
            },
            delRole(row){
                del(row.id).then(res=>{
                    this.$message.success(`角色 "${row.title}" 已删除`);
                    this.refresh();
                })
            },
            refresh(){
                this.$refs.table.refresh();
            }
        }
    
    
    }
    </script>
    
    <style lang="scss" scoped>
        .tips{
            color:#C0C4CC;
        }
    </style>