import request from '@/utils/request'

export function categoryList(data) {
    return request({
        url: `/api/article/category-list?page=${data.page}&pageSize=${data.pageSize}`,
        method: 'post',
        data
    })
}

export function saveCategory(data) {
    return request({
        url: `/api/article/save-category`,
        method: 'post',
        data:data
    })
}

export function delCategory(data) {
    return request({
        url: `/api/article/del-category`,
        method: 'post',
        data:data
    })
}

export function list(data) {
    return request({
        url: `/api/article/list`,
        method: 'post',
        data
    })
}


export function save(data) {
    return request({
        url: `/api/article/save`,
        method: 'post',
        data
    })
}


//问题审核状态列表
export function del(data) {
    return request({
        url: `/api/article/del`,
        method: 'post',
        data
    })
}