/**
 * 此处放置不需要鉴权的路由，则表示，此文件下的所有路由都应该设置
 * @meta
 *
 * @param {Boolean} common 表示此路由不需要做登录限制 , meta里不包含此项的话，在未登录的情况下将默认跳转到登录页
 *
 * @param {Boolean} login 表示需要登录验证的公共界面，及需要登录验证，但是又是动态生成的，不包含在layout下面的子页面
 *
 * 注意，动态路由不需要设置login以及common
 *
 */
import login from "@/views/common/login/index";
export default function () {

  return [
    //登录页面
    {

      path:'/login',
      name:'login',
      component:login,
      meta:{
        common:true,
        title:"欢迎使用守望者数字乡村管理平台"
      }
    }
  ]
}

