<template>
    <el-card class="r-panel" :class="{'r-panel-scroll-wrapper':isScroll}">
        <div slot="header" class="r-flex" v-if="headerShow">
            <div class="r-flex r-panel-title" :class="{'r-flex-auto-w r-pr-10':autoSize == 'left'}">
                <span>{{title}}</span>
                <slot name="title"></slot>
            </div>
            <div class="r-panel-toolbar r-row-right r-flex" :class="{'r-flex-auto-w r-pl-10':autoSize == 'right'}">
                <slot name="toolbar"></slot>
            </div>
        </div>
        <div class="r-panel-scroll">
            <slot></slot>
        </div>
    </el-card>
</template>
<script>

export default {
    name:'rPanel',
    props:{
        title:{
            type:String,
            default:''
        },
        headerShow:{
            type:Boolean,
            default:true
        },
        isScroll:{
            type:Boolean,
            default:true
        },
        autoSize:{
            type:String,
            default:'right'
        }
    }


}
</script>

<style lang="scss" scoped>
.r-panel{
    height:100%;
    display: flex;flex-direction: column;
    ::v-deep .el-card__header{
        padding: 10px 15px;
        position: relative;
        &:after{
            content:'';
            position: absolute;
            height:50%;
            width: 3px;
            background: var(--primary);
            left:0;
            top:0;
            bottom:0;
            margin:auto 0;
        }
    }

    &-title{
        span{
            white-space: nowrap;
        }
    }

    ::v-deep .el-card__body{
        
        padding: 15px;
        height:100%;
    }

    &.r-panel-scroll-wrapper ::v-deep .el-card__body{
        overflow: hidden;
        overflow-y:auto;
    }

    .r-panel-scroll{
        height:100%;
    }
}
</style>