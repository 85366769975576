import request from '@/utils/request'

export function getDepByTree(data) {
  return request({
    url: '/api/company/dep-by-tree',
    method: 'post',
    data: data
  })
}

export function create(data) {
  return request({
    url: '/api/company/create-department',
    method: 'post',
    data: data
  })
}

export function del(data) {
  return request({
    url: '/api/company/del-department',
    method: 'post',
    data: data
  })
}

export function setDepartmentDirectorByEmployee(data) {
  return request({
    url: '/api/company/set-dep-director',
    method: 'post',
    data: data
  })
}

export function setHideDepartment(data) {
  return request({
    url: '/api/company/set-hide-department',
    method: 'post',
    data: data
  })
}

export function getDepartmentDetail(data) {
  return request({
    url: '/api/company/get-department-detail',
    method: 'post',
    data: data
  })
}

export function updateDepartment(data) {
  return request({
    url: '/api/company/update-department',
    method: 'post',
    data: data
  })
}
