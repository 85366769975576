<template>
    <div>
        <r-drawer ref="rDialog" size="800px" direction="rtl" dialog-title="操作记录" @close="close">
            <template v-slot:title>
                <div class="r-flex r-row-bet r-col-bottom">
                    <p class="r-drawer-title">操作记录</p>
                    <div class="r-flex">
                        <div class="r-pr-20">
                            <el-button-group>
                                <el-button :type="query.orderBySort == 'asc' ? 'primary' : ''" size="mini" round
                                    @click="sortChange('asc')">时间正序</el-button>
                                <el-button :type="query.orderBySort == 'desc' ? 'primary' : ''" size="mini" round
                                    @click="sortChange('desc')">时间倒序</el-button>
                            </el-button-group>
                        </div>
                    </div>
                </div>
            </template>
            <div class="top-table-container">
                <el-timeline v-if="list.length>0 && isInit">
                    <el-timeline-item :timestamp="item.createdAt" placement="top" v-for="item in list" :key="item.id">
                    <el-card>
                        <h4>{{ item.title}}</h4>
                        <p style="color:#666666">{{ item.content }}</p>
                    </el-card>
                    </el-timeline-item>
                </el-timeline>
                <r-empty v-else>暂无操作记录</r-empty>
            </div>
            <template v-slot:footer>
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-sizes="pageSizes" :page-size="pageSize" background
                    layout="total, sizes, prev, pager, next" :hide-on-single-page="false" :total="total">
                </el-pagination>
            </template>
        </r-drawer>
    </div>
</template>
<script>
import { taskLog } from '@/api/approve';
export default {
    data() {
        return {
            query: {
                taskId:'',
                orderBySort:'desc'
            },
            currentPage: 1,
            pageSizes: [10, 20, 30, 40, 50, 100],
            pageSize: 20,
            total: 0,
            list:[],
            isInit:false
        }
    },
    computed:{
        columns(){
            return [
                {
                    label: '操作时间',
                    prop: 'createdAt',
                    "width": '200px',
                    sortable: 'custom',
                }, 
                {
                    label:'操作内容',
                    prop: 'content',
                    'min-width': "300px"
                }
            ]
        }
    },
    methods: {
        open(taskId) {
            this.query.taskId = taskId;
            this.loader();
            this.$refs.rDialog.handleOpen();
        },
        close(){
            this.list = [];
            this.isInit = false;
            this.pageSize = 50;
            this.currentPage = 1;
            this.query = {
                taskId:'',
                orderBySort:'desc'
            }
        },
        search() {
            this.currentPage = 1;
            this.loader(true);
        },
        sortChange(order) {
            this.query.orderBySort = order;
            this.$nextTick(() => {
                this.search();
            })
        },
        handleCurrentChange(page){
            this.currentPage = page;
            this.loader(true);
        },
        handleSizeChange(size){
            this.pageSize = size;
            this.loader(true);
        },
        loader(isTop = false) {
            return taskLog({ page:this.currentPage, pageSize:this.pageSize, ...this.query }).then(res => {
                this.isInit = true;
                this.list = res.data.list;
                this.total = res.data.total;
                if(isTop){
                    this.$refs.rDialog.scrollTop();
                }
            })
        }
    }


}
</script>

<style lang="scss">
.top-table-container {
    height: 100%;
    .el-timeline-item__timestamp{
        font-size:14px;
    }
}
</style>