<template>
    <div class="pro-sidebar">
        <el-menu  :default-active="routeActive" router class="sidebar-menu" :unique-opened="true" background-color="var(--sidebar)"
      text-color="#fff"
      active-text-color="#ffd04b">
            <template v-for="(item, index) in menuList">
                <el-submenu :index="item.name" :key="index" v-if="item.children">
                    <template slot="title">
                        <i :class="item.icon"></i>
                        <span>{{ item.text }}</span>
                    </template>
                    <el-menu-item :index="sitem.name" :route="{ name: sitem.name}"
                        v-for="(sitem, sindex) in item.children" :key="sindex">
                        <i class="iconfont iconhome"></i>
                        <span slot="title">{{ sitem.text }}</span>
                    </el-menu-item>
                </el-submenu>
                <el-menu-item :index="item.name" :key="index" :route="{ name: item.name}" v-else>
                    <i class="iconfont iconhome"></i>
                    <span slot="title">{{ item.text }}</span>
                </el-menu-item>
            </template>
        </el-menu>
    </div>
</template>
<script>

export default {

    data() {
        return {
            list: []
        }
    },
    methods: {
        link(item) {
            this.$router.push({ path: item.path });
        }
    },
    computed:{
        menuList(){
            return this.$store.state.userInfo.originalMenus;
        },
        routeActive(){
            return this.$route.name
        }
    }


}
</script>

<style lang="scss" scoped>
.pro-sidebar {
    width: 240px;
    height: 100%;
    overflow: scroll;
    padding-top: 20px;
    border-right: 1px solid var(--header-border);
    background: var(--sidebar);
    .el-menu{
       border-right:0; 
    }
    &::-webkit-scrollbar {
        display: none;
    }

    &::-webkit-scrollbar-track {
        display: none;
    }

    &-item {
        height: 80px;
        cursor: pointer;
        border-right: 2px solid var(--theme-bg);
        color: #fff;

        &.active {
            background: rgba(255, 255, 255, .1);
            border-right: 2px solid rgba(255, 255, 255, .4);
        }
    }
}
</style>