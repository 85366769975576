<template>
  <div class="dialog-cover" v-loading="loading" element-loading-text="加载中...">
    <div class="dialog-cover-detail" v-show="!isResubmit">
      <div class="dialog-cover-ctn r-flex r-col-top">
        <div class="dialog-apply-content r-flex-2 r-flex r-flex-column">
          <div class="scroll-view">
            <div class="apply-item-cover content">
              <template v-for="(item, index) in list">
                <div class="form-item r-flex r-col-top" :key="item.id">
                  <div class="form-item-label" v-if="itemLabelShow(item)">{{ item.config.label }}：</div>
                  <template v-if="item.config.type == 'TYPE_SYSTEM'">
                    <div class="form-item-value" v-if="item.config.tagIcon == 'taskNumber'">
                      <el-tag>#{{item.config.defaultValue}}</el-tag>
                    </div>   
                    <div class="form-item-value" v-else-if="item.config.tagIcon == 'status' || item.config.tagIcon == 'filingsStatus'">
                      <el-tag :color="item.config.defaultValue.color" style="color:#fff">{{item.config.defaultValue.label}}</el-tag>
                    </div>    
                    <div class="form-item-value" v-else-if="item.config.tagIcon == 'score'">
                      <span class="main-color">{{item.config.defaultValue}}</span>
                    </div>
                    <div class="form-item-value" v-else-if="item.config.tagIcon == 'employee'">
                      <span class="main-color hover--opacity" @click="showName">{{item.config.defaultValue}}</span>
                    </div>
                    <div class="form-item-value r-flex r-flex-wrap" v-else-if="item.config.tagIcon == 'parentList'">
                      <div class="r-flex" v-for="(_item,_index) in item.config.defaultValue" :key="_item.id">
                        <span class="r-pl-10 r-pr-10" v-if="_index !=0">/</span>
                        <el-tag type="info" class="hover--opacity" @click="indicatorOpen(_item)">{{ _item.name }}</el-tag>
                      </div>
                    </div>
                    <div class="form-item-value" v-else :class="{'task-name':item.config.tagIcon == 'name'}">{{ item.config.defaultValue || '暂无' }}</div>
                  </template>
                  <div class="form-item-value" v-else-if="item.config.tagIcon == 'upload-image' || item.config.tagIcon == 'camera'">
                    <div class="image-list" v-if="item.value.length>0">
                      <div class="image-item" v-for="(_item,_index) in item.value" :key="_index"
                          @click="previewImageShow(item.value,_index)">
                        <el-image
                          style="width: 100%; height: 100%"
                          :src="_item | imgUrl"
                          fit="cover"></el-image>
                      </div>
                    </div>
                    <span v-else>--</span>
                  </div>    
                  <div class="form-item-value" v-else-if="item.config.tagIcon == 'upload'">
                    <div class="file-list">
                      <div class="file-item" v-for="(_item,_index) in item.value" :key="_index">
                        <div @click="downloadFile(_item)" class="file-item-link">
                          <i class="el-icon-download c-icon"></i>
                          <span>{{ _item.name }}</span>
                          <a :href="_item.url | imgUrl" @click.stop target="_blank" class="el-icon-view file-preview"></a>
                        </div>
                      </div>
                    </div>
                  </div>    
                  <div class="form-item-value" v-else-if="item.config.tagIcon == 'edit'">
                    <div class="rich-text" v-html="item.config.defaultValue"></div>
                  </div>
                  <div class="form-item-value" v-else>{{ item.value }}</div>
                </div>
              </template>
            </div>
          </div>
          <div class="scroll-footer r-flex r-row-right" v-if="isInit">
            <el-button type="danger" size="small" plain @click="delTask" v-if="$has('task-delete')">删除</el-button>
            <el-button type="primary" size="small" @click="editTask" v-if="$has('task-edit')">编辑</el-button>
          </div>
        </div>
        <div class="flow-wrapper r-flex-1 r-flex r-flex-column r-col-top">
          <div class="flow-toolbar r-flex r-row-right">
              <el-button type="success" size="small" class="r-mr-20" @click="taskLogOpen">操作记录</el-button>
              <r-icon name="close" size="32" class="hover--opacity" @click.native="detailClose(false)"></r-icon>
          </div>
          <div class="title">流程</div>
          <div class="scroll-view">
            <div class="flow-list">
            <div class="flow-line-wrapper">
              <div class="flow-line-cover">
                <div class="line"></div>
              </div>
            </div>
            <div class="flow-list-cover">
              <div class="flow-item" v-for="(item, index) in flow" :key="index">
                <div class="flow-item-cover u-flex u-col-top">
                  <div class="avatar">
                    <img :src="(item.avatar || 'wx-image/avatar-none.png') | imgUrl" v-avatar v-if="!item.isGroup" />
                    <div class="icon-cover u-flex u-col-center u-row-center" v-else>
                      <i :class="item.type == 1
                      ? 'fxy-icon-a_6'
                      : item.type == 5 ? 'fxy-icon-zhihangren-01-copy' : 'fxy-icon-laba'
                      "></i>
                                      </div>
                                      <div class="status-icon" :class="{
                      success: item.status == 1,
                      err: item.status == 2,
                      wait: item.status == 3,
                    }"></div>
                  </div>
                  <div class="flow-item-content">
                    <div class="flow-item-top u-flex u-col-center u-row-between">
                      <div class="s-title">{{ item.title }}</div>
                      <div class="date">
                        <span>{{ item.date }}</span>
                        <div v-if="item.isGroup && item.type != 5" class="arrow-cover" @click="toggleMore(item)">
                          <i :class="{ open: item.show }" class="switch fxy-icon-xiala-01"></i>
                        </div>
                      </div>
                    </div>
                    <div class="tips" v-if="item.tips">{{ item.tips }}</div>
                    <div class="p-list u-flex u-col-top" v-if="item.list && item.show && item.isGroup">
                      <div class="p-item u-flex u-col-center u-row-center" v-for="(_item, index) in item.list"
                        :key="index">
                        <div class="s-avatar">
                          <img :src="_item.avatar | imgUrl" v-avatar />
                          <div class="s-status" :class="{
                              success: _item.status == 1,
                              err: _item.status == 2,
                              wait: _item.status == 3,
                            }"></div>
                        </div>
                        <div class="s-name">{{ _item.name }}</div>
                      </div>
                    </div>
                    <div class="s-remark" v-if="item.remark">{{ item.remark }}</div>
                    <div class="s-remark" v-if="!!item.auto"><span class="m-color">未找到审批人</span>，已自动通过</div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
          <div class="scroll-footer r-flex r-row-right">
            <el-button type="warning" size="small" @click="handleScore" v-if="scoreChangeShow && $has('task-score-edit')">修改评分</el-button>
            <el-button type="warning" size="small" @click="handleRefuse(1)" v-if="nodeType == 5 && isCheck">评分</el-button>
            <el-button type="danger" size="small" @click="handleRefuse(2)" v-if="(nodeType == 1 || nodeType == 9) && isCheck">驳回</el-button>
            <el-button type="primary" size="small" @click="handleRefuse(1)" v-if="(nodeType == 1 || nodeType == 9) && isCheck">通过</el-button>
          </div>
        </div>
      </div>
      <image-show v-model="imagePreview" :imageList="imageList" :imageIndex="imageIndex"></image-show>
    </div>
    <el-dialog :title="nodeType == 5 ? '评分' : '审批' + '备注'" :visible.sync="dialogVisible" :close-on-click-modal="false"
      :width="nodeType == '5' ? '300px'  : '600px'" append-to-body class="remark-dialog">
      <el-form :model="checkData" ref="formData" label-width="auto" size="small">
        <el-form-item label="任务评分" prop="remark" v-if="nodeType == 5">
          <el-input-number v-model="checkData.score" :min="0" :precision="2"  :max="5" :controls="false" style="width:196px"></el-input-number>
        </el-form-item>
        <el-form-item :label="'备注'" prop="remark" v-if="nodeType == 1 || nodeType == 9">
          <el-input type="textarea" :placeholder="'请填写备注'" v-model="checkData.remark" maxlength="300"
            show-word-limit rows="4">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleRefuseCheck" size="small" :disabled="loading" :loading="loading">确 定</el-button>
      </span>
    </el-dialog> 
    <el-dialog title="修改评分" :visible.sync="scoreDialogVisible" :close-on-click-modal="false"
      width="300px" append-to-body class="remark-dialog">
      <el-form :model="scoreData" ref="formData" label-width="auto" size="small">
        <el-form-item label="任务评分" prop="remark">
          <el-input-number v-model="scoreData.score" :min="0" :precision="2"  :max="5" :controls="false" style="width:196px"></el-input-number>
        </el-form-item>
        <el-form-item :label="'备注'" prop="remark">
          <el-input type="textarea" :placeholder="'请填写备注'" v-model="scoreData.remark" maxlength="300"
            show-word-limit rows="4">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="scoreDialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleScoreChange" size="small" :disabled="loading" :loading="loading">确 定</el-button>
      </span>
    </el-dialog>
    <approveForm ref="approveForm" @close="approveUpdate"></approveForm>
    <indicatorDetail ref="indicatorDetail"></indicatorDetail>
    <task-log ref="taskLog"></task-log>
  </div>
</template>
<script>
import { isArray, guid, isObjectNone, cloneDeep, convertCurrency,findIndex } from "@/utils";
import imageShow from "@/components/imageShow.vue";
import {  check, detail,taskDel,taskScore } from "@/api/approve";
import taskLog from "@/components/task-log"
export default {
  name: 'applyDetail',
  components: {
    imageShow,
    approveForm:()=>import('@/components/approve/createForm/index'),
    indicatorDetail:()=>import('@/views/indicator/components/detail/index'),
    taskLog
  },
  props: {
    //当前选中的数据
    data: {
      type: String,
      default: '0',
    },
    //锁，用于隐藏重复引用
    lock: {
      type: Array,
      default() {
        return []
      }
    },
    operate: {
      type: Boolean,
      default: true
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      drawerDet: false,
      drawerEdit: false,
      currentApplyId: '',
      isResubmit: false,
      isFullScreen: false,
      dialogFull: false,
      drawer: false,
      drawerItem: {},
      drawerTitle: '成交单明细',
      salaryShow: false,
      salaryDialogConfig: {
        title: '工资单明细',
        date: '',
        depId: ''
      },
      imagePreview: false,
      imageList: [],
      imageIndex: 0,
      detailData: {},
      //针对特殊套件处理用成交单
      detailBeatData: {},
      loading: true,
      flow: [],
      list: [],
      suiteName: "",
      dialogVisible: false,
      checkData: {
        remark: '',
        status: 1,
        score:'',
      },
      approvalStaffType: {
        1: "审批人",
        2: "抄送人",
        5: "评分人",
        6: '申请人'
      },
      approvalStatus: {
        0: "待审批",
        1: "已通过",
        2: "已驳回",
        3: "审批中",
        6: '已撤回'
      },
      approvalTypeStatus: {
        1: "依次审批",
        2: "会签",
        3: "或签",
      },
      suiteNameText: {
        'reimbursementSuite': '报销单',
        'collectionSuite': '收款单',
        'punishSuite': '惩罚单',
        'rewardSuite': '奖励单'
      },
      //含有明细的套件
      detailSuite: ['collectionSuite', 'punishSuite', 'rewardSuite', 'officialStaffSuite', 'promotionSuite', 'salaryIncreaseSuite', 'shiftJobsSuite'],
      resubmitLoading: false,
      //是否要选择公账
      isSelectBankAccount: false,
      //选择公账选项列表
      bankAccountObj: {
        checkBankList: []
      },
      isInit:false,
      scoreCheck:['status_overdue_completion','status_completed','unable_complete','status_not_finished'],
      scoreData:{
        score:'',
        remark:''
      },
      scoreDialogVisible:false
    };
  },
  filters: {
    imgUrl(value) {
      if (!value) return "";
      if (value.indexOf('http:') != -1 || value.indexOf('https:') != -1) {
        return value;
      } else {
        return process.env.VUE_APP_IMG_URL + value;
      }
    },
    money(val) {
      let money = parseFloat(val);
      return `￥${money.toLocaleString()}`
    }
  },
  computed: {
    title() {
      return this.detailData?.title;
    },
    avatar() {
      return this.detailData?.employee?.avatar || "wx-image/avatar-none.png";
    },

    //公司名称
    companyName() {
      return this.$store.state.userInfo.company?.companyName || "-";
    },

    //审批编号
    approvalNumber() {
      return this.detailData?.number || "-";
    },
    //创建时间
    createdAt() {
      return this.detailData?.createdAt || "";
    },

    //用户信息
    employee() {
      return this.detailData?.employee || {};
    },

    //本人的id
    userId() {
      return this.$store.state.userInfo.employee?.userId;
    },

    //本人的员工id
    employeeId() {
      return this.$store.state.userInfo?.employee?.id;
    },

    //是否为本人提交的单子
    isSelf() {
      return this.employee?.id === this.employeeId;
    },

    //权限类型，现在有，个人，审批人，抄送人, 默认是抄送人
    permission() {
      return this.detailData?.permission || 3;
    },

    //是否为审批人
    isCheck() {
      return !!this.detailData?.isCheck;
    },

    //审批单的状态 默认是待审批
    status() {
      return this.detailData?.status;
    },
    //提交次数
    submitNumber() {
      return this.detailData?.submitNumber || 0;
    },
    //状态提示语
    statusTips() {
      return this.detailData?.status == 0
        ? "审批中"
        : this.detailData?.status == 1
          ? "审批通过"
          : this.detailData?.status == 2 ? "审核已驳回" : '-';
    },
    //标题
    approvalTitle() {
      return this.detailData?.subTitle;
    },
    //执行相关状态
    handleOptions() {
      return this.detailData?.handleOptions || null;
    },
    //是否为审核人
    isAuditor() {
      return this.detailData?.userId == this.$store.state?.userInfo?.employee?.userId;
    },
    guid() {
      return guid(32)
    },
    linked() {
      return this.detailData?.linked || []
    },
    type() {
      return this.detailData?.type;
    },
    remarkLabel() {
      return this.checkData.status == 1 ? this.type == 5 ? '执行备注' : '同意备注' : '驳回原因';
    },
    //是否为撤回单子
    isRevoke() {
      return !!this.detailData?.isRevoke;
    },
    //模板id
    templateId() {
      return this.detailData?.templateId;
    },
    revokeStatus() {
      return !!this.detailData?.revokeStatus;
    },
    position() {
      return this.detailData?.employee?.post?.name || '暂无 '
    },
    //是否允许再次提交
    isAgainConfirm() {
      return !!this.detailData?.isAgainConfirm;
    },
    taskPermission() {
      return this.detailData?.taskPermission || {};
    },
    nodeType(){
      return this.detailData?.nodeType || '';
    },
    scoreChangeShow(){
      return this.scoreCheck.includes(this.detailData?.status)
    }

  },
  methods: {
    //文件下载
    downloadFile(item) {
      this.$downloadFile(item.url, item?.name)
    },
    //重新填充数据
    setApplyFormData() {
      //填充数据
      this.$refs.applyForm.applyFormDataInit(!isObjectNone(this.detailBeatData) ? this.detailBeatData : this.detailData);
    },
    applyCreateConfirm() {
      this.applyCreateClose();
      this.detailClose(true);
    },
    applyCreateClose() {
      this.$emit('titleChange', '');
      this.isResubmit = false;
      this.$refs.applyForm?.resetForm && this.$refs.applyForm.resetForm();
    },
    detailClose(update) {
      this.$emit('close', update);
    },
    //初始化的时候合并一下选项
    formInit() {
      this.loading = true;
      detail({ taskId: this.data })
        .then(res => {
          this.detailData = res.data;
          this.createDetailList(this.detailData?.taskTemplate);
          this.createFlowList(this.detailData?.workflowNodes);
          this.isInit = true;
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading = false;
        })
    },
    resetForm() {
      this.detailData = {};
      this.checkData = { status: 2, remark: "" };
      this.scoreData = {score:''}
      this.flow = [];
      this.list = [];
      this.dialogVisible = false;
      this.dialogVisible = false;
      this.imageList = [];
      this.suiteName = "";
      this.imageIndex = 0;
      this.loading = false;
      this.isResubmit = false;
    },
    //生成详情列表
    createDetailList(control) {
      let list = [];
      control.forEach((el) => {
        el.value = this.detailItemHandle(el);
        list.push(el);
      });

      this.list = list;
    },
    //数据处理
    detailItemHandle(el) {
      let value;
      if (el.config?.tagIcon == "upload" || el.config?.tagIcon == "upload-image" || el.config?.tagIcon == "camera") {
        if (el.config?.tagIcon == "upload") {
          value = el.value.map((_el) => {
            return {
              name: _el.label,
              url: _el.value,
            };
          });
        } else {
          value = el.value.map((_el) => {
            return _el.value;
          });
        }
      //单选
      } else if(el.config?.tagIcon == 'radio'){
        if(el.value?.length>0){
          value = el.value[0]?.label;
        }else{
          value = '--';
        }
      }else if(el.config?.tagIcon == 'checkbox'){
        if(el.value?.length>0){
          value = el.value.map(_el=>_el.label).join('、');
        }else{
          value = '--';
        }
      } else {
        value = el.value || el.value === 0 ? el.value : '--';
      }
      return value;
    },
    //生成流程列表
    createFlowList(flow) {
      let flowList = [];

      //渲染列表生成
      flow = flow.map((el) => {
        let obj = {};
        el.workflowNodesUser = el.workflowNodesUser || [];
        obj.isGroup = el.workflowNodesUser.length > 1;
        obj.show = obj.isGroup;
        //是否为无审核人自动通过状态
        obj.auto = el.workflowNodesUser?.length == 0 && el?.noHanderAction === 1;
        //1已通过，2已拒绝,3审批中
        obj.avatar = obj.isGroup
          ? ""
          : el.workflowNodesUser[0]?.avatar ||
          this.defaultAvatar;
        if (!obj.isGroup) {
          obj.tips = `${el.workflowNodesUser[0]?.employeeId == this.employee.id
            ? "我"
            : el.workflowNodesUser[0]?.nickname ||
            el.workflowNodesUser[0]?.name ||
            ""
            }${el.type == 9 ? '' : el.workflowNodesUser[0]?.status == 1
              ? el.type == 6 ? '(已撤回)' : el.type == 5 ? '(已评分)' : el.type == 0 || el.type == 8 ? '' : "(已同意)"
              : el.workflowNodesUser[0]?.status == 2
                ? "(已驳回)"
                : el.workflowNodesUser[0]?.status == 3
                  ? el.type == 5 ? '(待评分)' : this.status == 4 ? '(审批中止)' : "(审批中)"
                  : ""
            }`
        } else {
          if (el.type == 1 || el.type == 5) {
            obj.tips = `${el.workflowNodesUser?.length || 0}人${this.approvalTypeStatus[el.examineMode]
              }${el.autoCheck === 1 ? '（' + el.nodeTxt + '）' : '中'}`
          } else if(el.type == 9) {
            obj.tips = `${el.workflowNodesUser?.length || 0}人${el.status == 3 ? '审批中' : el.status == 1 ? '已同意' : '审批中止'}`
          } else {
            obj.tips = `${el.workflowNodesUser?.length || 0}人已${el.type == 5 ? '评分' : '抄送'}`
          }
        }

        (obj.title = el?.nodeName || this.approvalStaffType[el.type]), (obj.status = el?.status);
        if (el.status == 1 || el.status == 2) {
          obj.date = el.checkTime || '';
        } else {
          obj.date = '';
        }
        obj.list = el.workflowNodesUser.map((_el) => {
          return {
            name:
              _el.employeeId == this.employee.id
                ? "我"
                : _el.employee?.nickname || _el?.name,
            status: _el?.status,
            avatar: _el?.avatar || this.defaultAvatar,
          };
        });
        obj.type = el.type;
        obj.remark = '';
        if (obj.isGroup) {
          let items = el.workflowNodesUser.filter(_el => {
            return !!_el.remark;
          });
          if (items.length > 0) {
            items.forEach((_el, _index) => {
              obj.remark += `${_index > 0 ? '\n' : ''}${_el?.name || '备注'}：${_el?.remark}。`
            })
          }
        } else {
          let item = el.workflowNodesUser.find(_el => {
            return !!_el.remark;
          });
          obj.remark = `${item?.remark || ''}`
        }
        return obj;
      });
      flowList.push(...flow);
      //必须判断
      if(this.nodeType == 9){
        let index = findIndex(flowList, el=>el.type == 0);
        flowList.splice(index,flowList.length-index);
      }
      this.flow = flowList;
    },
    toggleMore(item) {
      item.show = !item.show;
    },
    //图片预览
    previewImageShow(list, index) {
      this.imageList = list.map(el => {
        return el.indexOf('http:') != -1 || el.indexOf('https:') != -1 ? el : process.env.VUE_APP_IMG_URL + el;
      })
      this.imageIndex = index;

      this.$nextTick(() => {
        this.imagePreview = true;
      })
    },
    //查看用车记录
    checkCarDetail(item) {
      this.currentApplyId = item.value;
      this.drawerDet = true;
    },
    //查看员工明细
    checkStaffDetail(item) {
      if (!item?.value) return;
      this.employeeId = { id: item.value };
      this.drawerEdit = true;
    },
    //查看工资单明细
    checkDepPayroll(item) {
      //拉出套件数据
      this.salaryDialogConfig = {
        title: `${item?.value?.date}${item?.value?.depName || ''}工资单明细`,
        date: item?.value?.date,
        approveId: this.data,
        salaryAdjustShow: false
      }
      this.salaryShow = true
    },
    closeTabs() {
      this.drawer = false;
    },
    checkDealDetail(item) {
      if (!item.value || item.value == '-') {
        this.$message.error('无对应成交单id');
        return;
      }
      this.drawer = true;
      this.drawerItem = item;
    },
    drawerInit() {
      this.$nextTick(() => {
        this.$refs.tabs.id = this.drawerItem.value;
        this.$refs.tabs.getDetail();
        this.$refs.tabs.activeName = 'uploadFile';
      })
    },
    handleAdopt() {
      this.$confirm(`是否${this.type == 5 ? '执行' : '同意'}审批单？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true;
        check({ approveId: this.detailData.id, status: 1, remark: "" })
          .then((response) => {
            this.loading = false;
            this.$message.success("审核成功");
            this.detailClose(true);
          })
          .catch(() => {
            this.loading = false;
          });
      }).catch(() => {

      });
      return;
    },
    handleRefuse(status) {
      //选择支出途径，当存在支出账户且存在支出途径时才选择
      this.checkData.remark = "";
      this.checkData.score = "";
      this.checkData.status = status;
      this.dialogVisible = true;
    },
    handleScore(){
      this.scoreData.score = parseFloat(this.detailData.score);
      this.scoreDialogVisible = true;
    },
    
    handleRefuseCheck() {
      if(this.checkData.status== 2 && this.checkData.remark == ''){
        this.$message.error('请填写驳回原因')
        return;
      } 
      if(this.checkData.status== 1 && this.nodeType == 5){
        if(this.checkData.score == 0){
          this.$message.error('请输入一个大于0且小于5的数字（可以是小数）');
          return;
        }
      }

      this.loading = true;

      check({
        taskId:this.data,
        nodeType:this.nodeType,
        ...this.checkData
      }).then(res=>{
        this.$message.success(`${this.nodeType == 9 ? '备案任务' : '审批单'}已${this.checkData.status == 1 ? this.nodeType == 5 ? '评分' : "通过" : "驳回"}`);
        this.formInit();
        this.$emit('close',true)
        this.dialogVisible = false;
        this.checkData.remark = '';
        this.checkData.score = '';
      }).catch(err=>{
        console.log(err)
      })
      .finally(()=>{
        this.loading=false;
      })

    },
    handleScoreChange() {
      if(this.scoreData.score < 0){
        this.$message.error('请输入一个大于或等于0且小于5的数字（可以是小数）');
        return;
      }
      this.loading = true;
      taskScore({
        taskId:this.data,
        ...this.scoreData
      }).then(res=>{
        this.$message.success(`评分修改成功`);
        this.formInit();
        this.$emit('close',true);
        this.scoreDialogVisible = false;
        this.scoreData = {
          score:'',
          remark:''
        }
      }).catch(err=>{
        console.log(err)
      })
      .finally(()=>{
        this.loading=false;
      })

    },
    editTask(){
      this.$refs.approveForm.handleOpen({
        id:this.data,
        title:'编辑任务',
        indicatorId:0,
        task:true
      });
    },
    itemLabelShow(item){
      return !!item.config?.expandParams?.showLabel;
    },
    delTask(){
      this.$confirm('确定要删除该任务吗？')
        .then(_ => {
          taskDel({taskId:this.data}).then(res=>{
            this.$message.success('任务删除成功');
            this.$emit('close',true);
          })
        })
        .catch(_ => { });
    },
    indicatorOpen(item){
      this.$refs.indicatorDetail.open(item.id);
    },
    approveUpdate(isUpdate){
      if(isUpdate){
        this.formInit();
      }
    },
    taskLogOpen(){
      this.$refs.taskLog.open(this.data);
    },
    showName(){
      this.$alert(`姓名：${this.detailData?.person?.nickname || '-'}\n电话：${this.detailData?.person?.phone || '-'}`, "负责人信息",{type:'success'});
    }
  },
}
  

</script>

<style lang="scss" scoped>
.abow_dialog {
  display: flex;
  justify-content: center;
  align-items: Center;
  overflow: hidden;

  ::v-deep .el-dialog {
    margin: 0 auto !important;
    height: 99%;
    overflow: hidden;

    .el-dialog__body {
      position: absolute;
      left: 0;
      top: 54px;
      bottom: 0;
      right: 0;
      padding: 0;
      z-index: 1;
      overflow: hidden;
      overflow-y: auto;
    }
  }
}

.remark-dialog {
  ::v-deep .el-input.is-disabled .el-input__inner {
    background: #fff;
    color: #666666;
  }

  ::v-deep .el-radio {
    display: block;
    margin-top: 10px;
  }
}

.form-item{
  padding-bottom:10px;
  &-label,&-value{
    line-height:32px;
  }

  &-label{
    color:#999999;
    padding-right:10px;
  }

  &-value{
    flex:1 1 auto;
    width: 0;
    &.task-name{
      font-size:20px;
    }

    ::v-deep .rich-text{
      img,video{
        max-width:100%;
        height:auto;
      }
      p{
        font-size:14px;
        line-height:24px;
      }
    }
    
    .file-list,
        .image-list {
          padding-top: 10px;
        }

        .file-item {


          .file-preview {
            padding: 0 15px;
            height: 30px;
            line-height: 30px;
            background: #4285F6;
            border-radius: 5px;
            color: #fff;
            opacity: .6;
            transition: opacity .3s;

            &:hover {
              opacity: 1;
            }
          }

          .file-item-link {
            display: flex;
            width: 100%;
            height: 50px;
            line-height: 50px;
            margin-bottom: 2px;
            background: rgba(0, 0, 0, .04);
            color: #666666;
            font-size: 14px;
            padding: 0 10px;
            cursor: pointer;
            align-items: center;

            .c-icon {
              font-size: 16px;
              padding-right: 5px;
            }

            span {
              flex: 1 1 auto;
              width: 0;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            &:hover {
              background: rgba(0, 0, 0, .08);
            }
          }
        }

        .image-list {
          display: flex;
          flex-wrap: wrap;
        }

        .image-item {
          width: 80px;
          height: 80px;
          margin-right: 10px;
          margin-bottom: 10px;
          border-radius: 5px;
          cursor: zoom-in;
          overflow: hidden;
        }
  }
}

.scroll-view,
.scroll-footer {
  width: 100%;
}

.scroll-view {
  flex: 1 1 auto;
  height: 0;
  overflow: hidden;
  overflow-y: auto;
  padding-right:10px;
}

.scroll-footer {
  padding-top:10px;
  border-top:1px solid #e5e5e5;
}

/*dialog header*/
.el-dialog__header {
  background: #e3eaed;
}

.avue-crud__dialog__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.el-dialog__title {
  color: rgba(0, 0, 0, .85);
  font-weight: 500;
  word-wrap: break-word;
}

.avue-crud__dialog__menu {
  padding-right: 25px;
  float: left;
}

.avue-crud__dialog__menu i {
  color: #909399;
  font-size: 15px;
}

.el-icon-full-screen {
  cursor: pointer;
}

.el-icon-full-screen:before {
  content: "\e719";
}

.dialog-cover {
  height: 100%;

  .dialog-cover-detail {
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  .apply-detail_footer {
    padding: 20px 30px;
    background-color: #ffffff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .dialog-cover-ctn {
    flex: 1 1 auto;
    width: 100%;
    height: 0;
    overflow: hidden;
    overflow-y: auto;
  }

  .u-flex {
    display: flex;
    align-items: center;
  }

  .u-row-between {
    justify-content: space-between;
  }

  .u-col-top {
    align-items: flex-start;
  }

  .u-col-center {
    align-items: center;
  }

  .u-row-center {
    justify-content: center;
  }

  .approver-info {
    color: #4285F6;
    font-size: 14px;
    padding-bottom: 12px;
    border-bottom: 1px solid #F4F4F4;
  }

  .employee-info {
    display: flex;
    align-items: center;
    padding-bottom: 12px;
    position: relative;

    .stauts-icon {
      width: 120px;
      height: 101px;
      position: absolute;
      top: 0;
      right: 42px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .avatar {
      width: 60px;
      height: 60px;
      margin-right: 10px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .name {
      font-size: 14px;
      padding-top: 2px;
      padding-bottom: 3px;
    }

    .apply-status {
      font-size: 14px;
      color: #999999;
    }
  }

  .detail-main {
    padding: 16px 0 8px;

    .item {
      margin-bottom: 22px;

      .label {
        color: #8f98a8;
        font-size: 14px;
        line-height: 1;
      }

      .value {
        font-size: 14px;
        line-height: 20px;
        color: #333333;

        &.pre-line {
          white-space: pre-wrap;
        }

      }

      &.date-info {
        background: #f0fbff;
        border-radius: 5px;
        padding: 11px;

        &.max {
          display: block;
        }

        .des {
          font-size: 15px;
          line-height: 1;
          margin-bottom: 10px;
          color: #333333;
        }

        .item-link {
          color: #4285f6;
        }

        .date-horizon {
          .date-item {
            line-height: 1;
            font-size: 15px;
            color: #8f98a8;
            padding: 7px 0 7px 15px;
            position: relative;

            .line-wrapper {
              position: absolute;
              width: 7px;
              height: 100%;
              top: 1px;
              left: 0;

              .line-cover {
                display: flex;
                height: 100%;
                flex-direction: column;
                align-items: center;

                .point {
                  width: 7px;
                  height: 7px;
                  border: 1px solid #a0a7b4;
                  border-radius: 50%;
                }

                &::after,
                &::before {
                  content: "";
                  flex: 1 1 auto;
                  height: 0;
                  background: #a0a7b4;
                  width: 1px;
                }
              }
            }

            &:first-child {
              margin-top: 0;

              .line-wrapper .line-cover {
                &::before {
                  background: transparent;
                }
              }
            }

            &:last-child {
              .line-wrapper .line-cover {
                &::after {
                  background: transparent;
                }
              }
            }
          }
        }
      }

      &.block {
        background: #f0fbff;
        border-radius: 10px;
        padding: 15px 15px 20px;
        flex-direction: column;

        .item-block-title {
          font-size: 16px;
          padding-bottom: 10px;
          width: 100%;

          span:last-child {
            color: #4285F6;
          }
        }

        .item-block-cover {
          width: 100%;

          .item-block_item {
            color: #8F98A8;
            line-height: 20px;
            font-size: 14px;
            padding-top: 8px;
            width: 50%;
            display: flex;
            align-items: flex-start;

            .item-block_item-label {
              width: 6em;
              padding-right: 10px;
              box-sizing: content-box;
            }

            .item-block_item-value {
              display: inline;
            }
          }
        }

        &.table {
          padding: 0;
          background: transparent;
        }
      }
    }
  }

  .dialog-apply-content {
    padding-right: 40px;
    height: 100%;

    .apply-item-cover {
      &.apply-user-info {
        margin-bottom: 22px;
        border-bottom: 1px solid #F4F4F4;
      }

      &.content{
        display: block;
      }

      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;

      .item {
        width: 50%;
        display: flex;
        align-items: flex-start;

        .label {
          margin-bottom: 0;
          width: 6em;
          line-height: 20px;
          box-sizing: content-box;
          padding-right: 10px;
        }

        .value {
          flex: 1 1 auto;
          width: 0;

          line-height: 20px;

          &.highlight {
            color: #1F87FE;
            font-size: 18px;
            font-weight: bold;
          }
        }

        &.block {
          width: 100%;
        }

        &.max {
          width: 100%;
        }

        &.file {
          display: block;

          .label,
          .value {
            display: block;
            width: 100%;
          }
        }
      }
    }

    .apply-user-info {
      display: flex;
      flex-wrap: wrap;

    }
  }

  .apply-detai_footer_rebtns {
    margin-left: 10px;
  }

  .flow-wrapper {
    padding: 0;
    background: #fff;
    padding-left: 40px;
    height: 100%;
    position: relative;
    .flow-toolbar{
      padding-bottom:40px;
      width: 100%;
    }
    &::before {
      content: '';
      position: absolute;
      top: 44px;
      left: 0;
      bottom: 13px;
      width: 1px;
      background: #F4F4F4;
    }

    .title {
      font-size: 16px;
      line-height: 1;
      color: #666666;
      padding-bottom:16px;
    }

    .flow-list {
      position: relative;

      .flow-line-wrapper {
        position: absolute;
        left: 0;
        top: 16px;
        width: 49px;
        bottom: 0;
        z-index: 10;

        .flow-line-cover {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;

          .line {
            width: 3px;
            background: #d1d1d1;
            height: 100%;
          }
        }
      }

      .flow-item {
        padding-bottom: 35px;

        &:last-child {
          background: #fff;
          position: relative;
          z-index: 20;
        }

        .flow-item-top {
          position: relative;
        }

        .date {
          .switch {
            transition: transform 0.3s;
            color: #d2d2d2;
            font-size: 15px;
            transform: rotate(0deg);
            display: block;
          }

          .open {
            transform: rotate(180deg);
          }

          .arrow-cover {
            padding: 12px;
            position: absolute;
            top: 16px;
            right: 0;
          }
        }

        .avatar {
          width: 49px;
          height: 49px;
          color: #fff;
          font-size: 24px;
          background: #eff5ff;
          text-align: center;
          line-height: 49px;
          border-radius: 5px;
          margin-right: 16px;
          position: relative;
          z-index: 20;

          .icon-cover {
            background: #4285f6;
            border-radius: 5px;
            width: 100%;
            height: 100%;

            i {
              font-size: 30px;
            }
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }

          .status-icon {
            position: absolute;
            width: 18px;
            height: 18px;
            bottom: -3px;
            right: -5px;

            &.success {
              background: url(https://static.swzpm.com/wx-image/approval-icon-success.png) no-repeat scroll center center / 100% 100%;
            }

            &.err {
              background: url(https://static.swzpm.com/wx-image/approval-icon-err.png) no-repeat scroll center center / 100% 100%;
            }

            &.wait {
              background: url(https://static.swzpm.com/wx-image/approval-icon-wait.png) no-repeat scroll center center / 100% 100%;
            }
          }
        }

        .flow-item-content {
          flex: 1 1 auto;
          width: 0;
          padding-top: 6px;

          .flow-item-top {
            padding-right: 5px;

            .s-title,
            .date {
              line-height: 1;
            }

            .s-title {
              font-size: 16px;
              color: #333333;
            }

            .date {
              font-size: 12px;
              color: #8f98a8;
            }
          }

          .tips {
            font-size: 13px;
            padding-top: 4px;
            color: #8f98a8;
          }

          .p-list {
            padding-top: 8px;
            flex-wrap: wrap;

            .p-item {
              margin-right: 9px;
              width: 48px;
              margin-top: 9px;
              flex-direction: column;

              .s-avatar {
                width: 35px;
                height: 35px;
                text-align: center;
                line-height: 35px;
                background: #eff5ff;
                border-radius: 5px;
                font-size: 12px;
                color: #fff;
                position: relative;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 5px;
                }

                .s-status {
                  position: absolute;
                  top: -5px;
                  right: -5px;
                  width: 13px;
                  height: 13px;

                  &.success {
                    background: url(https://static.swzpm.com/wx-image/approval-icon-success.png) no-repeat scroll center center / 100% 100%;
                  }

                  &.err {
                    background: url(https://static.swzpm.com/wx-image/approval-icon-err.png) no-repeat scroll center center / 100% 100%;
                  }

                  &.wait {
                    background: url(https://static.swzpm.com/wx-image/approval-icon-wait.png) no-repeat scroll center center / 100% 100%;
                  }
                }
              }

              .s-name {
                width: 100%;
                color: #8f98a8;
                padding-top: 8px;
                font-size: 13px;
                text-align: center;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }

          .s-remark {
            background: #EFF5FF;
            border-radius: 6px;
            padding: 8px;
            margin-top: 10px;
            color: #777777;
            font-size: 13px;
            line-height: 20px;
            white-space: pre-line;

            .m-color {
              color: #4285f6;
            }
          }
        }
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .3s;
  }

  .fade-enter,
  .fade-leave-to

  /* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
  }
}
</style>
