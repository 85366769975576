<template>
<div>
    <el-tag>任务状态</el-tag>
 </div>
</template>
<script>

export default {

    name:'taskStatus'


}
</script>

<style lang="scss" scoped>

</style>