const getObjects = (obj, key, val) => {
  let objects = [];
  for (let i in obj) {
    if (!obj.hasOwnProperty(i)) continue;
    if (typeof obj[i] == 'object') {
      objects = objects.concat(getObjects(obj[i], key, val));
    } else if (i == key && obj[i] == val || i == key && val == '') { //
      objects.push(obj);
    } else if (obj[i] == val && key == '') {
      if (objects.lastIndexOf(obj) == -1) {
        objects.push(obj);
      }
    }
  }
  return objects;
}

//return an array of values that match on a certain key
const getValues = (obj, key) => {
  let objects = [];
  for (let i in obj) {
    if (!obj.hasOwnProperty(i)) continue;
    if (typeof obj[i] == 'object') {
      objects = objects.concat(getValues(obj[i], key));
    } else if (i == key) {
      objects.push(obj[i]);
    }
  }
  return objects;
}
//arr to str
const arrToString = (obj, key = 'name') => {
  if (obj) {
    return obj.map(item => {
      return item[key]
    }).toString()
  }
}
//return an array of keys that match on a certain value
const getKeys = (obj, val) => {
  let objects = [];
  for (let i in obj) {
    if (!obj.hasOwnProperty(i)) continue;
    if (typeof obj[i] == 'object') {
      objects = objects.concat(getKeys(obj[i], val));
    } else if (obj[i] == val) {
      objects.push(i);
    }
  }
  return objects;
}

const setKeysValues = (list, key, value) => {
  list.forEach((item) => {
    item[key] = value
    if (Array.isArray(item.children) && item.children.length !== 0) {
      setKeysValues(item.children, key, value)
    }
  })
  return list
}

const setNodeKeysValues = (list, nodeId, key, value) => {
  if (!nodeId) {
    return list;
  }
  if (!Array.isArray(nodeId)) {
    nodeId = [nodeId]
  }
  if (nodeId.length === 0) {
    return list;
  }
  list.forEach((item) => {
    if (nodeId.indexOf(item.id) !== -1) {
      item[key] = value
    }
    if (Array.isArray(item.children) && item.children.length !== 0) {
      setNodeKeysValues(item.children, nodeId, key, value)
    }
  })
  return list
}


const setDepDisabled = (list, key) => {
  list.forEach((item) => {
    if (item.type === 1) {
      item[key] = true
    } else if (item.type === 2) {
      item[key] = false
    }
    if (Array.isArray(item.children) && item.children.length > 0) {
      setDepDisabled(item.children, key)
    }
  })
  return list
}

const setTreeNodeChildrenKeysValues = (list, nodeId, key, value) => {
  let setChildrenKeyValues = (list, childrenNodeId, k, v) => {
    list.forEach((item) => {
      if (childrenNodeId === item.parentId) {
        item[k] = v
        nodeId = item.id
      }
      if (Array.isArray(item.children) && item.children.length !== 0) {
        setChildrenKeyValues(item.children, nodeId, k, v)
      }
    })
    return list
  }
  return setChildrenKeyValues(list, nodeId, key, value)
}

const getTreeNodeAllById = (list, arr = []) => {
  list.forEach((item) => {
    arr.push(item.id)
    if (Array.isArray(item.children) && item.children.length !== 0) {
      getTreeNodeAllById(item.children, arr)
    }
  })
}
// 字符串下划线转驼峰
const formatToHump = (value) => {
  return value.replace(/\_(\w)/g, (_, letter) => letter.toUpperCase())
}

// 字符串驼峰转下划线
const formatToLine = (value) => {
  return value.replace(/([A-Z])/g, '_$1').toLowerCase()
}

/**
 * 数据对象key 驼峰下划线互相转化
 * @param {Object} data 需要转换的对象
 * @param {String} type hump-转驼峰 toLine-转下划线
 */
const formatHumpLineTransfer = (data, type = 'hump') => {
  let hump = ''
  // 转换对象中的每一个键值为驼峰的递归
  const formatTransferKey = (data) => {
    if (data instanceof Array) {
      data.forEach(item => formatTransferKey(item))
    } else if (data instanceof Object) {
      for (const key in data) {
        hump = type === 'hump' ? formatToHump(key) : formatToLine(key)
        data[hump] = data[key]
        if (key !== hump) {
          delete data[key]
        }
        if (data[hump] instanceof Object) {
          formatTransferKey(data[hump])
        }
      }
    } else if (typeof data === 'string') {
      data = type === 'hump' ? formatToHump(data) : formatToLine(data)
    }
  }
  formatTransferKey(data)
  return data
}
export {
  getObjects,
  getValues,
  arrToString,
  getKeys,
  setKeysValues,
  setNodeKeysValues,
  setTreeNodeChildrenKeysValues,
  getTreeNodeAllById,
  setDepDisabled,
  formatToHump,
  formatToLine,
  formatHumpLineTransfer
}
