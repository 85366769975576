<template>
<div class="pro">
    <pro-header
        company="工作台"
    ></pro-header>
    <div class="pro-container r-flex r-flex-auto-h">
        <pro-sidebar v-if="!isIndex"></pro-sidebar>
        <div class="pro-content r-flex-auto-w" :style="{background:bg || 'var(--theme-bg)'}">
            <router-view></router-view>
        </div>
    </div>
</div>
</template>
<script>
import proHeader from "@/components/pro/header";
import proSidebar from "@/components/pro/sidebar";
export default {
    components:{
        proHeader,
        proSidebar
    },
    computed:{
        bg(){
            return this.$route.meta?.bg;
        },
        isIndex(){
            return this.$route.name === 'index';
        }
    }
}
</script>

<style lang="scss" scoped>
.pro{
    min-width: 1000px;
    height:100%;
    display: flex;
    flex-direction: column;
    background:var(--theme-bg);
    color:var(--theme-color);
    &-content{
        height:100%;
        overflow:hidden;
        overflow-y:auto;
        padding: 20px;
    }
}
</style>