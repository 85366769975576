<template>
    <div>
        <r-drawer ref="rDialog" size="100%"  direction="btt" dialog-title="一级指标统计" :footer="false">
          <template v-slot:title>
            <div class="r-flex r-row-bet r-col-bottom">
              <p class="r-drawer-title">一级指标统计</p>
              <div class="r-flex">
                <el-button size="mini" type="primary" style="margin-right: 10px;" @click="exportData()">导出</el-button>
              </div>
            </div>
          </template>
            <div class="top-table-container">
                <r-table ref="table" :loader="loader" :columns="columns"  height="100%" row-key="id" @sortChange="sortChange">
                    <template v-slot:statusRatio1="{row,column}">
                        {{ row[column.prop] }}%
                    </template>       
                    <template v-slot:statusRatio2="{row,column}">
                        {{ row[column.prop] }}%
                    </template>   
                    <template v-slot:statusRatio3="{row,column}">
                        {{ row[column.prop] }}%
                    </template>  
                    <template v-slot:statusRatio4="{row,column}">
                        {{ row[column.prop] }}%
                    </template>   
                    <template v-slot:statusRatio5="{row,column}">
                        {{ row[column.prop] }}%
                    </template>
                    <template v-slot:progress="{ row, column }">
                        <span :style="{color:parseFloat(row[column.prop]) >= warningValue? 'green' :'red'}">{{ row[column.prop] }}</span>
                    </template>
                </r-table>
            </div>
        </r-drawer>
    </div>
</template>
<script>
import {topIndicatorStatistic} from '@/api/statistics';
import fileDownload from "js-file-download";
export default {
    props:{
        warningValue:{
            type:Number,
            default:0
        }
    },
    data(){
        return {
            columns:[
                {
                    label:'一级指标',
                    prop:'name',
                    sortable:'custom',
                    "min-width":'200px',
                    "show-overflow-tooltip":true
                },{
                    label:'任务指标总数',
                    prop:'totalCount',
                    sortable:'custom',
                    'min-width':"140px"
                },{
                    label:'未启动',
                    prop:'status1',
                    sortable:'custom',
                    'min-width':"120px"
                },{
                    label:'未启动占比',
                    prop:'statusRatio1',
                    sortable:'custom',
                    'min-width':"140px"
                },{
                    label:'正常进行',
                    prop:'status2',
                    sortable:'custom',
                    'min-width':"120px"
                },{
                    label:'正常进行占比',
                    prop:'statusRatio2',
                    sortable:'custom',
                    'min-width':"140px"
                },{
                    label:'进行缓慢',
                    prop:'status3',
                    sortable:'custom',
                    'min-width':"120px"
                },{
                    label:'进行缓慢占比',
                    prop:'statusRatio3',
                    sortable:'custom',
                    'min-width':"140px"
                },{
                    label:'已完成',
                    prop:'status4',
                    sortable:'custom',
                    'min-width':"120px"
                },{
                    label:'已完成占比',
                    prop:'statusRatio4',
                    sortable:'custom',
                    'min-width':"140px"
                },{
                    label:'未完成',
                    prop:'status5',
                    sortable:'custom',
                    'min-width':"120px"
                },{
                    label:'未完成占比',
                    prop:'statusRatio5',
                    sortable:'custom',
                    'min-width':"140px"
                },{
                    label:'综合进度',
                    prop:'progress',
                    sortable:'custom',
                    'min-width':"120px"
                },
            ],
            query:{}
        }
    },
    methods:{
        exportData() {
          topIndicatorStatistic({ page: 1, pageSize: 9999999, ...this.query, export: 1  }).then(res => {
            fileDownload(res, "一级指标统计.xlsx");
          })
        },
        open(){
            this.$refs.rDialog.handleOpen();
        },
        search(){
            this.$refs.table.refresh(1);
        },
        sortChange(order) {
            this.query = Object.assign(this.query, order);
            this.$nextTick(() => {
                this.search();
            })
        },
        loader(page,pageSize){
            return topIndicatorStatistic({page,pageSize,...this.query}).then(res=>{
                return res.data;
            })
        }
    }


}
</script>

<style lang="scss">
.top-table-container{
    height:100%;
}
</style>