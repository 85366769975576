<template>
  <r-drawer ref="rDialog" :dialogTitle="title" size="600px" @close="reset" @submit="submit">
    <div class="dialog-content">
      <el-form size="small" label-position="top" :rules="rules" :model="form" ref="form">
        <el-form-item prop="name" label="分类名称">
          <el-input v-model="form.name" placeholder="请输入分类名称"></el-input>
        </el-form-item>
        <el-form-item prop="sort" label="排序">
          <el-input-number v-model="form.sort" controls-position="right" :min="1" :max="10000"></el-input-number>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="form.status">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">开启</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
  </r-drawer>
</template>
<script>
import {saveCategory} from "@/api/article";
import {cloneDeep, isArray} from "@/utils";

const defaultForm = {
  name: '',
  sort: 0,
  status: 1,
}
export default {
  data() {
    return {
      form: cloneDeep(defaultForm),
      title: '',
      rules: {
        name: [
          {required: true, message: '请输入分类名称', trigger: ['change', 'blur']}
        ]
      },
      options: []
    }
  },
  methods: {
    open(form = null, title = '新建分类') {
      this.title = title;
      if (form) {
        this.form = Object.assign(cloneDeep(defaultForm), form);
      }
      this.$refs.rDialog.handleOpen();
    },
    reset() {
      this.form = cloneDeep(defaultForm);
    },
    submit(done) {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let form = cloneDeep(this.form);
          saveCategory(form).then(res => {
            this.$message.success('提交成功');
            done(true);
            this.$emit('confirm');
          }).catch(err => {
            done(false);
          })
        } else {
          done(false);
        }
      });
    },
    cChange(val) {
      console.log(val)
    }
  }


}
</script>

<style lang="scss" scoped>
.dialog-content {
  height: 250px;
}
</style>