<template>
  <div class="image-upload-wrapper" :class="{left:align=='left'}">
    <el-upload
      class="image-upload"
      :class="customizeClass"
      :accept="accept"
      drag
      :action="ossUploadUrl"
      :show-file-list="false"
      :on-success="uploadSuccess"
      :on-error="uploadError"
      :before-upload="beforeThumbUpload"
      :http-request="handleUpload"
      :on-progress="uploadProgress"
    >
      <div class="thumb-cover" v-if="value" @click.stop="thumbPreview">
        <img :src="value | $staticFilter" :style="{background:imageBackground}"/>
        <div class="close-btn" @click.stop="thumbRemove">
          <i class="el-icon-close"></i>
        </div>
      </div>
      <i v-else class="el-icon-plus uploader-icon"></i>
    </el-upload>
    <!-- 提示语 -->
    <p class="upload-tips"><slot></slot></p>
    <image-preview
      v-model="imageShow"
      :imageList="imageList"
      :imageIndex="imageIndex"
    >
    </image-preview>
    <template v-if="cropper">
      <cropperImage
        :option="cropperOption"
        v-model="cropperShow"
        dialogTitle="图片裁切"
        @confirm="cropperConfirm"
      ></cropperImage>
    </template>
  </div>
</template>
<script>
import uploadProgress from "@/mixins/uploadProgress";
import imagePreview from "@/components/imageShow";
import cropperImage from "@/components/cropper-image.vue";
import { policy } from "@/api/oss";
import emitter from 'element-ui/src/mixins/emitter';
export default {
  mixins: [uploadProgress,emitter],

  components: {
    imagePreview,
    cropperImage,
  },

  props: {
    value: {
      type: String,
      default: "",
    },
    //客制样式
    customizeClass: {
      type: String,
      default: "",
    },
    //是否使用图片预览，默认否，如果是这样就要自己书写绑定方法
    preview: {
      type: Boolean,
      default: false,
    },
    //是否使用图片裁切
    cropper: {
      type: Boolean,
      default: false,
    },
    align:{
      type:String,
      default:'center'
    },
    //图片背景颜色
    imageBackground:{
      type:String,
      default:'#fff'
    },
    customImageAccept:{
      type:String,
      default(){
        return ''
      }
    }
  },

  computed:{
    accept(){
      return this.customImageAccept || this.imageAccept;
    }
  },

  data() {
    return {
      //图片地址
      imageBaseUrl: process.env.VUE_APP_IMG_URL,
      imageShow: false,
      imageList: [],
      imageIndex: 0,
      //图片裁切预览显示
      cropperShow: false,
      //图片裁切选项
      cropperOption: {
        img: "",
      },
    };
  },

  methods: {
    uploadSuccess(res,file) {
      if(!res?.url){
        this.$message.error('图片上传失败，请稍后重试');
        this.updateProgress(file.uid,'fail');
        return false;
      }
      this.updateProgress(file.uid,'success');
      let url = res.url.replace('http:','https:').replace(process.env.VUE_APP_IMG_URL,'');
      //根据传值进行赋值
      this.$emit("input", url);
      this.dispatch('ElFormItem', 'el.form.change', [url]);
    },
    thumbPreview() {
      //判断是否开启了预览
      if (this.preview) {
        this.imageList = this.value.indexOf('http:') !=-1 || this.value.indexOf('https:') !=-1 ? [this.value] : [this.imageBaseUrl + this.value];
        this.imageShow = true;
      } else {
        //没有开启就自定义的预览方法
        this.$emit("preview");
      }
    },
    //删除图片
    thumbRemove() {
      this.$emit("input", "");
      this.dispatch('ElFormItem', 'el.form.change', [""]);
    },

    /**
     * 图片裁切方法
     *
     * @param {Object} file 文件对象
     */
    imageCropper(file) {
      //如果没有开启图片裁切就不执行
      if (!this.cropper) return;
      this.cropperOption.img = URL.createObjectURL(file);

      //打开裁切窗口
      this.cropperShow = true;
    },

    //选定图片裁切数据
    cropperConfirm(data) {
      //上传图片
      policy(data)
        .then((res) => {
          this.$emit("input", res.data.url);
        })
        .catch((err) => {});
    },
  }
};
</script>

<style lang="scss">
.image-upload-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &.left{
    align-items: flex-start;
  }
  .image-upload {
    width: 140px;
    height: 140px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    .el-upload-dragger {
      width: 140px;
      height: 140px;
      border: 0 !important;
    }

    .thumb-cover {
      position: relative;
      height: 100%;
      img {
        width: 100%;
      }
      .close-btn {
        position: absolute;
        width: 100px;
        height: 50px;
        right: -80px;
        top: -32px;
        background: rgba(0, 0, 0, 0.6);
        text-align: center;
        padding-top: 12px;
        transform: rotate(45deg);
        cursor: pointer;
        transition: transform 0.3s;
        i {
          font-size: 20px;
          color: #fff;
          transform: rotate(-45deg);
        }
      }

      &:hover {
        .close-btn {
          transform: translateY(20px) translateX(-45px) rotate(45deg);
        }
      }
    }

    .uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 140px;
      height: 140px;
      line-height: 140px;
      text-align: center;
    }

    &:hover {
      border-color: #409eff;
    }
  }
  .el-carousel__item {
    overflow: visible;
  }
}
</style>