import request from '@/utils/request'

export function fetchList(params) {
  return request({
    url: '/api/employee/employee-list',
    method: 'get',
    params: params
  })
}
/**
 * @用途:银行账户列表（法人）
 */
 export function bankList(params) {
  return request({
    url: '/api/finance/get-bank-list',
    method: 'get',
    params: params,
  });
}
/**
 * @用途:新增银行账户（法人）
 */
 export function createBank(data) {
  return request({
    url: '/api/finance/create-bank',
    method: 'post',
    data: data
  })
}
/**
 * @用途:编辑银行账户（法人）
 */
 export function editBank(data) {
  return request({
    url: '/api/finance/edit-bank',
    method: 'post',
    data: data
  })
}
