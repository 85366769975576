
export default {
    data(){
        return{
            tableRefKey:"table"
        }
    },
    mounted() {
        //如果是缓存页面，不运行
        if(!this.$route?.meta?.keepAlive){
            window.addEventListener('resize',this.tableColumnChange);
        }
    },
    beforeDestroy(){
        window.removeEventListener('resize',this.tableColumnChange);
    },
    activated(){
        window.addEventListener('resize',this.tableColumnChange);
    },
    deactivated(){
       window.removeEventListener('resize',this.tableColumnChange);
    },
    methods: {
        tableColumnChange(){
            this.$nextTick(()=>{
                this.$refs[this.tableRefKey]?.doLayout && this.$refs[this.tableRefKey]?.doLayout();
            })
        },
    }   
}

