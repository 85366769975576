<template>
    <div class="pro-header r-flex r-row-bet">
        <div class="pro-header--brand" :class="{'pro-header--brand_normal':isIndex}">
            <slot name="logo">
                <div class="pro-header--brand_default r-flex hover--opacity">
                    <div class="pro-header--brand_default-logo r-flex hover--opacity" @click="subLink({name:'index'})">
                        <img :src="logo|$staticFilter" alt="" v-if="logo && !isIndex">
                        <r-icon name="weibiaoti--" type="task" size="56" v-else color="var(--primary)"></r-icon>
                    </div>
                    <div class="pro-header--brand_default-title hover--opacity r-line-1 r-flex-auto-w" @click="subLink({name:'index'})">
                        <div class="pro-header--brand_default-company">{{ company }}</div>
                    </div>
                </div>
            </slot>
        </div>
        <div class="pro-header--user r-flex r-flex-auto-w r-border-bottom r-row-bet">
            <div class="pro-header--user_page-title" v-if="!isIndex">{{ pageTitle }}</div>
            <div class="r-flex r-row-right r-flex-auto-w">
                <div class="pro-header--user_tips pro-header--user_item hint r-flex"  v-if="!isIndex">
                    <r-icon name="bell" size="36"></r-icon>
                </div>
                <div class="pro-header--user_name pro-header--user_item r-flex r-flex-column r-row-center"  v-if="!isIndex">
                    <p>{{ userName }}</p>
                    <p>{{ position }}</p>
                </div>
                <div class="pro-header--user_department pro-header--user_item r-flex r-flex-column r-row-center">
                    <el-dropdown @command="dropdownClick" placement="bottom-end">
                        <r-icon name="shezhi-xianxing" size="32" type="task"></r-icon>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="logout">
                                <div class="r-flex">
                                    <r-icon type="task" name="logout" left="0" right="5"></r-icon>
                                    <span>退出登录</span>
                                </div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import avatar from "@/assets/images/avatar.png";
export default {

    props: {
        desc: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            headerMenus:[
                {
                    label:'指标管理',
                    name:'indicator',
                    route:'indicator'
                },{
                    label:'任务管理',
                    name:'task-list',
                    route:'task-list'
                },{
                    label:'任务审批',
                    name:'task-audit',
                    route:'task',
                },{
                    label:'统计',
                    name:'statistics',
                    route:'statistics'
                }
            ]
        }
    },
    computed: {
        step() {
            return !this.isIndex;
        },
        routeName() {
            return this.$route.name;
        },
        history() {
            return !!this.$route?.meta?.history;
        },
        avatar() {
            return this.$store.state.userInfo.info?.avatar || avatar
        },
        isIndex() {
            return this.$route?.name == 'index';
        },
        company() {
            return this.isIndex ? '工作台' : this.$store.state.userInfo?.company?.companyName || '';
        },
        userName(){
            return this.$store.state.userInfo?.employee?.nickname;
        },
        position(){
            return this.$store.state.userInfo?.employee?.positionName;
        },
        logo(){
            return this.$store.state.userInfo?.company?.logo || '';
        },
        menus(){
            return this.$store.state.userInfo.originalMenus.map(el=>el.name)
        },
        isIndex(){
            return this.$route.name == 'index';
        },
        pageTitle(){
            return this.$route.meta.title || '';
        }
    },
    methods: {
        subLink(item) {
            this.$router.push(item);
        },
        dropdownClick(val) {
            if (val == 'logout') {
                this.$logout();
            }
        }
    }


}
</script>

<style lang="scss" scoped>
.pro-header {
    &--container {
        padding: 0 20px;
    }

    &--brand {
        width: 240px;
        background: var(--sidebar);
        height:80px;
        padding: 0 20px;
        color:#fff;
        border-bottom:1px solid rgba(153, 153, 153,.6);
        &_normal{
            background: #fff;
            color:var(--primary);
            border-bottom:1px solid #e5e5e5;
        }
        &_default {
            height:80px;
            &-logo {
                width: 55px;
                height: 55px;
                img{
                    width: 100%;
                    height:100%;
                    border-radius: 6px;
                    object-fit: cover;
                }
            }

            &-title {
                padding-left: 20px;
            }

            &-company {
                font-size: 20px;
                font-weight: 700;
                line-height:30px;
                width: 100%;
            }
        }
    }

    &--center {
        position: relative;
    }

    &--history {
        &_cover {
            width: 100%;
            cursor: pointer;
            transition: opacity .1s;
            white-space: nowrap;

            &:hover {
                opacity: .8;
            }
        }

        &-icon {
            font-size: 18px;
            padding-right: 10px;
        }
    }

    &--step {
        border-radius: 6px;
        width: 40%;
        min-width: 320px;

        .gap {
            font-size: 12px;
        }

        &_item {
            height: 80px;
            font-size:18px;
            transition:background .1s;
            &:hover {
                background: var(--hover-primary);
            }
        }

        &_title {
            cursor: pointer;
            transition: transform .3s;
            height: 80px;
            position: relative;
            top: -1px;
            padding: 0 20px;

            &.active:after {
                content:'';
                height:4px;
                width: 80%;
                border-radius: 3px;
                position: absolute;
                bottom:0;
                left:0;
                right:0;
                margin:0 auto;
                background: #fff;
            }
            
        }
    }

    &--user {
        height:80px;
        &_page-title{
            padding-left:20px;
            font-size:20px;
            color:#666666;
            font-weight: bold;
        }
        &_avatar {
            width: 48px;
            height: 48px;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }

            cursor: pointer;
        }

        &_name {
            white-space: nowrap;
            font-size: 15px;
            line-height:20px;
            p:last-child {
                font-size: 13px;
                opacity: .9;
            }
        }

        &_item {
            cursor: pointer;
            transition: opacity .2s;
            height: 80px;
            padding: 0 20px;

            &:hover {
                opacity: .5;
            }
        }

        &_tips {
            position: relative;

            &.hint:after {
                content: '';
                position: absolute;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: red;
                top: 22px;
                right: 22px;
            }
        }
    }
}
</style>