<template>
    <div class="kpi-wrapper r-page" @click="popCoverHide">
        <div class="r-filters">
            <div class="r-search">
                <el-form size="small" inline @submit.native.prevent>
                    <el-form-item>
                        <el-input placeholder="请输入关键词" v-model.trim="query.keyword" @keydown.enter.native="search"
                            clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
                    </el-form-item> <el-form-item>
                        <el-button type="primary" @click="exportExcel()" :loading="exportLoading">导出</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="r-toolbar">
                <el-form inline size="small">
                    <el-form-item>
                        <div class="r-flex">
                            <el-popover placement="bottom-end" width="500" trigger="manual" v-model="popShow"
                                @click.stop.native>
                                <div>
                                    <div class="r-pb-20">筛选条件</div>
                                    <el-form size="small" label-width="100px" label-position="left">
                                        <el-form-item label="末级指标状态">
                                            <el-select v-model="searchQuery.lastStatus" multiple placeholder="请选择末级指标状态"
                                                class="r-w-max">
                                                <el-option v-for="item in [
                                                    { label: '未启动', value: 1 },
                                                    { label: '正常进行', value: 2 },
                                                    { label: '进度缓慢', value: 3 },
                                                    { label: '已完成', value: 4 },
                                                    { label: '未完成', value: 5 },
                                                ]" :key="item.value" :label="item.label" :value="item.value">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item label="负责人">
                                            <r-tag-select v-model="searchQuery.employee" class="r-w-max"
                                                :collapseTags="true" placeholder="请选择负责人"
                                                @focus="handleEmployee('checkbox', '请选择负责人', 'employee')"
                                                labelKey="name"></r-tag-select>
                                        </el-form-item>
                                        <el-form-item label="协作人">
                                            <r-tag-select v-model="searchQuery.collaborateEmployee" class="r-w-max"
                                                :collapseTags="true" placeholder="请选择协作人"
                                                @focus="handleEmployee('checkbox', '请选择协作人', 'collaborateEmployee')"
                                                labelKey="name"></r-tag-select>
                                        </el-form-item>
                                        <el-form-item label="负责部门">
                                            <r-tag-select v-model="searchQuery.department" class="r-w-max"
                                                :collapseTags="true" placeholder="请选择部门"
                                                @focus="handleChangeSelect('checkbox', '请选择负责部门', 'department')"
                                                labelKey="name"></r-tag-select>
                                        </el-form-item>
                                        <el-form-item label="协作部门">
                                            <r-tag-select v-model="searchQuery.collaborateDepartment" class="r-w-max"
                                                :collapseTags="true" placeholder="请选择协作部门"
                                                @focus="handleChangeSelect('checkbox', '请选择协作部门', 'collaborateDepartment')"
                                                labelKey="name"></r-tag-select>
                                        </el-form-item>
                                        <el-form-item label="计划开始时间">
                                            <div class="r-flex">
                                                <el-date-picker class="r-w-max" v-model="searchQuery.startTime" type="daterange"
                                                    range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期">
                                                </el-date-picker>
                                            </div>
                                        </el-form-item>         
                                        <el-form-item label="计划结束时间">
                                            <div class="r-flex">
                                                <el-date-picker class="r-w-max" v-model="searchQuery.endTime" type="daterange"
                                                    range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期">
                                                </el-date-picker>
                                            </div>
                                        </el-form-item>
                                        <el-form-item>
                                            <div class="r-flex r-row-right">
                                                <el-button @click="popHide">取消</el-button>
                                                <el-button type="primary" @click="searchConfirm">确认</el-button>
                                            </div>
                                        </el-form-item>
                                    </el-form>
                                </div>
                                <el-button slot="reference" class="r-mr-10 sx-btn" @click="popOpen"
                                    :class="{ active: searchActive }">
                                    <r-icon name="shaixuan" type="task" size="16"></r-icon>
                                </el-button>
                            </el-popover>
                            <el-popover placement="bottom-end" width="270" trigger="manual" v-model="pointPopShow"
                                @click.stop.native>
                                <div>
                                    <div class="r-pb-20">自动评分设置</div>
                                    <el-form size="small" label-width="80px" label-position="left">
                                        <el-form-item :label="item.label" v-for="item in scoreRuleTemplate"
                                            :key="item.field">
                                            <el-input-number :controls="false" class="center" :min="0" :max="10"
                                                v-model="item.value"></el-input-number><span class="r-pl-15">分</span>
                                        </el-form-item>
                                        <el-form-item>
                                            <div class="r-flex r-row-right">
                                                <el-button @click="pointPopHide" class="r-mr-10">取消</el-button>
                                                <el-popconfirm title="请再次确认是否修改自动评分设置？" @confirm="updateScoreRule">
                                                    <el-button type="primary" slot="reference">确认</el-button>
                                                </el-popconfirm>
                                            </div>
                                        </el-form-item>
                                    </el-form>
                                </div>
                                <el-button slot="reference" class="r-mr-10 sx-btn" @click="pointPopOpen">
                                    <r-icon :name="scoreRuleLoading ? 'loading' : 'c-scale-to-original'"
                                        size="18"></r-icon>
                                </el-button>
                            </el-popover>
                            <el-button type="primary" icon="el-icon-plus" @click="add"
                                v-if="$has('indicator-create')">新建指标</el-button>
                            <el-button type="primary" icon="el-icon-edit" v-if="$has('indicator-template')"
                                @click="editIndicatorTemplate">指标模板</el-button>
                            <el-button type="primary" icon="el-icon-edit" @click="editTemplate"
                                v-if="$has('indicator-form-template')">默认表单</el-button>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="r-content">
            <r-table ref="table" :loader="loader" :columnLoader="columnLoader" height="100%"
                :cell-class-name="cellClassName" @cell-click="detailOpen" row-key="id" @sortChange="sortChange"
                :columnsProps="{ filter: columnsFilter }" default-expand-all>
                <template v-slot:footerToolbar>
                    <div class="r-flex r-flex-wrap r-pr-20 count-wrapper">
                        <div v-for="(item, index) in count" :key="index" class="r-pr-20">{{ item.level }}级指标<span
                                class="main-color">{{ item.count }}</span>个</div>
                    </div>
                </template>
                <template v-slot:name="{ row, $index }">
                    <template v-if="!row.isEdit">
                        <div class="r-flex r-flex-auto-w">
                            <r-icon name="folder-checked" color="#ff6565" right="5" size="16"
                                v-if="row.type == 2"></r-icon>
                            <el-link class="r-line-1 r-row-left">
                                <div v-html="row.name"></div>
                            </el-link>
                        </div>
                        <!-- 此时中间态，可新建两个 -->
                        <el-dropdown placement="top-start"
                            v-if="row.type == 0 && ($has('indicator-create') || $has('indicator-form-template'))"
                            @visible-change="(show) => { visibleChange(row, show) }"
                            @command="(command) => { commandConfirm(row, command, $index) }">
                            <div class="item-menu" @click.stop><r-icon name="circle-plus-outline hover--opacity"
                                    size="22"></r-icon>
                            </div>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="add-top-indicator"
                                    v-if="$has('indicator-create')">上方插入同级指标</el-dropdown-item>
                                <el-dropdown-item command="add-bottom-indicator"
                                    v-if="$has('indicator-create')">下方插入同级指标</el-dropdown-item>
                                <el-dropdown-item command="add-indicator"
                                    v-if="$has('indicator-create')">新建子指标</el-dropdown-item>
                                <el-dropdown-item command="add-template"
                                    v-if="$has('indicator-form-template')">新建任务模板</el-dropdown-item>
                                <!-- <el-dropdown-item command="add-task">新建任务</el-dropdown-item> -->
                            </el-dropdown-menu>
                        </el-dropdown>
                        <el-dropdown placement="top-start" v-if="row.type == 1 && $has('indicator-create')"
                            @visible-change="(show) => { visibleChange(row, show) }"
                            @command="(command) => { commandConfirm(row, command, $index) }">
                            <div class="item-menu" @click.stop><r-icon name="circle-plus-outline hover--opacity"
                                    size="22"></r-icon>
                            </div>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="add-top-indicator"
                                    v-if="$has('indicator-create')">上方插入同级指标</el-dropdown-item>
                                <el-dropdown-item command="add-bottom-indicator"
                                    v-if="$has('indicator-create')">下方插入同级指标</el-dropdown-item>
                                <el-dropdown-item command="add-indicator"
                                    v-if="$has('indicator-create')">新建子指标</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <!-- 此时中间态，可新建两个 -->
                        <el-dropdown placement="top-start"
                            v-if="row.type == 2 && ($has('indicator-create') || $has('task-create'))"
                            @visible-change="(show) => { visibleChange(row, show) }"
                            @command="(command) => { commandConfirm(row, command, $index) }">
                            <div class="item-menu" @click.stop><r-icon name="circle-plus-outline hover--opacity"
                                    size="22"></r-icon>
                            </div>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="add-top-indicator"
                                    v-if="$has('indicator-create')">上方插入同级指标</el-dropdown-item>
                                <el-dropdown-item command="add-bottom-indicator"
                                    v-if="$has('indicator-create')">下方插入同级指标</el-dropdown-item>
                                <el-dropdown-item command="add-task" v-if="$has('task-create')">新建任务</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                    <template v-else>
                        <el-input v-model="row.name" placeholder="请输入指标名称，按enter可直接提交" clearable ref="editInput"
                            @blur="indicatorAddOver(row)" :readonly="row.loading" id="editInput"
                            :suffix-icon="row.loading ? 'el-icon-loading' : ''"
                            @keydown.enter.native="indicatorAddOver(row)"></el-input>
                    </template>
                </template>
                <template v-slot:status="{ row }">
                    <el-dropdown @command="(command) => statusDropdownClick(command, row)" placement="bottom-start"
                        trigger="click" :show-timeout="500" v-if="row.statusStr"
                        @visible-change="(visible) => { statusVisibleChange(visible, row) }" class="r-w-max">
                        <p :style="`color:${row.statusColor};`" class="status-item">
                            <r-icon name="share" :color="row.statusColor" size="16" v-if="row.statusEdit"></r-icon>
                            <span :class="{ 'r-pl-5': row.statusEdit }">{{ row.statusStr }}</span>
                        </p>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="item.value" v-for="item in statusList" :key="item.value">
                                <span>{{ item.label }}</span>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <p v-else class="status-item">--</p>
                </template>
                <template v-slot:handle="{ row }">
                    <el-link type="primary" @click="edit(row)" v-if="!row.isEdit && $has('indicator-edit')">编辑</el-link>
                    <el-divider direction="vertical"
                        v-if="row.type == 0 && $has('indicator-delete') && $has('indicator-edit')"></el-divider>
                    <el-popconfirm title="确定要删除该指标吗？" @confirm="delIndicator(row)">
                        <el-link type="primary" slot="reference"
                            v-if="row.type == 0 && $has('indicator-delete')">删除</el-link>
                    </el-popconfirm>
                </template>
                <template v-slot:no="{ row }">
                    <div class="r-flex r-row-bet r-w-max">
                        <span>{{ row | noFilter }}</span>
                        <span class="hover--opacity" v-show="row.hasChildrenKey">
                            <r-icon name="arrow-right" class="arrow-icon" size="12"
                                :class="row.expand ? 'open' : ''"></r-icon>
                        </span>
                    </div>
                </template>
                <template v-slot:employee="{ row }">
                    {{ row.isEdit ? '' : row.employee }}
                </template>
                <template v-slot:collaborateEmployee="{ row }">
                    {{ row.isEdit ? '' : row.collaborateEmployee }}
                </template>
                <template v-slot:department="{ row }">
                    {{ row.isEdit ? '' : row.department }}
                </template>
                <template v-slot:leader="{ row }">
                    {{ row.isEdit ? '' : row.leader }}
                </template>
                <template v-slot:annex="{ row }">
                    <div class="file-list r-flex r-flex-column r-col-top" v-if="row.annex">
                        <el-link type="primary" v-for="(item, index) in JSON.parse(row.annex)" :key="index"
                            :href="$getStaticUrl(item.url)" target="_blank">{{ item.name }}</el-link>
                    </div>
                </template>
            </r-table>
        </div>
        <update ref="update" @success="refresh(0)"></update>
        <dep-role-modal :config="modalConfig" v-model="dialogVisible" @confirm="handleConfirm"></dep-role-modal>
        <detail ref="detail" @close="detailClose" @update="detailClose(true)"></detail>
        <approveDetail ref="approveDetail" @close="detailClose"></approveDetail>
        <indicator-template ref="indicatorTemplate" @update="tableRefresh"></indicator-template>
    </div>
</template>
<script>
import { list, add, del, template, exportList, weightSave, getScoreRule, editScoreRule, statusSave, statusList } from "@/api/indicator";
import update from "./components/update";
import DepRoleModal from "@/components/dialog/DepRoleModal";
import detail from "./components/detail";
import approveDetail from "@/components/approve/createForm";
import { getDateRange, readParentNodes, guid, cloneDeep, isObjectNone, isArray, readNodes } from "@/utils";
import indicatorTemplate from "./components/template";
import { columns } from "@/config/table";
import fileDownload from "js-file-download";
import moment from "moment";
const defaultQuery = {
    keyword: '',
    // 末级指标状态
    lastStatus: [],
    //负责人
    employee: [],
    //协作人
    collaborateEmployee: [],
    //负责部门
    department: [],
    //协作部门
    collaborateDepartment: [],
    orderBy: '',
    orderBySort: '',
    startTime: ['', ''],
    endTime: ['', ''],
}
export default {
    components: { update, DepRoleModal, detail, approveDetail, indicatorTemplate },
    data() {
        return {
            modalConfig: {},
            dialogVisible: false,
            query: cloneDeep(defaultQuery),
            searchQuery: cloneDeep(defaultQuery),
            popShow: false,
            exportLoading: false,
            count: [],
            pointPopShow: false,
            scoreRuleLoading: false,
            scoreRuleTemplate: [],
            statusList: [],
            //树形平铺
            treeData: {},
            isSearch: false
        }
    },
    filters: {
        noFilter(row) {
            return row?.noLink?.length > 0 ? row.noLink.join('.') : ''
        }
    },
    computed: {
        columnsConfig() {
            return columns['indicator'];
        },
        searchActive() {
            return this.query.employee.length > 0 || this.query.lastStatus.length > 0 || this.query.department.length > 0 || this.query.collaborateEmployee.length > 0 || this.query.collaborateDepartment.length > 0 || (this.query.startTime || []).some(el=>el) || (this.query.endTime || []).some(el=>el);
        },
        //正在接入参数
        searchModel() {
            return !!this.query.keyword || this.searchActive;
        }
    },
    methods: {
        exportExcel() {
            this.exportLoading = true;
            // this.$message.warning("正在开发中...");
            // return false;
            exportList({
                ...this.query,
                page: 1,
                pageSize: 9999999,
                export: 1
            })
                .then((res) => {
                    fileDownload(res, "指标文件.xlsx");
                })
                .finally((res) => {
                    this.exportLoading = false;
                });
        },
        loader(page, pageSize) {
            let query = cloneDeep(this.query);
            query.startTime = query.startTime || ['', ''];
            query.endTime = query.endTime || ['', ''];
            return list({ page, pageSize, ...query }).then(res => {
                this.count = res.data.count?.level || [];
                res.data.list = this.getTreeList(res.data.list);
                //更新
                this.isSearch = false;
                return res.data;
            })
        },
        columnLoader() {
            return template().then(res => {
                //设置别名
                this.$store.commit('setIndicatorName', res.data);
                return this.columnsFilter(res.data);
            })
        },
        refresh(page) {
            this.$refs.table.refresh(page || '');
        },
        search() {
            this.isSearch = true;
            this.$refs.table.refresh(1);
        },
        add() {
            this.$refs.update.open();
        },
        edit(row) {
            this.$refs.update.open(
                {
                    id: row.id
                },
                true,
                true
            );
        },
        addSub(row) {
            //父指标不存在下级
            if (!row.children) {
                this.$set(row, 'children', []);
            }

            //添加一行
            row.children.unshift({
                name: '',
                isEdit: true,
                startDate: row.startTime,
                endDate: row.endTime,
                no: 1,
                leader: row.leaderData || [],
                describe: '',
                pid: row.id,
                collaborateDepartment: row.collaborateDepartmentData || [],
                department: row?.departmentData?.length ? row.departmentData : [],
                collaborateEmployee: row.collaborateEmployeeData || [],
                employee: row.employeeData ? [row.employeeData] : [],
                id: guid(20),
                parent: row,
                loading: false,
                //附件
                annex: '',
                //督办文件
                superviseFile: '',
                //来文单位
                sourceUnity: '',
                editIndex: 0,
                sourceTime: ''
            });

            this.$nextTick(() => {
                this.$set(row, 'expand', true);
                this.$set(row, 'hasChildrenKey', true);
                this.$refs.table.toggleRowExpansion(row, true);
                this.$nextTick(() => {
                    document.getElementById('editInput')?.focus && document.getElementById('editInput').focus()
                })
            })
        },
        /**
         * 
         * @param {*} row 当前元素
         * @param {*} insertType 插入类型
         */
        addSibling(row, insertType) {
            //获取父级元素
            let parent = this.$refs.table.getNode(row.pid);
            // 获取当前指标下标
            let index = 0;
            if (parent) {
                index = parent.children.findIndex(el => el.id == row.id);
            } else {
                index = this.$refs.table.getTopIndex(row.id);
            }
            //不存在下标
            if (index == -1) return;
            index = insertType == 'pre' ? index > 0 ? index : 0 : index + 1;
            let form = {
                name: '',
                isEdit: true,
                startDate: parent ? parent.startTime : moment().startOf('year').format('YYYY-MM-DD'),
                endDate: parent ? parent.endTime : moment().endOf('year').format('YYYY-MM-DD'),
                leader: parent?.leaderData || [],
                describe: '',
                pid: parent?.id || '',
                collaborateDepartment: parent?.collaborateDepartmentData || [],
                department: isArray(parent?.departmentData) && parent?.departmentData?.length ? parent.departmentData : [],
                collaborateEmployee: parent?.collaborateEmployeeData || [],
                employee: parent?.employeeData ? [parent.employeeData] : [],
                id: guid(20),
                parent: parent || '',
                loading: false,
                //附件
                annex: '',
                //督办文件
                superviseFile: '',
                //来文单位
                sourceUnity: '',
                editIndex: index,
                insertType,
                sourceTime: '',
                insertId: row.id
            }

            if (parent) {
                parent.children.splice(index, 0, form);
            } else {
                this.$refs.table.splice(index, 0, form);
            }

            this.$nextTick(() => {
                if (parent) this.$refs.table.toggleRowExpansion(parent, true);
                this.$nextTick(() => {
                    document.getElementById('editInput')?.focus && document.getElementById('editInput').focus()
                })
            })
        },
        indicatorAddOver(row) {
            if (row.loading) return;
            if (!row.name && row.name !== 0) {
                if (row.parent) {
                    row.parent.children.splice(row.editIndex, 1);
                    row.parent.expand = false;
                    if (row.parent.children.length == 0) {
                        row.parent.hasChildrenKey = false;
                    }
                } else {
                    this.$refs.table.splice(row.editIndex, 1);
                }
            } else {
                row.loading = true;
                let form = cloneDeep(row);
                delete form.id;
                delete form.parent;
                add(form)
                    .then(res => {
                        //刷新页面
                        this.refresh('');
                    }).catch(err => {
                        row.loading = false;
                        this.$nextTick(() => {
                            this.$refs.editInput && this.$refs.editInput.focus();
                        })
                    })
            }
        },
        addTask(row) {
            let nodes = readParentNodes(this.$refs.table.getTableData(), row);
            let indicatorName = [...nodes.map(el => el.name), row.name].join('/');
            this.$refs.approveDetail.handleOpen({
                id: 0,
                title: '新建任务',
                indicatorId: row.id,
                task: true,
                indicatorName,
                indicatorDate: getDateRange(row.startTime, row.endTime),
                leader: row.leaderData || [],
                employee: row.employeeData ? [row.employeeData].map(el => {
                    return {
                        id: el.id,
                        name: el.nickname
                    }
                }) : '',
                department:row.departmentData || []
            });
        },
        handleChangeSelect(selectType, title, model) {
            this.modalConfig = {
                type: 1,
                selectType,
                title,
                checkStrictly: true,
                model,
                checkList: this.searchQuery[model] || [],
            };
            this.dialogVisible = true;
        },
        handleEmployee(selectType, title, model) {
            this.popLock = true;
            this.modalConfig = {
                type: 1,
                selectType,
                title,
                checkStrictly: true,
                model,
                checkList: this.searchQuery[model] || [],
                isStaff: 1,
                onlyStaff: 1
            };
            this.dialogVisible = true;
        },
        handleConfirm(val) {
            this.searchQuery[this.modalConfig['model']] = val;
            this.dialogVisible = false;
        },
        cellClassName({ row, column }) {
            if (column.property == 'name' && !row.isEdit) {
                return `hover--opacity table-expend`;
            } else if (column.property == 'no') {
                return 'hover--opacity no-switch'
            } else if (column.property == 'weight') {
                return 'hover--opacity'
            } else if (column.property == 'status') {
                return 'hover--opacity p-hide'
            }
            else {
                return '';
            }
        },
        detailOpen(row, column) {
            if (row.isEdit) return;
            if (column.property == 'name') {
                this.$refs.detail.open(row.id);
            } else if (column.property == 'no') {
                this.toggleExpand(row)
            } else if (column.property == 'weight') {
                this.$prompt('请输入一个范围为0~100的整数', '权重', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputValue: row.weight,
                    inputValidator: (val) => {
                        let numReg = /^[0-9]+$/g;
                        if (!numReg.test(val)) {
                            return '权重只能是整数'
                        }
                        if (parseInt(val) < 0 || parseInt(val) > 100) {
                            return '权重范围为0~100'
                        }
                        return true;
                    },
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            if (row.weight === parseInt(instance.inputValue)) {
                                this.$message.success('权重未发生更改');
                                done();
                                return;
                            }
                            weightSave({
                                id: row.id,
                                weight: parseInt(instance.inputValue)
                            }).then(() => {
                                this.$message.success('权重修改成功');
                                this.refresh();
                                done();
                            }).catch(() => { })
                        } else {
                            done();
                        }
                    }
                }).then(({ value }) => { }).catch(() => { });
            }
        },
        sortChange(order) {
            this.query = Object.assign(this.query, order);
            this.$nextTick(() => {
                this.search();
            })
        },
        handleAddApprove({ id = '' }) {
            history.pushState({ type: 'open' }, '', this.$route.path + '#open');
            this.approveId = 0;
            this.approveDetailShow = true;
        },
        open() {
            this.$refs.approveDetail.handleOpen();
        },
        detailClose(isUpdate = false) {
            if (isUpdate) {
                this.refresh();
            }
        },
        visibleChange(row, isVisible) {
            this.$set(row, 'isShow', isVisible)
        },
        commandConfirm(row, command, index) {
            if (['add-indicator', 'add-top-indicator', 'add-bottom-indicator'].includes(command)) {
                if (command == 'add-indicator') {
                    this.addSub(row);
                } else {
                    this.addSibling(row, command == 'add-top-indicator' ? 'pre' : 'next');
                }
            } else if (command == 'add-task') {
                this.addTask(row)
            } else {
                this.$refs.approveDetail.handleOpen(
                    {
                        id: 0,
                        title: '新建任务模板',
                        indicatorId: row.id
                    }
                );
            }
        },
        //编辑全局任务模板
        editTemplate() {
            this.$refs.approveDetail.handleOpen({
                title: '编辑全局任务模板',
                isGlobal: true
            });
        },
        //删除指标
        delIndicator(row) {
            del({ id: row.id }).then(res => {
                this.$message.success('指标删除成功');
                this.refresh();
            })
        },
        editIndicatorTemplate() {
            this.$refs.indicatorTemplate.open();
        },
        //过滤方法
        columnsFilter(columns) {
            let _columns = columns.filter(el => el.show).map(el => {
                return {
                    label: el.alias || el.label,
                    prop: el.prop,
                    ...this.columnsConfig[el.prop]
                }
            })
            _columns.push({
                label: '操作',
                prop: 'handle',
                "min-width": '100px',
                fixed: 'right'
            });
            return _columns;
        },
        tableRefresh() {
            this.$refs.table.columnsRefresh();
        },
        popOpen() {
            this.popShow = !this.popShow;
            if (this.popShow) {
                this.searchQuery = cloneDeep(this.query);
                this.pointPopHide();
            }
        },
        popHide() {
            this.popShow = false;
        },
        pointPopOpen() {
            let show = !this.pointPopShow;
            if (show) {
                if (this.scoreRuleLoading) return;
                this.popHide();
                //请求数据
                this.scoreRuleLoading = true;
                getScoreRule().then(res => {
                    this.scoreRuleTemplate = res.data;
                    this.pointPopShow = true;
                }).finally(() => {
                    this.scoreRuleLoading = false;
                })
            }
        },
        pointPopHide() {
            this.pointPopShow = false;
        },
        popCoverHide() {
            this.popHide();
            this.pointPopHide();
        },
        searchConfirm() {
            this.popHide();
            this.query = cloneDeep(this.searchQuery);
            this.search();
        },
        updateScoreRule() {
            this.pointPopHide();
            editScoreRule({ formData: this.scoreRuleTemplate }).then(res => {
                this.$message.success('自动评分规则修改成功');
            }).catch(err => { })
        },
        getStatusList(command = 0, row) {
            this.statusList = [];
            statusList().then(res => {
                this.statusList = res.data.filter(el => el.value != (command || row.status));
            })
                .catch(err => {
                    console.log(err);
                })
        },
        statusVisibleChange(visible, row) {
            if (visible) {
                this.getStatusList(row.status, row)
            }
        },
        statusDropdownClick(command, row) {
            row.status = command;
            row.statusEdit = 1;
            statusSave({ id: row.id, status: command, statusEdit: 1 }).then(res => {
                this.$message.success('指标状态修改成功');
                this.tableRefresh();
            })
        },
        getTreeList(list) {
            //记录一级id
            let topIds = list.map(el => el.id);
            //缓存数据
            readNodes(list, el => el).forEach(el => {
                if (el?.children && el.children.length > 0) {
                    el.childrenKeys = el.children.map(el => el.id);
                    el.children = [];
                    el.hasChildrenKey = true;
                }else{
                    el.hasChildrenKey = false;
                    el.childrenKeys = [];
                }
                //是否存在接入数据
                if (this.searchModel) {
                    //标记一下用于后续展开
                    this.itemShowCheck(el);
                } else {
                    el.isShow = true;
                }
                
                //应该是合并
                this.treeData[el.id] = this.treeData[el.id] ? Object.assign(this.treeData[el.id], el, { expand: this.isSearch ? false : !!this.treeData[el.id]?.expand }) : el;
            })
            //此时必须要打开所有已展开节点
            this.toggleAllRowExpansion();
            return topIds.map(el => this.treeData[el]);
        },
        toggleExpand(row) {
            if (!row.hasChildrenKey) return;
            this.$set(row, 'expand', !row.expand);
            if (row.children.length == 0) {
                row.children = row.childrenKeys.filter(el => !!this.treeData[el]?.isShow).map(el => this.treeData[el]);
                this.$refs.table.toggleRowExpansion(row, true);
            } else {
                row.children = [];
                this.$refs.table.toggleRowExpansion(row, false);
            }
        },
        //树级展开
        treeExpand() {
            //提取出当前命中的元素
            let searchItems = Object.values(this.treeData).filter(el => el.isShow);
            //根据命中的元素补充父级数据
            const traverse = (nodes) => {
                let parent = [];
                nodes.forEach(el => {
                    //此时必须校验当前元素是否存在可开关元素
                    this.treeData[el.id].hasChildrenKey = (el.childrenKeys || []).some(el => !!this.treeData[el]?.isShow);
                    if (el.pid != '00000000-0000-0000-0000-000000000000') {
                        //展开及显示
                        this.treeData[el.pid].expand = true;
                        this.treeData[el.pid].isShow = true;
                        parent.push(this.treeData[el.pid])
                    }
                });
                if (parent.length == 0) return;
                //继续执行
                searchItems.push(...parent);
                traverse(parent);
            }

            traverse(searchItems);
        },
        toggleAllRowExpansion() {
            //此时要进行树级展开
            if (this.searchModel) {
                this.treeExpand();
            }
            //渲染结束后执行
            this.$nextTick(() => {
                //拉取出所有展开的数据
                let openRows = Object.values(this.treeData).filter(el => el.expand);
                //填充展开的数据
                openRows.forEach(el => {
                    //过滤掉不存在的元素
                    el.children = el.childrenKeys.filter(el => !!this.treeData[el]?.isShow).map(el => this.treeData[el]);
                    if (el.children.length == 0) {
                        el.hasChildrenKey = false;
                    }
                    this.$refs.table.toggleRowExpansion(el, true);
                });
            })
        },
        //判断是否允许展示
        itemShowCheck(el) {
            let startTimeCheck = (this.query.startTime || []).some(_el=>_el);
            let endTimeCheck = (this.query.endTime || []).some(_el=>_el);
            el.isShow = false;
            //替换掉关键字
            if (this.query.keyword) {
                //命中
                el.name = el.name.replace(new RegExp(this.query.keyword, 'gi'), `<span class="main-color">${this.query.keyword}</span>`);
                
                if((new RegExp(this.query.keyword, 'gi')).test(el.name)){
                    el.isShow = true;
                    return;
                }
            }
            //末级指标
            if(this.query.lastStatus.length > 0){
                let check = this.query.lastStatus.includes(el.status) && el.type == 2;
                if(check){
                    el.isShow = true;
                    return;
                }
            }
            //负责人
            if(this.query.employee.length > 0){
                let check = this.query.employee.find(item=>item?.id == el.employeeData?.id);
                if(check){
                    el.isShow = true;
                    return;
                }
            }
            //协作人
            if(this.query.collaborateEmployee.length > 0){
                let check = this.query.collaborateEmployee.some(item=>el.collaborateEmployeeData.find(_item=>_item?.id == item?.id));
                if(check){
                    el.isShow = true;
                    return;
                }
            }
            //负责部门
            if(this.query.department.length > 0){
                let check = this.query.department.some(item=>el.departmentData.find(_item=>_item?.id == item?.id));
                if(check){
                    el.isShow = true;
                    return;
                }
            }
            //协作部门
            if(this.query.collaborateDepartment.length > 0){
                let check = this.query.collaborateDepartment.some(item=>el.collaborateDepartment .find(_item=>_item?.id == item?.id));
                if(check){
                    el.isShow = true;
                    return;
                }
            }
            if(startTimeCheck || endTimeCheck){
                //一定存在
                let itemStartTime = moment(el.startTime);
                let itemEndTime = moment(el.endTime);
                //如果同时出现选项
                if(startTimeCheck && endTimeCheck){
                    let startTimeStart = moment(this.query.startTime[0]);
                    let startTimeEnd = moment(this.query.startTime[1]);
                    let endTimeStart = moment(this.query.endTime[0]);
                    let endTimeEnd = moment(this.query.endTime[1]);
                    let check = (startTimeStart <= itemStartTime && itemStartTime <= startTimeEnd) && (endTimeStart <= itemEndTime && itemEndTime <= endTimeEnd);
                    if(check){
                        el.isShow = true;
                        return;
                    }
                }else{
                    if(startTimeCheck){
                        let startTime = moment(this.query.startTime[0]);
                        let endTime = moment(this.query.startTime[1]);
                        let check = startTime <= itemStartTime && itemStartTime <= endTime;
                        if(check){
                            el.isShow = true;
                            return;
                        }
                    }
                    
                    if(endTimeCheck){
                        let startTime = moment(this.query.endTime[0]);
                        let endTime = moment(this.query.endTime[1]);
                        let check = startTime <= itemEndTime && itemEndTime <= endTime;
                        if(check){
                            el.isShow = true;
                            return;
                        }
                    }
                }
            }
        }
    },
    created() {
        if (!isObjectNone(this.$route.params)) {
            this.query = Object.assign(this.query, this.$route.params);
        }
    },


}
</script>

<style lang="scss" scoped>
.kpi-wrapper {
    .item-menu {
        opacity: 0;
        transition: opacity .2s;
        padding: 0px 5px;
        color: var(--primary);
    }

    ::v-deep .el-table__expand-icon {
        display: none !important;
    }

    .arrow-icon {
        transition: all .3s;
        transform: rotate(0deg);

        &.open {
            transform: rotate(90deg);
        }
    }

    ::v-deep .highlight {
        color: #5eff00;
    }

    .sx-btn {
        padding: 7px 15px;

        &.active {
            border-color: var(--primary);
            color: var(--primary);
        }
    }

    .status-item {
        padding: 12px 10px;
        width: 100%;
    }

    .table-expend {
        &.menu-show {
            .item-menu {
                opacity: 1;
            }
        }

        &:hover {
            .item-menu {
                opacity: 1;
            }
        }
    }

    .count-wrapper {
        font-size: 13px;
    }
}
</style>