
const sessionStorageData =(()=>{
    
    let prefix = 'task_common_';

        try{

            let StorageSetter = (key,val)=>{

                return sessionStorage.setItem(prefix+key,JSON.stringify(val));

            }
            
            let StorageGetter = (key)=>{

                 let data=  sessionStorage.getItem(prefix+key);

                 return data && data!='undefined' ?  JSON.parse(data) : data;

            }

            let StorageRemove = (key)=>{

                return sessionStorage.removeItem(prefix+key);

            }

            let StorageClear = ()=>{

                return sessionStorage.clear();

            }
            
            return {
                        
                StorageSetter,
                        
                StorageGetter,
                        
                StorageRemove,

                StorageClear
                    
            }

        }catch(err){

             console.log('暂不支持此功能');

        }

})()


export default sessionStorageData;