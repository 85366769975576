import { render, staticRenderFns } from "./login_code_check.vue?vue&type=template&id=5254a0ec&scoped=true"
import script from "./login_code_check.vue?vue&type=script&lang=js"
export * from "./login_code_check.vue?vue&type=script&lang=js"
import style0 from "./login_code_check.vue?vue&type=style&index=0&id=5254a0ec&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5254a0ec",
  null
  
)

export default component.exports