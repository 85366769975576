<template>
   <div class="fxy-image-slide">
         <transition name="viewer-fade">
            <image-viewer v-if="value" :initialIndex="imageIndex" :appendToBody="true" ref="imageViewer" :urlList="previewImageList" :zIndex="59999" :onClose="imageClose" :isInfinite="viewerLoop"></image-viewer>
         </transition>
   </div>
</template>

<script>
/**
 * 
 * 图片展示组件
 * 
 * 传参
 * 
 * @param model 是否显示图片走廊
 * 
 * @param imageList 需要展示的图片列表
 * 
 * @param imageIndex 从哪张图片开始展示
 * 
 */
import {isArray} from "@/utils";
import imageViewer from "@/components/image-viewer";
export default {

    components:{
        
        imageViewer

    },

    props:{

        value:{

            type:Boolean,
            
            default:false

        },

        imageList:{

            type:Array,

            default:null

        },

        imageIndex:{

            type:Number,

            default:0

        },
        viewerLoop:{
            type:Boolean,
            default:false
        }

    },

    data(){

        return {}

    },

    computed:{

        //显示dialog

        dialogShow:{

            get(){

                return this.value;

            },

            set(){

                this.$emit('input',this.value);

            }

        },

        previewImageList(){
            return isArray(this.imageList) ?  this.imageList.map(el=>{
                return this.$getStaticUrl(el);
            }) : [];
        }

    },

    methods:{
        imageClose(){
            this.$emit('input',false);
            this.$emit('close');
        }
    }

}
</script>

<style lang="scss">

    .image-banner__dialog-wrapper{

        position: fixed;

        top:0;

        left:0;

        bottom:0;

        right:0;

        z-index:3888;

        background: rgba(0,0,0,.5);

        .el-carousel__container{

        height:100%;
    
        .el-carousel__item{
    
            overflow: auto;
    
        }

        .el-carousel__arrow{

            width: 60px;

            height:60px;

            background-color: rgba(0,0,0,.2);

            i{

                font-size:24px;

            }

        }
    
    }

        .image-banner__dialog-cover{

            width: 100%;

            height:100%;

            position: relative;

        }

        .image-banner__dialog-content{

            width: 100%;

            text-align: center;

            height:100%;

            .el-carousel{

                height:100%;


            }

            .image-cover{

                position: absolute;
                top:10px;
                left:0;
                bottom:0;
                right:0;
                margin: auto;
                overflow: auto;

            }

                            

            img{
                transition:all .2s;
                position: absolute;
                top:0;
                bottom:0;
                right:0;
                left:0;
                margin: 0 auto;
                max-width: 90%;
            }
            

        }

        .image-banner__dialog-close{

            position: absolute;

            right:-100px;

            top:-100px;

            z-index:10;

            width: 200px;

            height:200px;

            line-height:300px;

            padding-left:50px;

            background: rgba(0,0,0,.4);

            border-radius: 50%;

            transition:background-color .3s;

            cursor: pointer;

            .close-icon{

                font-size:35px;

                color:#d3d3d3;

                font-weight: bold;

                transition:color .3s;

            }

            &:hover{

                background: rgba(0,0,0,.5);

                .close-icon{

                    color:#f5f5f5;

                }

            }

        }

        .image-banner__dialog-actionbar{

            position: absolute;

            z-index:10;

            width: 90%;

            left:0;

            right:0;

            bottom:25px;

            margin: 0 auto;

            height:80px;

            background: rgba(0,0,0,.3);

            .image-banner__dialog-actionbar__cover{

                height: 100%;

                display: flex;

                align-items: center;

                padding-left:20px;
                

                .item{

                    color:#fff;

                    font-size:20px;

                    padding: 0 10px;

                    cursor: pointer;

                    opacity: .7;

                    transition:opacity .3;

                    &.btn{

                        font-size:30px;

                        i{

                            font-size:30px;

                        }

                    }

                    &:hover{

                        opacity: 1;

                    }

                }

            }

        }


    }

</style>
