<template>
<div class="task-employee">
    分管领导
</div>
</template>
<script>

export default {
    name:"taskLeader"

}
</script>

<style lang="scss" scoped>
    .task-employee{
        font-weight: bold;
    }
</style>