<template>
    <div class="stack-line-item">
        <div class="stack-line-item_label r-line-1">
            <span>{{ item.name }}</span>
            <span :style="{color:parseFloat(item.progress) >= warningValue? 'green' :'red'}">（{{ item.progress }}）</span>
        </div>
        <div class="stack-line-item_value r-flex">
            <template v-for="pipe in colors">
                <div class="stack-line-item_value_pipe"  :key="pipe.prop"
                    :style="{ flex: closeItems[pipe.prop] ? 0 : `${item['status' + pipe.num]}`}" v-if="item['status' + pipe.num] != 0">
                    <stack-line-item-line :num="item['status' + pipe.num]" :color="pipe.color"></stack-line-item-line>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import stackLineItemLine from "./stack-line-item_line";
export default {
    components:{
        stackLineItemLine
    },
    props:{
        item:{
            type:Object,
            default(){
                return {}
            }
        },
        colors:{
            type:Array,
            default(){
                return []
            }
        },
        closeItems:{
            type:Object,
            default(){
                return {}
            }
        },
        warningValue:{
            type:Number,
            default:0
        }
    },
    methods:{
        getItemFlex(item,prop){
        }
    },
    watch:{
        closeItems(val){
            console.log(val)
        }
    }



}
</script>

<style lang="scss" scoped>
.stack-line-item{
    margin-bottom:15px;
    &_value{
        margin-top:5px;
        background:#f5f5f5;
        height:30px;
        &_pipe{
            height:30px;
            overflow: hidden;
            transition: all 0.3s;
        }
    }
   }
</style>