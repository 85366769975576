<template>
    <div class="login-module login-check-code">
        <div class="login-module-back-container">
            <div class="back opacity-hover" @click="back">
                <i class="el-icon-arrow-left"></i>
                <span>返回</span>
            </div>
        </div>
        <div class="login-module-title__container">
            <div class="login-module-title">验证手机号</div>
            <div class="login-module-title-subtitle"><span>请输入发送至 <span class="phone">{{mobileString}}</span> 的 6 位验证码，有效期 10 分钟。如未收到，请尝试重新获取验证码。</span></div>
        </div> 
        <div class="login-module-content between">
            <div class="login-module-content-top">
                <phone-check-code
                ref="checkCode"
                @complete="checkCodeComplete"
                :disabled="loading"
                v-model="checkCode"></phone-check-code>
                <p class="check-code-conutdow">
                    <span v-if="countdown">{{num}}秒后可重新获取验证码</span>
                    <span v-else class="main-color opacity-hover" @click="getCheckCode">重新获取验证码</span>
                </p>
            </div>
            <div class="login-module-content-bottom">
                <el-button type="primary" class="login-btn" :disabled="btnDisabled || loading" :loading="loading">下一步</el-button>
            </div>
        </div>
        <login-verification-code ref="loginVerificationCode" v-if="codeShow" @confirm="verificationCodeConfirm"></login-verification-code>
    </div>
</template>
<script>
/**
 * 登录验证码校验
 */
import phoneCheckCode from '@/components/phoneCheckCode/index';
import {getCheckCode,login} from "@/api/user";
export default {

    components:{
        phoneCheckCode
    },

    props:{
        //用户手机号码
        moduleData:{
            type:Object,
            default:()=>({})
        }
    },

    data(){
        return{
            //是否在加载中
            loading:false,
            //是否在倒计时
            countdown:false,
            //倒计时秒数
            countdownSecond:60,
            //当前倒计时
            num:0,
            //状态码
            checkCode:'',
            codeShow:true
        }
    },

    computed:{

        //手机号码
        mobile(){
            return this.$store.state.login?.mobile;
        },

        //号码类型
        mobileType(){
            return this.$store.state.login?.mobileType;
        },

        //转化为文字
        mobileString(){
            return `${this.mobileType}${this.mobile}`
        },

        btnDisabled(){
            return this.checkCode.length <6
        }

    },

    methods:{
        //检查验证码
        checkCodeComplete(code){
            this.loading=true;
            login({phone:this.mobile,code})
            .then((res)=>{
                //登录成功,设置用户基础数据缓存
                this.$store.commit('userInfoInit',res.data);
                // 缓存unionId，用于请求公司列表，进入公司
                localStorage.setItem('unionId', '')
                //触发下一步
                this.$emit('next');
            }).catch(el=>{
                //报错清空
                this.$refs.checkCode.reset();
                this.$nextTick(()=>{
                    this.$refs.checkCode.inputFocus();
                    //错误的时候直接清除数据
                    this.$refs.loginVerificationCode.reset();
                });
            }).finally(()=>{
                this.loading=false;
            })
        },

        //开始倒计时
        countdownStart(){
            //开始倒计时
            this.countdown=true;
            this.num=this.countdownSecond;
            this.num--;
            const inv_id = global.setInterval(()=>{
                this.num--;
                if(!this.num){
                    this.countdown=false;
                    global.clearInterval(inv_id);
                }
            },1000);
        },

        //重新发送验证码
        resetCheckCode(code){
            if(this.loading) return;
            getCheckCode({phone:this.mobile,...code}).then(()=>{
                this.$message.success('已发送验证码')
                this.countdownStart();
                this.$refs.checkCode.inputFocus();
                this.codeShow = false;
            }).catch((err)=>{
                this.$refs.loginVerificationCode.reset();
            })
        },

        //返回上一页
        back(){
            this.$emit('back')
        },

        //发起校验
        verificationCodeConfirm(data){
            this.resetCheckCode(data);
        },
        getCheckCode(){ 
            this.codeShow = true;
            this.$nextTick(()=>{
                this.$refs.loginVerificationCode.reset();
            })
        }
    },
    mounted(){
        //重置
        this.$refs.checkCode.reset();
        // this.$nextTick(()=>{
        //     this.$refs.checkCode.inputFocus();
        //     //倒计时开始
        //     this.countdownStart();
        // })
    }



}
</script>

<style lang="scss" scoped>
.login-module{
    position: relative;
}
.login-check-code{
    .login-module-back-container{
        position: relative;
        z-index:100;
    }
    .login-module-content{
        margin-top:24px;
        .check-code-conutdow{
            color:#999999;
            margin-top:12px;

        }
    }
}
</style>
