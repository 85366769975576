<template>
<div class="r-empty">
    <slot></slot>
</div>
</template>
<script>

export default {

    name:'rEmpty'


}
</script>

<style lang="scss" scoped>
    .r-empty{
        text-align: center;
        padding: 60px;
        font-size:18px;
        color:#999999;
        width: 100%;
    }
</style> 