<template>
  <div class="code-check-container">
    <div class="code-check-wrapper">
      <template v-for="(item,index) in inputLength">
        <div class="code-check-item code-check-line" :key="index+45" v-if="index == lineShow && isEven && line"></div>
        <div class="code-check-item" :key="index">
          <input type="text" 
          @input="setCheckCode($event,index)"
          @focus="inputFocus" 
          :ref="'check_input_'+index" 
          v-model="checkCode[index]" 
          :disabled="disabled" 
          :class="{disabled}"
          @keydown.delete="deleteCheckCode($event,index)">
        </div>
      </template>
    </div>
  </div>
</template>
<script>
/**
 * 
 * 验证码组件
 * 
 */
import {isArray,findIndex} from '@/utils';
export default {
  props:{
    //需要多少个输入框
    inputLength:{
      type:Number,
      default:6
    },
    //分割线,偶数才显示
    line:{
      type:Boolean,
      default:true
    },
    disabled:{
      type:Boolean,
      default:false
    },
    //双向绑定
    value:{
      type:String,
      default:''
    }
  },

  computed:{

    //是否为偶数,只有偶数才能显示line
    isEven(){
      return this.inputLength%2 == 0;
    },
    //啥时候显示line
    lineShow(){
      return this.inputLength/2;
    },
    //当前的状态，是否为完成状态


  },

  methods:{
    /**
     * 限制输入数据并过滤
     */
    setCheckCode($event,index){
      //锁住
      this.lockOn();
      //过滤掉非数字字符
      this.checkCode[index] = this.checkCode[index].replace(/\D/g, '');
      //传递数据
      this.$emit('input',this.checkCode.join(''));
      //检查是否存在内容，如果是进入下一个输入框
      if(this.checkCode[index].length>=1){
        //已到尾
        if(index == this.inputLength-1){
          //锁定最后一位的输入
          this.checkCode[index] = this.checkCode[index].split('')[0];
          //触发完成方法
          this.$emit('complete',this.checkCode.join(''));
        }else{
          //下一个输入框
          let next = index+1;
          //进入下一个输入框
          isArray(this.$refs['check_input_'+next]) 
          && this.$refs['check_input_'+next].length>0
          && this.$refs['check_input_'+next][0].focus();
        }
        
      }
    },

    /**
     * 删除数据
     */
    
    deleteCheckCode($event,index){
      //上一个输入框
      let prev = index-1;
      if(prev < 0) return;
      //进入上一个输入框
      if(index == this.inputLength-1 && this.checkCode[index]){
        this.checkCode[index] = '';
      }else{
        this.checkCode[index] = this.checkCode[prev] = '';
        isArray(this.$refs['check_input_'+prev]) 
        && this.$refs['check_input_'+prev].length>0
        && this.$refs['check_input_'+prev][0].focus();
      }

    },
    
    /**
     * 聚焦方法
     * 必须按顺序选中
     */
    inputFocus(){
      if(this.lock) return;
      //按顺序获取第一个空元素
      let index = findIndex(this.checkCode,(el)=>{
        return !el;
      });
      index = index  == -1 ? this.inputLength-1 : index;
      isArray(this.$refs['check_input_'+index]) 
      && this.$refs['check_input_'+index].length>0
      && this.$refs['check_input_'+index][0].focus();
   
    },

    //输入上锁
    lockOn(){
      this.lock=true;
      if(this.invId){
        global.clearTimeout(this.invId);
      }

      this.invId=global.setTimeout(()=>{
        this.lock=false
      },400)
    },

    /**
     * 重置方法
     */
    reset(){
      this.checkCode = new Array(this.inputLength);
      //传递数据
      this.$emit('input','');
    }
  },

  data(){

    return {
      checkCode:[],
      //输入锁
      lock:false,
      invId:null
    }

  },

  created(){
    //创建对应的数据，防止循环的时候出错
    this.checkCode = new Array(this.inputLength);
  }


}
</script>

<style lang="scss" scoped>
  .code-check-container{

    .code-check-wrapper{

      display: flex;

      justify-content: space-between;

      align-items: center;

      .code-check-item{

        input{
          width: 42px;
          height:42px;
          outline: none;
          border:1px solid #bbbfc4;
          color:#1f2329;
          background: transparent;
          font-size:18px;
          line-height:18px;
          text-align: center;
          border-radius:4px;
          &:focus{

            border-color:#00a0e4;

          }

          &.disabled{

              background-color: #f5f7fa;
              border-color: #e4e7ed;
              color: #c0c4cc;
              cursor: not-allowed;

          }
        }

        &.code-check-line{

          width: 11px;

          height:1px;

          background: #181d25;

        }

      }

    }

  }
</style>