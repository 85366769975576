<template>
<div>
    <el-button type="primary" icon="el-icon-camera-solid">点击拍照</el-button>
</div>
</template>
<script>

export default {

    name:'camera'


}
</script>

<style lang="scss" scoped>

</style>