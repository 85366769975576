<template>
  <div class="base-info-wrapper r-flex r-row-center r-col-top">
    <div class="base-setting">
      <el-form label-position="top" ref="form" size="small">
        <el-form-item label="所属指标" prop="createTask" class="is-required" v-if="isTask">
          <el-input placeholder="请选择所属指标" v-model="infoData.parentName" readonly></el-input>
        </el-form-item>
        <el-form-item label="任务名称" prop="name" class=" is-required" v-if="isTask">
          <el-input placeholder="请输入任务名称" v-model="infoData.name"></el-input>
        </el-form-item>
        <div class="r-flex" v-if="isFilings && isTask">
          <el-form-item label="备案审批人" prop="taskFilingsPersonMany" class="r-flex-1 is-required">
            <r-tag-select v-model="infoData.taskFilingsPersonMany" labelKey="name" :collapseTags="infoData.taskFilingsPersonMany.length>5" placeholder="请选择审批负责人" max
              @focus="handleEmployee('checkbox', '请选择备案审批人', 'taskFilingsPersonMany')" :closable="false" :disabled="!!taskId && (nodeType != 9 && filingsStatus!= 'STATUS_REJECT')"></r-tag-select>
          </el-form-item>
        </div>
        <div class="r-flex" v-if="isTask && !isBatchTask">
          <el-form-item label="负责人" prop="personId" class="r-flex-1 is-required">
            <r-tag-select v-model="infoData.personId" labelKey="name" :collapseTags="true" placeholder="请选择负责人" max
              @focus="handleEmployee('radio', '请选择负责人', 'personId')" :closable="false"></r-tag-select>
          </el-form-item>
          <el-form-item label="协作人" class="r-flex-3 r-ml-15">
            <r-tag-select v-model="infoData.collaborator" labelKey="name" placeholder="请选择协作人" max
              @focus="handleEmployee('checkbox', '请选择协作人', 'collaborator')" valueKey="id"
              :collapseTags="infoData.collaborator && infoData.collaborator.length > 4"></r-tag-select>
          </el-form-item>
        </div>
        <div class="r-flex" v-if="isTask && !isBatchTask">
          <el-form-item label="负责部门" class="r-flex-1">
            <r-tag-select v-model="infoData.personDepId" labelKey="name" :collapseTags="true" placeholder="请选择负责部门" max
              @focus="handleChangeSelect('checkbox', '请选择负责部门', 'personDepId')"></r-tag-select>
          </el-form-item>
          <el-form-item label="协作部门" class="r-flex-3 r-ml-15">
            <r-tag-select v-model="infoData.collaborationDep" labelKey="name" placeholder="请选择协作部门" max
              @focus="handleChangeSelect('checkbox', '请选择协作部门', 'collaborationDep')" valueKey="id"
              :collapseTags="infoData.collaborationDep && infoData.collaborationDep.length > 4"></r-tag-select>
          </el-form-item>
        </div>
        <el-form-item label="审核人" class="r-flex-1" v-if="!isBatchTask">
          <r-tag-select v-model="infoData.taskReviewer" labelKey="name" placeholder="请选择审核人" max
            @focus="handleEmployee('checkbox', '请选择审核人', 'taskReviewer')" valueKey="id"
            :collapseTags="infoData.taskReviewer && infoData.taskReviewer.length > 4" @remove-tag="updateWorkFlow"
            :disabled="!editStatus.workflow"></r-tag-select>
        </el-form-item>
        <el-form-item label="评分人" class="r-flex-1" v-if="!isBatchTask">
          <div class="r-flex">
            <div class="r-flex-auto-w">
              <r-tag-select v-model="infoData.taskScorePerson" labelKey="name" placeholder="请选择评分人" max
                @focus="handleEmployee('radio', '请选择评分人', 'taskScorePerson')" valueKey="id" :collapseTags="true"
                @remove-tag="updateWorkFlow" :disabled="!editStatus.workflow || isAutoScore"></r-tag-select>
            </div>
            <el-checkbox :value="isAutoScore" @input="isAutoScoreChange" class="r-ml-20"
              v-if="isTask">自动评分</el-checkbox>
          </div>
        </el-form-item>
        <el-form-item label="分管领导" class="r-flex-1" v-if="!isBatchTask">
          <r-tag-select v-model="infoData.taskLeader" labelKey="name" placeholder="请选择分管领导" max
            @focus="handleEmployee('radio', '请选择分管领导', 'taskLeader')" valueKey="id" :collapseTags="true"
            @remove-tag="updateWorkFlow" :disabled="!editStatus.workflow"></r-tag-select>
        </el-form-item>
        <el-form-item :label="editStatus.endDate ? '计划结束日期' : '计划开始日期'" prop="date" class="is-required"
          v-if="(editStatus.startDate || editStatus.endDate) && isTask && !isBatchTask">
          <el-date-picker v-model="infoData.date" value-format="yyyy-MM-dd" type="daterange" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"
            v-if="editStatus.startDate && (editStatus.endDate && editStatus.endDate != 'add')">
          </el-date-picker>
          <el-date-picker v-else-if="editStatus.endDate == 'add'" v-model="infoData.date[1]"
            :picker-options="pickerOptions" type="date" value-format="yyyy-MM-dd" placeholder="选择结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="频率" prop="createRule" class="is-required r-form-item" v-if="!taskId && isTask &&!isBatchTask && !isFilings">
          <template v-slot:label>
            <div class="r-flex">
              <r-tooltip content="当遇到周期性任务时,可选择对应频率,系统会自动创建多个任务">频率</r-tooltip>
            </div>
          </template>
          <el-radio-group v-model="infoData.createRule" @change="createRuleChange">
            <el-radio :label="1">仅一次</el-radio>
            <el-radio :label="6">每周</el-radio>
            <el-radio :label="2">每月</el-radio>
            <el-radio :label="3">每季度</el-radio>
            <el-radio :label="4">每半年</el-radio>
            <el-radio :label="5">每年</el-radio>
          </el-radio-group>
          <div v-if="!taskId && isTask && [2, 6].includes(infoData.createRule)" class="r-pt-10">
            <div v-if="infoData.createRule == 6" class="r-flex r-shadow r-p-20">
              <span class="r-pr-10">每周</span><el-select v-model="infoData.ruleStart" style="width: 100px"><el-option
                  v-for="item in 7" :key="item" :value="item" :label="item"></el-option></el-select><span
                class="r-pl-10">开始</span><span class="r-p-h-20">到</span>
              <span class="r-pr-10">每周</span><el-select v-model="infoData.ruleEnd" style="width: 100px"><el-option
                  v-for="item in 7" :key="item" :value="item" :label="item"></el-option></el-select><span
                class="r-pl-10">结束</span>
            </div>
            <div v-else class="r-flex r-shadow r-p-20">
              <span class="r-pr-10">每月</span><el-select v-model="infoData.ruleStart" style="width: 100px"><el-option
                  v-for="item in 31" :key="item" :value="item" :label="item"></el-option></el-select><span
                class="r-pl-10">开始</span><span class="r-p-h-20">到</span>
              <span class="r-pr-10">每月</span><el-select v-model="infoData.ruleEnd" style="width: 100px"><el-option
                  v-for="item in 31" :key="item" :value="item" :label="item"></el-option></el-select><span
                class="r-pl-10">结束</span>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="逾期空间" v-if="isTask && !isBatchTask">
          <template v-slot:label>
            <div class="r-flex">
              <r-tooltip content='当任务配置逾期空间>0天时,超过计划结束日期仍可以完成任务, 但在逾期空间时间段内完成的任务会被标记为"逾期完成"'>逾期空间</r-tooltip>
            </div>
          </template>
          <el-input-number placeholder="请输入逾期空间" v-model="infoData.overdueValidDay" :controls="false"
            :disabled="!editStatus.overdueValidDay"
            :min="editStatus.overdueValidDay == 'add' ? infoData.overdueValidDay || 0 : 0"></el-input-number>
          <span class="r-pl-10">天</span>
        </el-form-item>
        <el-form-item v-if="isBatchTask" class="is-required r-form-item">
          <template #label>
            <div class="r-flex r-w-max">
              <p>批量创建<span v-if="infoData.batchList.length>0" class="main-color">（已添加{{infoData.batchList.length}}个）</span></p>
              <el-link class="r-flex-item-right" icon="el-icon-plus" :underline="false" type="primary" @click="addTaskItem">添加</el-link>
            </div>
          </template>
          <el-card class="box-card r-mt-10" v-for="(item, index) in infoData.batchList" :key="index">
              <div class="close-item hover--opacity" @click="removeTaskItem(index)">
                <r-icon name="delete" color="var(--danger)" size="26"></r-icon>
              </div>
              <el-form size="mini">
                <div class="r-flex">
                  <el-form-item label="负责人" class="r-flex-1">
                  <r-tag-select v-model="item.personId" labelKey="name" :collapseTags="true" placeholder="请选择负责人" max
                    @focus="handleEmployee('radio', '请选择负责人', 'personId',item)" :closable="false"></r-tag-select>
                </el-form-item>
                <el-form-item label="协作人" class="r-flex-1 r-ml-10">
                  <r-tag-select v-model="item.collaborator" labelKey="name" placeholder="请选择协作人" max
                    @focus="handleEmployee('checkbox', '请选择协作人', 'collaborator',item)" valueKey="id"
                    :collapseTags="item.collaborator && item.collaborator.length > 4"></r-tag-select>
                </el-form-item>
                <el-form-item label="负责部门" class="r-flex-1 r-ml-10">
                  <r-tag-select v-model="item.personDepId" labelKey="name" :collapseTags="true" placeholder="请选择负责部门" max
                    @focus="handleChangeSelect('checkbox', '请选择负责部门', 'personDepId',item)"></r-tag-select>
                </el-form-item>
                <el-form-item label="协作部门" class="r-flex-1 r-ml-10">
                  <r-tag-select v-model="item.collaborationDep" labelKey="name" placeholder="请选择协作部门" max
                    @focus="handleChangeSelect('checkbox', '请选择协作部门', 'collaborationDep',item)" valueKey="id"
                    :collapseTags="item.collaborationDep && item.collaborationDep.length > 4"></r-tag-select>
                </el-form-item>
                </div>
                <div class="r-flex">
                  <el-form-item label="审核人" class="r-flex-1">
                    <r-tag-select v-model="item.taskReviewer" labelKey="name" placeholder="请选择审核人" max
                      @focus="handleEmployee('checkbox', '请选择审核人', 'taskReviewer',item,index)" valueKey="id"
                      :collapseTags="item.taskReviewer && item.taskReviewer.length > 4"
                      :disabled="!editStatus.workflow" @remove-tag="updateBatchWorkFlow(index)"></r-tag-select>
                  </el-form-item>
                  <el-form-item label="评分人" class="r-flex-1 r-ml-10">
                    <div class="r-flex">
                      <div class="r-flex-auto-w">
                        <r-tag-select v-model="item.taskScorePerson" labelKey="name" placeholder="请选择评分人" max
                          @focus="handleEmployee('radio', '请选择评分人', 'taskScorePerson',item,index)" valueKey="id" :collapseTags="true"
                           :disabled="item.taskScorePerson?.length > 0 && item.taskScorePerson[0].id == '00000000-0000-0000-0000-000000000000'" @remove-tag="updateBatchWorkFlow(index)"></r-tag-select>
                      </div>
                      <el-checkbox :value="item.taskScorePerson?.length > 0 && item.taskScorePerson[0].id == '00000000-0000-0000-0000-000000000000'" @input="(val)=>isAutoScoreChange(val,item,index)" class="r-ml-20" size="mini">自动评分</el-checkbox>
                    </div>
                  </el-form-item>
                </div>
                <div class="r-flex">
                  <el-form-item label="分管领导" class="r-flex-1">
                    <r-tag-select v-model="item.taskLeader" labelKey="name" placeholder="请选择分管领导" max
                      @focus="handleEmployee('radio', '请选择分管领导', 'taskLeader',item,index)" valueKey="id" :collapseTags="true"
                      :disabled="!editStatus.workflow" @remove-tag="updateBatchWorkFlow(index)"></r-tag-select>
                  </el-form-item>
                  <el-form-item :label="editStatus.endDate ? '计划结束日期' : '计划开始日期'" prop="date" class="is-required t-flex-1 r-ml-10"
                    v-if="(editStatus.startDate || editStatus.endDate) && isTask">
                    <el-date-picker v-model="item.date" value-format="yyyy-MM-dd" type="daterange" range-separator="至"
                      start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" :clearable="false"
                      v-if="editStatus.startDate && (editStatus.endDate && editStatus.endDate != 'add')">
                    </el-date-picker>
                    <el-date-picker v-else-if="editStatus.endDate == 'add'" v-model="infoData.date[1]"
                      :picker-options="pickerOptions" type="date" value-format="yyyy-MM-dd" placeholder="选择结束日期">
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item label="逾期空间" class="r-ml-10">
                  <template v-slot:label>
                    <div class="r-flex">
                      <r-tooltip content='当任务配置逾期空间>0天时,超过计划结束日期仍可以完成任务, 但在逾期空间时间段内完成的任务会被标记为"逾期完成"'>逾期空间</r-tooltip>
                    </div>
                  </template>
                  <el-input-number placeholder="请输入逾期空间" v-model="item.overdueValidDay" :controls="false"
                    :disabled="!editStatus.overdueValidDay"
                    :min="editStatus.overdueValidDay == 'add' ? item.overdueValidDay || 0 : 0"></el-input-number>
                  <span class="r-pl-10">天</span>
                </el-form-item>
                </div>
                <div class="r-flex">
                  <el-form-item label="频率" prop="createRule" class="is-required r-form-item">
                  <template v-slot:label>
                    <div class="r-flex">
                      <r-tooltip content="当遇到周期性任务时,可选择对应频率,系统会自动创建多个任务">频率</r-tooltip>
                    </div>
                  </template>
                  <el-radio-group v-model="item.createRule" @change="(val)=>createRuleChange(val,item)">
                    <el-radio :label="1">仅一次</el-radio>
                    <el-radio :label="6">每周</el-radio>
                    <el-radio :label="2">每月</el-radio>
                    <el-radio :label="3">每季度</el-radio>
                    <el-radio :label="4">每半年</el-radio>
                    <el-radio :label="5">每年</el-radio>
                  </el-radio-group>
                  <div v-if="!taskId && isTask && [2, 6].includes(item.createRule)" class="r-pt-10">
                    <div v-if="item.createRule == 6" class="r-flex r-shadow r-p-20">
                      <span class="r-pr-10">每周</span><el-select v-model="item.ruleStart" style="width: 100px"><el-option
                          v-for="_item in 7" :key="_item" :value="_item" :label="_item"></el-option></el-select><span
                        class="r-pl-10">开始</span><span class="r-p-h-20">到</span>
                      <span class="r-pr-10">每周</span><el-select v-model="item.ruleEnd" style="width: 100px"><el-option
                          v-for="_item in 7" :key="_item" :value="_item" :label="_item"></el-option></el-select><span
                        class="r-pl-10">结束</span>
                    </div>
                    <div v-else class="r-flex r-shadow r-p-20">
                      <span class="r-pr-10">每月</span><el-select v-model="item.ruleStart" style="width: 100px"><el-option
                          v-for="_item in 31" :key="_item" :value="_item" :label="_item"></el-option></el-select><span
                        class="r-pl-10">开始</span><span class="r-p-h-20">到</span>
                      <span class="r-pr-10">每月</span><el-select v-model="item.ruleEnd" style="width: 100px"><el-option
                          v-for="_item in 31" :key="_item" :value="_item" :label="_item"></el-option></el-select><span
                        class="r-pl-10">结束</span>
                    </div>
                  </div>
                </el-form-item>
                </div>
              </el-form>
          </el-card>
        </el-form-item>   
      </el-form>
    </div>
    <dep-role-modal :config="modalConfig" v-model="dialogVisible" @confirm="handleConfirm"></dep-role-modal>
  </div>
</template>
<script>
import DepRoleModal from "@/components/dialog/DepRoleModal";
import moment from "moment";
import {readObjectNodes} from "@/utils";
export default {
  components: { DepRoleModal },
  data() {
    return {
      infoConfig: {},
      dialogVisible: false,
      modalConfig: {},
    }
  },
  inject: ['id', 'task'],
  computed: {
    infoData() {
      return this.$store.state.approve.form?.info || {}
    },
    dateRange() {
      return this.$store.state.approve.form.info?.indicatorDate || [];
    },
    pickerOptions() {
      let that = this;
      return {
        disabledDate(time) {
          if (that.editStatus.endDate == 'add') {
            let date = (new Date(time)).getTime();
            let endDate = (new Date(that.infoData.date[1])).getTime();
            //必须往后选，但是不能超过当前限制的值
            return date < endDate || date > (new Date(that.dateRange[that.dateRange.length - 1])).getTime();
          } else {
            let date = moment(time).format("YYYY-MM-DD");
            return !that.dateRange.includes(date);
          }
        },
      }
    },
    isTask() {
      return this.task.task;
    },
    isBatchTask(){
      return this.task?.batchTask;
    },
    isFilings(){
      return this.task?.filings;
    },
    taskId() {
      return this.id.id;
    },
    editStatus() {
      return this.$store.state.approve.editStatus || {};
    },
    //是否为自动评分
    isAutoScore() {
      return this.infoData.taskScorePerson?.length > 0 && this.infoData.taskScorePerson[0].id == '00000000-0000-0000-0000-000000000000';
    },
    nodeType(){
      return this.infoData?.nodeType || '';
    },
    filingsStatus(){
      return this.task?.filingsStatus || '';
    }
  },
  methods: {
    handleConfirm(val) {
      let check = ['taskLeader', 'taskReviewer', 'taskScorePerson'].includes(this.modalConfig['model']);
      if(this.modalConfig?.item){
        this.modalConfig.item[this.modalConfig['model']] = val;
        this.dialogVisible = false;
        if(this.modalConfig['model'] == 'personId'){
            this.setBatchDep('personId',val[0],this.modalConfig.item);
        }else if(this.modalConfig['model'] == 'collaborator'){
            this.setBatchDep('collaborator',val,this.modalConfig.item);
        }

        if(check){
          this.updateBatchWorkFlow(this.modalConfig.index);
        }
      }else{
        this.infoData[this.modalConfig['model']] = val;
        this.dialogVisible = false;
        if(this.modalConfig['model'] == 'personId'){
            this.setDep('personId',val[0]);
        }else if(this.modalConfig['model'] == 'collaborator'){
            this.setDep('collaborator',val);
        }
        if (check) {
          this.updateWorkFlow();
        }
        if(this.modalConfig['model'] == 'taskFilingsPersonMany'){
          this.updateFilingsWorkflow();
        }
      }
    },
    //获取时间区间
    getDateRange() {
      let dateRange = this.dateRange;
      return dateRange;
    },
    handleChangeSelect(selectType, title, model,item='') {
      this.modalConfig = {
        type: 1,
        selectType,
        title,
        checkStrictly: true,
        model,
        checkList: this.infoData[model] || [],
        item
      };
      this.dialogVisible = true;
    },
    handleEmployee(selectType, title, model,item='',index=-1) {
      this.modalConfig = {
        type: 1,
        selectType,
        title,
        checkStrictly: true,
        model,
        checkList: item ? item[model] || [] : this.infoData[model] || [],
        isStaff: 1,
        onlyStaff: 1,
        item,
        index
      };
      this.dialogVisible = true;
    },
    /**
     * @param String type 协作人还是负责人 传key
     */
    setDep(type, item) {
      //负责人
      if (type == 'personId') {
        //存在负责部门
        if (item.depId && !this.infoData.personDepId.some(el => el.id == item.depId)) {
          this.infoData.personDepId.push({ id: item.depId, name: item.depName, type:1 });
        }
      } else if (type == 'collaborator') {
        item.forEach(el => {
          //存在负责部门
          if (el.depId && !this.infoData.collaborationDep.some(_el => _el.id == el.depId)) {
            this.infoData.collaborationDep.push({ id: el.depId, name: el.depName,type:1 });
          }
        })
      }
    },
    /**
     * @param String type 协作人还是负责人 传key
     */
    setBatchDep(type, item,batchItem) {
      //负责人
      if (type == 'personId') {
        //存在负责部门
        if (item.depId && !batchItem.personDepId.some(el => el.id == item.depId)) {
          batchItem.personDepId.push({ id: item.depId, name: item.depName, type:1 });
        }
      } else if (type == 'collaborator') {
        item.forEach(el => {
          //存在负责部门
          if (el.depId && !batchItem.collaborationDep.some(_el => _el.id == el.depId)) {
            batchItem.collaborationDep.push({ id: el.depId, name: el.depName,type:1 });
          }
        })
      }
    },
    updateWorkFlow() {
      this.$store.commit('updatePropertyValue');
    },
    updateBatchWorkFlow(index) {
      this.$store.commit('updateBatchPropertyValue',index);
    },
    updateFilingsWorkflow(){
      this.$store.commit('updateFilingsPropertyValue');
    },
    createRuleChange(val,item) {
      if (val == 2) {
        //注入数据
        if(item){
          item.ruleStart = 1
          item.ruleEnd = 31
        }else{
          this.infoData.ruleStart = 1
          this.infoData.ruleEnd = 31
        }
      } else if (val == 6) {
        //注入数据
        if(item){
          item.ruleStart = 1
          item.ruleEnd = 7
        }else{
          this.infoData.ruleStart = 1
          this.infoData.ruleEnd = 7
        }
      } else {
        //注入数据
        if(item){
          item.ruleStart = 0
          item.ruleEnd = 0
        }else{
          this.infoData.ruleStart = 0
          this.infoData.ruleEnd = 0
        }
      }
    },
    isAutoScoreChange(val,item=null,index=-1) {
      if (val) {
        if(item){
          item.taskScorePerson = [{
            "id": "00000000-0000-0000-0000-000000000000",
            "userId": "00000000-0000-0000-0000-000000000000",
            "name": "自动评分",
            "parentId": "00000000-0000-0000-0000-000000000000",
            "employeeId": "00000000-0000-0000-0000-000000000000",
            "type": 2,
            "avatar": ""
          }];
          this.updateBatchWorkFlow(index);
        }else{
          this.infoData.taskScorePerson = [{
            "id": "00000000-0000-0000-0000-000000000000",
            "userId": "00000000-0000-0000-0000-000000000000",
            "name": "自动评分",
            "parentId": "00000000-0000-0000-0000-000000000000",
            "employeeId": "00000000-0000-0000-0000-000000000000",
            "type": 2,
            "avatar": ""
          }];
          this.updateWorkFlow();
        }
      } else {
        if(item){
          item.taskScorePerson = [];
          this.updateBatchWorkFlow(index);
        }else{
          this.infoData.taskScorePerson = [];
          this.updateWorkFlow();
        }
      }
    },
    addTaskItem(){
      this.$store.commit('addTaskItem');
    },
    removeTaskItem(index){
      this.$store.commit('delTaskItem',index);
    },
  },
  created() {
    this.infoConfig = this.infoData
  }
}
</script>

<style lang="scss" scoped>
.base-info-wrapper {
  width: 100%;
  background: #f5f5f9;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  padding: 10px 0;
  .box-card{
    position: relative;
    padding-top:10px;
    .close-item{
      position: absolute;
      right:15px;
      top:5px;
    }
  }
  .base-setting {
    width: 80%;
    background: #fff;
    border-radius: 10px;
    padding: 20px 30px;
    max-width: 900px;
    min-height: 100%;

    .form-icon {
      margin-right: 20px;

      .bg {
        line-height: 1;
        padding: 8px;
        border-radius: 8px;
        cursor: pointer;

        i {
          font-size: 30px;
          color: #ffffff;
        }
      }
    }

    ::v-deep .el-form--label-top .el-form-item__label {
      padding-bottom: 0;

      .tips {
        color: #999999;
        font-size: 12px;
        margin-left: 5px;
      }
    }

    ::v-deep .el-select {
      width: 100%;
    }
  }
}

.icon-box {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;

  .icon-box_list {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
    margin-right: 10px;

    &:hover,
    &.active {
      background: rgba(17, 31, 44, 0.12);
      border-radius: 6px;
    }

    i {
      font-size: 18px;
      color: #888a8e;
    }
  }
}

.icon-color {
  border-top: 1px solid #e9e9e9;
  padding-top: 10px;
}
</style>
