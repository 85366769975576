/**
 * 用户数据相关 store
 * 所有用户有关数据存储在此处
 */
import _dataStorage from "@/utils/sessionStorage";
import { cloneDeep, isObject,isArray,getMenuKey } from '@/utils';
import Vue from "vue";
import {template} from "@/api/indicator"

const state = {
  //用户token
  token: "",
  //用户数据缓存
  info: {},
  rules: [],
  menus: [],
  //原始菜单数据,用于缓存
  originalMenus: [],
  menuRules:[],
  //公司数据
  company: {},
  //个人数据
  employee: {},
  btnRules: [],
  companyRules: [],
  indicatorName:{},
  filingsIndicatorName:{}
}

const mutations = {
  /**
   * 用户数据初始化
   * @data 初始化用户数据
   */
  userInfoInit(state, data) {
    //用户token
    state.token = data.token;
    //用户数据
    state.info = data.userInfo;
    //缓存一下
    _dataStorage.StorageSetter('cache', data);

    this.commit('setRouter', []);
  },

  /**
   * 注入系统权限、菜单、以及初始化路由
   *
   * @param {object} data 包含菜单权限列表的数据
   */
  systemInit(state, data) {
    //公司id
    state.companyId = data.company.id;
    //权限
    state.rules = data.rules;
    //储存公司数据
    state.company = data.company;
    //储存个人数据
    state.employee = data.employee;
    //储存一下原始数据
    state.originalMenus = cloneDeep(data.menus);
    //储存key
    state.menuRules = getMenuKey(data.menus);
    //按钮权限
    state.btnRule = data.btnRule;
    //缓存一下
    _dataStorage.StorageSetter('system_config', data);
  },

  /**
   * 重置用户数据
   */
  resetUserInfo(state) {

    state.token = "";
    state.info = {};
    state.rules = [];
    state.companyId = '';
    state.menus = [];
    state.originalMenus = [];
    state.companyRules = [];
    state.company = {};
    state.menuRules = []
    //清空路由
    this.commit('resetRouter');
    //删除缓存
    _dataStorage.StorageRemove('cache');
    _dataStorage.StorageRemove('system_config');
  },

  /**
   * 设置用户基本数据
   * @param {Object} options ,传入一个对象
   */
  setUserInfoData(state, data) {

    if (!isObject(data)) {
      console.error('data 必须要为一个对象');
      return;
    }
    // 通过正则表达式  查找路径数据
    Object.keys(data).forEach(el => {
      const regex = /([\w$]+)|\[(:\d)\]/g
      const patten = el.match(regex);
      let result = state // 指向调用的数据 如data
      // 遍历路径  逐级查找  最后一级用于直接赋值
      for (let i = 0; i < patten.length - 1; i++) {
        const key = patten[i];
        result = result[key];
      }
      result[patten[patten.length - 1]] = data[el]
    });

    //只保存用户数据以及token
    _dataStorage.StorageSetter('cache', {
      userInfo: state.info,
      token: state.token
    });
  },


  /**
   * 设置系统基本数据,为啥要与用户数据区分开来
   * 是为了更好地进行缓存
   *
   * @param {Object} options ,传入一个对象
   */

  setSystemData(state, data) {
    if (!isObject(data)) {
      console.error('data 必须要为一个对象');
      return;
    }

    Object.keys(data).forEach(el => {
      Vue.set(state, el, data[el]);
    });

    //保存 权限、公司id、菜单、公司token、权限列表
    _dataStorage.StorageSetter('system_config', {
      companyId: state.companyId,
      rules: state.rules,
      menus: state.originalMenus,
      companyRules: state.companyRules,
      company: state.company,
      employee: state.employee
    });
  },


  /**
   * 设置指标名称
   */
  setIndicatorName(state, data) {
    if(!isArray(data)) return;
    data.forEach(item => {
      state.indicatorName[item.prop] = item.alias || item.label;
    });
  },

  /**
   * 设置备案指标名称
   */
  setFilingsIndicatorName(state, data) {
    if(!isArray(data)) return;
    data.forEach(item => {
      state.filingsIndicatorName[item.prop] = item.alias || item.label;
    });
  }

}

const actions = {
  /**
   * @method getInitData 获取初始化数据
   * @param {Object} to 跳转下一个页面的对象
   * @return promise  返回一个promise
   */
  getInitData({ commit, state,dispatch }, to) {
    //不存在当页缓存,检查local 中是否有缓存token
    const localCommonData = _dataStorage.StorageGetter('cache');
    const systemConfig = _dataStorage.StorageGetter('system_config');
    return new Promise((res, rej) => {
      //存在当页缓存，直接使用
      if (localCommonData && localCommonData?.token) {
        commit('userInfoInit', localCommonData);
        //检查是否存在系统缓存,存在此缓存一定存在公司id
        if (systemConfig) {
          commit('systemInit', systemConfig);
          //此时首先获取一次指标名称
          dispatch('getIndicatorName');
          //备案
          dispatch('getFilingsIndicatorName');
        }
        //返回登录，如果没有对应的系统权限数据则返回登录页面,路由表没生成必定没有相应的权限数据
        res({
          path: to.path == '/' || to.path == '/login' || !to.path ?
            '/'
            : to.path
        });
      } else {
        //重置用户
        commit('resetUserInfo');
        //不存在token直接跳转到登录页
        rej();
      }
    })
  },

  getIndicatorName({ commit }) {
    template().then(res=> {
      commit('setIndicatorName', res.data);
    })
  },
  getFilingsIndicatorName({ commit }) {
    template({module:'filings'}).then(res=> {
      commit('setFilingsIndicatorName', res.data);
    })
  },
}
const getters = {}
export default {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
