<template>
  <div class="dialog__wrapper">
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogShow"
      :destroy-on-close="true"
      custom-class="disabled-none"
      top="12vh"
      :modal="dialogModal"
      :close-on-click-modal="false"
      :append-to-body="true"
      :props="{label:'name'}"
      width="800px"
      @close="modalClose"
      @closed="modelClosed"
      @open="handleOpen"
    >
      <div class="dep-container">
        <el-row type="flex" class="row-bg dep-cover" justify="space-between">
          <el-col :span="12" class="panel-box">
            <div class="panel-box_wrap">
              <div class="left-panel_box">
                <div class="search-box">
                  <el-row type="flex">
                    <el-input
                      placeholder="请输入搜索内容"
                      v-model="keyword"
                      size="small"
                      class="input-box"
                    >
                      <i slot="prefix" class="el-input__icon el-icon-search"></i>
                    </el-input>
                  </el-row>
                </div>
                <div class="nav-box" v-if="config.type === 2">
                  <el-radio-group v-model="config.typeNav" size="mini" @change="handleChangeTypeNav">
                    <el-radio-button label="system">组织架构</el-radio-button>
                    <el-radio-button label="post" v-if="!config.postShow">职务列表</el-radio-button>
                    <!--                    <el-radio-button label="role">角色列表</el-radio-button>-->
                  </el-radio-group>
                </div>

                <div class="dep-box" v-loading="loading" v-if="treeShow" :class="{hidden:!isShow}">
                  <el-tree
                    ref="tree"
                    :data="treeData"
                    node-key="id"
                    highlight-current
                    :expand-on-click-node="false"
                    :default-expand-all="false"
                    show-checkbox
                    render-after-expand
                    :check-strictly="config.checkStrictly"
                    :default-expanded-keys="defaultExpandedKeys"
                    @check="handleCheckTree"
                    :filter-node-method="filterNode"
                    :props="{label:'name'}"
                  >
                    <div class="custom-tree-node" slot-scope="{ node, data }">
                      <template v-if="data.type === 1">
                        <i class="fxy-icon-wenjianjia"></i>
                      </template>
                      <template v-else-if="data.type === 2">
                        <el-image :src="img_url + data.avatar" fit="cover" class="fxy-avatar"></el-image>
                      </template>
                      <template v-else-if="data.type === 3">
                        <i class="fxy-icon-yingpinzhiwei"></i>
                      </template>
                      <span class="nickname">{{ node.label }}</span>
                      <span class="nickname" v-if="data.type == 1 && node.data && node.data.employeeNum">（{{node.data.employeeNum}}）</span>
                    </div>
                  </el-tree>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12" class="panel-box">
            <div class="panel-box_wrap">
              <div class="right-panel_box">
                <div class="check-txt">已选：</div>
                <div
                  class="r-flex"
                  v-for="(item, i) in checkList"
                  :key="i"
                >
                  <div class="r-flex-auto-w">
                    <el-row type="flex" class="row-bg" justify="space-between">
                      <div class="">
                        <template v-if="item.type === 1">
                          <i class="fxy-icon-wenjianjia"></i>
                        </template>
                        <template v-else-if="item.type === 2">
                          <el-image :src="img_url + item.avatar" fit="cover" class="fxy-avatar"></el-image>
                        </template>
                        <template v-else-if="item.type === 3">
                          <i class="fxy-icon-yingpinzhiwei"></i>
                        </template>
                        <span class="nickname">{{ item.name }}</span>
                      </div>
                    </el-row>
                  </div>
                  <div class="child" @click="handleDeleteNode(item)">
                    <i class="el-icon-delete"></i>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" class="modal-footer" justify="end">
          <el-checkbox v-model="isHideDep" class="switch-flex flex-left" :true-label="1" :false-label="0" v-if="config.typeNav == 'system' && !hideDepLock" @change="handleOpen(true)">显示隐藏部门</el-checkbox>
          <el-checkbox v-model="includeSubDepSwitch" @change="includeSubDepChange" class="switch-flex" v-if="isIncludeSubDep">是否包含子部门</el-checkbox>
          <el-button size="small" @click="modalClose">取 消</el-button>
          <el-button type="primary" size="small" @click="onSubmit">确 认</el-button>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dialogMixin from "@/mixins/dialogCommon";
import {getDepByTree} from "@/api/dep";
import {getPostListByTree} from "@/api/post";
import {setKeysValues, setNodeKeysValues, setTreeNodeChildrenKeysValues, getTreeNodeAllById, getValues} from "@/utils/arr";
import {cloneDeep,readNodes} from "@/utils";
import throttleMixin from "@/mixins/throttle"

export default {
  name: "ApprovePromoterModal",
  mixins: [dialogMixin,throttleMixin],
  props: {
    config: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    config: {
      deep: true,
      immediate: true,
      handler(val) {
      }
    },
    keyword(val) {
      this.loading=true;
      this.throttle({
        key:'ApproveProMoterModal',
        time:400,
        callback:()=>{
          this.$nextTick(()=>{
           this.$refs?.tree?.filter && this.$refs.tree.filter(val);
            this.loading = false;
          })
        }
      });
    },
  },
  data() {
    return {
      loading: false,
      keyword: null,
      checkList: [],
      disabledList: [],
      treeData: [],
      depTree: [],
      postTree: [],
      defaultExpandedKeys: [],
      treeShow:true,
      isHideDep:0,
      isShow:true,
      includeSubDepSwitch: false
    };
  },
  computed: {
    dialogTitle() {
      return this.config.title;
    },
    dialogModal() {
      return this.config.hasOwnProperty('modal') ? this.config.modal : true;
    },
    isIncludeSubDep() {
      return this.config?.isIncludeSubDep && (this.config?.type == 1 || this.config?.type == 2);
    },
    hideDepLock(){
      return !!this.config?.hideDepLock;
    }
  },
  methods: {
    modalClose(){
      this.treeShow=false;
      this.$nextTick(()=>{
        this.onClose(false);
      })
    },
    modelClosed(){
      this.keyword = '';
    },
    filterNode(value, data, node) {
      if (!value) return true
      let parentNode = node.parent,
        labels = [node.label],
        level = 1
      while (level < node.level) {
        labels = [...labels, parentNode.label]
        parentNode = parentNode.parent
        level++
      }
      return labels.some(label=> label.indexOf(value) !== -1)
    },
    onSubmit() {
      this.config.checkList = this.checkList;
      if(this.isIncludeSubDep){
        this.config.includeSubDep = this.includeSubDepSwitch ? 1 : 0;
      }
      this.$emit("confirm", this.config);
    },
    handleCheckTree(data, node) {
      let nodes = [];
      if (this?.config?.isSelectSub) {
        // 选中对象的所有下级
        nodes = readNodes(data.children, () => {
          return true
        }, []);
      }
      //自动更新禁用部门 且开启了选择下级公司
      if(this.config.disabledHot && this.includeSubDepSwitch){
        let _nodes = [];
        if(!this.config?.isSelectSub){
           // 选中对象的所有下级
           _nodes = readNodes(data.children, () => {
            return true
          }, []);
        }else{
          _nodes = cloneDeep(nodes);
        }

        let disabled = _nodes.map(el=>el.id);
        //隐藏
        setNodeKeysValues(this.depTree ,disabled, 'disabled', node.checkedKeys.indexOf(data.id) > -1);
        //清空他的下级
        this.checkList = this.checkList.filter(el=>{
          return !disabled.includes(el.id);
        })
      }
      let checkList = this.checkList;
      if (this.config.selectType === "radio") {
        if (node.checkedKeys.indexOf(data.id) > -1) {
          checkList = [{
            id: data.id,
            userId: data?.userId || '',
            name: data.name,
            parentId: data.parentId,
            employeeId: data.employeeId || '',
            type: data.type
          }];
        }
      } else if (this.config.selectType === "checkbox") {
        let childIds = [];
        if (!this.config?.isNoRelation) {
          let disabled = false;
          if (node.checkedKeys.indexOf(data.id) > -1) {
            disabled = true;
            getTreeNodeAllById(data.children || [], childIds);
          }
          setTreeNodeChildrenKeysValues(this.treeData, data.id, 'disabled', disabled);
        }
        let checkIdList = getValues(checkList, 'id');
        //选中状态
        if (checkIdList.indexOf(data.id) === -1) {
            checkList.push({
              id: data.id,
              userId: data?.userId || '',
              name: data.name,
              parentId: data.parentId,
              employeeId: data.employeeId || '',
              type: data.type,
              avatar: data.avatar || ''
            })
          if(this?.config?.isSelectSub){
            checkList.push(...(nodes.map(el=>{
                return {
                id: el.id,
                userId: el?.userId || '',
                name: el.name,
                parentId: el.parentId,
                employeeId: el.employeeId || '',
                type: el.type,
                avatar: el.avatar || ''
              }
            })));
          }
        } else {
          checkList.splice(checkIdList.indexOf(data.id), 1);
          //清除掉选中数据所有下级
          checkList = checkList.filter(el=>{
            let item = nodes.find(_el=>{return _el.id == el.id});
            return !item;
          })
        }
        let arr = checkList.filter((item) => {
          if (childIds.indexOf(item.id) === -1) {
            return item;
          }
        })
        checkList = arr;
      }
      this.checkList = checkList;
      this.$refs.tree.setCheckedNodes(checkList);
    },
    //包含子部门切换
    includeSubDepChange(val){
      if(!this.config?.disabledHot) return;
      //如果选择包含子部门，将所选项下的所有子部门全部禁用并取消选择
      if(val){
        //首先获取所有被禁用的选项，此时被禁用会有两种情况
        //1、该节点被选中
        //2、该节点父级被选中
        //获取选择的元素
        let nodes = [];
        this.$refs.tree.getCheckedNodes().forEach(el=>{
          if(el.children){
            nodes.push(...el.children);
          }
        });
        //选中对象的所有下级，并剔除当前选中的元素
        let children = readNodes(nodes, () => {
          return true
        }, []);
        //首先发起校验
        let check = children.find(el=>el.defaultDisabled);
        if(check){
          if(this.$refs.tree.getCheckedNodes().map(el=>el.id).includes(check.id)){
            this.$message.warning(`无法选择包含子部门，因为 ${check.name} 已选中`);
          }else{
            this.$message.warning(`无法选择包含子部门，因为 ${check.name} 在其他分配规则已选中`);
          }
          this.$nextTick(()=>{
            this.$set(this,'includeSubDepSwitch',false);
          })
          return;
        }
        //设置禁用
        setNodeKeysValues(this.depTree ,children, 'disabled', true);
        children = children.map(el=>el.id);
        //剔除掉选择
        this.checkList = this.checkList.filter(el=>{
          return !children.includes(el.id);
        });
        this.$refs.tree.setCheckedNodes(this.checkList);
      }else{
        //取消子部门选择限制
        let nodes = this.$refs.tree.getCheckedNodes();
        let children = [];
        //此时存在选中项
        if(nodes.length > 0){
          children = readNodes(nodes, el => {
            return !el.defaultDisabled;
          }, []).map(el=>el.id);
        }else{
          //不存在选中项
          children = readNodes(this.depTree, el => {
            return !el.defaultDisabled;
          }, []).map(el=>el.id);
        }
        //设置启用
        if(children.length){
          setNodeKeysValues(this.depTree ,children, 'disabled', false);
        }
      };

      //此时触发方法
      this.$nextTick(()=>{
        this.$emit('includeSubDepChange',val);
      })
    },
    handleDeleteNode(node) {
      this.checkList.forEach((item, index) => {
        if (item.id === node.id) {
          if(!this.config.disabledHot){
            setTreeNodeChildrenKeysValues(this.treeData, item.id, 'disabled', false);
          }
          this.checkList.splice(index, 1);
        }
      })
      this.$refs.tree.setCheckedNodes(this.checkList);
      
      // this.$nextTick(()=>{
      //   this.$emit('includeSubDepChange',this.includeSubDepSwitch);
      // })
    },
    async handleChangeTypeNav(val) {
      //职位
      if (val === 'system') {
        this.treeData = this.depTree;
      } else if (val === 'post') {
        if (this.postTree.length === 0) {
          this.postTree = await this.getPostData();
        }
        this.treeData = this.postTree;
      }
      this.$nextTick(() => {
        if (!this.config?.isNoRelation) {
          this.checkList.forEach((item) => {
            setTreeNodeChildrenKeysValues(this.treeData, item.id, 'disabled', true);
          })
        }
        this.$refs.tree.setCheckedNodes(this.checkList);
      })
    },
    getPostData() {
      this.loading = true;
      return getPostListByTree({type: this.config.type, isStaff: 0}).then((response) => {
        if (response.data.length > 0) {
          this.defaultExpandedKeys = [response.data[0].id]
        }
        this.postTree = response.data;
        this.loading = false;
        return setKeysValues(response.data, 'disabled', false);
      })
    },
    async handleOpen(isReset) {
      this.treeShow=true;
      if(!isReset){
        this.checkList = cloneDeep(this.config.checkList);
        this.disabledList = this.config.disabledList ? cloneDeep(this.config.disabledList) : [];
        this.includeSubDepSwitch = !!this?.config?.includeSubDepSwitch;
      }
      let res = [];
      if (this.config.type === 3) {
        res = await this.getPostData()
      } else {
        this.loading = true;
        res = await getDepByTree({type: this.config.type, isStaff: this.config.isStaff || 0,isQuit:this.config?.isQuit || 0,isHideDep:this?.config?.hideDepLock ? 0 :this.isHideDep,formType:'system'}).then((response) => {
          if (response.data.length > 0) {
            this.defaultExpandedKeys = [response.data[0].id]
          }
          this.loading = false;
          let data = setKeysValues(response.data, 'disabled', false);
          //存在处理方法则启用处理方法
          this.disabledList = this.config?.disabledFn ? this.config.disabledFn(data) : this.disabledList;
          return setNodeKeysValues(data,this.disabledList, 'disabled', true);
        });
        this.depTree = res;
        
        this.isShow = false;
        this.$nextTick(()=>{
          this.isShow=true;
        })
      }
      this.treeData = res;
      this.$nextTick(() => {
        if (!this.config?.isNoRelation) {
          this.checkList.forEach((item) => {
            setTreeNodeChildrenKeysValues(this.treeData, item.id, 'disabled', true);
          })
        }
        this.$refs.tree.setCheckedNodes(this.checkList);

        //此时触发初始化完毕事件
        this.$emit('init',this.includeSubDepSwitch);
      })
    },
    //设置禁用
    setDisabled(list,disabled){
      if(list.length ==0) return;
      setNodeKeysValues(this.depTree,list, 'disabled', disabled);
    },
    //设置参数
    setTreeItem(list,key,value){
      if(list.length == 0) return;
      setNodeKeysValues(this.depTree,list, key, value);
    },
    //获取禁用数据
    getDisabled(fn){
      let disabled = readNodes(this.depTree,(el)=>{
        return !!el.disabled && (fn ? fn(el) : true);
      },[]);
      return disabled;
    },
    //获取对应的数据列表
    getTreeList(key){
      let list = readNodes(this.depTree,el => {
        return !!el?.[key];
      }, []);
      return list;
    },
    //获取当前的选中项
    getCheckedList(key){
      return cloneDeep(this.checkList)
    },
    //获取对应的node
    getNode(key){
      return this.$refs.tree.getNode(key);
    },
    getCheckedNodes(){
      return this.$refs.tree.getCheckedNodes();
    }
  }
}
</script>
<style lang="scss" scoped>

::v-deep .el-dialog__body {
  padding: 0 30px 20px;
}

.switch-flex {
  display: flex;
  align-self: center;
  padding-right: 10px;

  ::v-deep .el-checkbox__input {
    position: relative;
    top: 2px;
  }
  &.flex-left{
    margin-right:auto;
  }
}


.dep-container {
  .dep-tree {
    margin-top: 20px;
  }

  .modal-footer {
    margin-top: 10px;
  }

  .dep-cover{
    max-height:65vh;
  }

  .panel-box {
    max-height:100%;
    overflow: hidden;
    overflow-y:auto;
    padding-right:10px;
    .left-panel_box {
      margin-top: 10px;
      padding-right: 15px;
      height: 400px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      .dep-box{
        flex: 1 1 auto;
        height:0;
        overflow-y: scroll;
        &.hidden{
          overflow: hidden;
        }
      }
      .search-box{
        margin-bottom:10px;
      }
      ::v-deep .input-box {
        .el-input__inner {
          background: #f5f6f7;
          border-color: #f5f6f7;
        }
      }
    }

    .right-panel_box {
      padding-left: 15px;

      .check-txt {
        margin-bottom: 10px;
      }
    }

    .all-checkbox_box {
      margin: 10px 0;
    }

    .checkbox-box {
      padding: 5px 0;

      ::v-deep .el-checkbox__label {
      }

      ::v-deep .el-checkbox__input {
        vertical-align: inherit;
      }
    }

    .fxy-icon-wenjianjia {
      margin-right: 4px;
      color: #aee4fa;
      font-size: 17px;
      vertical-align: middle;
    }

    .fxy-icon-yingpinzhiwei {
      font-size: 20px;
      vertical-align: middle;
      color: #faad14;
    }

    .child {
      cursor: pointer;

      .el-icon-delete {
        font-size: 16px;
        color: #f56c6c;
      }
    }

    .nav-box {
      text-align: center;
      margin-top: 10px;
    }
  }

  .fxy-avatar {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    margin-right: 4px;
    vertical-align: middle;
  }

  .nickname {
    vertical-align: middle;
  }
}
</style>
