<template>
    <div class="r-table" v-loading="loading" element-loading-text="拼命加载中..."
        element-loading-background="rgba(255,255, 255, 0.8)">
        <div class="r-table-content" handle=".drag-handle">
            <el-table v-bind="$attrs" v-on="$listeners" :data="tableData" :border="border" ref="elTable" @sort-change="sortChange"
                :class="{ 'draggable-table': drag }" :key="tableKey">
                <template v-for="(column, index) in tableColumns">
                    <el-table-column :key="index" v-bind="column" v-if="!column.hide">
                        <!-- 插槽 -->
                        <template v-slot="scope" v-if="column.prop && $scopedSlots[column.prop]">
                            <slot :name="column.prop" v-bind="scope" :column="column"></slot>
                        </template>
                        <template v-slot:header="scope" v-if="column.prop && $scopedSlots[`${column.prop}_header`]">
                            <slot :name="`${column.prop}_header`" v-bind="{ column: column, $index: index, ...scope }">{{
                                column.label }}</slot>
                        </template>
                    </el-table-column>
                </template>
            </el-table>
        </div>
        <div class="r-table-footer r-flex r-row-bet">
            <div class="r-table-footer-toolbar">
                <slot name="footerToolbar"></slot>
            </div>
            <div class="r-table-pagination" v-if="$attrs['layout'] || loader">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-sizes="pageSizes" :page-size="pageSize" background
                    :layout="$attrs['layout'] || layout" :hide-on-single-page="false" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import Sortable from "sortablejs"
import { cloneDeep,guid,readNodes } from '@/utils';
export default {
    name: 'rTable',
    // mixins:[tableFixed],
    props: {
        columns: {
            type: Array,
            default() {
                return [];
            }
        },
        data: {
            type: Array,
            default() {
                return [];
            }
        },
        loader: {
            type: Function,
            default: null
        },
        auto: {
            type: Boolean,
            default: true
        },
        drag: {
            type: Boolean,
            default: false
        },
        dragBefore: {
            type: Function,
            default: null
        },
        //表头请求方法
        columnLoader:{
            type:Function,
            default:null
        },
        border:{
            type:Boolean,
            default:true
        },
        selection:{
            type:Object,
            default:null
        }
    },
    data() {
        return {
            currentPage: 1,
            pageSizes: [10, 20, 30, 40, 50, 100],
            pageSize: 50,
            total: 0,
            layout: 'total, sizes, prev, pager, next',
            list: [],
            tableRefKey: 'elTable',
            loading: false,
            sortable: null,
            insideColumns:[]
        }
    },
    computed: {
        tableData() {
            return this.loader ? this.list : this.data;
        },
        tableColumns(){
            return this.columnLoader ? this.insideColumns : this.columns;
        },
        tableKey(){
            return guid(20)
        }
    },
    methods: {
        //切换一屏显示数量
        handleSizeChange(size) {
            this.pageSize = size;
            this.load();
        },
        //切换页码
        handleCurrentChange(page) {
            this.currentPage = page;
            this.load(true);
        },
        //加载方法
        load(isTop) {
            if (!this.loader) return;
            this.loading = true;
            //请求方法
            this.loader(this.currentPage, this.pageSize).then(res => {
                if (res) {
                    if(res?.list){
                        this.list = res.list;
                        this.total = res.total;
                    }else{
                        this.list = res;
                        this.total = res.length;
                    }
                }
                if (isTop) {
                    this.scrollTop();
                }
            }).finally(() => {
                this.loading = false;
            })
        },
        //刷新方法
        refresh(page) {
            this.currentPage = page || this.currentPage;
            this.load(!!page);
        },
        //用于集成方法
        extendMethods(ref, names = []) {
            if (!ref) return

            names.forEach(name => {
                // 子组件的方法加到实例
                this[name] = (...args) => {
                    ref[name].apply(ref, args)
                }
            })

        },
        //滚动方法
        scrollTop(top) {
            if(!this.$refs.elTable?.$refs?.bodyWrapper) return;
            this.$refs.elTable.$refs.bodyWrapper.scrollTop = top || 0;
        },
        //获取当前y轴滚动条位置
        getScrollTop(){
            return this.$refs.elTable.$refs.bodyWrapper.scrollTop;
        },
        //初始化方法
        init() {
            // 支持el-table的全部方法
            this.extendMethods(this.$refs.elTable, [
                'clearSelection',
                'toggleRowSelection',
                'toggleAllSelection',
                'toggleRowExpansion',
                'setCurrentRow',
                'clearSort',
                'clearFilter',
                'doLayout',
                'sort'
            ])
            if (this.auto) {
                this.firstLoad();
            }

            if (this.drag) {
                const tbody = document.querySelector('.draggable-table .el-table__body-wrapper tbody')
                this.sortable = new Sortable(tbody, {
                    handle: '.handle', // handle's class
                    animation: 150,

                    // 需要在odEnd方法中处理原始eltable数据，使原始数据与显示数据保持顺序一致
                    onEnd: ({ newIndex, oldIndex }) => {
                        if (this.dragBefore && !this.dragBefore(newIndex, oldIndex)) {
                            this.sortRestore(newIndex, oldIndex)
                        } else {
                            let tableData = cloneDeep(this.tableData)
                            //之前的元素
                            let item = tableData[oldIndex];
                            //删除掉之前的
                            tableData.splice(oldIndex, 1);
                            //插入到新的位置    
                            tableData.splice(newIndex, 0, item);
                            
                            //读取当前滚动条位置
                            let scrollTop = document.querySelector('.draggable-table .el-table__body-wrapper').scrollTop;
                            this.$emit('dragEnd', tableData,()=>{
                                this.$nextTick(()=>{
                                    document.querySelector('.draggable-table .el-table__body-wrapper').scrollTop = scrollTop;
                                })
                            });
                        }
                    },
                })
            }
        },
        //初次加载
        async firstLoad(){
            if(this.columnLoader){
                await this.columnsInit();
                this.load();
            }else{
                this.load();
            }
        },
        //表头初始化
        columnsInit(){
            //请求表头
            this.loading = true;
            this.columnLoader().then(res=>{
                this.$set(this,'insideColumns',res);
                //执行完必须触发一次样式修改
                this.$nextTick(()=>{
                    this.doLayout();
                })
            });       
        },
        sortChange({ prop, order }) {
            this.$emit('sortChange', {
                orderBy: prop,
                orderBySort: order ? order == 'ascending' ? 'asc' : 'desc' : ''
            });
        },
        sortRestore(newIndex, oldIndex) {
            let list = this.sortable.toArray();
            let item = list[newIndex];
            list.splice(newIndex, 1);
            list.splice(oldIndex, 0, item);
            this.sortable.sort(list);
        },
        getTableData() {
            return this.tableData;
        },
        columnsRefresh(){
            this.firstLoad();
        },
        getNode(rowKey){
            let node = readNodes(this.tableData,(el)=>{
                return el[this.$attrs['row-key'] || 'id'] == rowKey;
            },[]);
            
            return node.length > 0 ? node[0] : null;
        },
        //获取顶级的index
        getTopIndex(rowKey){
            let index = this.tableData.findIndex((el)=>el[this.$attrs['row-key'] || 'id'] == rowKey);
            return index;
        },
        splice(index,remove = 0,row){
            if(remove){
                this.tableData.splice(index,remove);
            }else{
                this.tableData.splice(index,0,row);
            }
        }
    },
    mounted() {
        this.init();
    },
    beforeDestroy() {
        this.sortable && this.sortable?.destroy && this.sortable.destroy();
    },
    watch:{
        columns(val){
            this.$nextTick(()=>{
                this.doLayout();
            })
        }
    }

}
</script>

<style lang="scss" scoped>
.r-table {
    height: 100%;
    display: flex;
    flex-direction: column;

    &-content {
        flex: 1 1 auto;
        height: 0;
        width: 100%;
    }

    &-footer {
        padding-top: 10px;
        width: 100%;
    }

    .draggable {
        height: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
    }
}
</style>