<template>
  <div>
      <r-drawer ref="rDialog" size="90%" class="apply-detail-dialog" :hideHeader="true" :footer="false" @close="reset">
            <div class="apply-detail-wrapper">
              <apply-detail ref="applyDetail" :data="id" v-if="show" @close="detailClose"></apply-detail>
            </div>
      </r-drawer>
  </div>
</template>

<script>
import applyDetail from "@/components/approve/detail/applyDetail"
export default {
  components:{applyDetail},
  data() {
      return {
        show:false,
        id:''
      }
  },
  methods: {
      open(id) {
        this.$refs.rDialog.handleOpen();
        this.id = id;
        this.show = true;
        this.$nextTick(()=>{
          this.$refs.applyDetail?.formInit && this.$refs.applyDetail.formInit();
        })
      },
      reset() {
        this.show = false;
        this.id = '';
      },
      detailClose(isUpdate){
        this.$emit('close',isUpdate);
        if(!isUpdate){
          this.$refs.rDialog.close();
        }
      }
  }
}
</script>

<style lang="scss" scoped>
.apply-detail-wrapper{
  height:100%;
  .apply-detail-lt,.apply-detail-rt{
    height:100%;
  }
  .apply-detail-lt{
  } 
  .apply-detail-rt{
  }
}
</style>