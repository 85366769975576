/**
 * 用户数据相关 store
 * 所有用户有关数据存储在此处
 */
 import Vue from "vue";
 import {isObject} from "@/utils";
 const state = {
    mobileType:'+86',
    mobile:''
 }
 
 const mutations = {

    /**
     * 设置登录数据
     * 
     * @param {object} options
     */
    setLoginData(state,data){

      if(!isObject(data)){
         console.error('data 必须要为一个对象');
         return;
      }

      Object.keys(data).forEach(el=>{
         Vue.set(state,el,data[el]);
      })

    },

    //重置登录数据
    resetLoginData(state){
      state.mobileType='+86';
      state.mobile = '';

    }

 }
 
 const actions = {
   
 }
 const getters = {}

 export default {
    state:state,
    mutations:mutations,
    actions:actions,
    getters:getters
 }
 