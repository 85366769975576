<template>
    <div class="kpi-wrapper r-page">
        <div class="r-filters">
            <div class="r-search">
                <el-form size="small" inline @submit.native.prevent>
                    <el-form-item>
                        <el-input placeholder="请输入合同名称" v-model="query.keyword" @keydown.enter.native="search"
                            clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="r-toolbar">
              <el-form inline size="small">
                <el-form-item>
                  <div class="r-flex">
                    <el-popover placement="bottom-end" width="500" trigger="manual" v-model="popShow"
                                @click.stop.native>
                      <div>
                        <div class="r-pb-20">筛选条件</div>
                        <el-form size="small" label-width="100px" label-position="left">
                          <el-form-item label="合同类型">
                            <el-select v-model="searchQuery.type" filterable remote reserve-keyword placeholder="请输入关键词"
                                       :remote-method="remoteMethod" :loading="pidLoading" class="r-w-max" clearable>
                              <el-option :label="item.title" :value="item.id" v-for="item in contractTypes"
                                         :key="item.id"></el-option>
                            </el-select>
                          </el-form-item>
                          <el-form-item label="合同状态">
                            <el-select v-model="searchQuery.status" filterablereserve-keyword placeholder="请输入关键词" clearable>
                              <el-option :label="'正常履约'" :value="1"></el-option>
                              <el-option :label="'合同结束'" :value="2"></el-option>
                              <el-option :label="'合同终止'" :value="3"></el-option>
                            </el-select>
                          </el-form-item>
                          <el-form-item label="村庄" prop="villageId">
                            <el-select v-model="searchQuery.villageId" filterable remote reserve-keyword placeholder="请输入关键词"
                                       :remote-method="remoteVillageMethod" :loading="pidLoading" class="r-w-max" clearable multiple>
                              <el-option :label="item.name" :value="item.id" v-for="item in cityList"
                                         :key="item.id"></el-option>
                            </el-select>
                          </el-form-item>
                          <el-form-item prop="partyA" label="甲方">
                            <el-select v-model="searchQuery.partyA" filterable remote reserve-keyword placeholder="请输入关键词"
                                       :remote-method="remoteMethodPartyA" :loading="pidLoading" class="r-w-max" clearable>
                              <el-option :label="item.title" :value="item.id" v-for="item in partyAList"
                                         :key="item.id"></el-option>
                            </el-select>
                          </el-form-item>
                          <el-form-item prop="partyB" label="乙方">
                            <el-select v-model="searchQuery.partyB" filterable remote reserve-keyword placeholder="请输入关键词"
                                       :remote-method="remoteMethodPartyB" :loading="pidLoading" class="r-w-max" clearable>
                              <el-option :label="item.title" :value="item.id" v-for="item in partyBList"
                                         :key="item.id"></el-option>
                            </el-select>
                          </el-form-item>
                          <el-form-item label="开始日期">
                            <div class="r-flex">
                              <el-date-picker class="r-w-max" v-model="searchQuery.startDate" type="daterange"
                                              range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期">
                              </el-date-picker>
                            </div>
                          </el-form-item>
                          <el-form-item label="结束时间">
                            <div class="r-flex">
                              <el-date-picker class="r-w-max" v-model="searchQuery.period" type="daterange"
                                              range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" :disabled="searchQuery.isLongTerm == 1">
                              </el-date-picker>
                              <el-checkbox v-model="searchQuery.isLongTerm" :true-label="1" :false-label="0" style="margin-left: 10px;">长期</el-checkbox>
                            </div>
                          </el-form-item>
                          <el-form-item>
                            <div class="r-flex r-row-right">
                              <el-button @click="popHide">取消</el-button>
                              <el-button type="primary" @click="searchConfirm">确认</el-button>
                            </div>
                          </el-form-item>
                        </el-form>
                      </div>
                      <el-button slot="reference" class="r-mr-10 sx-btn" @click="popOpen"
                                 :class="{ active: searchActive }">
                        <r-icon name="shaixuan" type="task" size="16"></r-icon>
                      </el-button>
                    </el-popover>
                    <el-button icon="el-icon-plus" size="small" type="primary" @click="add">新增合同</el-button>
                  </div>
                </el-form-item>
              </el-form>
            </div>
        </div>
        <div class="r-content">
            <r-table ref="table" :loader="loader" :columns="columns" height="100%"
                :cell-class-name="cellClassName" row-key="id" @sortChange="sortChange" default-expand-all
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}" @cell-click="cellClick">
                <template v-slot:action="{row}">
                    <!-- <el-link type="primary" @click="edit(row)" v-if="false">编辑</el-link>
                    <el-divider direction="vertical"></el-divider> -->
                    <el-link type="primary" @click="detail(row)">查看</el-link>
                    <el-divider direction="vertical"></el-divider>
                    <el-popconfirm title="确定要删除该合同吗？" @confirm="del(row)">
                        <el-link type="primary" slot="reference">删除</el-link>
                    </el-popconfirm>
                </template>
            </r-table>
        </div>
        <update-contract ref="contractUpdate" @confirm="refresh"></update-contract>
        <detail-contract ref="contractDetail" @confirm="refresh"></detail-contract>
    </div>
</template>
<script>
import {  cloneDeep, isObjectNone } from "@/utils";
import updateContract from "./components/update-contract";
import detailContract from "./components/detail-contract";
import { isObject } from "lodash";
import {contractDelete, contractList, contractPartyList, contractTypeDelete, contractTypeList} from "@/api/contract";
import {getAreaCityList, getCityList} from "@/api/common";
const defaultQuery = {
    keyword: ''
}
export default {
    components:{
      updateContract,
      detailContract
    },
    data() {
        return {
            query: cloneDeep(defaultQuery),
            searchQuery: cloneDeep(defaultQuery),
            columns:[
                {
                    label:'合同名称',
                    prop:'title',
                    'min-width':'300px'
                },
                {
                  label:'合同类型',
                  prop:'contractTypeName',
                  'width':'200px'
                },
                {
                  label:'合同状态',
                  prop:'statusStr',
                  'width':'200px'
                },{
                  label:'村庄',
                  prop:'villageStr',
                  'width':'200px'
                },
                {
                  label:'甲方',
                  prop:'partyAName',
                  'width':'200px'
                },
                {
                  label:'乙方',
                  prop:'partyBName',
                  'width':'200px'
                },
                {
                  label:'签订日期',
                  prop:'date',
                  'width':'200px'
                },
                {
                  label:'开始日期',
                  prop:'startDate',
                  'width':'200px'
                },
                {
                  label:'结束日期',
                  prop:'period',
                  'width':'200px'
                },
                {
                  label:'待办事项',
                  prop:'taskCount',
                  'width':'200px'
                },
                {
                  label:'合同金额',
                  prop:'amount',
                  'width':'200px'
                },
                {
                  label:'合同附件',
                  prop:'annexCount',
                  'width':'200px'
                },
                {
                    label:'创建时间',
                    prop:'createdAt',
                    'width':'200px'
                },
                {
                    label:'操作',
                    prop:'action',
                    'width':'130px',
                    fixed:'right'
                }
            ],
            popShow: false,
            contractTypes: [],
            pidLoading: false,
            cityList: [],
            partyAList: [],
            partyBList: []
        }
    },
    computed: {
      searchActive() {
        let active = false;
        Object.keys(this.searchQuery).forEach(key => {
          if (typeof this.searchQuery[key] === 'object') {
            if (Array.isArray(this.searchQuery[key])) {
              for (let i = 0; i < this.searchQuery[key].length; i++) {
                if (this.searchQuery[key][i]) {
                  active = true;
                }
              }
            }
          }else {
            if (this.searchQuery[key]) {
              active = true;
            }
          }
        });
        return active;
      },
    },
    methods: {
        remoteMethodPartyA(name) {
          this.pidLoading = true;
          contractPartyList({ keyword:name, page: 1, pageSize: 20 }).then(res => {
            this.partyAList = res.data.list;
          })
              .catch(err => {
                console.log(err)
              })
              .finally(() => {
                this.pidLoading = false;
              })
        },
        remoteMethodPartyB(name) {
          this.pidLoading = true;
          contractPartyList({ keyword:name, page: 1, pageSize: 20 }).then(res => {
            this.partyBList = res.data.list;
          })
              .catch(err => {
                console.log(err)
              })
              .finally(() => {
                this.pidLoading = false;
              })
        },
        searchConfirm() {
          this.popHide();
          this.query = cloneDeep(this.searchQuery);
          this.search();
        },
        remoteMethod(name) {
          this.pidLoading = true;
          contractTypeList({ keyword:name, page: 1, pageSize: 20 }).then(res => {
            this.contractTypes = res.data.list;
          })
              .catch(err => {
                console.log(err)
              })
              .finally(() => {
                this.pidLoading = false;
              })
        },
        remoteVillageMethod(name) {
          this.pidLoading = true;
          getAreaCityList({ keyword:name, page: 1, pageSize: 20 }).then(res => {
            this.cityList = res.data.list;
          })
              .catch(err => {
                console.log(err)
              })
              .finally(() => {
                this.pidLoading = false;
              })
        },
        popOpen() {
          this.popShow = !this.popShow;
          if (this.popShow) {
            this.remoteMethod();
            this.remoteMethodPartyA();
            this.remoteMethodPartyB();
            this.remoteVillageMethod();
            this.searchQuery = cloneDeep(this.query);
            this.pointPopHide();
          }
        },
        popHide() {
          this.popShow = false;
        },
        pointPopHide() {
          this.pointPopShow = false;
        },

        loader(page, pageSize) {
            let form = this.getFormData();
            return contractList({ page, pageSize, ...form }).then(res => {
                return res.data;
            })
        },
        refresh(page) {
            this.$refs.table.refresh(page || '');
        },
        search() {
            this.$refs.table.refresh(1);
        },
        cellClassName({ row, column }) {
            return  column.property == 'title' ? 'hover--opacity' : '';
        },
        sortChange(order) {
            this.query = Object.assign(this.query, order);
            this.$nextTick(() => {
                this.search();
            })
        },
        tableRefresh() {
            this.$refs.table.columnsRefresh();
        },
        getFormData(){
            let form = cloneDeep(this.query);
            return form;
        },
        add(pid=''){
            if(isObject(pid)){
                pid = '';
            }
            this.$refs.contractUpdate.open({pid});
        },
        edit(row){
            this.$refs.contractUpdate.open(row,'编辑合同');
        },
        detail(row){
          this.$refs.contractDetail.open(row,'查看合同');
        },
        del(row){
          contractDelete({id:row.id}).then(res=>{
                this.$message.success('删除成功');
                this.refresh();
            })
        },
        cellClick(row,column){
            if(column.property == 'title'){
                this.detail(row);
            }
        }
    },
    created() {
        if (!isObjectNone(this.$route.params)) {
            this.query = Object.assign(this.query, this.$route.params);
        };
    }

}
</script>

<style lang="scss" scoped>
.kpi-wrapper {
    .item-menu {
        opacity: 0;
        transition: opacity .2s;
        padding: 0px 5px;
        color: var(--primary);
    }

    .sx-btn {
        padding: 7px 15px;

        &.active {
            border-color: var(--primary);
            color: var(--primary);
        }
    }

    .table-expend {
        &.menu-show {
            .item-menu {
                opacity: 1;
            }
        }

        &:hover {
            .item-menu {
                opacity: 1;
            }
        }
    }

    .count-wrapper {
        font-size: 13px;
    }
}
</style>