<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="dialogShow"
    :destroy-on-close="true"
    custom-class="approve-create-dialog"
    :close-on-click-modal="false"
    width="940px"
    @open="formInit"
    @closed="resetForm"
    @close="onClose(false)">
    <div class="cropper-cover">
        <div class="cropper-content">
        <div
          class="cropper"
          style="text-align: center; width: 580px; height: 300px"
        >
          <img :src="cropperOption.img" ref="img" style="opacity:0;height:100%;width:auto"/>
        </div>
        <div class="cropper-preview"></div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
        <el-button @click="onClose(false)" size="small">取 消</el-button>
        <el-button type="primary" @click="cropperConfirm" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import dialogCommon from "@/mixins/dialogCommon";
import { VueCropper }  from 'vue-cropper'
import 'cropperjs/dist/cropper.css'
import Cropper from 'cropperjs'
export default {
    props:{
        dialogTitle:{
            type:String,
            default:'图片裁切'
        },
        //需要生成裁切图片的信息
        option:{
            type:Object,
            default:()=>({})
        }
    },
    components:{
        VueCropper
    },
    mixins:[dialogCommon],

    data(){
        return{
            cropperOptionBak:{
                img: '',             //裁剪图片的地址
                outputSize: 1,       //裁剪生成图片的质量(可选0.1 - 1)
                outputType: 'png',  //裁剪生成图片的格式（jpeg || png || webp）
                info: true,          //图片大小信息
                canScale: true,      //图片是否允许滚轮缩放
                autoCrop: true,      //是否默认生成截图框
                autoCropWidth: 240,  //默认生成截图框宽度
                autoCropHeight: 240, //默认生成截图框高度
                fixed: true,         //是否开启截图框宽高固定比例
                fixedNumber: [1, 1], //截图框的宽高比例
                full: false,         //false按原比例裁切图片，不失真
                fixedBox: true,      //固定截图框大小，不允许改变
                canMove: true,      //上传图片是否可以移动
                canMoveBox: true,    //截图框能否拖动
                original: false,     //上传图片按照原始比例渲染
                centerBox: false,    //截图框是否被限制在图片里面
                high: true,        //是否按照设备的dpr 输出等比例图片
                infoTrue: false,     //true为展示真实输出图片宽高，false展示看到的截图框宽高
                maxImgSize: 3000,    //限制图片最大宽度和高度
                enlarge: 1,          //图片根据截图框输出比例倍数
                mode: 'contain'  //图片默认渲染方式
            },
            cropperOption:{},
            cropper:null

        }
    },
    methods:{
        //初始化的时候合并一下选项
        formInit(){
            this.cropperOption = Object.assign(this.cropperOptionBak,this.option);
            this.$nextTick(()=>{
                // 获取img的DOM元素
                const image = this.$refs.img;

                // 这些配置可以去参考官方文档 这里是最常用的基础配置
                    this.cropper = new Cropper(image, {
                    viewMode: 1,
                    dragMode: "move",
                    aspectRatio: 1,
                    autoCropArea: 0.8,
                    cropBoxMovable: false,
                    cropBoxResizable: false,
                    background: true,
                    zoomOnWheel:true,
                    center: true,
                    preview:'.cropper-preview'
                    });
            })
        },
        resetForm(){
            this.cropperOption={};
            this.cropper?.destroy && this.cropper?.destroy();
        },
        //生成对应的上传数据
        cropperConfirm(){
            this.cropper.getCroppedCanvas(() => {
                imageSmoothingQuality: 'high'
            }).toBlob((blob)=>{
                let formData = new FormData();
                formData.append("file", blob, "file.png");
                this.$emit('confirm',formData);
                this.onClose(false);
            }) ;
        }
    }


}
</script>

<style lang="scss" scoped>
</style>