<template>
    <div>
        <r-drawer ref="rDialog" size="600px" :dialogTitle="form.id ? '编辑角色' : '新增角色'"  @close="destroy" @submit="submit">
            <el-form size="small" label-position="left" label-width="100px" ref="form" :model="form">
                <el-form-item label="角色名称：" :rules="[
                    { required: true, message: '请输入角色名称', trigger: 'blur' },
                ]" prop="title">
                    <el-input v-model="form.title" placeholder="请输入角色名称"></el-input>
                </el-form-item>
                <el-form-item label="权限：">
                    <div class="r-pb-10">
                        <el-input placeholder="请输入权限关键字进行搜索" v-model="keyword" @input="keywordChange"></el-input>
                    </div>
                    <el-tree ref="formTree" :data="treeData" node-key="id" highlight-current
                        :expand-on-click-node="false" :default-expand-all="true" :check-strictly="true" show-checkbox
                        element-loading-text="拼命加载中" :current-node-key="0" @check="handleRulesCheckChange"
                        :props="{ label: 'title' }" :filter-node-method="filterNode">
                        <div class="custom-tree-node flex flex-center" slot-scope="{ node, data }">
                            <div class="flex-item">{{ node.label }}
                                <el-tooltip v-if="data.note !== ''" class="item" effect="dark" :content="data.note"
                                    placement="right">
                                    <i class="el-icon-warning"></i>
                                </el-tooltip>
                            </div>
                        </div>
                    </el-tree>
                </el-form-item>
                <el-form-item label="描述：">
                    <el-input type="textarea" v-model="form.remark" :autosize="{ minRows: 2, maxRows: 4 }"
                        placeholder="角色描述"></el-input>
                </el-form-item>
                <el-form-item label="状态：">
                    <el-radio-group v-model="form.status">
                        <el-radio :label="0">关闭</el-radio>
                        <el-radio :label="1">开启</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
        </r-drawer>
    </div>
</template>
<script>
import { roleListByPage, listByTree, create, edit } from "@/api/system/role";
import { isArray, readNodes, cloneDeep } from "@/utils";
const defaultForm = {
    title: '',
    status: 1,
    remark: '',
    id: null,
    dataRule: 1,
    extendDataRule: 0,
    rules:[]
}
export default {

    data() {
        return {
            form: {
                title: '',
                status: 1,
                remark: '',
                id: null,
                dataRule: 1,
                extendDataRule: 0,
            },
            treeData: [],
            loading: false,
            keyword: ''
        }
    },


    methods: {
        open(form) {
            if (form) {
                this.form = Object.assign(cloneDeep(defaultForm), form);
            }else{
                this.form = cloneDeep(defaultForm);
            }
            this.$refs['form']?.clearValidate && this.$refs['form'].clearValidate();
            this.listByTree();
            this.$refs.rDialog.handleOpen();
        },
        loader() {
            return roleListByPage().then(res => {
                return res.data;
            })
        },
        listByTree() {
            listByTree().then(res => {
                this.treeData = res.data;
                if(this.form.id){
                    this.$nextTick(()=>{
                        this.$refs.formTree.setCheckedNodes(this.form.rulesArr);
                    })
                }
            })
        },
        //递归取消或选中所有上级
        reSelectParent(el, selected = true) {
            if (!el?.pid) return;
            this.$refs.formTree.setChecked(el.pid, selected);
            let parent = this.$refs.formTree.getNode(el.pid);
            this.reSelectParent(parent?.data);
        },
        //递归取消或选中所有下级
        reSelectChild(el, selected = true) {
            if (!isArray(el?.children) || el?.children?.length === 0) return;
            el.children.forEach((_el) => {
                this.$refs.formTree.setChecked(_el, selected);
                this.reSelectChild(_el, selected);
            });
        },
        handleRulesCheckChange(data, check) {
            // 是否选中当前元素
            let isSelected = check.checkedKeys.indexOf(data.id) > -1;
            if (isSelected) {
                // 节点选中时同步选中子父节点
                if (isArray(data?.children)) {
                    // 获取所有下级
                    let nodes = readNodes(data.children, () => { return true }, []);
                    this.$refs.formTree.setCheckedKeys([...(nodes.map(el => { return el.id })), data.id, ...check.checkedKeys], true);
                }
                this.reSelectParent(data, true);
            } else {
                // 取消选择，需要清除掉所有下级
                this.reSelectChild(data, false);
            }
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.title.indexOf(value) !== -1;
        },
        keywordChange(val) {
            this.$refs.formTree.filter(val);
        },
        submit(done) {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let form = cloneDeep(this.form);
                    form.rules = this.$refs.formTree.getCheckedNodes();
                    if (form.id) {
                        edit(form)
                            .then((response) => {
                                this.loading = false;
                                this.$message.success("角色编辑成功");
                                done(true);
                                this.$emit('update');
                            })
                            .catch(() => {
                                done(false);
                            });
                    } else {
                        create(form)
                            .then((response) => {
                                this.$refs.formTree.setCheckedKeys([]);
                                this.$message.success("角色创建成功");
                                done(true);
                                this.$emit('update');
                            })
                            .catch(() => {
                                done(false);
                            });
                    }
                } else {
                    done();
                }
            });
        },
        destroy() {
            this.treeData = [];
        },
    }


}
</script>

<style lang="scss" scoped>
.tips {
    color: #C0C4CC;
}
</style>