import _, { get } from "lodash";
import moment from "moment";
const isArray = (item) => {
  return Object.prototype.toString.call(item) == '[object Array]';
}
const isObject = (item) => {
  return Object.prototype.toString.call(item) == '[object Object]';
}
const isFn = (item) => {
  return Object.prototype.toString.call(item) == "[object Function]"
}
const isString = (item) => {
  return Object.prototype.toString.call(item) == '[object String]';
}
const isWeex = () => {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true;
  } else {
    return false;
  }
}
const isMoney = (money) => {
  let reg = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/;
  return reg.test(money);
}
//判断一个对象是否为空
const isObjectNone = (item) => {
  if(!isObject(item)) return false;
  return Object.keys(item).length == 0;
}
//判断一个变量是否为数字
const isNumber = (item) => {
  return _.isNumber(item);
}
//判断一个字符串或这数字是否为手机号吗
const isPhone = (item) => {
  const phoneReg = /^(1[3-9])\d{9}$/;
  return phoneReg.test(item);
}
const omitBy = (item, fn) => {
  return _.omitBy(item, fn);
}
//对象比较
const isEqual = (item, item2) => {
  return _.isEqual(item, item2);
}
//是否为图片
const isImage = (item) =>{
  if(!item) return false;
  let mime = 'jpg, jpeg, png, gif, bmp, JPG, JPEG, PNG, GIF, BMP , webp';
  let _item = item.split('.');
  return mime.indexOf(_item[_item.length-1]) > -1;
}
//克隆元素
const cloneDeep = (item) => {
  return _.cloneDeep(item);
}
//获取匹配到的第一个元素的下标
const findIndex = (item, fn) => {
  return _.findIndex(item, fn);
}
//获取匹配到的最后一个元素的下标
const findLastIndex = (item, fn) => {
  return _.findLastIndex(item, fn);
}
//删除数组中的指定元素
const pull = (arr, item) => {
  return _.pull(arr, item);
}
//有条件的求和
const sumBy = (arr, fn) => {
  return _.sumBy(arr, fn);
}
//删除数组中的所有指定元素(带条件)
const pullAllBy = (arr, values, fn) => {
  return _.pullAllBy(arr, values, fn);
}
//过滤对象
const pickBy = (obj, fn) => {
  return _.pickBy(obj, fn);
}
//合并数组
const merge = (arr1, arr2) => {
  return _.merge(arr1, arr2);
}
//合并去重数组
const differenceBy = (arr1, arr2, options) => {
  return _.differenceBy(arr1, arr2, options)
}
//提取数组交集
const intersection = (arr) => {
  return _.intersection(...arr);
}
//提取数组交集(自定义条件)
const intersectionBy = (arr, fn) => {
  return _.intersectionBy(...arr, fn);
}
//去重
const uniq = (item, item2, callback) => {
  let _data;
  if (isArray(item) && !item2) {
    _data = _.uniq(item);
  } else {
    _data = {};
    Object.keys(item).forEach(el => {
      let _item = item[el];
      //特殊处理
      callback && callback({
        //原数据
        item,
        //比较数据
        item2,
        //当前循环到的数据
        el,
        //返回的数据
        _data
      });
      //不一样才能添加
      if (!isEqual(item2[el], _item)) {
        _data[el] = _item;
      }
    })
  }
  return _data;

}
//根据条件去重
const uniqBy = (item, options) => {
  return _.uniqBy(item, options);
}
//将参数转化为字符串
const _toString = (val) => {
  return _.toString(val);
}
//过滤非整数数据
const iniFilter = (val) => {
  let _val = _toString(val);
  _val = _val.replace(/\D/g, '');
  return _val;
}

/**
 *
 * 时间格式化
 *
 * 1 本日  2 本周  3 上周  4 本月  5 上月
 *
 * 6 最近三个月  7 最近六个月  8 本年
 *
 */

const getDateFky = (type) => {

  //本日

  if (type == 1) {

    const end = new Date();

    const start = new Date();

    return {end, start}

  } else if (type == 2) {

    //本周

    const end = new Date();

    const start = new Date();

    start.setTime(start.getTime() - ((start.getDay() > 0 ? start.getDay() : start.getDay() + 7) - 1) * 3600 * 24 * 1000);

    return {end, start}

  } else if (type == 3) {

    //上周

    const date = new Date();

    date.setHours(0, 0, 0);

    //按自然周计算，开始时间应当是自然周的第一天

    const start = new Date();

    const end = new Date();

    start.setTime(date.getTime() - (date.getDay() + (7 - 1 - 0)) * (24 * 60 * 60 * 1000));

    end.setTime(date.getTime() - (date.getDay() + (7 - 1 - 6)) * (24 * 60 * 60 * 1000));

    return {end, start}


  } else if (type == 4) {

    //本月

    const end = new Date();

    const start = new Date();

    start.setDate(1);

    return {end, start}


  } else if (type == 5) {

    //上个月

    const date = new Date();

    const start = new Date();

    const end = new Date();

    start.setMonth(date.getMonth() - 1, 1);

    end.setMonth(date.getMonth(), 0);

    return {end, start}


  } else if (type == 6) {

    //最近三个月

    const end = new Date();

    const start = new Date();

    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);

    return {end, start}

  } else if (type == 7) {

    //最近6个月

    const end = new Date();

    const start = new Date();

    start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);

    return {end, start}

  } else if (type == 8) {

    //本年

    const end = new Date();

    const start = new Date(end.getFullYear(), 0, 1);


    return {end, start};

  }


}

const getDateText = (type) => {

  const dateList = {

    0: '不限',

    1: '本日',

    2: "本周",

    3: '上周',

    4: '本月',

    5: '上个月',

    6: '最近三个月',

    7: '最近六个月',

    8: '本年'

  }


  return dateList[type];

}

/**
 *
 * 转化时间格式
 *
 * @param _val 时间戳
 *
 * @param type 获取的类型  有 date 和 time 默认为date+time
 *
 */

const dateChange = (_val, type) => {

  let val = new Date(_val);

  let year = val.getFullYear();
  let month = val.getMonth() + 1;
  let day = val.getDate();
  let hour = val.getHours();

  let minute = val.getMinutes();

  if (month >= 1 && month <= 9) {
    month = `0${month}`
  }
  ;

  if (day >= 1 && day <= 9) {
    day = `0${day}`
  }
  ;

  if (hour >= 0 && hour <= 9) {
    hour = `0${hour}`
  }
  ;

  if (minute >= 0 && minute <= 9) {
    minute = `0${minute}`
  }
  ;

  if (type == 'date') {

    return `${year}-${month}-${day}`;

  } else if (type == 'time') {

    return `${hour}:${minute}`

  } else {

    return `${year}-${month}-${day} ${hour}:${minute}`;

  }

}

const pickerFormatter = (type, value) => {
  if (type === 'year') {
    return `${value}年`
  } else if (type === 'month') {
    return `${value}月`
  } else if (type === 'day') {
    return `${value}日`
  } else if (type === 'hour') {
    return `${value}时`
  } else if (type === 'minute') {
    return `${value}分`
  } else if (type === 'second') {
    return `${value}秒`
  }
  return value
}

//过滤空值

const filterNull = (item, fn) => {

  fn = fn ? fn : () => {
    return true
  };

  if (isArray(item)) {

    item.filter(el => {

      return !!el || fn(el);

    });

    return item;

  }


  if (isObject(item)) {

    let _item = {};

    Object.keys(item).forEach(el => {

      if (!!item[el] || fn(item[el])) {

        _item[el] = item[el];

      }


    });


    return _item;


  }


}

/**
 *
 * 获取基于当前屏幕的高度比例，可以用vh代替
 *
 * @param {number} num 需要获取的高度比例 0-1
 *
 */

const getScreenHeight = (num) => {

  return Math.ceil(document.documentElement.clientHeight * num);

}


/**
 *
 * 将vw 转化为px
 *
 * @params vw 需要转化的值，必须为数字
 *
 */

const vwToPx = (vw) => {

  let n = document.documentElement.clientWidth / 100;

  return parseFloat((vw * n).toFixed(2));

}


/**
 * 将vh 转化为px
 *
 * @params vh 必须转化的值，必须为数字
 *
 */


/**
 *
 * 将 px 转化为 vw
 *
 * @params px 需要转化的值，必须为数字
 */

const pxToVw = (px) => {

  let n = document.documentElement.clientWidth / 100;

  return parseFloat((px / n).toFixed(2));

}


/**
 *
 * 递归获取数组中的值
 *
 */

const readNodes = (nodes = [], fn, arr = []) => {

  for (let item of nodes) {

    if (fn(item,arr)) {

      arr.push(item);

    }

    if (item.children && item.children.length) readNodes(item.children, fn, arr)

  }

  return arr
}

/**
 *
 * 递归获取父级元素
 */

const readParentNodes = (nodes,item) => {
  //平铺
  let list = readNodes(nodes,()=>{return true},[]);
  let parent = [];
  let getParent = (el)=>{
    //递归子级
    if(el.pid && el.pid !='00000000-0000-0000-0000-000000000000'){
      let _item = list.find(_el=>_el.id == el.pid);
      if(_item){
        parent.push(_item);
        getParent(_item);
      }
    }
  }
  getParent(item);
  return parent.reverse();
  
}

/**
 * 递归删除对应元素
 */

const removeNodes = (nodes = [], fn) => {

  if (!isArray(nodes)) return;

  nodes = nodes.filter(el => {

    return fn(el);

  })

  nodes.forEach(el => {

    el.children = el.children.filter(item => {

      return fn(item);

    });

    if (el.children && el.children.length) removeNodes(el.children, fn)

  })

  return nodes;


}


/**
 *
 * 一些工具的集合
 *
 */


/**
 * 获取图片宽高
 *
 * @param {string} src 图片地址
 *
 * @return {object} w:图片宽度 ， h:图片高度
 */

const getImgSize = (src) => {

  return new Promise((res, rej) => {

    let img = document.createElement('img');

    img.src = src;

    //等待图片加载事件完成

    img.onload = function () {

      res({w: img.width, h: img.height});

    }

    //加载失败事件

    img.onerror = function (err) {

      rej(err);

    }

  })

}

/**
 * 报备文件专用下载
 * @param {*} data 
 * @param {*} filename 
 */
const recordDataDownload = (data,filename)=>{


  let fileName = filename ||  Date.parse(new Date()) + '.xlsx';

  var link = document.createElement('a');

  link.href =data;

  link.download = fileName;

  link.click()

  //释放内存

  window.URL.revokeObjectURL(link.href);

}

/**
 *
 * 下载文件通用
 *
 */

const download = (url, callback) => {

  url = url.replace(/\\/g, '/');

  const xhr = new XMLHttpRequest();

  const saveAs = (data, name) => {

    var urlObject = window.URL || window.webkitURL || window;

    var export_blob = new Blob([data]);

    var save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a')

    save_link.href = urlObject.createObjectURL(export_blob);

    save_link.download = name;

    save_link.click();

  }



  const getFileName = (url) => {

    var num = url.lastIndexOf('/') + 1

    var fileName = url.substring(num);

    //把参数和文件名分割开

    fileName = decodeURI(fileName.split("?")[0]);

    return fileName;

  }

  xhr.open('GET', url, true);

  xhr.responseType = 'blob';

  xhr.onload = () => {

    if (xhr.status === 200) {

      // 获取文件blob数据并保存

      var fileName = getFileName(url);

      saveAs(xhr.response, fileName);

      callback && callback();

    } else {

      callback && callback('err');

    }

  };

  xhr.send();

}

const downloadFile  = (url,name) =>{
  var save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a')

  save_link.href = url;

  save_link.download = name;

  save_link.target = "_blank"

  save_link.click();

  save_link.remove();
}


/**
 * 将菜单子数组合并到一个数组
 * @param {*} menu  菜单数组
 * @param {*} key   子数组的key名
 *
 * @return {Array} menuList 返回一个对象
 */

const getMenuList = (menu, key) => {

  let _key = key || 'submenu'

  if (!isArray(menu)) {

    console.error('menu必须为一个数组');

    return {};

  }

  let menuList = {};


  menu.forEach(el => {

    el[_key] && el[_key].forEach(_el => {

      menuList[_el.path] = _el.name;

    })

  });

  return menuList;

}


/**
 *
 * 获取格式化的时间
 *
 * @param date 时间戳
 *
 * @param separator 分隔符，不填默认'-'
 *
 * @param format 类型 是要日期，还是时间部分，默认显示全部
 *
 * @return date 格式化后的时间区间
 *
 */

const getFormatDate = ({date, separator, format, br}) => {

  let _date = new Date(date);

  let _separator = separator || '-';

  if (format == 'date') {


    return `${_date.getFullYear()}${_separator}${_date.getMonth() + 1 < 10

      ? '0' + (_date.getMonth() + 1)

      : _date.getMonth() + 1

    }${_separator}${_date.getDate() < 10 ? '0' + _date.getDate() : _date.getDate()}`

  } else {

    if (format == 'minute') {

      return `${_date.getFullYear()}${_separator}${_date.getMonth() + 1 < 10 ? '0' + (_date.getMonth() + 1) : _date.getMonth() + 1}${_separator}${_date.getDate() < 10 ? '0' + _date.getDate() : _date.getDate()}${br ? '<br>' : '  '}${_date.getHours() < 10 ? '0' + _date.getHours() : _date.getHours()}:${_date.getMinutes() < 10 ? '0' + _date.getMinutes() : _date.getMinutes()}`


    } else {

      return `${_date.getFullYear()}${_separator}${_date.getMonth() + 1 < 10 ? '0' + (_date.getMonth() + 1) : _date.getMonth() + 1}${_separator}${_date.getDate() < 10 ? '0' + _date.getDate() : _date.getDate()} ${_date.getHours() < 10 ? '0' + _date.getHours() : _date.getHours()}:${_date.getMinutes() < 10 ? '0' + _date.getMinutes() : _date.getMinutes()}:${_date.getSeconds() < 10 ? '0' + _date.getSeconds() : _date.getSeconds()}`

    }


  }


}


/**
 *
 * 分隔获取到的区域列表
 *
 * @param {Array} 一个区域的数据
 *
 * @return {renderList,searchCityList}
 *
 * 返回一个对象，
 *
 * renderList array 用于渲染的数组
 *
 * searchCityList Object 以id为key 的对象
 *
 *
 */

const initCityList = (cityList) => {

  if (!isArray(cityList)) {

    console.error('城市列表必须唯一个数组');

    return {};

  }

  let checkList = [];

  cityList.forEach((el, index) => {

    el.children.forEach(_el => {

      if (_el.id != el.id) {

        checkList.push(Object.assign(_el, {areaIndex: index}));

      }

    })

  });


  return checkList;


}


/**
 * 获取对应的城市数据
 *
 * @param {object} store 实例对象
 *
 * @param {string}  城市名称
 *
 * @param {string} id 城市id 如果同时存在城市名称和id，将会使用id进行检索
 *
 * @return {object | null} cityData 返回一个城市对象 , 查询不到将会返回 null
 *
 *
 */

const getCityData = (store, {id, name}) => {

  if (!isObject(store)) {

    console.error('store 应当为一个对象');

    return null;

  }

  if (id) {

    //获取对应的数据

    let index = findIndex(store.state.userInfo.checkCityList, (item) => {

      return item.id == id;

    });

    if (index != -1) {

      return store.state.userInfo.checkCityList[index];

    } else {

      return null;

    }

  } else if (name) {

    //获取对应的数据

    let index = findIndex(store.state.userInfo.checkCityList, (item) => {

      return name.indexOf(item.text) != -1;

    });

    if (index != -1) {

      return store.state.userInfo.checkCityList[index];

    } else {

      return null;

    }

  } else {

    return null;

  }


}

/**
 * 获取全局唯一标识
 */
const guid = (tLength, tFirstU, tRadix) => {

  let len = tLength ? tLength : 32;
  let firstU = !tFirstU ? tFirstU : true;
  let radix = tRadix ? tRadix : null;
  let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  let uuid = [];
  radix = radix || chars.length;
  if (len) {
    // 如果指定uuid长度,只是取随机的字符,0|x为位运算,能去掉x的小数位,返回整数位
    for (let i = 0; i < len; i++) {
      uuid[i] = chars[0 | Math.random() * radix];
    }

  } else {
    let r;
    // rfc4122标准要求返回的uuid中,某些位为固定的字符
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';

    for (let _i = 0; _i < 36; _i++) {
      if (!uuid[_i]) {
        r = 0 | Math.random() * 16;
        uuid[_i] = chars[_i == 19 ? r & 0x3 | 0x8 : r];
      }
    }
  }
  // 移除第一个字符,并用u替代,因为第一个字符为数值时,该guuid不能用作id或者class
  if (firstU) {
    uuid.shift();
    return 'u' + uuid.join('');
  } else {
    return uuid.join('');
  }
}
//return an array of values that match on a certain key
const getValues = (obj, key) => {
  let objects = [];
  for (let i in obj) {
    if (!obj.hasOwnProperty(i)) continue;
    if (typeof obj[i] == 'object') {
      objects = objects.concat(getValues(obj[i], key));
    } else if (i == key) {
      objects.push(obj[i]);
    }
  }
  return objects;
}
/**
 * 相加
 * @param num1
 * @param num2
 * @returns {*}
 */
const accAdd = (num1, num2) => {
  return _.add(num1, num2)
}
/**
 *
 * @param {Array} layout  注册路由所使用的的数组
 * @param {*} routerMenu  包含所有路由的列表
 *
 *
 */
const deepRouter = (layout, routerMenu, parent, routerComponentList) => {
  routerMenu.forEach(el => {
    //判断是否存在children,不存在则直接注册数据
    if (el.children) {
      deepRouter(layout, el.children, el, routerComponentList);
    } else {
      el.name = el.index.replace(/\/(\w)/g, function ($0, $1) {
        return $1.toUpperCase();
      });
      el.name = el.name.replace(/^[A-Z]+/, function ($0, $1) {
        return $0.toLowerCase();
      })
      if (parent) {
        layout.children.push({
          path: el.index,
          name: el.name,
          component: routerComponentList[el.name],
          meta: {
            title: el.text,
            //产生面包屑用的父级
            pName: parent?.text,
            pIndex: parent?.index,
            keepAlive: !!el?.keepAlive
          }
        });
      } else {
        layout.children.push({
          path: el.index,
          name: el.name,
          component: routerComponentList[el.name],
          meta: {
            title: el.text,
            keepAlive: !!el?.keepAlive
          }
        });
      }

    }
  });

  return layout;
}
/**
 * Indexes and/or groups the array according to a specified key.
 * @param {Array} array  数组
 * @param {String} key  key
 */
const arrToIndex = (array, key) => {
  let obj = [];
  for (let i = 0; i < array.length; i++) {
    obj[array[i][key]] = array[i]
  }
  return obj
}

//限制小数个数，不进行四舍五入
const numToFixed = (item, num) => {
  let _item = `${item}`;
  if (_item.indexOf('.') == -1) return _item;
  _item = _item.split('.');
  let decimal = _item[1].substring(0, num);
  return `${_item[0]}.${decimal}`
}

const numberFormat = (value, currency = '', decimals = 2) => {
  const digitsRE = /(\d{3})(?=\d)/g;
  value = parseFloat(value);
  if (!value && value !== 0) return '';
  const stringified = Math.abs(value).toFixed(decimals);
  const $int = decimals ? stringified.slice(0, -1 - decimals) : stringified;
  const i = $int.length % 3;
  const head = i > 0 ? ($int.slice(0, i) + ($int.length > 3 ? ',' : '')) : '';
  const $float = decimals ? stringified.slice(-1 - decimals) : '';
  const sign = value < 0 ? '-' : '';
  return `${sign}${head}${$int.slice(i).replace(digitsRE, '$1,')}${$float} ${currency}`;
}

/**
 * 数据转换为树形（递归），示例：toTreeByRecursion(source, 'id', 'parentId', null, 'children')
 * @param {Array} source 数据
 * @param {String} idField 标识字段名称
 * @param {String} parentIdField 父标识字段名称
 * @param {Any} parentIdNoneValue 父级标识空值
 * @param {String} childrenField 子节点字段名称
 * @param {Object} treeOption tree树形配置
 */

const toTree = (
  source = [],
  idField = 'id',
  parentIdField = 'parentId',
  childrenField = 'children',
  treeOption = undefined,
  parentIdNoneValue = '',
) => {
  const treeOptions = {
    enable: true, // 是否开启转tree插件数据
    keyField: 'key', // 标识字段名称，默认为key
    valueField: 'value', // 值字段名称，默认为value
    titleField: 'title', // 标题字段名称，默认为title
    keyFieldBind: 'id', // 标识字段绑定字段名称，默认为id
    valueFieldBind: 'id', // 值字段名称绑定字段名称，默认为id
    titleFieldBind: 'name' // 标题字段名称绑定字段名称，默认为name
  }
  // 合并tree树形配置
  if (treeOption) {
    Object.assign(treeOptions, treeOption)
  }

  // 对源数据深度克隆
  const cloneData = JSON.parse(JSON.stringify(source))
  return cloneData.filter(parent => {
    // 返回每一项的子级数组
    const branchArr = cloneData.filter(child => parent[idField] === child[parentIdField])

    // 绑定tree树形配置
    if (treeOptions.enable) {
      branchArr.map(child => {
        child[treeOptions.keyField] = child[treeOptions.keyFieldBind]
        child[treeOptions.valueField] = child[treeOptions.valueFieldBind]
        child[treeOptions.titleField] = child[treeOptions.titleFieldBind]
        return child
      })
    }

    // 如果存在子级，则给父级添加一个children属性，并赋值，否则赋值为空数组
    if (branchArr.length > 0) {
      parent[childrenField] = branchArr
    } else {
      parent[childrenField] = []
    }

    // 绑定tree树形配置
    if (treeOptions.enable) {
      parent[treeOptions.keyField] = parent[treeOptions.keyFieldBind]
      parent[treeOptions.valueField] = parent[treeOptions.valueFieldBind]
      parent[treeOptions.titleField] = parent[treeOptions.titleFieldBind]
    }

    return parent[parentIdField] === parentIdNoneValue // 返回第一层
  })
}

/**
 * 
 * @param {String} type base64的文件头 例：'data:audio/wav;base64'
 * @param {*}      file 后台返回的文件流
 */
const fileToBase64 = (type,file)=>{
  return `${type},${window.btoa(new Uint8Array(file).reduce((data, byte) => data + String.fromCharCode(byte),""))}`
}


const getDateContent = (date)=>{
  if(!date) return '';
  let _date = date.split(' ');
  return _date[0];
}
/**
 * @param {Array} 需要转换的数组
 * @return {Object}  将选项元素转成value:label 的对象
 */

const getOptionsLabelKeys = (arr)=>{
  let keys = {};
  arr.forEach(el=>{
    keys[el.value] = el.label;
  });
  

  return keys;
}
const convertCurrency = (money) => {
  //汉字的数字
  var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');
  //基本单位
  var cnIntRadice = new Array('', '拾', '佰', '仟');
  //对应整数部分扩展单位
  var cnIntUnits = new Array('', '万', '亿', '兆');
  //对应小数部分单位
  var cnDecUnits = new Array('角', '分', '毫', '厘');
  //整数金额时后面跟的字符
  var cnInteger = '整';
  //整型完以后的单位
  var cnIntLast = '元';
  //最大处理的数字
  var maxNum = 999999999999999.9999;
  //金额整数部分
  var integerNum;
  //金额小数部分
  var decimalNum;
  //输出的中文金额字符串
  var chineseStr = '';
  //分离金额后用的数组，预定义
  var parts;
  // 传入的参数为空情况 
  if(money == '') {
      return '';
  }
  money = parseFloat(money)
  if(money >= maxNum){
      return ''
  }
  // 传入的参数为0情况 
  if(money == 0) {
      chineseStr = cnNums[0] + cnIntLast + cnInteger;
      return chineseStr
  }
  // 转为字符串
  money = money.toString();
  // indexOf 检测某字符在字符串中首次出现的位置 返回索引值（从0 开始） -1 代表无
  if(money.indexOf('.') == -1){
      integerNum = money;
      decimalNum = ''
  }else{
      parts = money.split('.');
      integerNum = parts[0];
      decimalNum = parts[1].substr(0,4);
  }
  //转换整数部分
  if(parseInt(integerNum,10) > 0){
      let zeroCount  = 0;
      let IntLen = integerNum.length
      for(let i = 0; i < IntLen; i++){
          let n = integerNum.substr(i,1);
          let p = IntLen - i - 1;
          let q = p / 4;
          let m = p % 4;
          if( n == '0'){
              zeroCount ++ ;
          }else{
              if(zeroCount > 0){
                  chineseStr += cnNums[0]
              }
              zeroCount = 0;
              chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
          }
          if(m == 0 && zeroCount < 4){
              chineseStr += cnIntUnits[q]; 
          }
      }
      // 最后+ 元
      chineseStr += cnIntLast;
  }
  // 转换小数部分
  if(decimalNum != ''){
      let decLen = decimalNum.length;
      for(let i = 0; i <decLen; i++){
          let n = decimalNum.substr(i,1);
          if(n != '0'){
              chineseStr += cnNums[Number(n)] + cnDecUnits[i]
          }
      }
  }
  if(chineseStr == ''){
      chineseStr += cnNums[0] + cnIntLast + cnInteger;
  }else if(decimalNum == ''){
      chineseStr += cnInteger;
  }

  return chineseStr
}

const sortBy = (arr,fn)=>{
  return _.sortBy(arr,fn)
};

//数组切割
const chunk = (arr,number)=>{
  return _.chunk(arr,number);
}

//创建数组
const createArr = (def='',length)=>{
  if(length == 0) return [];
  let arr = [];
  for(let i = 0;i<length;i++){
    arr.push(def);
  }
  return arr;
}

//时间对比
const dateContrast = (start,end)=>{
  let _start = (new Date(start)).getTime();
  let _end = (new Date(end)).getTime();

  return _end > _start;
}

//获取时间区间

const getDateRange = (start,end)=>{
  const dates = [];
  let currentDate = moment(start);
 
  while (currentDate.isSameOrBefore(end)) {
    dates.push(currentDate.clone().format("YYYY-MM-DD"));
    currentDate.add(1, 'days');
  }
 
  return dates;
}

//处理列表key
const getMenuKey = (menus,arr=[]) => {
    menus.forEach(menu => {
      arr.push(menu.name);
      if(menu.children){
        getMenuKey(menu.children,arr);
      }
    })

    return arr;
}

const readObjectNodes = (obj = {},fn,arr = [])=>{
  if(fn(obj)){
    arr.push(obj);
  }
  
  if(obj?.childNode){
    readObjectNodes(obj.childNode,fn,arr);
  }

  return arr;
}

export {
  isArray,
  isObject,
  isFn,
  getMenuList,
  isObjectNone,
  getFormatDate,
  initCityList,
  isEqual,
  cloneDeep,
  findIndex,
  findLastIndex,
  isNumber,
  pull,
  pullAllBy,
  merge,
  uniq,
  readNodes,
  readParentNodes,
  removeNodes,
  omitBy,
  isString,
  filterNull,
  download,
  getImgSize,
  isPhone,
  getScreenHeight,
  vwToPx,
  getDateFky,
  dateChange,
  pickerFormatter,
  differenceBy,
  intersection,
  intersectionBy,
  isWeex,
  pickBy,
  getCityData,
  getDateText,
  pxToVw,
  _toString,
  iniFilter,
  guid,
  getValues,
  uniqBy,
  deepRouter,
  arrToIndex,
  numToFixed,
  accAdd,
  numberFormat,
  sumBy,
  toTree,
  downloadFile,
  fileToBase64,
  recordDataDownload,
  getDateContent,
  getOptionsLabelKeys,
  convertCurrency,
  isMoney,
  sortBy,
  isImage,
  chunk,
  createArr,
  dateContrast,
  getDateRange,
  getMenuKey,
  readObjectNodes
}
