
import system from "./config/system";


// 表单属性【右面板】
export const formConf = {
  formRef: 'elForm',
  formModel: 'formData',
  size: 'small',
  labelPosition: 'right',
  labelWidth: 100,
  formRules: 'rules',
  gutter: 15,
  disabled: false,
  span: 24,
  formBtns: true
}

// 输入型组件 【左面板】
export const inputComponents = [
  {
    // 组件的自定义配置
    __config__: {
      label: '单行输入框',
      labelWidth: null,
      showLabel: true,
      changeTag: true,
      tag: 'el-input',
      tagIcon: 'input',
      field: 'input',
      defaultValue: undefined,
      required: true,
      layout: 'colFormItem',
      span: 24,
      // 正则校验规则
      regList: [],
      formId: 'input'
    },
    // 组件的插槽属性
    __slot__: {},
    // 其余的为可直接写在组件标签上的属性
    placeholder: '请输入',
    style: {width: '100%'},
    readonly: false,
    disabled: true
  },
  {
    __config__: {
      label: '多行输入框',
      labelWidth: null,
      showLabel: true,
      tag: 'el-input',
      tagIcon: 'textarea',
      field: 'textarea',
      defaultValue: undefined,
      required: true,
      layout: 'colFormItem',
      span: 24,
      regList: [],
      changeTag: true,
    },
    type: 'textarea',
    placeholder: '请输入',
    style: {width: '100%'},
    maxlength: null,
    'show-word-limit': false,
    readonly: false,
    disabled: true,
  },
  {
    __config__: {
      label: '数字输入框',
      showLabel: true,
      changeTag: true,
      labelWidth: null,
      tag: 'el-input',
      tagIcon: 'number',
      field: 'number',
      defaultValue: undefined,
      span: 24,
      layout: 'colFormItem',
      required: true,
      regList: [],
    },
    placeholder: '',
    disabled: true
  },
  {
    __config__: {
      label: '单选框组',
      labelWidth: null,
      showLabel: true,
      tag: 'el-radio-group',
      tagIcon: 'radio',
      field: 'radio',
      changeTag: true,
      defaultValue: undefined,
      layout: 'colFormItem',
      span: 24,
      optionType: 'default',
      regList: [],
      required: true,
      border: false,
      expandParams: {
        isValueLabel: 'label'
      }
    },
    __slot__: {
      options: [{
        label: '选项1',
        value: 1
      }, {
        label: '选项2',
        value: 2
      }]
    },
    style: {},
    size: 'small',
    disabled: false
  },
  {
    __config__: {
      label: '多选框组',
      tag: 'el-checkbox-group',
      tagIcon: 'checkbox',
      field: 'checkbox',
      defaultValue: [],
      showLabel: true,
      layout: 'colFormItem',
      optionType: 'default',
      required: true,
      changeTag: true,
      border: false,
      expandParams: {
        isValueLabel: 'label'
      }
    },
    __slot__: {
      options: [{
        label: '选项1',
        value: 1
      }, {
        label: '选项2',
        value: 2
      }]
    },
    style: {},
    size: 'small',
    disabled: false,
  },{
    __config__: {
      label: '水印相机',
      tag: 'camera',
      tagIcon: 'camera',
      field: 'camera',
      layout: 'colFormItem',
      defaultValue: null,
      showLabel: true,
      labelWidth: null,
      required: true,
      span: 24,
      showTip: false,
      buttonText: '点击上传',
      regList: [],
      changeTag: true,
      expandParams:{
        tips:'水印相机是带有时间与定位的图片,只能通过拍照获取'
      }
    },
    action:'',
    __slot__: {
      'list-type': true
    },
    disabled: true,
  },
  {
    __config__: {
      label: '普通图片',
      tag: 'el-upload',
      tagIcon: 'upload-image',
      field: 'uploadImg',
      layout: 'colFormItem',
      defaultValue: null,
      showLabel: true,
      labelWidth: null,
      required: true,
      span: 24,
      showTip: false,
      buttonText: '点击上传',
      regList: [],
      changeTag: true,
      expandParams:{
        tips:'普通图片既可以拍照上传,也可以从相册中上传'
      }
    },
    __slot__: {
      'list-type': true
    },
    action:'',
    disabled: true,
  },
  {
    __config__: {
      label: '附件',
      tag: 'el-upload',
      tagIcon: 'upload',
      field: 'upload',
      layout: 'colFormItem',
      defaultValue: null,
      showLabel: true,
      labelWidth: null,
      required: true,
      span: 24,
      showTip: false,
      buttonText: '点击上传',
      expandParams:{
        alert:'可选择微信聊天中的文件进行上传'
      }
    },
    action:'',
    __slot__: {
      'list-type': true
    },
    disabled: true
  },
  {
    __config__: {
      label: '日期',
      tag: 'el-date-picker',
      tagIcon: 'date',
      field: 'date',
      defaultValue: null,
      showLabel: true,
      labelWidth: null,
      span: 24,
      layout: 'colFormItem',
      required: true,
      regList: [],
      changeTag: true,
    },
    placeholder: '请选择',
    type: 'date',
    style: {width: '100%'},
    disabled: true,
    clearable: true,
    format: 'yyyy-mm-dd',
    'value-format': 'yyyy-mm-dd',
    readonly: false
  },
  {
    __config__: {
      label: '日期区间',
      tag: 'el-date-picker',
      tagIcon: 'date-range',
      field: 'date-range',
      defaultValue: null,
      span: 24,
      showLabel: true,
      labelWidth: null,
      required: true,
      layout: 'colFormItem',
      regList: [],
      changeTag: true,
    },
    placeholder: '请选择',
    style: {width: '100%'},
    type: 'daterange',
    'range-separator': '至',
    'start-placeholder': '开始日期',
    'end-placeholder': '结束日期',
    disabled: true,
    clearable: true,
    format: 'yyyy-mm-dd',
    'value-format': 'yyyy-mm-dd',
    readonly: false
  }, 
]



// 显示用空间组件 【左面板】
export const showComponents = [
  {
    // 组件的自定义配置
    __config__: {
      label: '富文本',
      labelWidth: null,
      showLabel: true,
      changeTag: true,
      tag: 'edit',
      tagIcon: 'edit',
      field: 'edit',
      defaultValue: undefined,
      required: true,
      layout: 'colFormItem',
      span: 24,
      // 正则校验规则
      regList: [],
      expandParams:{
        placeholderHide:true,
        labelDisabled:true,
        showLabel:false,
        content:''
      }
    },
    // 组件的插槽属性
    __slot__: {},
    // 其余的为可直接写在组件标签上的属性
    placeholder: '请输入',
    style: {width: '100%'},
    readonly: false,
    disabled: true
  },
  {
    __config__: {
      label: '分割线',
      labelWidth: null,
      showLabel: true,
      tag: 'el-divider',
      tagIcon: 'divider',
      field: 'divider',
      defaultValue: undefined,
      required: true,
      layout: 'colFormItem',
      span: 24,
      regList: [],
      expandParams:{
        showLabel:false
      }
    },
    placeholder: '请输入',
    style: {width: '100%'},
    readonly: false,
    disabled: true,
  }
]



export const systemComponents = system;