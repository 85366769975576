/**
 *
 * 文件进度条的一些方法
 */
import bigNumber from 'bignumber.js';
import {guid} from "@/utils";
import upload from '@/utils/ossUpload';
export default {
    data(){
        return {
            imageMaxSize:10,
            //进度条
            uploadProgressList:{},
            messageId:'',
            messageItem:'',
            ossUploadUrl: process.env.VUE_APP_IMG_URL,
            videoAccept:'.wmv,.asf,.asx,.rm,.rmvb,.mov,.m4v,.avi,.dat,.mkv,.flv,.vob,.mp4',
            imageAccept:'.jpg, .jpeg, .png, .gif, .bmp, .JPG, .JPEG, .PNG,.GIF, .BMP'
        }
    },
    methods: {
        /**
         * 文件上传前的检查
         */
        beforeThumbUpload(file){
            if(!file) return;
            //所有通过校验的元素添加一个待上传标记
            this.$set(this.uploadProgressList,file.uid,'await') 

            //此时进行上传处理
            if(!this.messageId){
                this.messageId = guid(20);
            }
        },
        //上传失败提示
        uploadError(err,file){
            this.updateProgress(file.uid,'fail');
            console.log(err)
            this.$message({
                message:`${file.name}上传失败，请检查网络是否正常，或稍后重试 ${err}`,
                type:'error'
            });
        },
        //触发进度条事件
        uploadProgress(process,file,fileList){
            this.progressInit(process,file,fileList)
        },
        /**
         * 
         * @param {*} process 进度
         * @param {*} file    当前上传的文件
         * @param {*} fileList 文件列表
         * 
         * 根据文件列表和当前文件进度计算出总的进度，当总进度为100时，清除并关闭进度条
         */
        progressInit(process,file,fileList){
            fileList = fileList.filter(el=>{
                return el?.percentage || el?.percentage === 0;
            })
            if(file.percentage == 100){
                this.$message.success('文件上传成功');
            }
            let num = bigNumber(0);
            fileList.forEach(el=>{
                num = num.plus(bigNumber(el.percentage))
            });
            num = num.div(bigNumber(fileList.length)).toFixed(2);
            //此时判断是否存在标签
            let message = document.getElementById(this.messageId);
            let messageText = document.getElementById(`${this.messageId}-message`);
            //存在就更新
            if(message){
                messageText.innerText = `共${fileList.length}个，当前进度：${num}%`;
                message.style.width = `${num}%`
            }else{
                this.messageItem = this.$notify({
                    title: '文件上传中',
                    dangerouslyUseHTMLString: true,
                    message: `<div class="notify-progress">
                        <p id="${this.messageId}-message">共${fileList.length}个</p>
                        <div class="notify-progress-block">
                            <div class="notify-progress-block-line" id="${this.messageId}">
                        </div>
                    </div>`,
                    duration: 0,
                    position:'top-right',
                    showClose:false,
                    iconClass:"el-icon-loading"
                });
            }
        },
        progressClose(){
            this.messageItem?.close && this.messageItem.close();
            let list = Object.keys(this.uploadProgressList);
            let success = 0;
            let fail = 0;
            list.forEach(el=>{
                if(this.uploadProgressList[el] == 'success'){
                    success++;
                }else if(this.uploadProgressList[el] == 'fail'){
                    fail++;
                }
            })
            this.$notify({
                title: `文件上传${list.length == success ? '全部成功' : list.length != fail ? '部分成功' : '全部失败'}`,
                message: `此次共上传${list.length}个，成功${success}个，失败${fail}个`,
                position:'top-right',
                duration:3000,
                type:list.length == fail ? 'error' : 'success'
            });
            //清空上传状态对象
            this.uploadProgressList = {};
        },
        /**
         * 针对上传进度发起更新
         * @param {Number|String} uid 上传文件的uid
         * @param {Boolean} status 当前文件的上传状态 await , success , fail
         */
        updateProgress(uid,status){
            //成功以后剔除对应的uid
            this.uploadProgressList[uid] = status;
            //检查是否还有未上传完毕的文件
            let check = Object.keys(this.uploadProgressList).filter(el=>{
                return this.uploadProgressList[el] == 'await'
            });

            if(check.length == 0){
                this.progressClose();
                //清空掉所有已上传文件列表
                this.$refs?.uploadFileCover?.clearFiles && this.$refs?.uploadFileCover?.clearFiles();
            }
        },
        // 自定义上传操作
        handleUpload(op) {
            let dir = `images`
            upload(
            op.file,
            res => {
                let temp = {
                name: res.attachment,
                url: res.host + '/' + res.aliyunAddress
                }
                this.host = res.host
                op.onSuccess(temp)
            },
            err => {
                console.log(err);
                op.onError(err);
            },
            res => {
                op.onProgress(res)
            },
            dir
            )
        }
    },
}