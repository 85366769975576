<template>
<div>
    <el-tag type="info">系统自动生成</el-tag>
</div>
</template>
<script>

export default {
    name:'taskNumber'


}
</script>

<style lang="scss" scoped>

</style>