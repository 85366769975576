<template>
<div class="task-score">
    任务的权重
</div>
</template>
<script>

export default {

    name:'taskWeight'


}
</script>

<style lang="scss" scoped>
</style>