<template>
  <div class="right-board">
    <div class="tab">
      <svg-icon :icon-class="componentIcon" class="tag-icon"/>
      {{ label }}
    </div>
    <div class="field-box">
      <el-scrollbar class="right-scrollbar">
        <!-- 组件属性 -->
        <el-form size="small" label-width="90px" label-position="top">
          <el-form-item v-if="activeData.__config__.label!==undefined && isFxyTips && !activeData.__config__.isGroup"
                        label="标题">
            <el-input v-model="activeData.__config__.label" placeholder="请输入标题" clearable @input="changeRenderKey" :disabled="activeData.__config__?.expandParams?.showLabel === false || activeData.__config__?.expandParams?.labelDisabled"/>
          </el-form-item>   
          <el-form-item v-if="activeData.__config__.label!==undefined && isFxyTips && !activeData.__config__.isGroup && activeData.__config__.tagIcon !='divider'">
            <el-checkbox v-model="showLabel">是否显示标题</el-checkbox>
          </el-form-item> 
          <el-form-item v-if="alertTips">
            <div class="item-alert-tips">{{ alertTips }}</div>
          </el-form-item>  
          <el-form-item v-if="dialogTipsShow" label="提示信息">
            <el-input type="textarea" placeholder="当填写提示信息后，系统组件标题会有问号图标，用户点击会弹出浮窗展示所填写的内容，不填写则不会展示问号，而输入组件则会出现提示语" :autosize="{minRows:4,maxRows:6}" v-model="dialogTips"></el-input>
          </el-form-item>
          <el-form-item v-if="descEditShow" label="提示信息详情">
            <form-edit v-model="descEdit" height="30vh"></form-edit>
          </el-form-item>
          <el-form-item v-if="activeData.__config__.tag === 'fxy-tips'" label="说明文字">
            <el-input v-model="activeData.__config__.defaultValue" placeholder="说明文字"/>
          </el-form-item>
          <el-form-item v-if="activeData.placeholder!==undefined && isFxyTips && isInput"
                        label="占位文字">
            <el-input v-model="activeData.placeholder" placeholder="请输入占位文字" @input="changeRenderKey"/>
          </el-form-item>
          <el-form-item v-if="activeData.__config__.tag === 'el-cascader'" label="选项分隔符">
            <el-input v-model="activeData.separator" placeholder="请输入选项分隔符"/>
          </el-form-item>
          <el-form-item v-if="activeData.autosize !== undefined" label="最小行数">
            <el-input-number v-model="activeData.autosize.minRows" :min="1" placeholder="最小行数"/>
          </el-form-item>
          <el-form-item v-if="activeData.autosize !== undefined" label="最大行数">
            <el-input-number v-model="activeData.autosize.maxRows" :min="1" placeholder="最大行数"/>
          </el-form-item>
          <el-form-item v-if="isShowMin" label="最小值">
            <el-input-number v-model="activeData.min" placeholder="最小值"/>
          </el-form-item>
          <el-form-item v-if="isShowMax" label="最大值">
            <el-input-number v-model="activeData.max" placeholder="最大值"/>
          </el-form-item>
          <el-form-item v-if="isShowStep" label="步长">
            <el-input-number v-model="activeData.step" placeholder="步数"/>
          </el-form-item>
          <el-form-item v-if="activeData.__config__.tag === 'el-input-number'" label="精度">
            <el-input-number v-model="activeData.precision" :min="0" placeholder="精度"/>
          </el-form-item>
          <el-form-item v-if="activeData.__config__.tag === 'el-input-number'" label="按钮位置">
            <el-radio-group v-model="activeData['controls-position']">
              <el-radio-button label="">
                默认
              </el-radio-button>
              <el-radio-button label="right">
                右侧
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="activeData['active-text'] !== undefined" label="开启提示">
            <el-input v-model="activeData['active-text']" placeholder="请输入开启提示"/>
          </el-form-item>
          <el-form-item v-if="activeData['inactive-text'] !== undefined" label="关闭提示">
            <el-input v-model="activeData['inactive-text']" placeholder="请输入关闭提示"/>
          </el-form-item>
          <el-form-item v-if="activeData['active-value'] !== undefined" label="开启值">
            <el-input
              :value="setDefaultValue(activeData['active-value'])"
              placeholder="请输入开启值"
              @input="onSwitchValueInput($event, 'active-value')"
            />
          </el-form-item>
          <el-form-item v-if="activeData['inactive-value'] !== undefined" label="关闭值">
            <el-input
              :value="setDefaultValue(activeData['inactive-value'])"
              placeholder="请输入关闭值"
              @input="onSwitchValueInput($event, 'inactive-value')"
            />
          </el-form-item>
          <el-form-item
            v-if="'el-date-picker' === activeData.__config__.tag && activeData.__config__.tagIcon === 'date'"
            label="日期类型"
          >
            <el-select
              v-model="activeData.format"
              placeholder="请选择时间类型"
              :style="{ width: '100%' }"
            >
              <el-option
                v-for="(item, index) in dateTypeOptions"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <!-- <el-form-item
            v-if="'el-time-picker' === activeData.__config__.tag"
            label="时间类型"
          >
            <el-select
              v-model="activeData.format"
              placeholder="请选择时间类型"
              :style="{ width: '100%' }"
            >
              <el-option
                v-for="(item, index) in timeTypeOptions"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item> -->
          <el-form-item v-if="activeData.accept !== undefined" label="文件类型">
            <el-select
              v-model="activeData.accept"
              placeholder="请选择文件类型"
              :style="{ width: '100%' }"
            >
              <el-option label="图片" value="image/*"/>
              <el-option label="视频" value="video/*"/>
              <el-option label="音频" value="audio/*"/>
              <el-option label="excel" value=".xls,.xlsx"/>
              <el-option label="word" value=".doc,.docx"/>
              <el-option label="pdf" value=".pdf"/>
              <el-option label="txt" value=".txt"/>
              <el-option label="其他" value="other"/>
            </el-select>
          </el-form-item>
          <el-form-item v-if="activeData.__config__.fileSize !== undefined">
            <template slot="label">
              <el-tooltip class="item" effect="dark" content="限制文件最大上传大小" placement="top-start">
                <span>文件大小</span>
              </el-tooltip>
            </template>
            <el-input v-model.number="activeData.__config__.fileSize" placeholder="请输入文件大小">
              <el-select slot="append" v-model="activeData.__config__.sizeUnit" :style="{ width: '66px' }">
                <el-option label="KB" value="KB"/>
                <el-option label="MB" value="MB"/>
                <el-option label="GB" value="GB"/>
              </el-select>
            </el-input>
          </el-form-item>
          <!--          <el-form-item v-if="activeData['range-separator'] !== undefined" label="分隔符">-->
          <!--            <el-input v-model="activeData['range-separator']" placeholder="请输入分隔符"/>-->
          <!--          </el-form-item>-->
          <el-form-item v-if="false" label="时间段">
            <el-input
              v-model="activeData['picker-options'].selectableRange"
              placeholder="请输入时间段"
            />
          </el-form-item>
          <!-- <el-form-item v-if="activeData.format !== undefined" label="时间格式">
            <el-input
              :value="activeData.format"
              placeholder="请输入时间格式"
              @input="setTimeValue($event)"
            />
          </el-form-item> -->
          <template v-if="['el-checkbox-group', 'el-radio-group', 'el-select'].indexOf(activeData.__config__.tag) > -1">
            <div class="txt">选项</div>
            <draggable
              :list="activeData.__slot__.options"
              :animation="340"
              group="selectItem"
              handle=".option-drag"
            >
              <div v-for="(item, index) in activeData.__slot__.options" :key="index" class="select-item">
                <div class="select-line-icon option-drag">
                  <i class="fxy-icon-xuanxiangtuozhuai"/>
                </div>
                <el-input v-model="item.label" placeholder="选项名" size="small"/>
                <div class="close-btn select-line-icon">
                  <i class="el-icon-delete" v-if="index > 1" @click="deleteTreeData(index)"/>
                </div>
              </div>
            </draggable>
            <div style="margin-left: 20px;margin-bottom: 20px">
              <el-button
                style="padding-bottom: 0"
                icon="el-icon-plus"
                type="text"
                @click="addSelectItem"
              >
                添加选项
              </el-button>
            </div>
          </template>

          <template v-if="['el-cascader', 'el-table'].includes(activeData.__config__.tag)">
            <el-divider>选项</el-divider>

            <!-- 级联选择静态树 -->
            <el-tree
              v-if="activeData.__config__.dataType === 'static'"
              draggable
              :data="activeData.options"
              node-key="id"
              :expand-on-click-node="false"
              :render-content="renderContent"
            />
            <div v-if="activeData.__config__.dataType === 'static'" style="margin-left: 20px">
              <el-button
                style="padding-bottom: 0"
                icon="el-icon-circle-plus-outline"
                type="text"
                @click="addTreeItem"
              >
                添加父级
              </el-button>
            </div>
            <el-divider/>
          </template>
          <el-form-item v-if="activeData.__config__.required !== undefined  && !activeData.__config__.isGroup && activeData.__config__?.expandParams?.showLabel !== false && !activeData.__config__?.expandParams?.placeholderHide && activeData.__config__?.type == 'TYPE_INPUT'" label="是否必填">
            <el-switch v-model="activeData.__config__.required"/>
          </el-form-item>
          <el-form-item label-width="0" class="form-Tooltip" v-if="activeData.__config__.tagIcon == 'upload'">
            <p class="title">上传说明：</p>
            <p>仅支持 doc, xls, ppt, pdf, docx, xlsx, pptx 共7个格式的文件在线预览</p>
          </el-form-item>
          <el-form-item v-if="activeData.__config__.tag == 'el-upload'">
            <template slot="label">
              <el-tooltip class="item" effect="dark" content="是否允许上传多个文件" placement="top-start">
                <span>是否多传</span>
              </el-tooltip>
            </template>
            <el-switch v-model="activeData.__config__.multipleImage"/>
          </el-form-item>
          <el-form-item v-if="activeData['show-score'] !== undefined" label="显示分数">
            <el-switch v-model="activeData['show-score']" @change="rateScoreChange"/>
          </el-form-item>
          <el-form-item v-if="activeData['show-stops'] !== undefined" label="显示间断点">
            <el-switch v-model="activeData['show-stops']"/>
          </el-form-item>
          <el-form-item v-if="activeData.range !== undefined" label="范围选择">
            <el-switch v-model="activeData.range" @change="rangeChange"/>
          </el-form-item>
          <el-form-item v-if="activeData.__config__.tag === 'el-color-picker'" label="颜色格式">
            <el-select
              v-model="activeData['color-format']"
              placeholder="请选择颜色格式"
              :style="{ width: '100%' }"
              clearable
              @change="colorFormatChange"
            >
              <el-option
                v-for="(item, index) in colorFormatOptions"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item v-if="activeData.__config__.tag === 'el-input-number'" label="严格步数">
            <el-switch v-model="activeData['step-strictly']"/>
          </el-form-item>   
          <el-form-item v-if="activeData.__config__.tagIcon === 'edit'" label="富文本信息">
            <form-edit v-model="activeData.__config__.defaultValue" height="40vh"></form-edit>
          </el-form-item>
          <template v-if="activeData.__config__.layoutTree">
            <el-divider>布局结构树</el-divider>
            <el-tree
              :data="[activeData.__config__]"
              :props="layoutTreeProps"
              node-key="renderKey"
              default-expand-all
              draggable
            >
              <span slot-scope="{ node, data }">
                <span class="node-label">
                  <svg-icon class="node-icon" :icon-class="data.__config__?data.__config__.tagIcon:data.tagIcon"/>
                  {{ node.label }}
                </span>
              </span>
            </el-tree>
          </template>
        </el-form>
      </el-scrollbar>
    </div>
    <treeNode-dialog :visible.sync="dialogVisible" title="添加选项" @commit="addNode"/>
    <icons-dialog :visible.sync="iconsVisible" :current="activeData[currentIconModel]" @select="setIcon"/>
  </div>
</template>

<script>
import {isArray, cloneDeep, getValues, findIndex} from '@/utils'
import TreeNodeDialog from './TreeNodeDialog'
import {isNumberStr} from '../utils/index'
import IconsDialog from './IconsDialog';
import draggable from 'vuedraggable'
import {
  inputComponents,
} from '../components/generator/config'
import {saveFormConf} from '../utils/db';
import reimbursement from "../components/mixins/reimbursement";
import accountItem from '@/components/accountItem';
import formEdit from "@/components/edit";
import uploadImage from "@/components/imageUpload/upload-image";
const dateTimeFormat = {
  date: 'yyyy-MM-dd',
  week: 'yyyy 第 WW 周',
  month: 'yyyy-MM',
  year: 'yyyy',
  datetime: 'yyyy-MM-dd HH:mm:ss',
  daterange: 'yyyy-MM-dd',
  monthrange: 'yyyy-MM',
  datetimerange: 'yyyy-MM-dd HH:mm:ss'
}

// 使changeRenderKey在目标组件改变时可用
const needRerenderList = ['tinymce']

export default {
  components: {
    TreeNodeDialog,
    IconsDialog,
    draggable,
    accountItem,
    formEdit,
    uploadImage
  },
  props: ['showField', 'activeData', 'formConf'],
  mixins: [reimbursement],
  data() {
    return {
      hideTitleItem:[],
      label: "",
      currentTab: 'field',
      currentNode: null,
      dialogVisible: false,
      iconsVisible: false,
      currentIconModel: null,
      dateTypeOptions: [
        {
          label: '年',
          value: 'yyyy'
        },
        {
          label: '年-月',
          value: 'yyyy-mm'
        },
        {
          label: '年-月-日',
          value: 'yyyy-mm-dd'
        },
        {
          label: '月-日',
          value: 'mm-dd'
        }, {
          label: '年-月-日 时：分',
          value: 'yyyy-mm-dd hh:MM'
        }, {
          label: '年-月-日 时',
          value: 'yyyy-mm-dd hh'
        }, {
          label: '月-日 时：分',
          value: 'mm-dd hh:MM'
        }, {
          label: '月-日 时',
          value: 'mm-dd hh'
        }
      ],
      dateRangeTypeOptions: [
        {
          label: '年-月-日',
          value: 'yyyy-MM-dd'
        },
        {
          label: '年-月',
          value: 'yyyy-MM'
        }
      ],
      timeTypeOptions: [
        {
          label: "时-分-秒",
          value: "hh:MM:ss"
        },
        {
          label: "时-分",
          value: "hh:MM"
        }
      ],
      colorFormatOptions: [
        {
          label: 'hex',
          value: 'hex'
        },
        {
          label: 'rgb',
          value: 'rgb'
        },
        {
          label: 'rgba',
          value: 'rgba'
        },
        {
          label: 'hsv',
          value: 'hsv'
        },
        {
          label: 'hsl',
          value: 'hsl'
        }
      ],
      justifyOptions: [
        {
          label: 'start',
          value: 'start'
        },
        {
          label: 'end',
          value: 'end'
        },
        {
          label: 'center',
          value: 'center'
        },
        {
          label: 'space-around',
          value: 'space-around'
        },
        {
          label: 'space-between',
          value: 'space-between'
        }
      ],
      layoutTreeProps: {
        label(data, node) {
          const config = data.__config__
          return data.componentName || `${config.label}: ${data.__vModel__}`
        }
      },
    }
  },
  filters: {
    limitText(val) {
      if (val === 0) return '提前提交，最晚当天';
      return val < 0 ? `提前提交,须提前${Math.abs(val)}天提交` : `可在${val}天后补交`;
    },
  },
  computed: {
    documentLink() {
      return (
        this.activeData.__config__.document
        || 'https://element.eleme.cn/#/zh-CN/component/installation'
      )
    },
    leaveOptions() {
      return this.$store.state.approve.leaveType || []
    },
    departmentList() {
      return this.$store.state.approve.departmentList || []
    },
    expenseTypeList() {
      return this.$store.state.approve.expenseTypeList || []
    },
    tagList() {
      return [
        {
          label: '输入型组件',
          options: inputComponents
        }
      ]
    },

    activeTag() {
      return this.activeData.__config__.tag
    },
    isShowMin() {
      return ['el-input-number', 'el-slider'].indexOf(this.activeTag) > -1
    },
    isShowMax() {
      return ['el-input-number', 'el-slider', 'el-rate'].indexOf(this.activeTag) > -1
    },
    isShowStep() {
      return ['el-input-number', 'el-slider'].indexOf(this.activeTag) > -1
    },
    //隐藏基本控件
    isFxyTips() {
      return this.activeData.__config__.tag != 'fxy-tips';
    },
    componentIcon() {
      return this.activeData.__config__.tagIcon || '';
    },
    showLabel:{
      get(){
        return !!this.activeData?.__config__?.expandParams?.showLabel;
      },
      set(val){
        this.$set(this.activeData.__config__.expandParams,'showLabel',val);
      }
    },
    alertTips(){
      return this.activeData?.__config__?.expandParams?.alert;
    },
    dialogTips:{
      get(){
        return this.activeData?.__config__?.expandParams?.dialogTips;
      },
      set(val){
        this.$set(this.activeData.__config__.expandParams,'dialogTips',val);
      }
    },
    descEdit:{
      get(){
        return this.activeData?.__config__?.expandParams?.descEdit;
      },
      set(val){
        this.$set(this.activeData.__config__.expandParams,'descEdit',val);
      }
    },
    dialogTipsShow(){
      return 'dialogTips' in (this.activeData?.__config__?.expandParams || {});
    },
    isInput(){
      return ['el-input'].includes(this.activeData?.__config__?.tag)
    },
    descEditShow(){
      return 'descEdit' in (this.activeData?.__config__?.expandParams || {});
    }
  },
  watch: {
    formConf: {
      handler(val) {
        saveFormConf(val)
      },
      deep: true
    },
    activeData(val) {
      //转正套件
      if (val.__config__.field === 'leaveSuite') {
        let checkOptions = (this.activeData.__config__.groupItem[this.activeData.__config__.expandParams.leaveTypeKey].__slot__?.options || []).filter((el) => {
          return el.status === 1
        })
        let leaveOptions = cloneDeep(this.leaveOptions)
        leaveOptions = leaveOptions.map((el) => {
          el.status = 0
          if (getValues(checkOptions, 'value').indexOf(el.value) > -1) {
            el.status = 1
          }
          return el
        })
        if (this.activeData.__config__.groupItem[this.activeData.__config__.expandParams.leaveTypeKey]) {
          this.activeData.__config__.groupItem[this.activeData.__config__.expandParams.leaveTypeKey].__slot__.options = leaveOptions
        }
      }
      if (['loanSuite', 'payBackSuite'].indexOf(val.__config__.field) > -1) {
        this.handleLoanType()
      }
      this.label = val?.__config__?.label ? cloneDeep(val?.__config__?.label) : '';
    }
  },
  methods: {
    handleSetAttendance() {

    },
    filterExpenseTypeList(val) {
      let expenseTypeList = cloneDeep(val)
      return expenseTypeList.filter((el) => {
        return el.group === this.activeData.__config__.field
      })
    },
    handleFormatTooltip(val) {
      if (val >= 0) {
        return '可在' + (val + 1) + '天内补交';
      }

      return '必须提前' + (val * -1) + '天提交';
    },
    //格式化限时拖拽提示
    limitFormatTooltip(val) {
      if (val === 0) return '提前提交，最晚当天';
      return val < 0 ? `提前提交,须提前${Math.abs(val)}天提交` : `可在${val}天后补交`;
    },
    addReg() {
      this.activeData.__config__.regList.push({
        pattern: '',
        message: ''
      })
    },
    addSelectItem() {
      let len = this.activeData.__slot__.options.length + 1;
      this.activeData.__slot__.options.push({
        label: '选项' + len,
        value: len
      })
    },
    addTreeItem() {
      ++this.idGlobal
      this.dialogVisible = true
      this.currentNode = this.activeData.options
    },
    renderContent(h, {node, data, store}) {
      return (
        <div class="custom-tree-node">
          <span>{node.label}</span>
          <span class="node-operation">
            <i on-click={() => this.append(data)}
               class="el-icon-plus"
               title="添加"
            ></i>
            <i on-click={() => this.remove(node, data)}
               class="el-icon-delete"
               title="删除"
            ></i>
          </span>
        </div>
      )
    },
    append(data) {
      if (!data.children) {
        this.$set(data, 'children', [])
      }
      this.dialogVisible = true
      this.currentNode = data.children
    },
    remove(node, data) {
      this.activeData.__config__.defaultValue = [] // 避免删除时报错
      const {parent} = node
      const children = parent.data.children || parent.data
      const index = children.findIndex(d => d.id === data.id)
      children.splice(index, 1)
    },
    addNode(data) {
      this.currentNode.push(data)
    },
    setOptionValue(item, val) {
      item.value = isNumberStr(val) ? +val : val
    },
    setDefaultValue(val) {
      if (Array.isArray(val)) {
        return val.join(',')
      }
      if (typeof val === 'boolean') {
        return `${val}`
      }
      return val
    },
    onDefaultValueInput(str) {
      if (isArray(this.activeData.__config__.defaultValue)) {
        // 数组
        this.$set(
          this.activeData.__config__,
          'defaultValue',
          str.split(',').map(val => (isNumberStr(val) ? +val : val))
        )
      } else if (['true', 'false'].indexOf(str) > -1) {
        // 布尔
        this.$set(this.activeData.__config__, 'defaultValue', JSON.parse(str))
      } else {
        // 字符串和数字
        this.$set(
          this.activeData.__config__,
          'defaultValue',
          isNumberStr(str) ? +str : str
        )
      }
    },
    onSwitchValueInput(val, name) {
      if (['true', 'false'].indexOf(val) > -1) {
        this.$set(this.activeData, name, JSON.parse(val))
      } else {
        this.$set(this.activeData, name, isNumberStr(val) ? +val : val)
      }
    },
    setTimeValue(val, type) {
      const valueFormat = type === 'week' ? dateTimeFormat.date : val
      this.$set(this.activeData.__config__, 'defaultValue', null)
      this.$set(this.activeData, 'value-format', valueFormat)
      this.$set(this.activeData, 'format', val)
    },
    spanChange(val) {
      this.formConf.span = val
    },
    multipleChange(val) {
      this.$set(this.activeData.__config__, 'defaultValue', val ? [] : '')
    },
    dateTypeChange(val) {
      this.setTimeValue(dateTimeFormat[val], val)
    },
    rangeChange(val) {
      this.$set(
        this.activeData.__config__,
        'defaultValue',
        val ? [this.activeData.min, this.activeData.max] : this.activeData.min
      )
    },
    rateTextChange(val) {
      if (val) this.activeData['show-score'] = false
    },
    rateScoreChange(val) {
      if (val) this.activeData['show-text'] = false
    },
    colorFormatChange(val) {
      this.activeData.__config__.defaultValue = null
      this.activeData['show-alpha'] = val.indexOf('a') > -1
      this.activeData.__config__.renderKey = +new Date() // 更新renderKey,重新渲染该组件
    },
    openIconsDialog(model) {
      this.iconsVisible = true
      this.currentIconModel = model
    },
    setIcon(val) {
      this.activeData[this.currentIconModel] = val
    },
    tagChange(tagIcon) {
      let target = inputComponents.find(item => item.__config__.tagIcon === tagIcon)
      // if (!target) target = selectComponents.find(item => item.__config__.tagIcon === tagIcon)
      this.$emit('tag-change', target)
    },
    changeRenderKey() {
      if (needRerenderList.includes(this.activeData.__config__.tag)) {
        this.activeData.__config__.renderKey = +new Date()
      }
    },

    deleteTreeData(index) {
      this.activeData.__slot__.options.splice(index, 1)
    },

    handleSalary(val, itemKey) {
      this.activeData.__config__.groupItem[itemKey].__slot__.options = val
    },
    handleAccount(val) {
      this.activeData.__config__.groupItem[this.activeData.__config__.expandParams.accountGroupKey].__slot__.options = val
    },
    handleFinance(val) {
      this.$set(this.activeData.__config__.expandParams, 'financeInitiateList', val)
    },
    handleRate(val, list) {
      let res = list.find((el) => {
        return el.id === val
      })
      return res?.field || ''
    },
    handleLoanType() {
      if (this.activeData.__config__.field === 'payBackSuite') {
        let groupItem = this.activeData.__config__.groupItem.filter((el) => {
          return ['loanCommission', 'loanBalance'].indexOf(el.__config__.field) < 0
        })
        this.$set(this.activeData.__config__, 'groupItem', groupItem)
      } else {
        if (!this.activeData.__config__.groupItem.find( (el) => {
          return el.__config__.field === 'loanCommission';
        })) {
          let index = findIndex(this.activeData.__config__.groupItem, (el) => {
            return el.__config__.field === 'bankAccount';
          });
          this.activeData.__config__.groupItem.splice(index + 1, 0, ...[{
            __config__: {
              label: '未还余额',
              labelWidth: null,
              showLabel: true,
              tag: 'el-input',
              tagIcon: 'money',
              field: 'loanBalance',
              defaultValue: undefined,
              required: true,
              layout: 'colFormItem',
              span: 24,
              regList: [],
              changeTag: true,
              expandParams: {
                disabled: true
              }
            },
            // 组件的插槽属性
            __slot__: {
              options: []
            },
            placeholder: '自动获取 ',
            style: {width: '100%'},
            readonly: false,
            disabled: true,
          }, {
            __config__: {
              label: '待付佣金',
              labelWidth: null,
              showLabel: true,
              tag: 'el-input',
              tagIcon: 'money',
              field: 'loanCommission',
              defaultValue: undefined,
              required: true,
              layout: 'colFormItem',
              span: 24,
              regList: [],
              changeTag: true,
              expandParams: {
                disabled: true
              }
            },
            // 组件的插槽属性
            __slot__: {
              options: []
            },
            placeholder: '自动获取 ',
            style: {width: '100%'},
            readonly: false,
            disabled: true,
          }])
        }
      }
      let kitPeopleType = this.expenseTypeList.filter((el) => {
        return el.group === this.activeData.__config__.field
      })

      let kitType = kitPeopleType.find((item) => {
        return this.activeData.__config__.expandParams.kitPeopleType === item.id
      })
      if (!kitType) {
        this.$set(this.activeData.__config__.expandParams, 'kitPeopleType', kitPeopleType[0].id)
      }

      if (kitType && ['employeeLoan', 'distributorLoan'].indexOf(kitType.field) > -1) {
        let groupItem = this.activeData.__config__.groupItem.map((el) => {
          if (el.__config__.field === 'loanCommission') {
            el.__config__.label = kitType.field === 'distributorLoan' ? '待付佣金' : '未结佣金'
          }
          return el
        })
        this.$set(this.activeData.__config__, 'groupItem', groupItem)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.right-scrollbar ::v-deep .el-scrollbar__view {
  padding: 12px 18px 130px 15px;
}

::v-deep .el-tabs__active-bar {
  display: none;
}

.tab {
  height: 42px;
  line-height: 42px;
  border-bottom: 1px solid #f1e8e8;
  font-size: 14px;
  padding: 0 12px;

  .tag-icon {
    margin-right: 5px;
    font-size: 18px;
  }
}

.item-alert-tips{
  background: var(--primary);
  color:#fff;
  padding: 5px 10px;
  border-radius: 4px;
  opacity: .8;
  line-height:24px;
}

.ipt {
  color:var(--primary);
}

.right-board {
  width: 500px;
  position: absolute;
  right: 0;
  top: 0;

  ::v-deep .node-operation {
    margin-left: 5px;

    i {
      padding: 0 2px;
      margin: 0 3px;
      font-size: 14px;

      &:hover {
        font-weight: bold;
      }

      &:first-child {
        color: #409eff;
      }

      &:last-child {
        color: #f56c6c;
      }
    }
  }

  ::v-deep .link-cover {

    color: #409eff;
    font-size: 13px;
    cursor: pointer;

  }

  ::v-deep .form-Tooltip {
    color: #666666;
    padding: 10px 0;

    &.sm p {
      font-size: 13px;
    }

    &.gutter p {
      padding-bottom: 15px;
    }

    &.no-margin {
      margin-bottom: 0;
    }

    &.no-padding {
      padding-bottom: 0;
    }

    p {
      line-height: 22px;

      &.title {
        padding-bottom: 10px;
      }
    }

  }

  .txt {
    margin-bottom: 10px;
  }

  .tip {
    line-height: 1.4;
    font-size: 13px;

    &.leave-tip {
      margin-top: 10px;

      p {
        padding-bottom: 10px;
      }
    }
  }

  ::v-deep .el-form-item {
    .el-form-item__label {
      padding-bottom: 0;
    }

    &.top {
      display: flex;
      flex-direction: column;

      .el-form-item__label {
        width: auto !important;
        text-align: left;

        p {
          line-height: 24px;
        }

        .desc {
          font-size: 12px;
          color: #999999;
        }
      }

      .el-form-item__content {
        margin-left: 0 !important;
      }
    }

    &.no-margin {
      margin-bottom: 0;
    }

    p.sm, span.sm {
      color: #999999;
      font-size: 13px;
    }

    &.auto {

      .el-form-item__label {
        width: auto !important;
      }

      .el-form-item__content {
        margin-left: 0 !important;
      }

    }

    .add-type-option {
      color: #409eff;
      cursor: pointer;
      opacity: .8;

      &:hover {
        opacity: 1;
      }

      i {
        padding-right: 3px;
      }
    }

    .select-cover {

      .select-item {
        display: flex;
        align-items: center;

        .reimbursement-option-delete {
          font-size: 20px;
          margin-left: 5px;
          cursor: pointer;
          color: #F56C6C;
          opacity: .7;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .limit-box {
      margin-top: 10px;
      line-height: 1.4;

      .limit-box_txt {
        margin-top: 10px;

        .limit-box_txt-switch {
          margin-top: 15px;
        }
      }

      .set {
        .title {
          color: rgba(0, 0, 0, 0.85);

          .required {
            color: #f56c6c;
            margin-right: 4px;
          }
        }

        .limit-txt {
          font-size: 12px;
          margin-top: 8px;
          color: #999999;
        }
      }
    }

    .limit-slider {
      margin-top: 10px;
      padding: 0 20px;
    }
  }

  .field-box {
    position: relative;
    height: calc(100vh - 42px);
    box-sizing: border-box;
    overflow: hidden;
  }

  .el-scrollbar {
    height: 100%;
  }

  .center-tabs ::v-deep .el-tabs__nav {
    width: 100%;

    .el-tabs__item {
      width: 50%;
      text-align: center;
    }
  }
}

.select-item {
  display: flex;
  border: 1px dashed #fff;
  box-sizing: border-box;

  & .close-btn {
    color: #f56c6c;
    width: 40px;
    text-align: right;
  }

  & .el-input + .el-input {
    margin-left: 4px;
  }

  .fxy-icon-xuanxiangtuozhuai {
    font-size: 14px;
  }
}

.select-item + .select-item {
  margin-top: 4px;
}

.select-item.sortable-chosen {
  border: 1px dashed #409eff;
}

.select-line-icon {
  line-height: 32px;
  font-size: 18px;
  padding: 0 4px;
  color: #777;
  cursor: pointer;
}

.option-drag {
  cursor: move;
}

.time-range {
  .el-date-editor {
    width: 227px;
  }

  ::v-deep .el-icon-time {
    display: none;
  }
}

.node-label {
  font-size: 14px;
}

.node-icon {
  color: #bebfc3;
}

::v-deep .el-form-item__label {
  color: rgba(0, 0, 0, 0.85);
}
</style>
