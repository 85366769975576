<template>
<div>
    <el-tag>备案任务状态</el-tag>
 </div>
</template>
<script>

export default {

    name:'filingsStatus'


}
</script>

<style lang="scss" scoped>

</style>