import request from '@/utils/request'

export function getList(data) {
  return request({
    url: '/api/approve/list',
    method: 'get',
    params: data
  })
}

export function getTaskList(data) {
  return request({
    url: '/api/task/get-indicator-task',
    method: 'get',
    params: data
  })
}

//任务管理列表
export function getTaskListByAll(data) {
  return request({
    url: `/api/task/get-task-list-by-all?page=${data.page}&pageSize=${data.pageSize}`,
    method: 'post',
    data
  })
}

export function getTaskStatusEnums(data) {
  return request({
    url: '/api/task/get-task-status-enums',
    method: 'get',
    params: data
  })
}

export function getApproveDashboard(data) {
  return request({
    url: '/api/approve/get-approve-dashboard?page=' + data.page + '&pageSize=' + data.pageSize,
    method: 'post',
    data: data
  })
}

export function approveTemplateList(data) {
  return request({
    url: '/api/approve/approve-template-list',
    method: 'post',
    data
  })
}

export function getDetail(data) {
  return request({
    url: '/api/indicator/get-template-detail',
    method: 'get',
    params: data
  })
}

/**
 * 考勤时长
 * @param {Object} data example: {startDate: '2021-08-18 15:40:00', endDate: '2021-08-20 23:59:59'}
 */
export function attendanceDuration(data) {
  return request({
    url: '/api/approve/attendance-duration',
    method: 'post',
    data
  })
}

//创建申请单
export function apply(data) {
  return request({
    url: '/api/approve/apply',
    method: 'post',
    data
  })
}

//获取申请单流程
export function getApproveWorkflow(data) {
  return request({
    url: '/api/approve/get-approve-workflow',
    method: 'post',
    data
  })
}

//获取补卡班次
export function clockClassPlan(data) {
  return request({
    url: '/api/approve/class-plan',
    method: 'post',
    data
  })
}

//通用接口数据
export function getCommonOptions(url, data, type = 'post') {

  if (type == 'get') {
    return request({
      url: url,
      method: 'get',
      params: data
    })
  } else {
    return request({
      url: url,
      method: 'post',
      data
    })
  }
}

//获取对应的薪资档案
export function getSalaryEmployeeArchives(data) {
  return request({
    url: '/api/employee/get-salary-employee-archives',
    method: 'post',
    data
  })
}

//获取调岗员工数据信息
export function getShiftJobsControl(data) {
  return request({
    url: '/api/approve/get-shift-jobs-control',
    method: 'post',
    data
  })
}

export function create(data) {
  return request({
    url: '/api/indicator/create-template',
    method: 'post',
    data: data
  })
}

export function createTask(data){
  return request({
    url: '/api/task/create-task',
    method: 'post',
    data
  })
}

export function editTask(data){
  return request({
    url: '/api/task/edit-task',
    method: 'post',
    data
  })
}

export function edit(data) {
  return request({
    url: '/api/indicator/edit-template',
    method: 'post',
    data: data
  })
}

export function editPermission(data) {
  return request({
    url: '/api/approve/edit-template-permission',
    method: 'post',
    data: data
  })
}

export function close(data) {
  return request({
    url: '/api/approve/close-approve-template',
    method: 'post',
    data: data
  })
}

export function updateTemplateGroup(data) {
  return request({
    url: '/api/approve/update-template-group',
    method: 'post',
    data: data
  })
}

export function open(data) {
  return request({
    url: '/api/approve/open-approve-template',
    method: 'post',
    data: data
  })
}

export function del(data) {
  return request({
    url: '/api/approve/del-approve-template',
    method: 'post',
    data: data
  })
}

export function getLeaveList(data) {
  return request({
    url: '/api/approve/leave-type-list',
    method: 'get',
    params: data
  })
}

export function getApplyList(data) {
  return request({
    url: '/api/approve/apply-list?page=' + data.page + '&pageSize=' + data.pageSize,
    method: 'post',
    data: data
  })
}

export function getApplyDetail(data) {
  return request({
    url: '/api/approve/apply-detail',
    method: 'get',
    params: data
  })
}

export function getFormDetail(data) {
  return request({
    url: '/api/approve/approve-template-detail',
    method: 'get',
    params: data
  })
}

export function getHandleList(data) {
  if(data?.exportBankType){
    return request({
      url: '/api/approve/get-handle-list?page=' + data.page + '&pageSize=' + data.pageSize,
      method: 'post',
      data: data,
      responseType: 'blob',
      timeout: 60000
    })
  }else{
    return request({
      url: '/api/approve/get-handle-list?page=' + data.page + '&pageSize=' + data.pageSize,
      method: 'post',
      data: data,
    })
  }
}

export function handleApprove(data) {
  return request({
    url: '/api/approve/handle-approve',
    method: 'post',
    data: data
  })
}

export function getAllTemplateList(data) {
  return request({
    url: '/api/approve/get-all-template-list',
    method: 'get',
    params: data
  })
}

export function getTemplateByAll(data) {
  return request({
    url: '/api/approve/get-template-by-group',
    method: 'get',
    params: data
  })
}

export function getStatusOptions(data) {
  return request({
    url: '/api/approve/get-status-options',
    method: 'get',
    params: data
  })
}

export function batchCheck(data) {
  return request({
    url: '/api/approve/batch-check',
    method: 'post',
    data: data
  })
}

export function getApproveCount(data) {
  return request({
    url: '/api/approve/count',
    method: 'get',
    params: data
  })
}

export function getFinanceDashboard(data) {
  return request({
    url: '/api/approve/get-finance-dashboard?page=' + data.page + '&pageSize=' + data.pageSize,
    method: 'post',
    data: data
  })
}

export function getFinanceDashboardPerformance(data) {
  return request({
    url: '/api/approve/get-finance-dashboard-performance?page=' + data.page + '&pageSize=' + data.pageSize,
    method: 'post',
    data: data
  })
}

//撤回审批单
export function approveRevoke(data) {
  return request({
    url: '/api/approve/approve-revoke',
    method: 'post',
    data: data
  })
}

//重新发起
export function approveAgainConfirm(data) {
  return request({
    url: '/api/approve/approve-again-confirm',
    method: 'post',
    data: data
  })
}

/**
 * @用途:获取工资项
 */
export function getSalaryItem(params) {
  return request({
    url: '/api/salary/get-salary-item-by-approve',
    method: 'get',
    params
  })
}

/**
 * 删除申请单
 * @param data
 * @returns {AxiosPromise}
 */
export function delApprove(data) {
  return request({
    url: '/api/approve/del-approve',
    method: 'post',
    data: data
  })
}


/**
 * 获取任务详情
 */
export function detail(data) {
  return request({
    url: '/api/task/get-detail',
    method: 'post',
    data: data
  })
}

/**
 * 删除任务
 */
export function taskDel(data) {
  return request({
    url: '/api/task/del',
    method: 'post',
    data: data
  })
}

/**
 * 获取任务详情
 */
export function check(data) {
  return request({
    url: '/api/task/check',
    method: 'post',
    data: data
  })
}

/**
 * 获取任务列表
 */
export function taskList(data) {
  return request({
    url: `/api/task/approve-list?page=${data.page}&pageSize=${data.pageSize}`,
    method: 'post',
    data: data
  })
}


/**
 * 获取任务列表
 */
export function taskWorkflow(data) {
  return request({
    url: `/api/task/workflow`,
    method: 'post',
    data: data
  })
}


/**
 * 获取全局任务模板
 */
export function getGlobalTemplate(data) {
  return request({
    url: `/api/indicator/get-app-template-detail`,
    method: 'get',
    params: data
  })
}


/**
 * 新增全局任务模板
 */
export function addGlobalTemplate(data) {
  return request({
    url: `/api/indicator/create-app-template`,
    method: 'post',
    data: data
  })
}


/**
 * 获取全局任务详情
 */
export function editGlobalTemplate(data) {
  return request({
    url: `/api/indicator/edit-app-template`,
    method: 'post',
    data: data
  })
}


/**
 * 修改评分
 */
export function taskScore(data) {
  return request({
    url: `/api/task/score`,
    method:'post',
    data
  })
}

/**
 * 保存权重
 */
export function saveWeight(data) {
  return request({
    url: `/api/task/save-weight`,
    method: 'post',
    data: data
  })
}

/**
 * 操作记录
 */
export function taskLog(data) {
  return request({
    url: `/api/task/log`,
    method: 'get',
    params: data
  })
}


/**
 * 批量修改任务状态
 */
export function batchEditField(data) {
  return request({
    url: `/api/task/batch-edit-field`,
    method: 'post',
    data
  })
}


/**
 * 批量创建任务
 */
export function batchCreate(data) {
  return request({
    url: `/api/task/batch-create`,
    method: 'post',
    data
  })
}
