<template>
    <i :class="iconClass" :style="iconStyle"></i>
</template>
<script>

export default {
    name: 'rIcon',
    props: {
        type: {
            type: String,
            default: 'el'
        },
        size: {
            type: Number | String,
            default: 20
        },
        name: {
            type: String,
            default: ''
        },
        left: {
            type: Number | String,
            default: 0
        },
        right: {
            type: Number | String,
            default: 0
        },
        top:{
            type: Number | String,
            default: 0
        },
        border: {
            type: Boolean,
            default: false
        },
        pointer: {
            type: Boolean,
            default: false
        },
        custom:{
            type:String,
            default:''
        },
        color:{
            type:String,
            default:''
        },
        circle:{
            type:Boolean,
            default:false
        },
        hover:{
            type:Boolean,
            default:false
        },
        disabled:{
            type:Boolean,
            default:false
        }
    },
    data() {
        return {
            prefix: {
                el: 'el-icon-',
                task:'fxy-icon-'
            }
        }
    },
    computed: {
        iconClass() {
            return `${this.getPrefix()}${this.name}${this.border ? ' r-icon-border' : ''}${this.pointer ? ' pointer' : ''}${this.circle ? ' circle' : ''}${this.hover ? ' r-hover-opacity' :""}${this.disabled ? ' disabled' : ''}`
        },
        iconStyle() {
            return { 'font-size': `${this.size}px`, "margin-left": `${this.left}px`, "margin-right": `${this.right}px`,"margin-top":`${this.top}px`,"color":this.color, "border-color":this.color || 'var(--theme-color)' }
        }
    },
    methods: {
        getPrefix() {
            //当不存在匹配时直接返回用户填写的名称
            return this.prefix[this.type] || this.type;
        }
    }
}
</script>

<style lang="scss" scoped>
.r-icon-border {
    border: 1px solid var(--theme-color);
    padding: 5px;
    transform: scale(.8);
    transition:color .2s,border-color .2s;
    &.circle{
        border-radius: 50%;
    }
}

.pointer {
    cursor: pointer;
}
.disabled{
    opacity: .3;
    cursor: not-allowed;
}
</style>