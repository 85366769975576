import request from '@/utils/request';

//登出
export function logout() {
  return request({
    url: `/api/login/logout`,
    method: 'post'
  })
}

/**
 * 登录
 * @param {string|number} mobile 用户手机号码
 * @param {string|number} code   验证码
 */

export function login(params) {
  return request({
    url: `/api/login/sign`,
    method: 'post',
    data: params
  })
}


//获取登录短信验证码
export function getCheckCode(params){
    return request({
      url:`/api/login/get-code`,
      method:'post',
      data:params
    })
}



/**
 * 填写用户昵称 | 注册
 * @param {String} name       传入一个用户名称
*/
export function updateUserInfo(params){
  return request({
    url:`/api/user/update-user-info`,
    method:'post',
    data:params
  })

}


/**
 * 获取用户公司列表
 */
export function getCompany(params){
  return request({
    url:`/api/user/get-company`,
    method:'get',
    params:params
  })

}

/**
 * 选择公司
 */

export function selectCompany(params){
  return request({
    url:`/api/company/select-company`,
    method:'post',
    data:params
  })
}

/**
 * 获取用户令牌
 */
export function getUserToken(params){
  return request({
    url:`/api/login/get-user-token`,
    method:'get',
    params:params
  })
}

/**
 * 获取公司审核数据详情
 */
export function getCompanyDetail(params){
  return request({
    url:`/api/company/detail`,
    method:'post',
    data:params
  })


}/**
 * 编辑公司审核数据
 */
export function upDateCompanyData(params){
  return request({
    url:`/api/company/update-company`,
    method:'post',
    data:params
  })
}

//创建公司
export function createCompany(params){
  return request({
    url:`/api/company/create-company`,
    method:'post',
    data:params
  })
}
/**
 * @用途:获取登录小程序码
 */
 export function qrCode(params){
  return request({
    url:`/api/login/build-mini-qr-code`,
    method:'get',
    data:params
  })
}
/**
 * @用途:轮询判断登录结果
 */
 export function qrCodeLogin(params){
  return request({
    url:`/api/login/mini-qr-code-login`,
    method:'post',
    data:params
  })
}

/**
 * @用途:websocket
 */
 export function getWebSocket(params){
  return request({
    url:`/api/user/get-websocket`,
    method:'get',
    params: params
  })
}

/**
 * @用途:校验安全认证
 */
 export function agentProgramBindAuth(params){
  return request({
    url:`/api/agent-program-bind/auth`,
    method:'get',
    params: params
  })
}
/**
 * @用途:编辑公司
 */
 export function editCompany(params){
  return request({
    url:`/api/company/edit-company`,
    method:'post',
    data:params
  })
}
/**
 * @用途:获取公司经营区域
 */
 export function companyBusiness(params){
  return request({
    url:`/api/company/company-business`,
    method:'get',
    params: params
  })
}
/**
 * @用途:获取验证码图片
 */
 export function verifyCode(params){
  return request({
    url:`/api/login/verify-code`,
    method:'get',
    params: params
  })
}

/**
 * @用途:短信校验
 */
 export function companySmsNotify(params){
  return request({
    url:`/api/company/sms-notify`,
    method:'post',
    data: params
  })
}