/**
 *
 * 图片上传的一些方法
 * 其中success 方法不会添加，因为这个一般需要定制化
 *  maxSize 默认为0 如果设置了的话就会启用大小检查
 */
 import bigNumber from 'bignumber.js';
 import {guid} from "@/utils";
 export default{
    data(){
        return{
            //文件类型判断
            imageMimeList:['image/gif', 'image/png', 'image/jpeg', 'image/bmp', 'image/webp', 'image/x-icon', 'image/vnd.microsoft.icon'],
            imageAccept:'.jpg, .jpeg, .png, .gif, .bmp, .JPG, .JPEG, .PNG,.GIF, .BMP',
            imageUploadApi:process.env.VUE_APP_BASE_URL+`/api/upload/index`,
            imageMaxSize:10,
            //进度条
            uploadProgressList:{},
            messageId:'',
            messageItem:''
        }

    },
    computed:{
        //需要上传的头部数据
        fileUploadToken(){
            return {
                Authorization: this.$store.state.userInfo.token || "",
                companyId: this.$store.state.userInfo.companyId || "",
            };
        }
    },
    methods: {
        /**
         * 图片上传前的检查
         */
        beforeThumbUpload(file){
            if(!file) return;
            if(file.type && this.imageMimeList.indexOf(file.type) == -1){
                global.setTimeout(()=>{
                 this.$message({
                   type: 'error',
                   message: `${file.name} 不是图片文件，请确认后重新上传！`,
                   duration:2500
                 });
                },200)
               return false;
            }
            //判断是否超出限制大小
            if(this.imageMaxSize && file.size && file.size/1024 > this.imageMaxSize * 1024){
                global.setTimeout(()=>{
                    this.$message({
                        type: 'error',
                        message: `${file.name} 大小超出限制，图片大小不能大于 ${this.imageMaxSize < 1 ? parseInt(this.imageMaxSize * 1000) + 'kb' : this.imageMaxSize + 'MB'}`,
                        duration:2500
                    });
                },200)
                return false;
            }
            //所有通过校验的元素添加一个待上传标记
            this.uploadProgressList[file.uid] = 'await'; 

            //此时进行上传处理
            if(!this.messageId){
                this.messageId = guid(20);
            }
            //检查是否存在裁切方法
            this.imageCropper && this.imageCropper(file);
            
            //不开启自动上传此处就不会执行;
            return !this.cropper
        },

        //上传失败提示
        imageUploadError(err,file){
            this.updateProgress(file.uid,'fail');
            console.log(err)
            this.$message({
                message:`${file.name}上传失败，请检查网络是否正常，或稍后重试 ${err}`,
                type:'error'
            });
        },

        //检查返回的数据
        fileUploadCheck(res){
            if(res.code != 200){
                this.$message.error(res?.message);
                //过期
                if(res.code == 401){
                    //清除所有的用户状态
                    this.$store.commit('resetUserInfo');
                    this.$router.replace({path:'/login'});
                }
                return false;
            }
            return true;
        },
        //触发进度条事件
        uploadProgress(process,file,fileList){
            this.progressInit(process,file,fileList)
        },
        /**
         * 
         * @param {*} process 进度
         * @param {*} file    当前上传的文件
         * @param {*} fileList 文件列表
         * 
         * 根据文件列表和当前文件进度计算出总的进度，当总进度为100时，清除并关闭进度条
         */
        progressInit(process,file,fileList){
            if(file.percentage == 100){
                this.$message.success('图片上传成功');
            }
            let num = bigNumber(0);
            fileList.forEach(el=>{
                num = num.plus(bigNumber(el.percentage))
            });

            num = num.div(bigNumber(fileList.length)).toFixed(2);
            //此时判断是否存在标签
            let message = document.getElementById(this.messageId);
            let messageText = document.getElementById(`${this.messageId}-message`);
            //存在就更新
            if(message){
                messageText.innerText = `共${fileList.length}张，当前进度：${num}%`;
                message.style.width = `${num}%`
            }else{
                this.messageItem = this.$notify({
                    title: '图片上传中',
                    dangerouslyUseHTMLString: true,
                    message: `<div class="notify-progress">
                        <p id="${this.messageId}-message">共${fileList.length}张</p>
                        <div class="notify-progress-block">
                            <div class="notify-progress-block-line" id="${this.messageId}">
                        </div>
                    </div>`,
                    duration: 0,
                    position:'top-right',
                    showClose:false,
                    iconClass:"el-icon-loading"
                });
            }
        },
        progressClose(){
            this.messageItem?.close && this.messageItem.close();
            let list = Object.keys(this.uploadProgressList);
            let success = 0;
            let fail = 0;
            list.forEach(el=>{
                if(this.uploadProgressList[el] == 'success'){
                    success++;
                }else if(this.uploadProgressList[el] == 'fail'){
                    fail++;
                }
            })
            this.$notify({
                title: `图片上传${list.length == success ? '全部成功' : list.length != fail ? '部分成功' : '全部失败'}`,
                message: `此次共上传${list.length}张，成功${success}张，失败${fail}张`,
                position:'top-right',
                duration:3000,
                type:list.length == fail ? 'error' : 'success'
            });
            //清空上传状态对象
            this.uploadProgressList = {};
        },
        /**
         * 针对上传进度发起更新
         * @param {Number|String} uid 上传文件的uid
         * @param {Boolean} status 当前文件的上传状态 await , success , fail
         * @param {Array} updateProgressCloseBefore 关闭前事件
         */
        updateProgress(uid,status,updateProgressCloseBefore=null){
            //成功以后剔除对应的uid
            this.uploadProgressList[uid] = status;
            //检查是否还有未上传完毕的文件
            let check = Object.keys(this.uploadProgressList).filter(el=>{
                return this.uploadProgressList[el] == 'await'
            });

            if(check.length == 0){
                //存在预处理才执行
                updateProgressCloseBefore && updateProgressCloseBefore();
                this.progressClose();
                //清空掉所有已上传文件列表
                this.$refs?.uploadImage?.clearFiles && this.$refs?.uploadImage?.clearFiles();
            }
        }
    },
}
