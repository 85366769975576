import request from '@/utils/request'

//获取角色列表
export function roleListByPage(data) {
  return request({
    url: '/api/role/list-by-page',
    method: 'get',
    params: data
  })
}

//获取权限列表
export function listByTree(data) {
  return request({
    url: '/api/rule/list-by-tree',
    method: 'get',
    params: data
  })
}

export function create(params) {
  return request({
    url: '/api/role/create',
    method: 'post',
    data: params
  })
}

export function edit(params) {
  return request({
    url: '/api/role/edit',
    method: 'post',
    data: params
  })
}

export function del(id) {
  return request({
    url: '/api/role/del?id=' + id,
    method: 'get',
  })
}

export function getList(params) {
  return request({
    url: '/api/role/list',
    method: 'get',
    params: params
  })
}

export function getEmployeeRoles(id) {
  return request({
    url: '/api/employee/get-role-by-employee?employeeId=' + id,
    method: 'get',
  })
}

