<template>
  <div>
    <div class="node-wrap" v-if="nodeConfig.type!=4">
      <div class="node-wrap-box"
           :class="(nodeConfig.type==0 || nodeConfig.type == 8?'start-node ':'')+(isTried&&nodeConfig.error?'active error':'')+ (workflowDisabled ? 'disabled' : '')">
        <div>
          <div class="title"
               :style="'background: rgb('+ ['87, 106, 149','255, 148, 62','50, 150, 250','251,96,45'][nodeConfig.type == 7 ? 1 :nodeConfig.type == 5 ? 3 :nodeConfig.type] +');'">
            <span class="iconfont" v-show="nodeConfig.type==1 || nodeConfig.type == 7"></span>
            <span class="iconfont" v-show="nodeConfig.type==2"></span>
            <span class="fxyfont fxy-icon-zhihangren-01-copy" v-show="nodeConfig.type==5"></span>
            <span v-if="nodeConfig.type==0 || nodeConfig.type == 8 || nodeConfig.type == 7">{{ nodeConfig.nodeName }}</span>
            <input type="text" class="ant-input editable-title-input" v-if="nodeConfig.type!=0 && nodeConfig.type!=8 && nodeConfig.type!=7&&isInput"
                   @blur="blurEvent()" @focus="$event.currentTarget.select()" v-focus
                   v-model="nodeConfig.nodeName" :placeholder="placeholderList[nodeConfig.type]">
            <span class="editable-title" @click="clickEvent()"
                  v-if="nodeConfig.type!=0 && nodeConfig.type!=8&& nodeConfig.type!=7&&!isInput">{{ nodeConfig.nodeName }}</span>
            <i class="anticon anticon-close close" v-if="nodeConfig.type!=0 && nodeConfig.type != 8 && nodeConfig.type != 7 && !workflowDisabled" @click="delNode()"></i>
          </div>
          <div class="content" @click="setPerson">
            <div class="text" v-if="nodeConfig.type==0">
              {{ arrToStr(flowPermission) ? arrToStr(flowPermission) : '负责人' }}
            </div> 
            <div class="text" v-if="nodeConfig.type==8">
              任务创建人
            </div>
            <div class="text" v-if="nodeConfig.type==1 || nodeConfig.type == 7">
              <span class="placeholder"
                    v-if="!setApproverStr(nodeConfig)">请选择{{ placeholderList[nodeConfig.type] }}</span>
              {{ setApproverStr(nodeConfig) }}
            </div>
            <div class="text" v-if="nodeConfig.type==2">
              <span class="placeholder" v-if="!copyerStr(nodeConfig)">请选择{{ placeholderList[nodeConfig.type] }}</span>
              {{ copyerStr(nodeConfig) }}
            </div>
            <div class="text" v-if="nodeConfig.type==5">
              <span class="placeholder" v-if="!setApproverStr(nodeConfig)">请选择{{
                  placeholderList[nodeConfig.type]
                }}</span>
              {{ setApproverStr(nodeConfig) }}
            </div>
            <i class="anticon anticon-right arrow" v-if="nodeConfig.type != 8 && nodeConfig.type != 7"></i>
          </div>
          <div class="error_tip" v-if="isTried&&nodeConfig.error">
            <i class="anticon anticon-exclamation-circle" style="color: rgb(242, 86, 67);"></i>
          </div>
        </div>
      </div>
      <addNode :childNodeP.sync="nodeConfig.childNode" :key="10" :node="nodeConfig"></addNode>
    </div>
    <div class="branch-wrap" v-if="nodeConfig.type==4">
      <div class="branch-box-wrap">
        <div class="branch-box">
          <button class="add-branch" @click="addTerm">添加条件</button>
          <div class="col-box" v-for="(item,index) in nodeConfig.conditionNodes" :key="index">
            <div class="condition-node">
              <div class="condition-node-box">
                <div class="auto-judge" :class="isTried&&item.error?'error active':''">
                  <div class="sort-left" v-if="index!=0" @click="arrTransfer(index,-1)"><i
                      class="el-icon-arrow-left"></i></div>
                  <div class="title-wrapper">
                    <input type="text" class="ant-input editable-title-input" v-if="isInputList[index]"
                           @blur="blurEvent(index)" @focus="$event.currentTarget.select()" v-focus
                           v-model="item.nodeName">
                    <span class="editable-title" @click="clickEvent(index)"
                          v-if="!isInputList[index]">{{ item.nodeName }}</span>
                    <span class="priority-title"
                          @click="setPerson(item.priorityLevel)">优先级{{ item.priorityLevel }}</span>
                    <i class="anticon anticon-close close" @click="delTerm(index)"></i>
                  </div>
                  <div class="sort-right" v-if="index!=nodeConfig.conditionNodes.length-1"
                       @click="arrTransfer(index)"><i class="el-icon-arrow-right"></i>
                  </div>
                  <div class="content" @click="setPerson(item.priorityLevel)">{{ conditionStr(item, index) }}</div>
                  <div class="error_tip" v-if="isTried&&item.error">
                    <i class="anticon anticon-exclamation-circle" style="color: rgb(242, 86, 67);"></i>
                  </div>
                </div>
                <addNode :childNodeP.sync="item.childNode" :key="11"></addNode>
              </div>
            </div>
            <nodeWrap v-if="item.childNode && item.childNode" :nodeConfig.sync="item.childNode" :tableId="tableId"
                      :isTried.sync="isTried" :directorMaxLevel="directorMaxLevel"></nodeWrap>
            <div class="top-left-cover-line" v-if="index==0"></div>
            <div class="bottom-left-cover-line" v-if="index==0"></div>
            <div class="top-right-cover-line" v-if="index==nodeConfig.conditionNodes.length-1"></div>
            <div class="bottom-right-cover-line" v-if="index==nodeConfig.conditionNodes.length-1"></div>
          </div>
        </div>
        <addNode :childNodeP.sync="nodeConfig.childNode" :key="13"></addNode>
      </div>
    </div>
    <el-drawer :append-to-body="true" title="发起人" :visible.sync="promoterDrawer" direction="rtl" class="set_promoter"
               size="650px" :before-close="savePromoter">
      <div class="demo-drawer__content">
        <div class="promoter_content drawer_content">
          <div class="promoter-box">
            <div class="title">谁可以提交</div>
            <el-checkbox-group v-model="flowPermission1">
              <el-checkbox :label="item.value" v-for="(item,key) in permission" :key="key" :disabled="item.value === 1">{{item.label}}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <el-row type="flex" class="promoter-footer" justify="end">
          <el-button @click="promoterDrawer = false" size="small">取 消</el-button>
          <el-button type="primary" @click="savePromoter" size="small">确 定</el-button>
        </el-row>
      </div>
    </el-drawer>
    <el-drawer :append-to-body="true" title="审批人设置" :visible.sync="approverDrawer" direction="rtl" class="set_promoter"
               size="650px" :before-close="saveApprover">
      <div class="demo-drawer__content">
        <div class="drawer_content">
          <div class="approver_content">
            <el-radio-group v-model="approverConfig.setType" class="clear r-w-max" @change="changeType">
              <el-radio :label="1">指定成员</el-radio>
<!--              <el-radio :label="2">父级指标负责人</el-radio>-->
<!--              <el-radio :label="3">任务负责人</el-radio>-->
<!--              <el-radio :label="4">发起人自选</el-radio>-->
<!--              <el-radio :label="5">任务协作人</el-radio>-->
<!--              <el-radio :label="6">职务</el-radio>-->
<!--              <el-radio :label="7">连续多级主管</el-radio>-->
            </el-radio-group>
            <el-button type="primary" @click="addApprover" v-if="approverConfig.setType==1" size="mini">添加/修改成员
            </el-button>
            <el-button type="primary" @click="addRoleApprover" v-if="approverConfig.setType==6" size="mini">添加/修改职务
            </el-button>
            <p class="selected_list" v-if="approverConfig.setType==1 || approverConfig.setType == 6">
                            <span v-for="(item,index) in approverConfig.nodeUserList" :key="index">{{ item.name }}
                                <img src="../assets/images/add-close1.png"
                                     @click="removeEle(approverConfig.nodeUserList,item,'targetId')">
                            </span>
              <a v-if="approverConfig.nodeUserList.length!=0" @click="approverConfig.nodeUserList=[]">清除</a>
            </p>
          </div>
<!--          <div class="approver_manager" v-if="approverConfig.setType==2 || approverConfig.setType==3">-->
<!--            <p>-->
<!--              <span>发起人的：</span>-->
<!--              <el-select v-model="approverConfig.directorLevel" style="width:400px" size="small">-->
<!--                <el-option v-for="item in directorMaxLevel" :value="item"-->
<!--                           :label="(item==1?approverConfig.setType==3?'直接':'部门':'第'+item+'级')+'主管'"-->
<!--                           :key="item"></el-option>-->
<!--              </el-select>-->
<!--            </p>-->
<!--            &lt;!&ndash;            <p class="tip" v-if="approverConfig.setType==2">找不到主管时，由上级主管代审批</p>&ndash;&gt;-->
<!--          </div>-->
          <div class="approver_self_select" v-show="approverConfig.setType==4">
            <el-radio-group v-model="approverConfig.selectMode" style="width: 100%;">
              <el-radio :label="1">选一个人</el-radio>
              <el-radio :label="2">选多个人</el-radio>
            </el-radio-group>
            <h3>选择范围</h3>
            <el-radio-group v-model="approverConfig.selectRange" style="width: 100%;" @change="changeRange">
              <el-radio :label="1">全部</el-radio>
              <el-radio :label="2">指定成员</el-radio>
<!--              <el-radio :label="3">指定职务</el-radio>-->
            </el-radio-group>
            <el-button type="primary" @click="addApprover" v-if="approverConfig.selectRange==2" size="small">添加/修改成员
            </el-button>
            <el-button type="primary" @click="addRoleApprover" v-if="approverConfig.selectRange==3" size="small">
              添加/修改职务
            </el-button>
            <p class="selected_list" v-if="approverConfig.selectRange==2||approverConfig.selectRange==3">
                            <span v-for="(item,index) in approverConfig.nodeUserList" :key="index">{{ item.name }}
                                <img src="../assets/images/add-close1.png"
                                     @click="removeEle(approverConfig.nodeUserList,item,'targetId')">
                            </span>
              <a v-if="approverConfig.nodeUserList.length!=0&&approverConfig.selectRange!=1"
                 @click="approverConfig.nodeUserList=[]">清除</a>
            </p>
          </div>
          <div class="approver_manager" v-if="approverConfig.setType==7">
            <p>审批终点</p>
            <p style="padding-bottom:20px">
              <span>发起人的：</span>
              <el-select v-model="approverConfig.examineEndDirectorLevel" style="width:400px" size="small">
                <el-option v-for="item in directorMaxLevel" :value="item"
                           :label="(item == 1 ? '直属' : '第' + item)+(item==1 ? '' : '层级')+'主管'"
                           :key="item"></el-option>
              </el-select>
            </p>
          </div>
          <div class="approver_some"
               v-if="approverConfig.setType != 7 && ((approverConfig.setType==1&&approverConfig.nodeUserList.length>1) || approverConfig.setType==7||(approverConfig.setType==6&&approverConfig.nodeUserList.length>0)||(approverConfig.setType==4&&approverConfig.selectMode==2))">
            <p>多人审批时采用的审批方式</p>
            <el-radio-group v-model="approverConfig.examineMode" class="clear">
              <el-radio :label="1" v-if="approverConfig.setType!=3 && approverConfig.setType!=6">依次审批</el-radio>
              <br/>
              <el-radio :label="2" v-if="approverConfig.setType!=3">会签（须所有审批人同意）</el-radio>
              <br/>
              <el-radio :label="3" v-if="approverConfig.setType!=3">或签（一名审批人同意或拒绝即可）</el-radio>
            </el-radio-group>
          </div>
          <div class="approver_some"
               v-if="approverConfig.setType==7||(approverConfig.setType==6&&approverConfig.nodeUserList.length>0)">
            <p>审批人为空时</p>
            <el-radio-group v-model="approverConfig.noHanderAction" class="clear">
              <el-radio :label="1">自动通过</el-radio>
              <br/>
              <el-radio :label="2">转交给审核管理员</el-radio>
            </el-radio-group>
          </div>
        </div>
        <el-row type="flex" class="promoter-footer" justify="end">
          <el-button @click="approverDrawer = false" size="small">取 消</el-button>
          <el-button type="primary" @click="saveApprover" size="small">确 定</el-button>
        </el-row>
      </div>
    </el-drawer>
    <el-drawer :append-to-body="true" title="评分人设置" :visible.sync="approverHandleVisible" direction="rtl"
               class="set_promoter"
               size="650px" :before-close="saveApprover">
      <div class="demo-drawer__content">
        <div class="drawer_content">
          <div class="approver_content">
            <el-radio-group v-model="approverConfig.setType" class="clear" @change="changeType">
<!--              <el-radio :label="1">指定成员</el-radio>-->
<!--              <el-radio :label="2">部门主管</el-radio>-->
<!--              <el-radio :label="3">直属主管</el-radio>-->
<!--              <el-radio :label="4">发起人自选</el-radio>-->
<!--              <el-radio :label="5">发起人自己</el-radio>-->
<!--              <el-radio :label="6">职务</el-radio>-->
            </el-radio-group>
            <el-button type="primary" @click="addScoreApprover" v-if="approverConfig.setType==1" size="mini">添加/修改成员
            </el-button>
            <el-button type="primary" @click="addRoleApprover" v-if="approverConfig.setType==6" size="mini">添加/修改职务
            </el-button>
            <p class="selected_list" v-if="approverConfig.setType==1 || approverConfig.setType == 6">
                            <span v-for="(item,index) in approverConfig.nodeUserList" :key="index">{{ item.name }}
                                <img src="../assets/images/add-close1.png"
                                     @click="removeEle(approverConfig.nodeUserList,item,'targetId')">
                            </span>
              <a v-if="approverConfig.nodeUserList.length!=0" @click="approverConfig.nodeUserList=[]">清除</a>
            </p>
          </div>
          <div class="approver_manager" v-if="approverConfig.setType==2 || approverConfig.setType==3">
            <p>
              <span>发起人的：</span>
              <el-select v-model="approverConfig.directorLevel" style="width:400px" size="small">
                <el-option v-for="item in directorMaxLevel" :value="item"
                           :label="(item==1?approverConfig.setType==3?'直接':'部门':'第'+item+'级')+'主管'"
                           :key="item"></el-option>
              </el-select>
            </p>
            <!--            <p class="tip" v-if="approverConfig.setType==2">找不到主管时，由上级主管代审批</p>-->
          </div>
          <div class="approver_self" v-if="approverConfig.setType==5">
            <p>该审批节点设置“发起人自己”后，执行人默认为发起人</p>
          </div>
          <div class="approver_self_select" v-show="approverConfig.setType==4">
            <el-radio-group v-model="approverConfig.selectMode" style="width: 100%;">
              <el-radio :label="1">选一个人</el-radio>
              <el-radio :label="2">选多个人</el-radio>
            </el-radio-group>
            <h3>选择范围</h3>
            <el-radio-group v-model="approverConfig.selectRange" style="width: 100%;" @change="changeRange">
              <el-radio :label="1">全部</el-radio>
              <el-radio :label="2">指定成员</el-radio>
              <el-radio :label="3">指定职务</el-radio>
            </el-radio-group>
            <el-button type="primary" @click="addApprover" v-if="approverConfig.selectRange==2" size="small">添加/修改成员
            </el-button>
            <el-button type="primary" @click="addRoleApprover" v-if="approverConfig.selectRange==3" size="small">
              添加/修改职务
            </el-button>
            <p class="selected_list" v-if="approverConfig.selectRange==2||approverConfig.selectRange==3">
                            <span v-for="(item,index) in approverConfig.nodeUserList" :key="index">{{ item.name }}
                                <img src="../assets/images/add-close1.png"
                                     @click="removeEle(approverConfig.nodeUserList,item,'targetId')">
                            </span>
              <a v-if="approverConfig.nodeUserList.length!=0&&approverConfig.selectRange!=1"
                 @click="approverConfig.nodeUserList=[]">清除</a>
            </p>
          </div>
          <div class="approver_some"
               v-if="approverConfig.setType != 7 && ((approverConfig.setType==1&&approverConfig.nodeUserList.length>1)||approverConfig.setType==2 || approverConfig.setType==7||(approverConfig.setType==6&&approverConfig.nodeUserList.length>0)||(approverConfig.setType==4&&approverConfig.selectMode==2))">
            <p>多人审批时采用的执行方式</p>
            <el-radio-group v-model="approverConfig.examineMode" class="clear">
              <el-radio :label="2" v-if="approverConfig.setType!=3">会签（须所有执行人同意）</el-radio>
              <br/>
              <el-radio :label="3" v-if="approverConfig.setType!=3">或签（一名执行人同意或拒绝即可）</el-radio>
            </el-radio-group>
          </div>
          <div class="approver_some"
               v-if="approverConfig.setType==2||approverConfig.setType==3||approverConfig.setType==7||(approverConfig.setType==6&&approverConfig.nodeUserList.length>0)">
            <p>执行人为空时</p>
            <el-radio-group v-model="approverConfig.noHanderAction" class="clear">
              <el-radio :label="1">自动通过</el-radio>
              <br/>
              <el-radio :label="2">转交给审核管理员</el-radio>
            </el-radio-group>
          </div>
        </div>
        <el-row type="flex" class="promoter-footer" justify="end">
          <el-button @click="approverHandleVisible = false" size="small">取 消</el-button>
          <el-button type="primary" @click="saveHandle" size="small">确 定</el-button>
        </el-row>
      </div>
    </el-drawer>
    <el-drawer :append-to-body="true" title="抄送人设置" :visible.sync="copyerDrawer" direction="rtl"
               class="set_copyer condition_copyer"
               size="550px" :before-close="saveCopyer">
      <div class="demo-drawer__content">
        <div class="copyer_content drawer_content">
          <el-button type="primary" @click="addCopyer" size="mini">添加成员</el-button>
          <div class="selected_list">
                <span v-for="(item,index) in copyerConfig.nodeUserList" :key="index">{{ item.name }}
                    <img src="../assets/images/add-close1.png"
                         @click="removeEle(copyerConfig.nodeUserList,item,'targetId')">
                </span>
            <a v-if="copyerConfig.nodeUserList&&copyerConfig.nodeUserList.length!=0"
               @click="copyerConfig.nodeUserList=[]">清除</a>
          </div>
<!--          <el-checkbox-group v-model="ccSelfSelectFlag" class="clear">-->
<!--            <el-checkbox :label="1">允许发起人自选抄送人</el-checkbox>-->
<!--          </el-checkbox-group>-->
        </div>
        <div class="demo-drawer__footer clear">
          <el-button type="primary" @click="saveCopyer" size="small">确 定</el-button>
          <el-button @click="copyerDrawer = false" size="small">取 消</el-button>
        </div>
      </div>
    </el-drawer>
    <el-drawer :append-to-body="true" title="条件设置" :visible.sync="conditionDrawer" direction="rtl"
               class="condition_copyer" size="650px" :before-close="saveCondition">
      <div class="lv-change">
        <el-select v-model="conditionConfig.priorityLevel" size="mini">
          <el-option v-for="item in conditionsConfig.conditionNodes.length" :label="'优先级'+item" :value="item"
                     :key="item"></el-option>
        </el-select>
      </div>
      <div class="demo-drawer__content">
        <div class="condition_content drawer_content">
          <ul>
            <li v-for="(item,index) in conditionConfig.conditionList" :key="index">
              <div class="r-flex">
                <div class="ellipsis">
                  {{ item.type == 1 ? '发起人' : item.showName }}
                  <template v-if="item.columnKey == 'duration'">(小时)</template>
                  <el-tooltip class="item" effect="dark" content="系统会根据考勤排请假开始时间与结束时间计算时长。" placement="top"
                              v-if="item.columnKey == 'duration'">
                    <i class="el-icon-warning-outline"></i>
                  </el-tooltip>
                </div>
                <div v-if="item.type==1" class="r-flex-auto-w">
                  <r-tag-select
                      v-model="conditionNodeUserList"
                      :readonly="true"
                      placeholder="请选择具体人员/职务/部门"
                      @focus="addConditionRole"
                      size="small"
                      max
                      labelKey="name">
                  </r-tag-select>
                </div>
                <div v-else-if="item.columnType == 'String' && item.showType == 3" class="r-flex-auto-w">
                  <template
                      v-if="['inductionDepartment','salaryDepartment','dealExpendDepartment','detailedDepartment','processDepartment'].indexOf(item.columnKey) > -1">
                    <r-tag-select
                        :value="item.fixedDownBoxValue | filterConditionDepartment"
                        :readonly="true"
                        :props="{ collapseTags: false,icon:'el-icon-arrow-down' }"
                        size="small"
                        placeholder="请选择具体部门"
                        @focus="addConditionDepartment('请选择具体部门', 'checkbox', item.columnKey,item.fixedDownBoxValue,0,2,false)"
                        class="fxy-select"
                    ></r-tag-select>
                  </template>
                  <template
                      v-else-if="['applyEmployee'].indexOf(item.columnKey) > -1">
                    <r-tag-select
                        :value="item.fixedDownBoxValue | filterConditionDepartment"
                        :readonly="true"
                        :props="{ collapseTags: false,icon:'el-icon-arrow-down' }"
                        size="small"
                        placeholder="请选择员工"
                        @focus="addConditionDepartment('请选择员工', 'checkbox', item.columnKey,item.fixedDownBoxValue,1,1)"
                        class="fxy-select"
                    ></r-tag-select>
                  </template>
                  <template
                      v-else-if="['processEmployee'].indexOf(item.columnKey) > -1">
                    <r-tag-select
                        :value="item.fixedDownBoxValue | filterConditionDepartment"
                        :readonly="true"
                        :props="{ collapseTags: false,icon:'el-icon-arrow-down' }"
                        size="small"
                        placeholder="请选择员工"
                        @focus="addConditionDepartment('请选择员工', 'radio', item.columnKey,item.fixedDownBoxValue,1,1,false)"
                        class="fxy-select"
                    ></r-tag-select>
                  </template>
                  <template v-else>
                    <div class="check_box">
                      <el-button type="text" class="check_btn"
                                 v-for="(item1,index1) in item.fixedDownBoxValue" :key="index1"
                                 :icon="toggleStrClass(item,item1.value)?'fxy-icon-xuanzhong1':'fxy-icon-weixuanzhong1'"
                                 @click="toStrChecked(item,item1.value)">{{ item1.label }}
                      </el-button>
                    </div>
                  </template>
                </div>
                <div class="r-flex-auto-w condition-box" v-else>
                  <div :class="item.optType!=6?'flex':'flex-select'">
                    <el-select v-model="item.optType" :style="'width:'+(item.optType==6?'100%':'140px')"
                               @change="changeOptType(item)" size="small">
                      <el-option value="1" label="小于"></el-option>
                      <el-option value="2" label="大于"></el-option>
                      <el-option value="3" label="小于等于"></el-option>
                      <el-option value="4" label="等于"></el-option>
                      <el-option value="5" label="大于等于"></el-option>
                      <el-option value="6" label="介于两个数之间"></el-option>
                    </el-select>
                    <el-input v-if="item.optType!=6" type="text" :placeholder="'请输入'+item.showName" v-enter-number="2"
                              v-model="item.zdy1" size="small" style="padding-left: 10px"></el-input>
                  </div>
                  <div v-if="item.optType==6" class="r-flex">
                    <el-input type="text" v-enter-number="2" v-model="item.zdy1" size="small"
                              class="condition-input left"></el-input>
                    <el-select v-model="item.opt1" size="small" class="condition-select">
                      <el-option value="<" label="<"></el-option>
                      <el-option value="≤" label="≤"></el-option>
                    </el-select>
                    <div class="r-flex-auto-w ellipsis">{{ item.showName }}
                      <template v-if="item.columnKey == 'duration'">(小时)</template>
                    </div>
                    <el-select v-model="item.opt2" size="small" class="condition-select">
                      <el-option value="<" label="<"></el-option>
                      <el-option value="≤" label="≤"></el-option>
                    </el-select>
                    <el-input type="text" v-enter-number="2" v-model="item.zdy2" size="small"
                              class="condition-input right"></el-input>
                  </div>
                </div>
                <el-button type="text" class="del" icon="el-icon-delete" v-if="item.type==1"
                           @click="conditionConfig.nodeUserList= [];removeEle(conditionConfig.conditionList,item,'columnKey')"></el-button>
                <el-button type="text" class="del" icon="el-icon-delete" v-if="item.type==2"
                           @click="removeEle(conditionConfig.conditionList,item,'columnKey')"></el-button>
              </div>
            </li>
          </ul>
          <el-button type="primary" @click="addCondition" size="mini" icon="el-icon-plus" class="add-condition">添加条件
          </el-button>
          <el-dialog title="选择条件" :visible.sync="conditionVisible" width="480px" append-to-body class="condition_list">
            <p>请选择用来区分审批流程的条件字段</p>
            <div class="check_box">
              <el-button type="text" class="check_btn"
                         :icon="toggleClass(conditionList,{columnKey:'default'},'columnKey')?'fxy-icon-xuanzhong1':'fxy-icon-weixuanzhong1'"
                         @click="toChecked(conditionList,{columnKey:'default'},'columnKey')">发起人
              </el-button>
              <el-button type="text" class="check_btn" v-for="(item,index) in conditions" :key="index"
                         :icon="toggleClass(conditionList,item,'columnKey')?'fxy-icon-xuanzhong1':'fxy-icon-weixuanzhong1'"
                         @click="toChecked(conditionList,item,'columnKey')">{{ item.showName }}
              </el-button>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="conditionVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="sureCondition" size="small">确 定</el-button>
            </span>
          </el-dialog>
        </div>
        <div class="demo-drawer__footer clear">
          <el-button type="primary" @click="saveCondition" size="small">确 定</el-button>
          <el-button @click="conditionDrawer = false" size="small">取 消</el-button>
        </div>
      </div>
    </el-drawer>
    <nodeWrap v-if="nodeConfig.childNode && nodeConfig.childNode" :nodeConfig.sync="nodeConfig.childNode"
              :tableId="tableId"
              :isTried.sync="isTried" :directorMaxLevel="directorMaxLevel"></nodeWrap>
    <approve-promoter-modal :config="approveModalConfig" v-model="approveModalVisible"
                            @confirm="handleConfirm"></approve-promoter-modal>
    <approve-check-modal :config="approveCheckConfig" v-model="approverVisible"
                         @confirm="sureApprover"></approve-check-modal>
    <dep-role-modal
        :config="conditionDepartmentConfig"
        v-model="conditionDepartmentVisible"
        @confirm="handleConditionDepartmentConfirm"
    ></dep-role-modal>
  </div>
</template>
<script>
import ApprovePromoterModal from '@/components/dialog/ApprovePromoterModal'
import ApproveCheckModal from '@/components/dialog/ApproveCheckModal'
import DepRoleModal from '@/components/dialog/DepRoleModal'
import workflow from "@/mixins/approve/workflow";
import {getValues} from "@/utils/arr";

export default {
  name: 'nodeWrap',
  props: ["nodeConfig", "directorMaxLevel", "isTried", "tableId"],
  mixins: [workflow],
  components: {ApprovePromoterModal, DepRoleModal, ApproveCheckModal},
  data() {
    return {
      placeholderList: ["发起人", "审核人", "抄送人", '', '', '执行人'],
      isInputList: [],
      isInput: false,
      approveModalConfig: {
        typeNav: "system",
        type: 2,
        selectType: 'checkbox',
        title: '以下部门和人员可看到审批模板',
        checkStrictly: true,
        isNoRelation: true,
        model: 'approveConfig',
        isStaff: true,
        key: 'approveModalConfig',
        checkList: [],
        isSelectSub: false
      },
      approveModalVisible: false,
      approveCheckConfig: {
        typeNav: "system",
        type: 1,
        selectType: 'checkbox',
        title: '选择成员',
        checkStrictly: true,
        model: 'approverEmplyessList',
        isStaff: true,
        checkList: []
      },
      conditionDepartmentConfig: {
        typeNav: "system",
        type: 1,
        selectType: 'checkbox',
        title: '选择成员',
        checkStrictly: true,
        model: 'conditionDepartmentConfig.checkList',
        isStaff: true,
        checkList: []
      },
      conditionDepartment: [],
      conditionDepartmentVisible: false,
      promoterDrawer: false,
      departments: {},
      checkedDepartmentList: [],
      checkedEmployessList: [],
      promoterSearchName: "",
      flowPermission1: [1],
      approverDrawer: false,
      approverVisible: false,
      approverRoleVisible: false,
      approverHandleVisible: false,
      approverConfig: {
        nodeUserList: []
      },
      handleConfig: {
        nodeUserList: []
      },
      approverEmplyessList: [],
      approverSearchName: "",
      roles: [],
      roleList: [],
      approverRoleSearchName: "",
      copyerDrawer: false,
      copyerVisible: false,
      copyerConfig: {},
      copyerSearchName: "",
      activeName: "1",
      copyerEmployessList: [],
      copyerRoleList: [],
      ccSelfSelectFlag: [],
      conditionDrawer: false,
      conditionVisible: false,
      conditionConfig: {},
      conditionsConfig: {
        conditionNodes: [],
      },
      bPriorityLevel: "",
      conditions: [],
      conditionList: [],
      conditionRoleVisible: false,
      conditionRoleSearchName: "",
      conditionDepartmentList: [],
      conditionEmployessList: [],
      conditionRoleList: [],
      permission: [
        {'label': '负责人', 'value': 1},
        {'label': '协作人', 'value': 2},
      ]
    }
  },
  computed:{
    flowPermission:{
      get(){
        return this.$store.state.approve?.form?.workflow.flowPermission;
      },
      set(val){}
    }
  },
  filters: {
    filterConditionDepartment(val) {
      return val ? getValues(val, 'label') : []
    }
  },
  mounted() {
    if (this.nodeConfig.type == 1) {
      this.nodeConfig.error = !this.setApproverStr(this.nodeConfig)
    } else if (this.nodeConfig.type == 2) {
      this.nodeConfig.error = !this.copyerStr(this.nodeConfig)
    } else if (this.nodeConfig.type == 4) {
      for (var i = 0; i < this.nodeConfig.conditionNodes.length; i++) {
        this.nodeConfig.conditionNodes[i].error = this.conditionStr(this.nodeConfig.conditionNodes[i], i) == "请设置条件" && i != this.nodeConfig.conditionNodes.length - 1
      }
    }
  },
  methods: {
    handleConfirm(val) {
      let key = val.model.split(".")
      if (key.length === 1) {
        this[val.model] = val.checkList
      } else if (key.length === 2) {
        this[key[0]][key[1]] = val.checkList
      }
      this.approveModalVisible = false
    },

    addConditionRole() {
      this.approveModalConfig.isNoRelation = true
      this.approveModalConfig.checkList = this.conditionConfig.nodeUserList
      this.approveModalConfig.model = 'conditionConfig.nodeUserList'
      this.approveModalVisible = true;
    },
    addConditionDepartment(title, selectType, value, fixedDownBoxValue, isStaff, type, isSub) {
      let department = [];
      for (let i = 0; i < fixedDownBoxValue.length; i++) {
        department.push({
          id: fixedDownBoxValue[i].value,
          name: fixedDownBoxValue[i].label,
        })
      }
      this.conditionDepartmentConfig = {
        type: type,
        selectType,
        title,
        checkStrictly: true,
        model: value,
        //是否显示包含子部门开关,只会在选择部门的时候生效
        includeSubDep: false,
        //1 打开 0 关闭
        includeSubDepSwitch: 0,
        isStaff: isStaff || 0,
        checkList: department,
        isSelectSub: !!isSub,
        onlyStaff: isStaff || 0
      };
      this.conditionDepartmentVisible = true;
    },
    handleConditionDepartmentConfirm(val) {
      this.conditionDepartmentVisible = false;
      this.conditionConfig.conditionList.forEach((el) => {
        if (el.columnKey === this.conditionDepartmentConfig.model) {
          el.zdy1 = getValues(val, 'id').join(',')
          el.fixedDownBoxValue = val.map((_el) => {
            return {label: _el.name, value: _el.id}
          });
        }
      })
    },
    addCondition() {
      this.conditionList = [];
      this.conditionVisible = true;
      let gennerator = this.$store.state.approve.form.formGennerator.filter((item) => {
        return item.__config__?.isGroup === true || item.__config__?.isGroup === 1;
      });
      let conditions = [];
      gennerator.forEach((item) => {
        let {groupItem} = item.__config__;
        groupItem.forEach((val) => {
          let {isProcess, tag, label, field} = val.__config__
          if (isProcess === 1) {
            conditions.push({
              "columnKey": field,
              "showType": tag === "el-checkbox-group" ? 3 : 1,
              "showName": label,
              "columnType": tag === "el-checkbox-group" ? "String" : "Double",
              "fixedDownBoxValue": val.__slot__?.options || []
            })
          }
        })
      })
      this.conditions = conditions;
      if (this.conditionConfig.conditionList) {
        for (var i = 0; i < this.conditionConfig.conditionList.length; i++) {
          var {columnKey} = this.conditionConfig.conditionList[i]
          if (columnKey == 'default') {
            this.conditionList.push({columnKey: 'default'})
          } else if (this.conditions.length > 0) {
            this.conditionList.push(this.conditions.filter(item => {
              return item.columnKey == columnKey;
            })[0])
          }
        }
      }
    },
    changeOptType(item) {
      if (item.optType == 1) {
        item.zdy1 = 2;
      } else {
        item.zdy1 = 1;
        item.zdy2 = 2;
      }
    },
    sureCondition() {
      //1.弹窗有，外面无+
      //2.弹窗有，外面有不变
      for (var i = 0; i < this.conditionList.length; i++) {
        var {columnKey, showName, columnName, showType, columnType, fixedDownBoxValue} = this.conditionList[i];
        if (this.toggleClass(this.conditionConfig.conditionList, this.conditionList[i], "columnKey")) {
          continue;
        }
        if (columnKey == 'default') {
          this.conditionConfig.nodeUserList == [];
          this.conditionConfig.conditionList.push({
            "type": 1,
            "columnKey": columnKey,
            "showName": '发起人'
          });
        } else {
          if (columnType == "Double") {
            this.conditionConfig.conditionList.push({
              "showType": showType,
              "columnKey": columnKey,
              "type": 2,
              "showName": showName,
              "optType": "1",
              "zdy1": "2",
              "opt1": "<",
              "zdy2": "",
              "opt2": "<",
              "columnDbname": columnName,
              "columnType": columnType,
            })
          } else if (columnType == "String" && showType == "3") {
            this.conditionConfig.conditionList.push({
              "showType": showType,
              "columnKey": columnKey,
              "type": 2,
              "showName": showName,
              "zdy1": "",
              "columnDbname": columnName,
              "columnType": columnType,
              "fixedDownBoxValue": fixedDownBoxValue
            })
          }
        }
      }
      ////3.弹窗无，外面有-
      for (let i = this.conditionConfig.conditionList.length - 1; i >= 0; i--) {
        if (!this.toggleClass(this.conditionList, this.conditionConfig.conditionList[i], "columnKey")) {
          this.conditionConfig.conditionList.splice(i, 1);
        }
      }
      this.conditionConfig.conditionList.sort(function (a, b) {
        return a.columnKey - b.columnKey;
      });
      this.conditionVisible = false;
    },
    saveCondition() {
      this.conditionDrawer = false;
      var a = this.conditionsConfig.conditionNodes.splice(this.bPriorityLevel - 1, 1)//截取旧下标
      if (a.length > 0) {
        this.conditionsConfig.conditionNodes.splice(this.conditionConfig.priorityLevel - 1, 0, a[0]);
      }
      //填充新下标
      this.conditionsConfig.conditionNodes.map((item, index) => {
        item.priorityLevel = index + 1;
      });
      for (var i = 0; i < this.conditionsConfig.conditionNodes.length; i++) {
        this.conditionsConfig.conditionNodes[i].error = this.conditionStr(this.conditionsConfig.conditionNodes[i], i) == "请设置条件" && i != this.conditionsConfig.conditionNodes.length - 1
      }
      this.$emit("update:nodeConfig", this.conditionsConfig);
    },
    addCopyer() {
      this.approveCheckConfig.typeNav = 'system'
      this.approveCheckConfig.checkList = this.copyerConfig.nodeUserList
      this.approveCheckConfig.title = "请选择抄送人"
      this.approveCheckConfig.type = 1
      this.approveCheckConfig.selectType = 'checkbox'
      this.approveCheckConfig.checkStrictly = true
      this.approveCheckConfig.model = 'copyerConfig.nodeUserList'
      this.approverVisible = true;
    },
    saveCopyer() {
      this.copyerConfig.ccSelfSelectFlag = this.ccSelfSelectFlag.length == 0 ? 0 : 1;
      this.copyerConfig.error = !this.copyerStr(this.copyerConfig);
      this.$emit("update:nodeConfig", this.copyerConfig);
      this.copyerDrawer = false;
    },
    copyerStr(nodeConfig) {
      if (nodeConfig.nodeUserList.length != 0) {
        return this.arrApproveToStr(nodeConfig.nodeUserList)
      } else {
        if (nodeConfig.ccSelfSelectFlag == 1) {
          return "请选择抄送人"
        }
      }
    },
    changeRange() {
      this.approverConfig.nodeUserList = [];
    },
    changeType(val) {
      this.approverConfig.nodeUserList = [];
      this.approverConfig.examineMode = this.approverConfig.type == 5 ? 2 : 1;
      this.approverConfig.noHanderAction = 2;
      if (val == 2) {
        this.approverConfig.directorLevel = 1;
      } else if (val == 4) {
        this.approverConfig.selectMode = 1;
        this.approverConfig.selectRange = 1;
      } else if (val == 7) {
        this.approverConfig.examineEndDirectorLevel = 1
      } else if (val == 6) {
        this.approverConfig.examineMode = 2
      }
    },
    addApprover() {
      //选择人员
      this.approveCheckConfig = Object.assign(this.approveCheckConfig, {
        title: "选择审批人员",
        checkList: this.approverConfig.nodeUserList,
        model: "approverConfig.nodeUserList",
        selectType: 'checkbox',
        type: 1,
        isStaff: 1,
      })
      this.approverVisible = true;
    },
    addScoreApprover() {
      //选择人员
      this.approveCheckConfig = Object.assign(this.approveCheckConfig, {
        title: "选择审批人员",
        checkList: this.approverConfig.nodeUserList,
        model: "approverConfig.nodeUserList",
        selectType: 'radio',
        type: 1,
        isStaff: 1,
      })
      this.approverVisible = true;
    },
    addRoleApprover() {
      this.approveCheckConfig = Object.assign(this.approveCheckConfig, {
        title: "选择审批职务",
        checkList: this.approverConfig.nodeUserList,
        model: "approverConfig.nodeUserList",
        selectType: 'radio',
        type: 3,
        isStaff: 0,
      })
      this.approverVisible = true;
    },
    sureApprover(val) {
      let key = this.approveCheckConfig.model.split(".")
      if (key.length === 1) {
        this[this.approveCheckConfig.model] = val
      } else if (key.length === 2) {
        this[key[0]][key[1]] = val
      }
      this.approverVisible = false
    },
    setApproverStr(nodeConfig) {
      if (nodeConfig.setType == 1 || nodeConfig.type == 5) {
        if (nodeConfig.nodeUserList.length == 1) {
          return nodeConfig.nodeUserList[0].name
        } else if (nodeConfig.nodeUserList.length > 1) {
          if (nodeConfig.examineMode == 1) {
            return this.arrApproveToStr(nodeConfig.nodeUserList)
          } else if (nodeConfig.examineMode == 2) {
            return nodeConfig.nodeUserList.length + "人会签"
          } else if (nodeConfig.examineMode == 3) {
            return nodeConfig.nodeUserList.length + "人或签"
          }
        }
      } else if (nodeConfig.setType == 2 || nodeConfig.setType == 3) {
        let level = nodeConfig.directorLevel == 1 ? (nodeConfig.setType == 2 ? '父级指标负责人' : '任务负责人') : '第' + nodeConfig.directorLevel + '级' + (nodeConfig.setType == 2 ? '部门主管' : '直属主管')
        if (nodeConfig.examineMode == 1) {
          return level
        } else if (nodeConfig.examineMode == 2) {
          return level + "会签"
        } else if (nodeConfig.examineMode == 3) {
          return level + "或签"
        }
      } else if (nodeConfig.setType == 4) {
        if (nodeConfig.selectRange == 1) {
          return "发起人自选"
        } else {
          if (nodeConfig.nodeUserList.length > 0) {
            if (nodeConfig.selectRange == 2) {
              return "发起人自选"
            } else {
              return '发起人从' + nodeConfig.nodeUserList[0].name + '中自选'
            }
          } else {
            return "";
          }
        }
      } else if (nodeConfig.setType == 5) {
        return "任务协作人"
      } else if (nodeConfig.setType == 6) {
        if (nodeConfig.examineMode == 2) {
          return nodeConfig.nodeUserList[0].name + "人会签"
        } else if (nodeConfig.examineMode == 3) {
          return nodeConfig.nodeUserList[0].name + "人或签"
        }
      } else if (nodeConfig.setType == 7) {
        return '从直接主管到通讯录中级别最高的第' + nodeConfig.examineEndDirectorLevel + '个层级主管'
      }
    },
    saveApprover() {
      this.approverConfig.error = !this.setApproverStr(this.approverConfig)
      this.$emit("update:nodeConfig", this.approverConfig);
      this.approverDrawer = false;
    },
    saveHandle() {
      this.approverConfig.error = !this.setApproverStr(this.approverConfig)
      this.$emit("update:nodeConfig", this.approverConfig);
      this.approverHandleVisible = false;
    },
    addPromoter() {
      this.approveModalConfig.typeNav = 'system'
      this.approveModalConfig.checkList = this.flowPermission1
      this.approveModalConfig.title = "以下部门和人员可看到审批模板"
      this.approveModalConfig.model = 'flowPermission1'
      this.approveModalVisible = true;
    },
    savePromoter() {
      this.$store.commit('approveSetData',{
        'form.workflow.flowPermission':this.flowPermission1
      })
      this.promoterDrawer = false;
    },
    toggleStrClass(item, key) {
      let a = item.zdy1 ? item.zdy1.split(",") : []
      return a.some(item => {
        return item == key
      });
    },
    toStrChecked(item, key) {
      let a = item.zdy1 ? item.zdy1.split(",") : []
      var isIncludes = this.toggleStrClass(item, key);
      if (!isIncludes) {
        a.push(key)
        item.zdy1 = a.toString()
      } else {
        this.removeStrEle(item, key);
      }
    },
    removeStrEle(item, key) {
      let a = item.zdy1 ? item.zdy1.split(",") : []
      var includesIndex;
      a.map((item, index) => {
        if (item == key) {
          includesIndex = index
        }
      });
      a.splice(includesIndex, 1);
      item.zdy1 = a.toString()
    },
    toggleClass(arr, elem, key = 'id') {
      return arr.some(item => {
        return item[key] == elem[key]
      });
    },
    toChecked(arr, elem, key = 'id') {
      var isIncludes = this.toggleClass(arr, elem, key);
      !isIncludes ? arr.push(elem) : this.removeEle(arr, elem, key);
    },
    removeEle(arr, elem, key = 'id') {
      var includesIndex;
      arr.map((item, index) => {
        if (item[key] == elem[key]) {
          includesIndex = index
        }
      });
      arr.splice(includesIndex, 1);
    },
    delNode() {
      this.$emit("update:nodeConfig", this.nodeConfig.childNode);
    },
    addTerm() {
      let len = this.nodeConfig.conditionNodes.length + 1
      this.nodeConfig.conditionNodes.push({
        "nodeName": "条件" + len,
        "type": 3,
        "priorityLevel": len,
        "conditionList": [],
        "nodeUserList": [],
        "childNode": null
      });
      for (var i = 0; i < this.nodeConfig.conditionNodes.length; i++) {
        this.nodeConfig.conditionNodes[i].error = this.conditionStr(this.nodeConfig.conditionNodes[i], i) == "请设置条件" && i != this.nodeConfig.conditionNodes.length - 1
      }
      this.$emit("update:nodeConfig", this.nodeConfig);
    },
    delTerm(index) {
      this.nodeConfig.conditionNodes.splice(index, 1);
      for (var i = 0; i < this.nodeConfig.conditionNodes.length; i++) {
        //刷新一下名称
        this.nodeConfig.conditionNodes[i].nodeName = "条件" + (i + 1);
        //刷新一下优先级
        this.nodeConfig.conditionNodes[i].priorityLevel = i + 1;
        this.nodeConfig.conditionNodes[i].error = this.conditionStr(this.nodeConfig.conditionNodes[i], i) == "请设置条件" && i != this.nodeConfig.conditionNodes.length - 1;
      }


      this.$emit("update:nodeConfig", this.nodeConfig);
      if (this.nodeConfig.conditionNodes.length == 1) {
        if (this.nodeConfig.childNode) {
          if (this.nodeConfig.conditionNodes[0].childNode) {
            this.reData(this.nodeConfig.conditionNodes[0].childNode, this.nodeConfig.childNode)
          } else {
            this.nodeConfig.conditionNodes[0].childNode = this.nodeConfig.childNode
          }
        }
        this.$emit("update:nodeConfig", this.nodeConfig.conditionNodes[0].childNode);
      }
    },
    reData(data, addData) {
      if (!data.childNode) {
        data.childNode = addData
      } else {
        this.reData(data.childNode, addData)
      }
    },
    setPerson(priorityLevel) {
      if(this.workflowDisabled) return;
      let {type} = this.nodeConfig;
      if(type == 8 || type == 7) return;
      if (type == 0) {
        this.promoterDrawer = true;
        this.flowPermission1 = this.flowPermission || this.$store.state.approve?.form?.workflow.flowPermission;
      } else if (type == 1) {
        this.approverDrawer = true;
        this.approverConfig = JSON.parse(JSON.stringify(this.nodeConfig))
        this.approverConfig.setType = this.approverConfig.setType ? this.approverConfig.setType : 1
      } else if (type == 2) {
        this.copyerDrawer = true;
        this.copyerConfig = JSON.parse(JSON.stringify(this.nodeConfig))
        this.ccSelfSelectFlag = this.copyerConfig.ccSelfSelectFlag == 0 ? [] : [this.copyerConfig.ccSelfSelectFlag]
      } else if (type == 5) {
        this.approverHandleVisible = true
        this.approverConfig = JSON.parse(JSON.stringify(this.nodeConfig))
        this.approverConfig.setType = this.approverConfig.setType ? this.approverConfig.setType : 1
      } else {
        this.conditionDrawer = true
        this.bPriorityLevel = priorityLevel;
        this.conditionsConfig = JSON.parse(JSON.stringify(this.nodeConfig))
        this.conditionConfig = this.conditionsConfig?.conditionNodes[priorityLevel - 1] || this.conditionsConfig?.conditionNodes[this.conditionsConfig?.conditionNodes?.length - 1];
      }
    },
    arrTransfer(index, type = 1) {//向左-1,向右1
      this.nodeConfig.conditionNodes[index] = this.nodeConfig.conditionNodes.splice(index + type, 1, this.nodeConfig.conditionNodes[index])[0];
      this.nodeConfig.conditionNodes.map((item, index) => {
        item.priorityLevel = index + 1
      })
      for (var i = 0; i < this.nodeConfig.conditionNodes.length; i++) {
        this.nodeConfig.conditionNodes[i].error = this.conditionStr(this.nodeConfig.conditionNodes[i], i) == "请设置条件" && i != this.nodeConfig.conditionNodes.length - 1
      }
      this.$emit("update:nodeConfig", this.nodeConfig);
    }
  }
}
</script>
<style scoped lang="scss">
@import "../css/node-wrap";

.el-icon-warning-outline {
  vertical-align: middle;
  margin-left: 2px;
  cursor: pointer;
}

</style>
