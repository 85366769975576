<template>
    <r-drawer ref="rDialog" :dialogTitle="title" size="600px" @close="reset" @submit="submit">
        <div class="dialog-content">
            <el-form size="small" label-position="top" :rules="rules" :model="form" ref="form">
                <el-form-item prop="pid" label="上级分类">
                   <el-cascader
                    v-model="form.pid"
                    :options="options" placeholder="请选择上级分类" class="r-w-max" :props="{emitPath:true,value:'id',label:'title',checkStrictly:true}" @change="cChange"></el-cascader>
                </el-form-item>    
                 <el-form-item prop="title" label="分类名称">
                   <el-input v-model="form.title" placeholder="请输入分类名称"></el-input>
                </el-form-item>
            </el-form>
        </div>
    </r-drawer>
</template>
<script>
import {questionCategorySave,questionCategoryList} from "@/api/question";
import { cloneDeep,isArray } from "@/utils";
const defaultForm = {
    pid:'',
    title:''
}
export default {
    data() {
        return {
            form: cloneDeep(defaultForm),
            title:'',
            rules:{
                title:[
                    { required: true, message: '请输入分类名称', trigger: ['change','blur'] }
                ]
            },
            options:[]
        }
    },
    methods: {
        open(form=null,title='新建问题分类') {
            this.title = title;
            if(form){
                form.pid = form.pid == '00000000-0000-0000-0000-000000000000' ? '' : form.pid;
                this.form = Object.assign(cloneDeep(defaultForm),form);
            }
            this.getList();
            this.$refs.rDialog.handleOpen();
        },
        getList(){
            questionCategoryList().then(res => {
                this.options = res.data;
            })
        },
        reset(){
            this.form = cloneDeep(defaultForm);
        },
        submit(done){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let form = cloneDeep(this.form);
                    if(isArray(form.pid)){
                        form.pid = form.pid[form.pid.length-1];
                    }
                    questionCategorySave(form).then(res=>{
                        this.$message.success('提交成功');
                        done(true);
                        this.$emit('confirm');
                    })
                    .catch(err=>{
                        done(false);
                    })
                } else {
                    done(false);
                }
            });
        },
        cChange(val){
            console.log(val)
        }
    }



}
</script>

<style lang="scss" scoped>
.dialog-content {
    height: 250px;
}
</style>