<template>
    <div class="r-drawer">
        <el-drawer :title="dialogTitle" :visible.sync="show" @close="close" @closed="reset" :size="size" @opened="opened" :direction="direction" :destroy-on-close="true" :close-on-press-escape="!loading" :wrapperClosable="false" append-to-body modal-append-to-body :class="{'header-hide':hideHeader,'header-ipt':headerIpt}">
            <template v-slot:title v-if="$scopedSlots['title']">
                <div class="r-drawer-header r-flex-auto-w">
                    <slot name="title"></slot>
                </div>
            </template>
            <div class="r-drawer-wrapper r-flex r-flex-column" v-loading="dialogLoading" element-loading-text="拼命加载中..."
        element-loading-background="rgba(255,255, 255, 0.8)">
                <div class="r-drawer-scroll-view r-flex-auto-h" :class="{'footer-hide':!footer,'scroll-none':!isScroll,'vertical-none':verticalNone}" ref="scrollView">
                    <slot></slot>
                </div>
                <div class="r-drawer-footer" v-if="footer">
                    <slot name="footer" v-if="$scopedSlots['footer']">
                    </slot>
                    <template v-else>
                        <el-button size="small" @click="close" :disabled="loading">取 消
                        </el-button>
                        <el-button type="primary" @click="onSubmit" :disabled="loading" :loading="loading"
                            size="small">
                            提 交
                        </el-button>
                    </template>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
export default {
    name:"rDrawer",
    props:{
        dialogTitle:{
            type:String,
            default:'标题'
        },
        size:{
            type:String,
            default:'800px'
        },
        footer:{
            type:Boolean,
            default:true
        },
        hideHeader:{
            type:Boolean,
            default:false
        },
        isScroll:{
            type:Boolean,
            default:true
        },
        dialogLoading:{
            type:Boolean,
            default:false
        },
        direction:{
            type:String,
            default:'rtl'
        },
        verticalNone:{
            type:Boolean,
            default:false
        },
        headerIpt:{
            type:Boolean,
            default:false
        }
    },
    data() {
        return {
            show: false,
            loading:false
        }
    },
    methods: {
        handleOpen() {
            this.show = true;
        },
        close() { this.show = false; },
        reset() {
            this.$emit('close');
            this.loading=false;
        },
        onSubmit() {
            this.loading=true;
            this.$emit('submit',(close = false)=>{
                this.loading = false;
                if(close){
                    this.close();
                }
            })
        },
        opened(){
            this.$emit('opened');
        },
        scrollTop(){
            this.$refs.scrollView.scrollTop = 0;
        }
    }

}
</script>

<style lang="scss" scoped>
::v-deep .r-drawer {
    &-wrapper{
        height:100%;
    }
    &-scroll-view {
        flex: 1 1 auto;
        height: 0;
        overflow: hidden;
        overflow-y: auto;
        padding: 20px;
        padding-bottom: 80px;
        width: 100%;
        &.footer-hide{
            padding-bottom:20px;
        }
        &.scroll-none{
            padding-bottom:20px;
            overflow: hidden;
            overflow-y: hidden;
        }
        &.vertical-none{
            padding: 0 20px;
        }
    }

    &-footer {
        width: 100%;
        padding: 20px;
        border-top: 1px solid #ebeef5;
        background-color: #ffffff;
        text-align: right;
    }

}
</style>