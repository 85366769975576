<template>
    <r-drawer ref="rDialog" :dialogTitle="title" size="600px" @close="reset" @submit="submit">
        <div class="dialog-content">
            <el-form size="small" label-position="top" :rules="rules" :model="form" ref="form">
                <el-form-item prop="title" label="名称">
                   <el-input v-model="form.title" placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item prop="contact" label="联系方式">
                  <el-input v-model="form.contact" placeholder="请输入联系方式"></el-input>
                </el-form-item>
                <el-form-item label="是否内部">
                    <el-switch
                    :active-value="1"
                    :inactive-value="2"
                    v-model="form.type"
                    active-text="是"
                    inactive-text="否">
                    </el-switch>
                </el-form-item>  
                <el-form-item label="备注">
                   <el-input type="textarea" v-model="form.description" placeholder="请输入备注" :autosize="{ minRows: 6, maxRows: 10}"></el-input>
                </el-form-item>
            </el-form>
        </div>
    </r-drawer>
</template>
<script>
import {contractPartySave} from "@/api/contract";
import { cloneDeep,isArray } from "@/utils";
const defaultForm = {
    title:'',
    contact: '',
    description:'',
    type:1
}
export default {
    data() {
        return {
            form: cloneDeep(defaultForm),
            title:'',
            rules:{
                title:[
                    { required: true, message: '请输入名称', trigger: ['change','blur'] }
                ],
                // contact:[
                //     { required: true, message: '请输联系方式', trigger: ['change','blur'] }
                // ],
            }
        }
    },
    methods: {
        open(form=null,title='新建参与方') {
            this.title = title;
            if(form){
                this.form = Object.assign(cloneDeep(defaultForm),form);
            }
            this.$refs.rDialog.handleOpen();
        },
        reset(){
            this.form = cloneDeep(defaultForm);
        },
        submit(done){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let form = cloneDeep(this.form);
                    contractPartySave(form).then(res=>{
                        this.$message.success('提交成功');
                        done(true);
                        this.$emit('confirm');
                    })
                    .catch(err=>{
                        done(false);
                    })
                } else {
                    done(false);
                }
            });
        },
        cChange(val){
            console.log(val)
        }
    }



}
</script>

<style lang="scss" scoped>
.dialog-content {
    height: 250px;
}
</style>