<template>
    <div class="question-detail">
        <r-drawer size="1200px" ref="dialog" @opened="init" :footer="false" @close="reset" :hideHeader="true"
            :isScroll="false" :verticalNone="true" :dialogLoading="loading">
            <div class="question-detail-wrapper r-flex r-col-top">
                <div class="question-detail-content r-flex-1 r-border-right r-flex r-flex-column">
                    <div class="r-flex-auto-h r-w-max">
                        <div class="scroll-view">
                            <div class="question-detail-content_title">
                                <span class="question-detail-content_title-tag" v-if="statusStr" :style="{color:statusColors[status],borderColor:statusColors[status]}">{{ statusStr }}</span>
                                <span class="question-detail-content_title-text">{{ title }}</span>
                            </div>
                            <div class="question-detail-content_banner" v-if="files.length">
                                <el-carousel>
                                    <el-carousel-item v-for="(item,index) in files" :key="index">
                                        <div class="question-detail-content_banner-item" @click="previewImage(files,index)">
                                            <img :src="item.path|$staticFilter" alt="">
                                        </div>
                                    </el-carousel-item>
                                </el-carousel>
                            </div>
                            <div class="question-detail-content_content">
                                {{ description }}
                            </div>
                            <div class="question-detail-content_address r-flex r-col-top" v-if="address">
                                <div class="question-detail-content_address_icon"><r-icon name="location-information"
                                        left="0" right="5" size="18" color="var(--primary)"></r-icon></div>
                                <div class="question-detail-content_address_text">{{ address }}</div>
                            </div>
                            <div class="question-detail-content_info">
                                <div class="question-detail-content_info_row r-flex r-row-bet">
                                    <div class="question-detail-content_info_row-item">{{employeeDepartmentName}}{{ employeeName }}</div>
                                    <div class="question-detail-content_info_row-item">创建于 {{ createdAt }}</div>
                                </div>
                            </div>
                            <div class="question-detail-content_info">
                                <div class="question-detail-content_info_row r-flex r-row-bet">
                                    <div class="question-detail-content_info_row-item u-line-1">负责部门：{{ responsibleDepartmentName }}</div>
                                    <div class="question-detail-content_info_row-item">负责人：<span
                                            class="main-color no-wrap">{{ responsibleEmployeeName }}</span></div>
                                </div>
                            </div>       
                            <div class="question-detail-content_info">
                                <div class="question-detail-content_info_row r-flex r-row-bet">
                                    <div class="question-detail-content_info_row-item u-line-1">村庄：{{ villageName }}</div>
                                </div>  
                                <div class="question-detail-content_info_row r-flex r-row-bet">
                                    <div class="question-detail-content_info_row-item">问题分类：<span class="main-color no-wrap">{{ categoryName }}</span></div>
                                </div>
                            </div>
                            <div class="question-detail-content_info">
                                <div class="question-detail-content_info_row r-flex r-row-bet">
                                    <div class="question-detail-content_info_row-item">整改期限至： <span
                                            class="content-color">{{ startTime }} ~ {{ endTime }}</span></div>
                                    <div class="question-detail-content_info_row-item" v-if="transferCount">已转交：<span
                                            class="danger-color num">{{ transferCount }}</span> 次</div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="question-detail-content_toolbar r-border-top r-w-max" v-if="permission.approval && isApproval">
                        <div class="question-detail-content_toolbar_cover r-flex r-row-right">
                            <el-button round type="success" @click="check(5)">同意</el-button>
                            <el-button round type="danger"  @click="check(6)">拒绝</el-button>
                        </div>
                    </div>
                </div>
                <div class="question-detail-record r-flex-1 r-flex r-flex-column">
                    <div class="question-detail-record_title r-flex r-row-bet r-w-max r-pb-20">
                        <div class="question-detail-record_title-text">整改记录<span class="r-pl-10" v-if="total">{{ total }}</span></div>
                        <div class="question-detail-record_title-close hover--opacity" @click="close">
                            <r-icon name="close" size="32"></r-icon>
                        </div>
                    </div>
                    <div class="scroll-view r-flex-auto-h r-w-max">
                        <div class="r-tips" v-if="logList.length == 0">暂无整改记录</div>
                        <div class="question-detail-record_item r-flex r-col-top" v-for="(item,index) in logList" :key="item.id" :class="{'r-pt-10':index > 0}">
                            <div class="question-detail-record_item-avatar r-avatar-mini">
                                <img :src="item.employeeAvatar|$imageUrlAvatar" alt="">
                            </div>
                            <div class="question-detail-record_item-content r-ml-15 r-flex-auto-w r-pb-10" :class="{'r-border-bottom':index != logList.length-1}">
                                <div class="question-detail-record_item-content_user-info r-flex">
                                    <div class="question-detail-record_item-content_user-info-dep tips-color r-line-1">{{ item.departmentName }}</div>
                                    <div class="question-detail-record_item-content_user-info-name r-pl-5 tips-color np-wrap">{{ item.employeeName }}</div>
                                </div>
                                <div class="question-detail-record_item-content_operation r-pt-5" :style="{color:item.type == 5 ? 'var(--success)' : item.type == 6 ? 'var(--danger)' : 'var(--primary)'}" v-if="item.operateDesc">
                                    {{ item.operateDesc }}
                                </div>   
                                <div class="question-detail-record_item-content_content r-pt-5" v-if="item.content">
                                    {{ item.content }}
                                </div>
                                <div class="question-detail-record_item-content_thumb r-flex r-flex-wrap r-pt-10" v-if="item.files.length">
                                    <div class="question-detail-record_item-content_thumb-item" v-for="(thumb,_index) in item.files" :key="_index" @click="previewImage(item.files,_index)">
                                        <img :src="thumb.path|$staticFilter" alt="">
                                    </div>
                                </div>
                                <div class="question-detail-record_item-content_time tips-color r-pt-5">
                                    {{ item.createdAt }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </r-drawer>
        <image-show v-model="showImage" :imageList="imageList" :imageIndex="imageIndex" :viewerLoop="true"></image-show>
        <check ref="check" @confirm="init(true)"></check>
    </div>
</template>
<script>
import {detail,questionLogList,questionCategoryList} from "@/api/question";
import imageShow from "@/components/imageShow";
import check from "./check";
import {readNodes} from "@/utils";
export default {
    components:{imageShow,check},
    data(){
        return {
            loading:true,
            id:'',
            detailData:{},
            statusColors:{
                1:'#AEAEAE',
                2:'#1D7FFE',
                4:'#E48000',
                5:'#1E7E00',
                6:'#FF0000'
            },
            logList:[],
            total:0,
            imageIndex:0,
            imageList:[],
            showImage:false,
            isApproval:false
        }
    },
    computed:{
        title(){
            return this.detailData?.title || '';
        },
        statusStr(){
            return this.detailData?.statusStr || '';
        },
        status(){
            return this.detailData?.status || '';
        },
        files(){
            return this.detailData?.files || [];    
        },
        address(){
            return this.detailData?.address || ''
        },
        employeeName(){
            return this.detailData?.employeeName || ''
        },
        employeeDepartmentName(){
            return this.detailData?.employeeDepartmentName || ''
        },
        description(){
            return this.detailData?.description || ''
        },
        createdAt(){
            return this.detailData?.createdAt || ''
        },
        responsibleDepartmentName(){
            return this.detailData?.responsibleDepartmentName || ''
        },
        responsibleEmployeeName(){
            return this.detailData?.responsibleEmployeeName || ''
        },
        startTime(){
            return this.detailData?.startTime || ''
        },
        endTime(){
            return this.detailData?.endTime || ''
        },
        transferCount(){
            return this.detailData?.transferCount || ''
        },
        permission(){
            return this.detailData?.permission || {}
        },
        villageName(){
            return this.detailData?.villageName || '';
        },
        categoryName(){
            return this.detailData?.category?.length > 0 ?  this.detailData?.category.map(el=>el.title).join('-') : this.detailData?.categoryName || '';
        }
    },
    methods: {
        open(row,isApproval) {
            this.isApproval = !!isApproval;
            this.id = row.id;
            this.$refs.dialog.handleOpen()
        },
        close(){
            this.$refs.dialog.close();
        },
        init(isUpdate = false) {
            this.getDetail();
            this.questionLogList();
            if(isUpdate){
                this.$emit('update');
            }
        },
        getDetail(){
            detail({id:this.id,isApproved:!!this.isApproval ? 1 : 0}).then(res=>{
                this.detailData = res.data;
                this.getCategoryTree();
            })
            .catch(err=>{
                console.log(err)
            })
            .finally(()=>{
                this.loading = false;
            })
        },
        questionLogList(){
            questionLogList({questionId:this.id,page:1,pageSize:9999}).then(res=>{
                this.logList = res.data.list;
                this.total = res.data.total;
            })
        },
        reset() {
            this.detailData = {};
            this.loading = true;
            this.id = '';
            this.total = 0;
            this.logList = [];
        },
        previewImage(files,index){
            this.imageList = files.map(item=>this.$getStaticUrl(item.path));
            this.imageIndex = index;

            this.$nextTick(()=>{
                this.showImage = true;
            })
        },
        check(type){
            this.$refs.check.open(type,this.id);
        },
        getCategoryTree(){
            if(!this.detailData.categoryId || this.detailData.categoryId == '00000000-0000-0000-0000-000000000000') return;
            questionCategoryList().then(res=>{
                this.$set(this.detailData,'category',this.getParent(res.data));
                if(this.detailData.category.length == 0){
                    this.$set(this.detailData,'category',[{title:this.detailData.categoryName,id:this.detailData.categoryId}]);
                }
            })
            .finally(()=>{})
        },
        //获取父级
        getParent(data){
            //平铺树
            let nodes = readNodes(data,(val)=>val,[]);
            let category = nodes.find(node=>node.id == this.detailData.categoryId);
            let list = [];
            if(!category){
                return [];
            }
            list.push(category);


            const traverse = (item)=> {
                if(item.pid == '00000000-0000-0000-0000-000000000000') return;
                let parent = nodes.find(node=>node.id == item.pid);
                if(parent){
                    list.unshift(parent);
                    traverse(parent);
                }
            };
            traverse(category);
            return list;
        },
    }


}
</script>

<style lang="scss" scoped>
.question-detail-wrapper {
    height: 100%;

    .question-detail-content {
        height: 100%;
        position: relative;

        &_title {
            padding-bottom:20px;
            &-tag {
                padding: 5px 15px;
                font-size: 13px;
                border: 1px solid #AEAEAE;
                color: #AEAEAE;
                border-radius: 33px;
                vertical-align: middle;
            }

            &-text {
                font-size: 18px;
                padding-left: 10px;
                vertical-align: middle;
                font-weight: bold;
            }
        }

        &_banner{
            height:300px;
            &-item{
                height:300px;
                width: 100%;
                cursor: pointer;
                img{
                    width: 100%;
                    height:100%;
                    object-fit: cover;
                }
            }
        }

        &_content {
            padding: 15px 0 0;
            line-height: 28px;
            font-size: 16px;
            color: #666666;
        }

        &_address {
            padding-top: 10px;
            font-size: 14px;
            color: #999999;
        }

        &_info {
            &_row {
                padding-top: 10px;

                &-item {
                    color: #999999;

                    .num {
                        font-size: 18px;
                    }
                }
            }
        }
        .scroll-view{
            padding-top: 10px;
            padding-right:20px;
        }
        &_toolbar {
            width: 100%;
            padding: 10px 0;
            padding-right:20px;
        }
    }
    .question-detail-record{
        height: 100%;
        padding-top:10px;
        padding-left:20px;
        &_title{
            &-text{
                font-size:18px;
                font-weight: bold;
            }
        }
        &_item{
            &-content{
                font-size:16px;
                &_thumb{
                    &-item{
                        width: 100px;
                        height:80px;
                        margin-right:10px;
                        margin-bottom:10px;
                        overflow: hidden;
                        cursor: pointer;
                        img{
                            width: 100%;
                            height:100%;
                            object-fit: cover;
                            transition:transform .3s;
                        }

                        &:hover{
                            img{
                                transform:scale(1.2);
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>