<template>
    <div>
        <r-drawer ref="rDialog" size="100%" direction="btt" dialog-title="人员统计" :footer="false">
            <template v-slot:title>
                <div class="r-flex r-row-bet r-col-bottom">
                    <p class="r-drawer-title">部门统计</p>
                    <div class="r-flex">
                        <p>模式选择：</p>
                        <div class="r-pr-20">
                            <el-button-group>
                                <el-button :type="query.type == 1 ? 'primary' : ''" size="mini" round
                                    @click="depIndicatorLoad(1)">负责指标</el-button>
                                <el-button :type="query.type == 2 ? 'primary' : ''" size="mini" round
                                    @click="depIndicatorLoad(2)">负责任务</el-button>
                            </el-button-group>
                        </div>
                        <p v-if="$has('statistics-department/edit')">配置部门：</p>
                        <div class="r-mr-20 hover--main" @click="openSave" v-if="$has('statistics-department/edit')">
                            <r-icon name="setting"
                                    size="26"></r-icon>
                        </div>
                        <el-button size="mini" type="primary" style="margin-right: 10px;" @click="exportData()">导出</el-button>
                    </div>
                </div>
            </template>
            <div class="top-table-container">
                <r-table ref="table" :loader="loader" :columns="columns" height="100%" row-key="id"
                    @sortChange="sortChange">
                    <template v-slot:statusRatio1="{ row, column }">
                        {{ row[column.prop] }}%
                    </template>
                    <template v-slot:statusRatio2="{ row, column }">
                        {{ row[column.prop] }}%
                    </template>
                    <template v-slot:statusRatio3="{ row, column }">
                        {{ row[column.prop] }}%
                    </template>
                    <template v-slot:statusRatio4="{ row, column }">
                        {{ row[column.prop] }}%
                    </template>
                    <template v-slot:statusRatio5="{ row, column }">
                        {{ row[column.prop] }}%
                    </template>      
                    <template v-slot:progress="{ row, column }">
                        <span :style="{color:parseFloat(row[column.prop]) >= warningValue? 'green' :'red'}">{{ row[column.prop] }}%</span>
                    </template>
                </r-table>
            </div>
        </r-drawer>
        <saveDep ref="saveDep" @change="search"></saveDep>
    </div>
</template>
<script>
import {departmentIndicatorStatistic} from '@/api/statistics';
import saveDep from "./save-dep.vue"
import fileDownload from "js-file-download";
export default {
    data() {
        return {
            query: {
                type:1
            }
        }
    },
    props:{
        warningValue:{
            type:Number,
            default:0
        }
    },
    components:{
        saveDep
    },
    computed:{
        columns(){
            return [
                {
                    label: '部门',
                    prop: 'name',
                    "min-width": '120px',
                    "show-overflow-tooltip": true
                }, 
                {
                    label:this.query.type == 1 ? '指标任务总数' : '包含任务总数',
                    prop: 'totalCount',
                    sortable: 'custom',
                    'min-width': "140px"
                }, 
                {
                    label: '未启动',
                    prop: 'status1',
                    sortable: 'custom',
                    'min-width': "140px"
                }, 
                {
                    label: this.query.type == 1 ? '正常进行' : '进行中',
                    prop: 'status2',
                    sortable: 'custom',
                    'min-width': "120px"
                },  
                {
                    label: this.query.type == 3 ? '进行缓慢' : '已逾期',
                    prop: 'status3',
                    sortable: 'custom',
                    'min-width': "120px"
                }, 
                {
                    label: '已完成',
                    prop: 'status4',
                    sortable: 'custom',
                    'min-width': "120px"
                },
                {
                    label: '未完成',
                    prop: 'status5',
                    sortable: 'custom',
                    'min-width': "120px"
                },
                {
                    label: '已得分值总和',
                    prop: 'score',
                    sortable: 'custom',
                    'min-width': "140px"
                },
                {
                    label: '应得分值总和',
                    prop: 'totalScore',
                    sortable: 'custom',
                    'min-width': "140px"
                },
                {
                    label: '综合进度',
                    prop: 'progress',
                    sortable: 'custom',
                    'min-width': "120px"
                },
            ]
        }
    },
    methods: {
        exportData() {
          departmentIndicatorStatistic({ page: 1, pageSize: 9999999, ...this.query, export: 1  }).then(res => {
            fileDownload(res, "部门进度排行.xlsx");
          })
        },
        open() {
            this.$refs.rDialog.handleOpen();
        },
        search() {
            this.$refs.table.refresh(1);
        },
        sortChange(order) {
            this.query = Object.assign(this.query, order);
            this.$nextTick(() => {
                this.search();
            })
        },
        loader(page, pageSize) {
            return departmentIndicatorStatistic({ page, pageSize, ...this.query }).then(res => {
                return res.data;
            })
        },
        depIndicatorLoad(type){
            this.query.type = type;
            this.search();
        },
        openSave(){
            this.$refs.saveDep.open();
        }
    }


}
</script>

<style lang="scss">
.top-table-container {
    height: 100%;
}
</style>