import axios from 'axios'
import {Message} from 'element-ui';
import store from "@/store";
//获取权限实例
import debounce from "@/utils/debounce";
import {cloneDeep, isString} from "@/utils";
import {router} from "@/router";
// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 60000 // 请求超时时间
});

// request拦截器
service.interceptors.request.use(config => {
  config.headers['Authorization'] = store.state.userInfo.token || '';
  config.headers['companyId'] = store.state.userInfo.companyId || '';
  try{

    if(config.data){
        let data = cloneDeep(config.data);
        if(data?.$isAddUrl){
          //需要添加url;
          let url = config.url.split('?');
          data.url = url[0];
          delete data.$isAddUrl;
          config.data = data;
        }
    }
    if(config.params?.$isAddUrl){
        let data = cloneDeep(config.params);
        let url = config.url.split('?');
        data.url = url[0];
        delete data.$isAddUrl;
        config.params = data;
    }
  }catch(err){
    console.log(err)
  }
  return config;
}, error => {
  // Do something with request error
  Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
  response => {
    let data = isString(response.config?.data) ? JSON.parse(response.config?.data) : response.config?.data;
    let params = isString(response.config?.params) ? JSON.parse(response.config?.params) : response.config?.params;
    let hideMessage = !!data?.quiet || !!params?.quiet || false;
    const res = response.data;
    if (res.code !== 200 && res.type !== 'application/download' && response?.headers['content-type'] != 'application/download') {
      // 401重新登陆
      if (res.code == 401) {
        //清除所有的用户状态
        store.commit('resetUserInfo');
        //跳转到登录页 
        router.push({name:'login'});
      }
      if(!hideMessage){
        //针对401单独处理
        if(res.code == 401){
          //即10秒内只能执行一次
          debounce(()=>{
            Message({
              message: res?.message,
              type: 'error',
              duration: 3 * 1000
            })
          },10000,true)
        }else{
          Message({
            message: res?.message,
            type: 'error',
            duration: 3 * 1000
          })
        }
      }
      return Promise.reject(res);
    } else {
      return response.data
    }
  },error => {
    let config = error?.config;
    if(config?.url != '/api/login/mini-qr-code-login'){
      Message({
        message:error?.message == 'Network Error' ? '网络异常，请刷新后重试' : error?.message ,
        type: 'error',
        duration: 3 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service
