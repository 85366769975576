<template>
    <div class="r-dialog">
        <el-dialog :title="dialogTitle" :visible.sync="show" @close="close" @closed="reset" :width="size" @opened="opened" direction="rtl" :destroy-on-close="true" :close-on-press-escape="!loading" :close-on-click-modal="false" append-to-body modal-append-to-body :class="{'header-hide':hideHeader,'border':border}" class="r-dialog-cover" :custom-class="customClass">
            <template v-slot:title v-if="$scopedSlots['title']">
                <div class="r-dialog-header r-flex-auto-w">
                    <slot name="title"></slot>
                </div>
            </template>
            <div class="r-dialog-wrapper r-flex r-flex-column" v-loading="dialogLoading" element-loading-text="拼命加载中..."
        element-loading-background="rgba(255,255, 255, 0.8)">
                <div class="r-dialog-scroll-view" :class="{'footer-hide':!footer,'scroll-none':!isScroll}">
                    <slot></slot>
                </div>
                <div class="r-dialog-footer" v-if="footer">
                    <el-button size="small" @click="close" :disabled="loading">取 消
                    </el-button>
                    <el-button type="primary" @click="onSubmit" :disabled="loading" :loading="loading"
                        size="small">
                        {{ confirmText }}
                    </el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name:"rDialog",
    props:{
        dialogTitle:{
            type:String,
            default:'标题'
        },
        size:{
            type:String,
            default:'800px'
        },
        footer:{
            type:Boolean,
            default:true
        },
        hideHeader:{
            type:Boolean,
            default:false
        },
        isScroll:{
            type:Boolean,
            default:true
        },
        dialogLoading:{
            type:Boolean,
            default:false
        },
        border:{
            type:Boolean,
            default:true
        },
        customClass:{
            type:String,
            default:''
        },
        confirmText:{
            type:String,
            default:'提  交'
        }
    },
    data() {
        return {
            show: false,
            loading:false
        }
    },
    methods: {
        handleOpen() {
            this.show = true;
        },
        close() { this.show = false; },
        reset() {
            this.$emit('close');
            this.loading=false;
        },
        onSubmit() {
            this.loading=true;
            this.$emit('submit',(close = false)=>{
                this.loading = false;
                if(close){
                    this.close();
                }
            })
        },
        opened(){
            this.$emit('opened');
        }
    }

}
</script>

<style lang="scss" scoped>
::v-deep .r-dialog {
    &-scroll-view {
        width: 100%;
        &.footer-hide{
            padding-bottom:20px;
        }
        &.scroll-none{
            padding-bottom:20px;
        }
    }

    &-footer {
        width: 100%;
        padding: 20px;
        border-top: 1px solid #ebeef5;
        background-color: #ffffff;
        text-align: right;
    }

}

.r-dialog-cover ::v-deep .el-dialog__body{
    padding-bottom:0;
    padding-top:0;
    &.border{
        border-top:1px solid #ebeef5
    }
}
.r-dialog-cover ::v-deep .el-dialog__header{
    display: flex;
}
</style>