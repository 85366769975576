<template>
    <div class="login-wrapper">
        <div class="login-right">
            <div class="login-content">
                <div class="login-content-container">
                      <transition-group name="fade">
                        <template v-for="(item,index) in modules">
                            <component 
                            :is="item.name" 
                            :key="index" 
                            v-if="activeIndex==index"
                            :ref="item.name"
                            @next="nextModule"
                            @back="prevModule(item.isBack)"></component>
                        </template>
                    </transition-group>
                </div>
            </div>
            <div class="copyright">
                <a href="https://beian.miit.gov.cn/" target="_blank">琼ICP备2021003995号 </a> <span>©2021-2022 海南房小云科技有限公司 版权所有</span>
            </div>
        </div>
    </div>
</template>
<script>

import loginStart from "@/views/common/login/components/login_start";
import loginCodeCheck from "@/views/common/login/components/login_code_check";
import {findIndex} from "@/utils";
export default {
    
    components:{
        loginStart,
        loginCodeCheck
    },

    data(){
        return{
            //当前显示的模块
            activeIndex:0,
            //定时器标识
            invId:null
        }
    },

    

    methods:{

        /**
         * 前往下一页
         */
        nextModule(page = 1){
            //页面级别的操作
            this.$nextTick(()=>{
                this.activeIndex+=page;
            })
        },
        /**
         * 返回上一页
         * @params
         */
        prevModule(isBack,name){
            if(!isBack) return;
            //定点跳转
            if(name){
                let index = findIndex(this.modules,el=>{
                    return el.name == name;
                });
                this.activeIndex = index > -1 ? index : this.activeIndex;
            }else{
                let _name = this.modules[this.activeIndex-1]?.name;
                //不允许回退到验证码界面
                if(_name == 'loginCodeCheck'){
                    this.activeIndex -=2;
                }else{
                    this.activeIndex -=1;
                }
            }
        },

    },
    
    computed:{

        //模块列表
        modules(){

            return [
                {
                    //开始登录
                    name:"loginStart",
                    //是否使用返回
                    isBack:false,
                }
            ]

        }
    },
    beforeDestroy(){
        if(this.invId){
            global.clearInterval(this.invId);
        }
    }

}
</script>

<style lang="scss">
.login-wrapper{
    height:100%;
    display: flex;
    background: url('@/assets/images/bg-more.png') no-repeat scroll left top;
    background-size:cover;
    .el-input__inner{
        background: #fff;
    }
    .login-right{
        flex:1 1 auto;
        position: relative;
        .copyright{
            position: absolute;
            bottom:0;
            left:0;
            right:0;
            text-align: center;
            line-height:40px;
            font-size:12px;
            color:#ffffff;
            text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
            a{
                color:#ffffff;
                &:hover{
                    opacity: .8;
                }
            }
        }
        .login-content{
            position: absolute;
            width: 800px;
            height: 570px;
            top: 50%;
            left: 50%;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
            .login-content-container{
                width: 100%;
                position: relative;
      
                .login-module{
                    position: absolute;
                    left:0;
                    top:0;
                    width: 100%;
                    height:100%;
                    padding: 0 32px;
                    display: flex;
                    flex-direction: column;
                    .login-module-back-container{
                        height:56px;
                        display: flex;
                        align-items: center;
                        .back{
                            i,span{
                                vertical-align: middle;
                            }
                            i{
                                font-size:20px;
                            }

                        }
                    }
                    .login-module-title__container{

                        .login-module-title{
                            font-size: 50px;
                            font-weight: 700;
                            line-height: 30px;
                            white-space: pre-wrap;
                            color:#fff;
                        }
                        .login-module-title-subtitle{
                            margin-top:8px;
                            color: rgb(100, 106, 115);
                            font-size: 13px;
                            margin-top: 8px;
                            line-height: 18px;
                            white-space: pre-wrap;
                            .phone{
                                font-weight: 600; color: rgb(31, 35, 41);
                            }
                        }
                    }

                    .login-module-content{

                        &.between{
                            display: flex;
                            flex-direction: column;
                            flex:1 1 auto;
                            justify-content: space-between;
                            padding-bottom:32px;
                            &.n-p-b{

                                padding-bottom:0;

                            }

                        }

                    }

                    .login-btn{
                        width:100%;margin-top:15px;
                    }

                }
            }

        }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .3s,transform .3s;
        
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
        transform: translate3d(-32px,0,0);
    }
    }


}
</style>