<template>
<div class="task-employee">
    任务负责人
</div>
</template>
<script>

export default {
    name:"taskEmployee"



}
</script>

<style lang="scss" scoped>
    .task-employee{
        font-weight: bold;
    }
</style>