<template>
    <div style="border: 1px solid #ccc;">
        <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
        <Editor :style="editStyle" v-model="html" :defaultConfig="editorConfig" :mode="mode" @onCreated="onCreated" />
    </div>
</template>
<script>
import Vue from 'vue';
import '@wangeditor/editor/dist/css/style.css';
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import ossUploadFn from "@/utils/ossUploadFn"
export default Vue.extend({
    components: { Editor, Toolbar },
    props: {
        value: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        height: {
            type: String,
            default: '500px'
        }
    },
    data() {
        return {
            editor: null,
            toolbarConfig: {
                excludeKeys: ['group-video', 'insertTable', 'emotion', 'todo', 'codeBlock']
            },
            mode: 'default', // or 'simple'
        }
    },
    computed: {
        html: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
        editorConfig() {
            return {
                placeholder: '请输入内容...',
                MENU_CONF: {
                    uploadImage: {
                        // 自定义上传
                        customUpload: async (file, insertFn) => {
                            let data = await this.beforeUpload(file);
                            // file 即选中的文件
                            // 自己实现上传，并得到图片 url alt href
                            // 最后插入图片
                            insertFn(this.$getStaticUrl(data.url), '', this.$getStaticUrl(data.url))
                        }
                    }
                },
                readOnly: this.disabled
            }
        },
        editStyle() {
            return `height: ${this.height}; overflow-y: hidden;`
        }
    },
    methods: {
        //初始化
        onCreated(editor) {
            this.$nextTick(() => {
                this.editor = Object.seal(editor);
            })
        },
        //销毁
        editorDestroy() {
            this.editor = null;
        },
        beforeUpload(file) {
            return ossUploadFn(file);
        }
    },

    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    },
})
</script>