import userInfo from "@/store/modules/userInfo";
import routerStore from "@/store/modules/routerStore";
import login from "@/store/modules/login";
import approve from "@/store/modules/approve";
import getters from './getters';
import Vuex from "vuex";
import Vue from 'vue';
//必须先调用此处
Vue.use(Vuex);
const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    login,
    userInfo,
    routerStore,
    approve
  },
  getters
})

/**
 * Vuex.Store 实例
 */
export default store;


