import request from '@/utils/request'

export function getEmployee(params) {
  return request({
    url: '/api/common/get-employee',
    method: 'get',
    params: params
  })
}

export function getCityList(params) {
  return request({
    url: '/api/company/area-by-tree',
    method: 'get',
    params
  })
}
export function getAreaCityList(params) {
  return request({
    url: '/api/company/area-list',
    method: 'get',
    params
  })
}

export function getAreaByTree(params) {
  return request({
    url: '/api/common/get-area-by-tree',
    method: 'post',
    data: params
  })
}

export function getAreaByCode(params) {
  return request({
    url: '/api/common/get-area-by-code',
    method: 'post',
    data: params
  })
}

export function getCommonOptions({url, params}, isArrayBuffer) {
  return request({
    url: url,
    method: 'post',
    data: params,
    responseType: isArrayBuffer ? "arraybuffer" : ''
  })
}

export function getSlsLogList(params) {
  return request({
    url: '/api/common/sls-logs-list',
    method: 'get',
    params: params
  })
}

export function getSecondaryRegion(params) {
  return request({
    url: '/api/region/secondary-region',
    method: 'get',
    params: params
  })
}

export function readExcel(params) {
  return request({
    url: '/api/common/read-excel',
    method: 'get',
    params: params,
    timeout: 6000000
  })
}

export function getExcelHeader(params) {
  return request({
    url: '/api/common/get-excel-header',
    method: 'post',
    data: params,
    timeout: 6000000
  })
}

export function importExcel(params) {
  return request({
    url: '/api/common/import-excel',
    method: 'post',
    data: params,
    timeout: 6000000
  })
}

export function getImportExcelTemplate(params) {
  return request({
    url: '/api/common/get-import-excel-template',
    method: 'post',
    data: params,
    timeout: 6000000
  })
}

export function saveUploadLog(params)
{
  return request({
    url: '/api/company/save-upload-record',
    method: 'post',
    data: params
  })
}
export function createDict(params) {
  return request({
    url: '/api/common/create-dict',
    method: 'post',
    data: params,
  })
}

export function batchCreateDict(params) {
  return request({
    url: '/api/common/batch-create-dict',
    method: 'post',
    data: params,
  })
}

export function getEnum(params) {
  return request({
    url: '/api/common/get-enum',
    method: 'get',
    params,
  })
}
