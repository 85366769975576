/**
 * 表格相关配置
 */

const indicator = {
    //序号
    no: { 
        'min-width': '120px', 
        'show-overflow-tooltip': false 
    },
    //指标名称
    name: {
        "show-overflow-tooltip": false,
        "min-width": '400px'
    },
    //负责人
    employee: {
        "show-overflow-tooltip": true,
        "min-width": '130px'
    },
    //协作人
    collaborateEmployee: {
        "show-overflow-tooltip": true,
        "min-width": '130px'
    },
    //负责部门
    department: {
        "show-overflow-tooltip": true,
        "min-width": '130px'
    },
    //开始时间
    startTime: {
        "show-overflow-tooltip": true,
        "min-width": '160px',
        sortable: 'custom'
    },
    //结束时间
    endTime: {
        "show-overflow-tooltip": true,
        "min-width": '130px',
        sortable: 'custom'
    },
    //任务完成数
    taskOver: {
        "show-overflow-tooltip": true,
        "min-width": '120px',
        sortable: 'custom'
    },
    //逾期完成数
    taskOverdueOver: {
        "show-overflow-tooltip": true,
        "min-width": '120px',
        sortable: 'custom'
    },
    //平均评分
    scoreAvg: {
        "show-overflow-tooltip": true,
        "min-width": '110px',
        sortable: 'custom'
    },
    //录入人
    cratedBy: {
        "show-overflow-tooltip": true,
        "min-width": '130px',
    },
    //录入时间
    createdAt: {
        "show-overflow-tooltip": true,
        "min-width": '180px',
    },
    //分管领导
    leader: {
        "show-overflow-tooltip": true,
        "min-width": '130px',
    },
    //协作部门
    collaborateDepartment: {
        "show-overflow-tooltip": true,
        "min-width": '130px',
    },
    //督办文件
    superviseFile: {
        "show-overflow-tooltip": true,
        "min-width": '140px',
    },
    //来文单位
    sourceUnity: {
        "show-overflow-tooltip": true,
        "min-width": '140px',
    },
    //附件
    annex: {
        "show-overflow-tooltip": false,
        "min-width": '200px',
    },
    //来文时间
    sourceTime: {
        "show-overflow-tooltip": false,
        "min-width": '200px',
    },
    //末级指标状态
    status: {
        "show-overflow-tooltip": false,
        "min-width": '120px',
    }
}




const columns = {
    indicator
}

export {
    columns
}