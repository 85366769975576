<template>
  <div class="account-item-box">
    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
    <div style="margin: 15px 0;"></div>
    <el-checkbox-group v-model="valueItem" @change="handleCheckedCitiesChange">
      <el-checkbox v-for="item in options" :label="item.value" :key="item.value" class="item-options">
        {{ item.label }}
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
import {getValues} from '@/utils';

export default {
  name: 'accountItem',
  props: {
    value: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      options: [],
      checkAll: false,
      isIndeterminate: true
    }
  },
  computed: {
    valueItem: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  created() {},
  methods: {
    handleCheckAllChange(val) {
      this.$emit('input', val ? getValues(this.options, 'value') : [])
      this.isIndeterminate = false
      this.emitInput(val ? getValues(this.options, 'value') : [])
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.options.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.options.length
      this.emitInput(this.valueItem)
    },
    emitInput(val) {
      let checkValue = []
      for (let item of val) {
        checkValue.push(this.options.find(el => {
          return el.value === item
        }))
      }
      this.$emit('change', checkValue)
    },
  }
}
</script>

<style scoped>
.account-item-box{
  width: 650px;
  padding-left: 10px;
  max-height: 400px;
  overflow-y: auto;
}
.item-options {
  margin-bottom: 10px;
  width: 280px;
}
</style>
