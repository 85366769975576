import Vue from 'vue';
import addNode from "../components/addNode";
import nodeWrap from "../components/nodeWrap"

/**
 * 异步注册组件，因为引用此编辑器，需要重新注册elementUi的部分组件
 */

const componentRegisterControl = {

  components: [addNode, nodeWrap],

  register: [],

  /**
   * 传入一个对象，对象中包含组件信息
   */

  componentsRegister(el) {
    //判断组件是否已经注册过
    if (el?.name && !this.register.includes(el?.name)) {
      Vue.use(el);
      Vue.component(el.name, el);
      this.register.push(el.name);
    }

  },

  init() {
    this.components.forEach(el => {
      this.componentsRegister(el);
    });
    Vue.directive('enterNumber', {
      bind: function (el, {value = 2}) {
        el = el.nodeName == "INPUT" ? el : el.children[0]
        var RegStr = value == 0 ? `^[\\+\\-]?\\d+\\d{0,0}` : `^[\\+\\-]?\\d+\\.?\\d{0,${value}}`;
        el.addEventListener('keyup', function () {
          el.value = el.value.match(new RegExp(RegStr, 'g'));
          el.dispatchEvent(new Event('input'))
        })
      }
    });

    Vue.directive('focus', {
      // 当被绑定的元素插入到 DOM 中时……
      inserted: function (el) {
        el.focus()
      }
    });
  }
}


export default componentRegisterControl;
