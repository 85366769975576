<template>
  <div class="dialog__wrapper">
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogShow"
      :destroy-on-close="true"
      custom-class="disabled-none"
      top="12vh"
      :modal="dialogModal"
      :append-to-body="true"
      :close-on-click-modal="false"
      :props="{label:'name'}"
      width="800px"
      @close="onClose(false)"
      @open="handleOpen"
      @closed="onClosed"
    >
      <div class="dep-container">
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="12" class="panel-box">
            <div class="panel-box_wrap">
              <div class="left-panel_box">
                <div class="search-box">
                  <el-row type="flex">
                    <el-input
                      placeholder="请输入搜索内容"
                      v-model="keyword"
                      size="small"
                      class="input-box"
                    >
                      <i slot="prefix" class="el-input__icon el-icon-search"></i>
                    </el-input>
                  </el-row>
                </div>
                <div class="nav-box" v-if="config.type === 2">
                  <el-radio-group v-model="typeNav" size="mini" @change="handleChangeTypeNav">
                    <el-radio-button label="system">组织架构</el-radio-button>
                    <el-radio-button label="post">职务列表</el-radio-button>
                  </el-radio-group>
                </div>

                <div class="dep-box" v-loading="loading">
                  <el-tree
                    ref="tree"
                    :data="treeData"
                    node-key="id"
                    highlight-current
                    :expand-on-click-node="false"
                    :default-expand-all="false"
                    :default-expanded-keys="defaultExpandedKeys"
                    show-checkbox
                    :check-strictly="config.checkStrictly"
                    @check="handleCheckTree"
                    :filter-node-method="filterNode"
                    :props="{label:'name'}"
                    class="dep-tree"
                  >
                    <div class="custom-tree-node" slot-scope="{ node, data }">
                      <template v-if="data.type === 1">
                        <i class="fxy-icon-wenjianjia"></i>
                      </template>
                      <template v-else-if="data.type === 2">
                        <el-image :src="data.avatar | filterAvatar" fit="cover" class="fxy-avatar"></el-image>
                      </template>
                      <template v-else-if="data.type === 3">
                        <i class="fxy-icon-yingpinzhiwei"></i>
                      </template>
                      <span class="nickname">{{ node.label }}</span>
                      <span class="nickname" v-if="data.type == 1 && node.data && node.data.employeeNum">（{{node.data.employeeNum}}）</span>
                    </div>
                  </el-tree>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12" class="panel-box">
            <div class="panel-box_wrap">
              <div class="right-panel_box">
                <div class="check-txt">已选：</div>
                <div
                  class="r-flex"
                  v-for="(item, i) in checkList"
                  :key="i"
                >
                  <div class="r-flex-auto-w check-box_list">
                    <el-row type="flex" class="row-bg" justify="space-between">
                      <div class="">
                        <template v-if="item.type === 1">
                          <i class="fxy-icon-wenjianjia"></i>
                        </template>
                        <template v-else-if="item.type === 2">
                          <el-image :src="item.avatar | filterAvatar" fit="cover" class="fxy-avatar"></el-image>
                        </template>
                        <template v-else-if="item.type === 3">
                          <i class="fxy-icon-yingpinzhiwei"></i>
                        </template>
                        <span class="nickname">{{ item.name }}</span>
                      </div>
                    </el-row>
                  </div>
                  <div class="child" @click="handleDeleteNode(item)">
                    <i class="el-icon-delete"></i>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" class="modal-footer" justify="end">
          <el-checkbox v-model="isHideDep" class="switch-flex flex-left" :true-label="1" :false-label="0" v-if="typeNav == 'system' && !hideDepLock" @change="handleOpen(true)">显示隐藏部门</el-checkbox>
          <el-button type="warning" size="small" @click="onClose(false)">取 消</el-button>
          <el-button type="primary" size="small" @click="onSubmit">确 认</el-button>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dialogMixin from "@/mixins/dialogCommon";
import {getDepByTree} from "@/api/dep";
import {getPostListByTree} from "@/api/post";
import {cloneDeep} from "@/utils"
import {setDepDisabled, getValues} from "@/utils/arr";

export default {
  name: "ApproveCheckModal",
  mixins: [dialogMixin],
  props: {
    /** config.type类型；1人员，2部门，3职务，4所有 **/
    config: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    config: {
      deep: true,
      immediate: true,
      handler(val) {
      }
    },
    keyword(val) {
      this.$refs.tree.filter(val);
    },
  },
  data() {
    return {
      loading: false,
      keyword: null,
      typeNav: "system",
      checkList: [],
      treeData: [],
      depTree: [],
      postTree: [],
      defaultExpandedKeys: [],
      isHideDep:0
    };
  },
  computed: {
    dialogTitle() {
      return this.config.title;
    },
    dialogModal() {
      return this.config.hasOwnProperty('modal') ? this.config.modal : true;
    },
    hideDepLock(){
      return !!this.config?.hideDepLock;
    }
  },
  methods: {
    onClosed(){
      this.keyword = '';
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    onSubmit() {
      this.$emit("confirm", this.checkList);
    },
    handleCheckTree(data, node) {
      let checkList = cloneDeep(this.checkList);
      if (this.config.selectType === "radio") {
        if (node.checkedKeys.indexOf(data.id) > -1) {
          checkList = [{
            id: data.id,
            userId: data?.userId || '',
            name: data.name,
            parentId: data.parentId,
            employeeId: data.employeeId || '',
            type: data.type,
            avatar: data.avatar || ''
          }];
        }
      } else if (this.config.selectType === "checkbox") {
        let index = getValues(checkList, 'id').indexOf(data.id)
        if (index > -1) {
          checkList.splice(index, 1)
        } else {
          checkList.push({
            id: data.id,
            userId: data?.userId || '',
            name: data.name,
            parentId: data.parentId,
            employeeId: data.employeeId || '',
            type: data.type,
            avatar: data.avatar || ''
          })
        }
      }
      this.checkList = checkList;
      this.$refs.tree.setCheckedNodes(checkList);
    },

    handleDeleteNode(node) {
      this.checkList.forEach((item, index) => {
        if (item.id === node.id) {
          this.checkList.splice(index, 1);
        }
      })
      this.$refs.tree.setCheckedNodes(this.checkList);
    },
    async handleChangeTypeNav(val) {
      //职位
      if (val === 'system') {
        this.treeData = this.depTree;
      } else if (val === 'post') {
        if (this.postTree.length === 0) {
          this.postTree = await this.getPostData();
        }
        this.treeData = this.postTree;
      }
    },
    getPostData() {
      this.loading = true;
      return getPostListByTree({type: this.config.type, isStaff: this.config.isStaff || 0}).then((response) => {
        if (response.data.length > 0) {
          this.defaultExpandedKeys = this.config?.defaultExpandedKeys ? this.config?.defaultExpandedKeys : [response.data[0].id];
        }
        this.postTree = response.data;
        this.loading = false;
        return response.data;
      })
    },
    async handleOpen(isReset=false) {
      if(!isReset){
        this.checkList = cloneDeep(this.config.checkList);
      }
      let res = [];
      if (this.config.type === 3) {
        res = await this.getPostData()
      } else {
        this.loading = true;
        res = await getDepByTree({type: this.config.type, isStaff: this.config.isStaff || 0,isHideDep:this.config?.hideDepLock ? 0 : this.isHideDep,formType:'system'}).then((response) => {
          if (response.data.length > 0) {
            this.defaultExpandedKeys = this.config?.defaultExpandedKeys ? this.config?.defaultExpandedKeys : [response.data[0].id]
          }
          let data = response.data;
          this.loading = false;
          if (this.config.type === 1) {
            setDepDisabled(response.data, 'disabled');
          }
          return data;
        });
        this.depTree = res;
      }
      this.treeData = res;
      this.$nextTick(() => {
        this.$refs.tree.setCheckedNodes(this.checkList);
      })
    }
  }
}
</script>
<style lang="scss" scoped>

::v-deep .el-dialog__body {
  padding: 0 30px 20px;
}

.switch-flex {
  display: flex;
  align-self: center;
  padding-right: 10px;

  ::v-deep .el-checkbox__input {
    position: relative;
    top: 2px;
  }
  &.flex-left{
    margin-right:auto;
  }
}

.dep-container {
  .dep-tree {
    margin-top: 20px;
  }

  .modal-footer {
    margin-top: 10px;
  }

  .panel-box {
    .left-panel_box {
      margin-top: 10px;
      padding-right: 15px;
      height: 400px;
      overflow: auto;

      ::v-deep .input-box {
        .el-input__inner {
          background: #f5f6f7;
          border-color: #f5f6f7;
        }
      }
    }

    .right-panel_box {
      padding-left: 15px;

      .check-txt {
        margin-bottom: 10px;
      }

      .check-box_list {
        padding: 5px 0;
      }
    }

    .all-checkbox_box {
      margin: 10px 0;
    }

    .checkbox-box {
      padding: 5px 0;

      ::v-deep .el-checkbox__label {
      }

      ::v-deep .el-checkbox__input {
        vertical-align: inherit;
      }
    }

    .fxy-icon-wenjianjia {
      margin-right: 4px;
      color: #aee4fa;
      font-size: 17px;
      vertical-align: middle;
    }

    .fxy-icon-yingpinzhiwei {
      font-size: 20px;
      vertical-align: middle;
      color: #faad14;
    }

    .child {
      cursor: pointer;

      .el-icon-delete {
        font-size: 16px;
        color: #f56c6c;
      }
    }

    .nav-box {
      text-align: center;
      margin-top: 10px;
    }
  }

  .fxy-avatar {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    margin-right: 4px;
    vertical-align: middle;
  }

  .nickname {
    vertical-align: middle;
  }
}
</style>
