<template>
    <r-dialog ref="rDialog" :dialogTitle="form.type == 5 ? '审批意见' : '驳回原因'" size="600px" @close="reset" @submit="submit">
        <div class="dialog-content">
            <el-form>
                <el-form-item >
                    <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 8 }" :placeholder="'请输入'+(form.type == 5 ? '审批意见' : '驳回原因')" v-model="form.content"></el-input>
                </el-form-item>
            </el-form>
        </div>
    </r-dialog>
</template>
<script>
import {approve} from "@/api/question";
export default {

    data() {
        return {
            form: {
                content: '',
                type:'',
                questionId:''
            }
        }
    },
    methods: {
        open(type, id) {
            this.form.type = type;
            this.form.questionId = id;
            this.$refs.rDialog.handleOpen();
        },
        reset(){
            this.form.content = '';
            this.type = '';
            this.questionId = '';
        },
        submit(done){
            if(this.form.type == 6 && this.form.content == ''){
                this.$message.error('驳回原因不能为空');
                done(false);
                return;
            }
            approve(this.form).then(res=>{
                this.$message.success('提交成功');
                done(true);
                this.$emit('confirm');
            })
            .catch(err=>{
                done(false);
            })
        }
    }



}
</script>

<style lang="scss" scoped>
.dialog-content {
    height: 250px;
}
</style>