<template>
    <div>
        <r-drawer ref="rDialog" size="700px" :dialog-title="isEdit ? '编辑员工' : '创建员工'" @submit="submit" @close="reset" :destroy-on-close="true">
            <el-form label-position="top" :rules="rules" :model="form" ref="form" :disabled="loading" size="small">
                <el-form-item label="姓名" prop="nickname">
                    <el-input placeholder="请输入姓名" v-model="form.nickname"></el-input>
                </el-form-item> 
                <div class="r-flex r-col-bottom">
                    <el-form-item label="手机号" prop="phone" class="r-flex-auto-w">
                        <el-input placeholder="请输入手机号" v-model="form.phone"></el-input>
                    </el-form-item>    
                    <el-form-item class="r-ml-20">
                        <el-checkbox v-model="form.hidePhone" class="sub-dep" :true-label="1" :false-label="0">对外隐藏号码</el-checkbox>
                    </el-form-item>
                </div>
                <el-form-item label="所在单位" prop="department">
                    <r-tag-select v-model="form.department" labelKey="name" :collapseTags="true" placeholder="请选择所在单位"
                        max @focus="handleChangeSelect" :closable="false"></r-tag-select>
                </el-form-item>
                <el-form-item label="区域" prop="areaId">
                    <el-cascader :options="cityList" v-model="form.areaId" class="r-w-max" :props="{label:'name',value:'id',emitPath:false,checkStrictly:true}" filterable></el-cascader>
                </el-form-item>
                <el-form-item label="角色" prop="roleData">
                    <el-select v-model="form.roleData" placeholder="请选择角色" class="r-w-max" multiple>
                        <el-option v-for="item in roles" :key="item.id" :value="item.id" :label="item.title"></el-option>
                    </el-select>
                </el-form-item>      
                <el-form-item label="职位" prop="position">
                    <el-select v-model="form.position" placeholder="请选择职位" class="r-w-max" filterable>
                      <el-option v-for="item in postList" :key="item.id" :value="item.id" :label="item.name"></el-option>
                    </el-select>
<!--                    <el-input placeholder="请输入职位" v-model="form.positionName"></el-input>-->
                </el-form-item>    
                <el-form-item label="备注">
                    <el-input placeholder="请输入备注" v-model="form.remarks" type="textarea" :autosize="{minRows:4,maxRows:6}"></el-input>
                </el-form-item>
            </el-form>
        </r-drawer>

        <dep-role-modal :config="modalConfig" v-model="dialogVisible" @confirm="handleConfirm"></dep-role-modal>
    </div>
</template>

<script>
import {cloneDeep, isArray,isPhone } from "@/utils";
import {getCityList} from "@/api/common"
import { addList,editList} from "@/api/employee/index";
import DepRoleModal from "@/components/dialog/DepRoleModal";
import {getList} from "@/api/system/role";
import {getList as getPostList} from "@/api/post/index"
export default {
    components: { DepRoleModal },
    data() {
        const checkPhone = (rule, value, callback)=>{
            if(value == ''){
                callback(new Error('请输入手机号码'));
            }else if(!isPhone(value)){
                callback(new Error('手机号码格式错误'));
            }else{
                callback();
            }
        }
        return {
            form: {
                //名称
                nickname:'',
                //手机号
                phone:'',
                //部门
                department:[],
                //职位
                positionName:'',
                //备注
                remarks:"",
                //是否隐藏手机号
                hidePhone:0,
                //地区
                areaId:'',
                //角色
                roleData:[],
                position: ""
            },
            rules: {
                nickname: [{ required: true, message: '请填写姓名', trigger: "blur" }],
                department: [{ required:true,message:'请选择所在单位', trigger: "change" }],
                // positionName: [{ required:true,message:'请填写职位', trigger: "blur" }],
                areaId: [{ required:true,message:'请选择区域', trigger: ["blur","change"] }],
                roleData:[{ required:true,message:'请选择角色', trigger:["blur","change"] }],
                position:[{ required:true,message:'请选择职务', trigger:["blur","change"] }]
            },
            isEdit: false,
            modalConfig: {},
            dialogVisible: false,
            cityList:[],
            loading:false,
            roles:[],
            postList:[],
        }
    },
    methods: {
        open(form = null,isEdit = false) {
            this.getCityList();
            this.getRoles();
            this.getPostList();
            if (form) {
                this.form = Object.assign(this.form, form);
            }
            
            this.isEdit = isEdit;
            this.$refs.rDialog.handleOpen();
            this.$nextTick(() => {
                this.$refs['form']?.clearValidate && this.$refs['form'].clearValidate();
            })
        },
        submit(done) {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let form = cloneDeep(this.form);
                    form.department = form.department[0].id;
                    this.loading = true;
                    (this.isEdit ? editList : addList)(form).then(res => {
                        this.$message.success(`员工${this.isEdit ? '编辑' : '创建'}成功`);
                        this.$emit('success');
                        done(true);
                    }).catch(err => {
                        done(false);
                    })
                    .finally(()=>{
                        this.loading =false;
                    })
                } else {
                    done();
                }
            });
        },
        getCityList(){
            getCityList().then(res=>{
                this.cityList = res.data;
            })
        },
        getPostList(){
          getPostList().then(res=>{
            this.postList = res.data.list;
          })
        },
        reset() {
            this.form = {
                //名称
                nickname:'',
                //手机号
                phone:'',
                //部门
                department:[],
                //职位
                positionName:'',
                //备注
                remarks:"",
                //是否隐藏手机号
                hidePhone:0,
                //地区
                areaId:'',
                //角色
                roleData:[],
                // 职务
                position: ""
            };
            this.cityList = [];
            this.modalConfig = {};
            this.isEdit = false;
            this.loading = false;
            this.roles = [];
        },
        removePid(){
            this.form.pid = '';
        },
        handleConfirm(val) {
            this.form.department = val;
            this.dialogVisible = false;
        },
        handleChangeSelect() {
            this.modalConfig = {
                type: 1,
                selectType: "radio",
                title: "选择上级部门",
                checkStrictly: true,
                model: "pid",
                checkList: isArray(this.form.department) ? this.form.department : [],
            };
            this.dialogVisible = true;
        },
        getRoles(){
            getList().then(res=>{
                this.roles = res.data;
            })
        }
    }


}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
    font-size: 16px;
    padding-bottom: 5px;
}
</style>