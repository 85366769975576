<template>
<div class="task-name">
    任务名称
</div>
</template>
<script>

export default {
    name:'taskName'


}
</script>

<style lang="scss" scoped>
    .task-name{
        font-size:20px;
        font-weight: bold;
    }
</style>