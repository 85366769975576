<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
  export default{
    created(){}
  }
</script>
<style lang="scss">
</style>
