export default [
  {
    // 组件的自定义配置
    __config__: {
      label: '任务名称',
      labelWidth: 0,
      changeTag: true,
      tag: 'task-name',
      tagIcon: 'name',
      field: 'name',
      defaultValue: '',
      layout: 'colFormItem',
      span: 24,
      // 正则校验规则
      regList: [],
      expandParams: {
        showLabel: false,
        //说明,展示在窗口右侧的说明，没有则不显示
        alert: '任务名称会自动读取创建任务时所填写的任务名称',
        //提示文字,弹窗说明文字
        dialogTips: '',
        labelDisabled:true,
        labelLeft:true
      }
    },
    // 其余的为可直接写在组件标签上的属性
    placeholder: '',
    style: { width: '100%' },
    clearable: true,
    readonly: false,
    disabled: true
  },{
    // 组件的自定义配置
    __config__: {
      label: '所属指标',
      labelWidth: 0,
      changeTag: true,
      tag: 'task-tags',
      tagIcon: 'parentList',
      field: 'parentList',
      defaultValue: '',
      layout: 'colFormItem',
      span: 24,
      // 正则校验规则
      regList: [],
      expandParams: {
        showLabel: true,
        //说明,展示在窗口右侧的说明，没有则不显示
        alert: '所属指标会显示出该任务的上级任务,用户可点击上级任务查看任务详情了解上级精神',
        //提示文字,弹窗说明文字
        dialogTips: '',
        labelDisabled:true,
        //上级显示方式 all所有 , parent直属上级 
        parentShow:'all'
      }
    },
    // 其余的为可直接写在组件标签上的属性
    placeholder: '',
    style: { width: '100%' },
    clearable: true,
    readonly: false,
    disabled: true
  },{
    // 组件的自定义配置
    __config__: {
      label: '任务编号',
      labelWidth: 0,
      changeTag: true,
      tag: 'task-number',
      tagIcon: 'taskNumber',
      field: 'number',
      defaultValue: '',
      layout: 'colFormItem',
      span: 24,
      // 正则校验规则
      regList: [],
      expandParams: {
        showLabel: true,
        //说明,展示在窗口右侧的说明，没有则不显示
        alert: '任务编号由系统自动生成,用于快速定位',
        //提示文字,弹窗说明文字
        dialogTips: '',
        labelLeft:true,
        labelDisabled:true
      }
    },
    // 其余的为可直接写在组件标签上的属性
    placeholder: '',
    style: { width: '100%' },
    clearable: true,
    readonly: false,
    disabled: true
  },{
    // 组件的自定义配置
    __config__: {
      label: '状态',
      labelWidth: 0,
      changeTag: true,
      tag: 'task-status',
      tagIcon: 'status',
      field: 'status',
      defaultValue: '',
      layout: 'colFormItem',
      span: 24,
      // 正则校验规则
      regList: [],
      expandParams: {
        showLabel: true,
        //说明,展示在窗口右侧的说明，没有则不显示
        alert: '状态会随任务的时间以及实际操作情况变动',
        //提示文字,弹窗说明文字
        dialogTips: '',
        labelLeft:true,
        labelDisabled:true
      }
    },
    // 其余的为可直接写在组件标签上的属性
    placeholder: '',
    style: { width: '100%' },
    clearable: true,
    readonly: false,
    disabled: true
  },{
    // 组件的自定义配置
    __config__: {
      label: '备案状态',
      labelWidth: 0,
      changeTag: true,
      tag: 'filings-status',
      tagIcon: 'filingsStatus',
      field: 'filingsStatus',
      defaultValue: '',
      layout: 'colFormItem',
      span: 24,
      // 正则校验规则
      regList: [],
      expandParams: {
        showLabel: true,
        //说明,展示在窗口右侧的说明，没有则不显示
        alert: '备案状态会随实际操作情况变动',
        //提示文字,弹窗说明文字
        dialogTips: '',
        labelLeft:true,
        labelDisabled:true
      }
    },
    // 其余的为可直接写在组件标签上的属性
    placeholder: '',
    style: { width: '100%' },
    clearable: true,
    readonly: false,
    disabled: true
  },{
    // 组件的自定义配置
    __config__: {
      label: '负责人',
      labelWidth: 0,
      changeTag: true,
      tag: 'task-employee',
      tagIcon: 'employee',
      field: 'employee',
      defaultValue: '',
      layout: 'colFormItem',
      span: 24,
      // 正则校验规则
      regList: [],
      expandParams: {
        showLabel: true,
        //说明,展示在窗口右侧的说明，没有则不显示
        alert: '此控件会展示任务负责人',
        //提示文字,弹窗说明文字
        dialogTips: '',
        labelLeft:true,
        labelDisabled:true
      }
    },
    // 其余的为可直接写在组件标签上的属性
    placeholder: '',
    style: { width: '100%' },
    clearable: true,
    readonly: false,
    disabled: true
  },{
    // 组件的自定义配置
    __config__: {
      label: '协作人',
      labelWidth: 0,
      changeTag: true,
      tag: 'task-collaborate-employee',
      tagIcon: 'collaborateEmployee',
      field: 'collaborateEmployee',
      defaultValue: '',
      layout: 'colFormItem',
      span: 24,
      // 正则校验规则
      regList: [],
      expandParams: {
        showLabel: true,
        //说明,展示在窗口右侧的说明，没有则不显示
        alert: '此控件会展示任务协作人',
        //提示文字,弹窗说明文字
        dialogTips: '',
        labelLeft:true,
        labelDisabled:true
      }
    },
    // 其余的为可直接写在组件标签上的属性
    placeholder: '',
    style: { width: '100%' },
    clearable: true,
    readonly: false,
    disabled: true
  },{
    // 组件的自定义配置
    __config__: {
      label: '负责部门',
      labelWidth: 0,
      changeTag: true,
      tag: 'task-person-depId',
      tagIcon: 'personDepId',
      field: 'personDepId',
      defaultValue: '',
      layout: 'colFormItem',
      span: 24,
      // 正则校验规则
      regList: [],
      expandParams: {
        showLabel: true,
        //说明,展示在窗口右侧的说明，没有则不显示
        alert: '此控件会展示任务部门',
        //提示文字,弹窗说明文字
        dialogTips: '',
        labelLeft:true,
        labelDisabled:true
      }
    },
    // 其余的为可直接写在组件标签上的属性
    placeholder: '',
    style: { width: '100%' },
    clearable: true,
    readonly: false,
    disabled: true
  },{
    // 组件的自定义配置
    __config__: {
      label: '协作部门',
      labelWidth: 0,
      changeTag: true,
      tag: 'task-collaboration-dep',
      tagIcon: 'collaborationDep',
      field: 'collaborationDep',
      defaultValue: '',
      layout: 'colFormItem',
      span: 24,
      // 正则校验规则
      regList: [],
      expandParams: {
        showLabel: true,
        //说明,展示在窗口右侧的说明，没有则不显示
        alert: '此控件会展示任务协作部门',
        //提示文字,弹窗说明文字
        dialogTips: '',
        labelLeft:true,
        labelDisabled:true
      }
    },
    // 其余的为可直接写在组件标签上的属性
    placeholder: '',
    style: { width: '100%' },
    clearable: true,
    readonly: false,
    disabled: true
  },{
    // 组件的自定义配置
    __config__: {
      label: '分管领导',
      labelWidth: 0,
      changeTag: true,
      tag: 'task-leader',
      tagIcon: 'task-leader',
      field: 'taskLeader',
      defaultValue: '',
      layout: 'colFormItem',
      span: 24,
      // 正则校验规则
      regList: [],
      expandParams: {
        showLabel: true,
        //说明,展示在窗口右侧的说明，没有则不显示
        alert: '此控件会展示任务协作人',
        //提示文字,弹窗说明文字
        dialogTips: '',
        labelLeft:true,
        labelDisabled:true
      }
    },
    // 其余的为可直接写在组件标签上的属性
    placeholder: '',
    style: { width: '100%' },
    clearable: true,
    readonly: false,
    disabled: true
  },{
    // 组件的自定义配置
    __config__: {
      label: '开始日期',
      labelWidth: 0,
      changeTag: true,
      tag: 'task-start-date',
      tagIcon: 'startDate',
      field: 'startDate',
      defaultValue: '',
      layout: 'colFormItem',
      span: 24,
      // 正则校验规则
      regList: [],
      expandParams: {
        showLabel: true,
        //说明,展示在窗口右侧的说明，没有则不显示
        alert: '此控件会展示任务的计划日期',
        //提示文字,弹窗说明文字
        dialogTips: '',
        labelLeft:true,
        labelDisabled:true,
        //计划时间显示 date 具体日期，countdown 倒计时
        dateShow:'date'
      }
    },
    // 其余的为可直接写在组件标签上的属性
    placeholder: '',
    style: { width: '100%' },
    clearable: true,
    readonly: false,
    disabled: true
  },{
    // 组件的自定义配置
    __config__: {
      label: '结束日期',
      labelWidth: 0,
      changeTag: true,
      tag: 'task-end-date',
      tagIcon: 'endDate',
      field: 'endDate',
      defaultValue: '',
      layout: 'colFormItem',
      span: 24,
      // 正则校验规则
      regList: [],
      expandParams: {
        showLabel: true,
        //说明,展示在窗口右侧的说明，没有则不显示
        alert: '此控件会展示任务的计划日期',
        //提示文字,弹窗说明文字
        dialogTips: '',
        labelLeft:true,
        labelDisabled:true,
        //计划时间显示 date 具体日期，countdown 倒计时
        dateShow:'date'
      }
    },
    // 其余的为可直接写在组件标签上的属性
    placeholder: '',
    style: { width: '100%' },
    clearable: true,
    readonly: false,
    disabled: true
  },{
    // 组件的自定义配置
    __config__: {
      label: '逾期',
      labelWidth: 0,
      changeTag: true,
      tag: 'task-overdue',
      tagIcon: 'overdue',
      field: 'overdue',
      defaultValue: '',
      layout: 'colFormItem',
      span: 24,
      // 正则校验规则
      regList: [],
      expandParams: {
        showLabel: true,
        //说明,展示在窗口右侧的说明，没有则不显示
        alert: '此控件会展示任务的逾期空间',
        //提示文字,弹窗说明文字
        dialogTips: '',
        labelLeft:true,
        labelDisabled:true
      }
    },
    // 其余的为可直接写在组件标签上的属性
    placeholder: '',
    style: { width: '100%' },
    clearable: true,
    readonly: false,
    disabled: true
  },{
    // 组件的自定义配置
    __config__: {
      label: '评分',
      labelWidth: 0,
      changeTag: true,
      tag: 'task-score',
      tagIcon: 'score',
      field: 'score',
      defaultValue: '',
      layout: 'colFormItem',
      span: 24,
      // 正则校验规则
      regList: [],
      expandParams: {
        showLabel: true,
        //说明,展示在窗口右侧的说明，没有则不显示
        alert: '此控件会展示任务评分,当任务被评分后才会显示出来',
        //提示文字,弹窗说明文字
        dialogTips: '',
        labelLeft:true,
        labelDisabled:true
      }
    },
    // 其余的为可直接写在组件标签上的属性
    placeholder: '',
    style: { width: '100%' },
    clearable: true,
    readonly: false,
    disabled: true
  },{
    // 组件的自定义配置
    __config__: {
      label: '权重',
      labelWidth: 0,
      changeTag: true,
      tag: 'task-weight',
      tagIcon: 'weight',
      field: 'weight',
      defaultValue: '',
      layout: 'colFormItem',
      span: 24,
      // 正则校验规则
      regList: [],
      expandParams: {
        showLabel: true,
        //说明,展示在窗口右侧的说明，没有则不显示
        alert: '此控件会展示任务权重',
        //提示文字,弹窗说明文字
        dialogTips: '',
        labelLeft:true,
        labelDisabled:true
      }
    },
    // 其余的为可直接写在组件标签上的属性
    placeholder: '',
    style: { width: '100%' },
    clearable: true,
    readonly: false,
    disabled: true
  },
]